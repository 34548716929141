import auth from '../parse-api/auth';
import { copyVariablesByPrefix, decrypt, equals, getBaseUrl, getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from "../util/algorithm";
import { clientConfigInitializedSignal } from '../util/signal';

export const PARSE_USER_SECRET_KEY = `${process.env.REACT_APP_USER_SECRET_KEY}`;

export const ActionType = () => ({
  FlowAction: "FlowAction",
  FlowCompletionAction: "FlowCompletionAction",
  FlowCompensationAction: "FlowCompensationAction",
  OnChangeAction: "OnChangeAction",
  OnChangeActionFrontEnd: "OnChangeActionFrontEnd",
  FormSetupAction: "FormSetupAction",
  PreSearchAction: "PreSearchAction",
  PostSearchAction: "PostSearchAction",
  PreGetAction: "PreGetAction",
  PostGetAction: "PostGetAction",
  PreSaveAction: "PreSaveAction",
  PostSaveAction: "PostSaveAction",
  PreDeleteAction: "PreDeleteAction",
  PostDeleteAction: "PostDeleteAction",
  ImportDataAction: "ImportDataAction",
  PreImportDataActionFrontEnd: "PreImportDataActionFrontEnd",
  PostExportDataActionFrontEnd: "PostExportDataActionFrontEnd",
  ReportAction: "ReportAction",
  EmailAction: "EmailAction",
  SmsAction: "SmsAction",
  AddRelationAction: "AddRelationAction",
  RefreshRelationAction: "RefreshRelationAction",
  RemoveRelationAction: "RemoveRelationAction",
  PageAction: "PageAction",
  PageActionFrontEnd: "PageActionFrontEnd",
  PageSetupAction: "PageSetupAction",
  ValidationRule: "ValidationRule",
  Event: "Event",
})

const AccountStoreCore = {
  PARSE_APPLICATION_ID: ``,
  PARSE_HOST_URL: ``,
}

export const AccountStore = {
  PARSE_USER_SECRET_KEY,
  ...AccountStoreCore,
  MOBILE_BREAKPOINT: 576,
  TABLET_BREAKPOINT: 768,
  ON_CHANGE_DELAY: 300,
  ON_CHANGE_WAIT_DELAY: 500,
  ON_CHANGE_END_DELAY: 1000,
  GOOGLE_TRANSLATE: false,
  LOGIN_WITH_PASSWORD: true,
  LOGIN_WITH_GUEST: true,
  LOGIN_WITH_SESSION_TOKEN: false,
  ENABLE_AVATAR_URL:false,
  COMPRESSDATA: false,
  EMAIL_REQUIRED: false,
  SIGNUP_DISABLED: false,
}
copyVariablesByPrefix(AccountStore, process.env, 'REACT_APP_')

const decryptClientConfig = (text) => {
  let clientConfig = null;
  try {
    clientConfig = text ? decrypt(text, PARSE_USER_SECRET_KEY) || JSON.parse(text)  : null;
  } catch (e) {}
  return clientConfig;
}

export const loadClientConfig = async (reload, url) => {
  const clientConfigEncrypted = getLocalStorageItem('client_config');
  let clientConfig = decryptClientConfig(clientConfigEncrypted);
  const baseUrl = getBaseUrl();
  if (!url) url = baseUrl+'/client-config';
  const response = await fetch(url);
  if (response.ok) {
    const text = await response.text();
    console.log("client-config text", text);
    try {
      const decrypted = decryptClientConfig(text);
      if (clientConfigEncrypted !== text) {
        if (!equals(clientConfig, decrypted)) {
          console.log("client-config loading...");
          reloadAccountStore(decrypted, reload);
          setLocalStorageItem('client_config', text);
        }
      }
      clientConfigInitializedSignal.value = true;
    } catch (e) {
      console.log('decrypte error', e);
    }
  }
}

const reloadAccountStore = async (clientConfig, reload) => {
  if (!clientConfig) return false;
  if (process.env.NODE_ENV === 'development') console.log('reloadAccountStore() clientConfig', process.env.NODE_ENV, clientConfig)
  const AccountStoreOld = {...AccountStore};
  copyVariablesByPrefix(AccountStore, clientConfig, 'REACT_APP_')
  setLocalStorageItem("ENFORCE_IFRAME", AccountStore.ENFORCE_IFRAME);
  if (reload) {
    let needSignOut = false;
    for (const key in AccountStoreCore) {
      if (AccountStoreOld[key] && AccountStore[key] !== AccountStoreOld[key]) {
        console.log('AccountStore[key] !== AccountStoreOld[key]', key, AccountStore[key], AccountStoreOld[key])
        needSignOut = true;
      }
    }
    if (needSignOut) {
      removeLocalStorageItem('user_obj');
      // removeLocalStorageItem('username');
      await auth.signOutParse();
    }
  }

  clientConfigInitializedSignal.value = true;
  return true;
}

reloadAccountStore(decryptClientConfig(getLocalStorageItem('client_config'))).then(isLoaded => {
  if (!window?.AppCtrl?.passiveMode) {
    if (!isLoaded) {
      loadClientConfig(true);
    } else {
      setTimeout(() => {
        loadClientConfig(true);
      }, 20000)
    }
  }
});