const initData = {
  "data": null,
  "currId": 2,
  "formKey": "form-1",
  "formName": "Unnamed Form",
  "saved": false,
  "allActions": [],
  "allSubmits": []
}

export default initData;

const btnbgcolor = "#1C658C"

export const defaultCss = `

.sf-panel .ant-card-head-title.main-panel {
  display: flex;
  flex-flow: row;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  font-size: 16pt;
}

.sf-panel.ant-card-bordered {
  border: 0;
  margin-bottom: 10px;
  background-color: #ffffff;
  box-shadow: 0 0 5px 5px rgb(0 0 0 / 3%);
  position: relative;
  border-radius: 0px;
  box-shadow: none;
}

.sf-panel .ant-card-head {
  border: 0 none;
}

.sf-panel .ant-card-head .ant-card-head-title {
  padding: 12px 0;
}

.sf-panel .ant-row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.sf-panel .ant-form-item {
  margin-top: 10px;
  margin-bottom: 10px;
}

.sf-panel .ant-card-body {
  padding-top:  10px;
}

.sf-tabs .ant-row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.sf-tabs .tab-body {
  padding-left: 15px;
  padding-right: 15px;
}

.sf-tabspane {
  width: 100%;
}

.sf-tabspane .ant-form-item {
  margin-top: 10px;
  margin-bottom: 10px;
}

.sf-tabspane .tab-title {
  padding-bottom: 10px;
}

.sf-tabs {
  box-sizing: border-box;
  margin-bottom: 10px;
  padding: 10px;
  color: #545454;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  background: #fff;
}

.sf-tabs .ant-row.craft-form-container {
  margin-left: 0px;
  margin-right: 0px;
}

.sf-input .ant-input,.ant-input-number,.ant-input-affix-wrapper {
}

.sf-input .ant-input-group-addon {
}

.sf-date .ant-picker {
}

.sf-systemdate .ant-picker {
}

.sf-time .ant-picker {
}

.sf-textarea .ant-input {
}

.sf-button.ant-btn {
  margin-left: 5px;
  margin-right: 5px;
}

.ant-divider-horizontal.sf-divider::after,.ant-divider-horizontal.sf-divider::before {
  transform: none;
}

.sf-tabs .sf-table {
  width: 100%;
}

.sf-panel.sf-table .ant-card-body {
  padding-top: 0px;
}

.sf-panel.sf-table .ant-table-pagination.ant-pagination {
  margin-top: 8px;
  margin-bottom: 0px;
}

.sf-table .ant-table-small .ant-table-thead > tr > th {
  background-color: #fafafa;
  text-align: center;
}

.sf-table .ant-table-wrapper {
  margin-bottom:10px;
}

.sf-table table label.ant-checkbox-wrapper {
  display: flex;
  justify-content: center;
}

.sf-table table .ant-table-cell .ant-switch {
  margin-left:20px;
  margin-right:20px;
}

td.ant-table-cell.drag-visible {
  text-align: center;
}

.sf-table .row-editable {
  cursor: pointer;
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;
}

.sf-table .ant-table-cell:has(.row-editable) {
  background-color: #ffffe0;
}

.sf-table .ant-table-cell.ant-table-cell-row-hover:has(.row-editable) {
  background-color: #ffffca;
}

.sf-table .row-editing {
  cursor: pointer;
  display: flex;
}

.sf-table .table-cell-textarea {
  white-space: pre-wrap;
  overflow: auto;
  min-height: 1rem;
}

.sf-table .row-editable.table-cell-inputnumber {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sf-table .row-editable.table-cell-color {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sf-table ant-table > tr > td.ant-table-cell {
    position: relative;
}

.sf-slider .ant-slider {
  margin-left: auto;
  margin-right: auto;
}

.sf-upload .ant-progress {
  margin-bottom: 0px;
}

.sf-upload .ant-upload-list-item .ant-btn {
  margin-bottom: 0px;
}

.sf-upload .ant-upload-list-item-actions {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sf-upload .ant-upload-list-item-actions a {
  margin-top: 4px;
}

.sf-upload div[role=alert] {
  margin-top: 15px;
}

.sf-select.in-table {
  height: 16px;
  line-height: 16px;
  margin: 1px 2px;
}

.icon-text{
  padding-bottom: 12px !important;
  padding-left: 30px !important;
  display:inline !important;
  vertical-align: middle;
}

.select-icon {
  vertical-align: middle !important;
  position: absolute;
}

.ant-divider-horizontal.sf-divider span:first-child {
  padding-left: 10px;
  padding-right: 10px;
}

.sf-checkbox .ant-checkbox-inner {
}

.sf-tranfer .ant-transfer-list-search-action {
  top:8px
}

.sf-tranfer .ant-checkbox-inner {
}

.sf-transfer .ant-transfer-list-search-action {
  top: 6px;
}

.sf-comment .ant-list-items {
  margin:15px;
}

.sf-markdown blockquote {
  padding: 10px 20px;
  margin: 0 0 20px;
  font-size: 17.5px;
  border-left: 5px solid #eee;
}

.sf-comment blockquote {
  padding: 10px 20px;
  margin: 0 0 20px;
  font-size: 17.5px;
  border-left: 5px solid #eee;
}

.sf-comment .ant-input {
}

.sf-comment .ant-progress {
  margin-bottom: 0px;
}

.sf-comment .ant-upload-list-item .ant-btn {
  margin-bottom: 0px;
}

.sf-comment .ant-upload-list-item-actions {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sf-comment .ant-upload-list-item-actions a {
  margin-top: 4px;
}

.sf-comment div[role=alert] {
  margin-top: 15px;
}

.sf-signature .signature {
  display: flex;
  align-items: end;
  justify-content: flex-start;
  position: relative;
}

.sf-signature .signature .image {
  border: 1px solid rgb(217, 217, 217);
  margin-bottom: 3px;
  margin-top: 3px;
  margin-left: 0px;
  margin-right: 0px;
}

.sf-signature .signature .image.ant-empty {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sf-signature .signature button {
  margin: 3px;
}

.sf-signature canvas {
  border: 1px solid rgb(217, 217, 217);
  margin: 3px;
}

.sf-signature .ant-modal-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5px;
  padding-bottom: 5px;
}

.sf-signature .ant-modal-body {
  padding: 5px;
}

.sf-signature .ant-modal-footer .ant-btn {
  width: 50px;
  margin: 0px 1px;
  border-radius: 0px;
  background-color: #eee;
}

.form-main-panel {
  display: flex;
  justify-content: center;
}

.sf-coor-btn {
  display: inline-flex;
  vertical-align: middle;
  cursor: pointer;
}

.leaflet-container {
  height: 400px;
  width: 400px;
}

.ant-btn.delete-btn {
  background-color: #f44336 !important;
  color: #ffffff !important;
}

.ant-btn.delete-btn:hover {
  background: #f44336 !important;
  filter: brightness(150%);
  color: #ffffff !important;
}

.ant-btn.create-btn {
  background-color: #038fde !important;
  color: #ffffff !important;
}

.ant-btn.create-btn:hover {
  background: ${btnbgcolor} !important;
  color: #ffffff !important;
}

.ant-btn.save-btn {
  background-color: #038fde !important;
  color: #ffffff !important;
}

.ant-btn.save-btn:hover {
  background: ${btnbgcolor} !important;
  color: #ffffff !important;
}

.ant-btn.action-btn {
  background-color: #038fde !important;
  color: #ffffff !important;
}

.ant-btn.action-btn:hover {
  background: ${btnbgcolor} !important;
  color: #ffffff !important;
}

.ant-btn.add-comment {
  background-color: #038fde !important;
  color: #ffffff !important;
}

.ant-btn.add-comment:hover {
  background: ${btnbgcolor} !important;
  color: #ffffff !important;
}

.ant-btn.report-open-btn {
  background-color: #038fde !important;
  color: #ffffff !important;
}

.ant-btn.report-open-btn:hover {
  background: ${btnbgcolor} !important;
  color: #ffffff !important;
}

.flow-timeline {
  max-height: 300px !important;
  overflow-y: scroll;
  scrollbar-width: none;
  padding-right: 15px;
  padding-bottom: 0px;
}
.flow-timeline.endded {
  padding-bottom: 0px;
}
.flow-timeline .ant-timeline-item {
  padding-bottom: 15px;
}
.flow-timeline::-webkit-scrollbar {
  width: 3px;
  background: #fff;
}
.flow-timeline::-webkit-scrollbar-thumb {
  background: #c5c5c5;
}

.sf-actionbutton {
  display: flex;
  align-items: center;
  justify-content: end;
}

.sf-actionbutton.align-start {
  justify-content: start;
}

.sf-actionbutton button {
  margin-bottom: 0px;
  margin-right: 0px;
}

.sf-group-list .ant-row.ant-form-item {
  margin-top: 0px;
  margin-bottom: 0px;
}

.sf-group-list .craft-form-container {
  min-height: 25px;
}

.sf-group-list .sf-button.delete {
  margin-bottom: 0px;
}

.ant-col-12 .ant-col.ant-form-item-control {
  padding-left: 8px;
}

.sf-table .expanded-row label {
  font-weight: bold;
}

@media (max-width: 576px) {
  .ant-form-item-label > label {
      font-size: 12px;
      font-weight: 500;
  }
}

.ant-col.hidden {
  display: none;
}

.ant-col.no-label .ant-form-item-label {
  display: none;
}

.craft-form-frame-panel .ant-col.hidden {
  display: block !important;
  background-color: lightgrey;
}

.sf-panel.no-title > .ant-card-head {
  display: none;
}

@media (min-width: 576px) {
  .sf-panel .ant-col {
    padding-left: 18px;
    padding-right: 18px;
  }
}

.sf-divider {
  position: relative;
}

`