import { useEditor, useNode } from "@craftjs/core";
import { Col, Empty, InputNumber, Popover, Switch, Tag } from "antd";
import { Form, Input } from "components/Form";
import React, { useEffect, useRef, useState } from "react";
import { SiPowerpages } from "react-icons/si";
import ReactJson from "react-json-view";
import IntlMessages from "util/IntlMessages";
import CircularProgress from "../../../../components/CircularProgress";
import { Select } from "../../../../components/Form";
import JsonTextArea from "../../../../components/Form/JsonTextArea";
import { AccountStore } from "../../../../constants/Account";
import { useLbl } from "../../../../lngProvider";
import { pageApi } from "../../../../parse-api";
import { compare, equals, getPageIdentity, isEmpty, tryAssignJson, tryParseJson } from "../../../../util/algorithm";
import PageRuntimeImpl from "../../PageSetup/runtimeimpl";
import {
  colSls, convertRules, convertStyleStr, deleteButton, EditorCollector, getId, getParentName, NodeCollector, registerComponent, SfLayoutSetting, SfPanelContext, shouldUpdate
} from "./common";
import { settingsSignal } from "../../../../util/signal";

const SfPageSetting = () => {
  const [allPages, setAllPages] = useState([]);
  const [pageOptions, setPageOptions] = useState([]);
  const { parents } = useEditor(EditorCollector);
  const form = Form.useFormInstance();
  const pageKey = Form.useWatch('pageKey', form);
  const config = Form.useWatch('config', form);
  const itemKey = Form.useWatch('itemKey', form);
  const [myPageKey, setMyPageKey] = useState()
  const [myItemKey, setMyItemKey] = useState()

  const mounted = useRef();
  const optionsSorter = (a, b) => compare(a.label, b.label)
  useEffect(()=>{
    mounted.current = true;
    const fetchData = async () => {
      if (mounted.current) setAllPages(await pageApi.loadSystemPage());
    }
    fetchData();
    return ()=>{
      mounted.current = false;
    }
  },[])

  useEffect(() => {
      if (mounted.current) setPageOptions(allPages.map(p => ({value: p.pageKey, label: p.pageKey})).sort(optionsSorter));
  }, [allPages])

  useEffect(() => {
    if (pageKey && (myPageKey !== pageKey || myItemKey !== itemKey)) {
      for (const p of allPages) {
        if (p.pageKey === pageKey && p.extraParams.configData) {
          const myConfig = tryAssignJson(p.extraParams.configData, config);
          myConfig.itemKey = itemKey;
          const value = JSON.stringify(myConfig, null, 2);
          form.setFields([
            { name: ["config"], value }
          ]);
          setMyPageKey(pageKey);
          setMyItemKey(itemKey);
          break;
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageKey, itemKey, allPages])

  return (
    <>
      <Form.Item name="title" label="title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="pageKey" label="pageKey" >
        <Select className="item-property" placeholder="page" allowClear showSearch
          options={pageOptions}/>
      </Form.Item>
      <Form.Item name="isPreview" label="preview" valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <Form.Item name="config" label="config" className="config"
        dependencies={['pageKey']}
        labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} >
        <JsonTextArea/>
      </Form.Item>
      <Form.Item name="hideVersion" label="hide ver." valuePropName="checked">
        <Switch className="item-property"/>
      </Form.Item>
      <Form.Item name="parentkeys" label="parents">
        <Select className="item-property" options={parents} mode="multiple" />
      </Form.Item>
      <Form.Item name="readOnly" label="read-only" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="disabled" label="disabled" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="hidden" label="hidden" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="hideInTable" label="hide col." valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="hideInMobile" label="hide mob." valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="tableColWidth" label="col width">
        <InputNumber min="1" className="item-property" />
      </Form.Item>
      <Form.Item name="tableColTitle" label="col title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="isSelectKey" label="select key" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <SfLayoutSetting />
    </>
  )
}

export const SfPage = ({ ...props }) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  return (
    <SfpPage doRef={ref => connect(drag(ref))} style={style} dbtn={dbtn} {...props} />
  )
}

export const MyPageEditor = ({
  readOnly,
  disabled,
  value,
  onChange,
  pageKey,
  hideVersion,
  isPreview,
  hidden,
  designer,
  parentkeys,
  parentValues,
  config,
  itemKey,
}) => {
  const [loading, setLoading] = useState(false);
  const [pathInfo, setPathInfo] = useState({
    path: null,
    state: {},
    history: []
  });
  const onMyChangeRef = useRef();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      if (pageKey) {
        const pg = isPreview ? 'preview' : 'pg';
        const p = `/system/prt/${pg}/${pageKey}`;
        setPathInfo({...pathInfo, path: p});
      } else {
        setPathInfo({...pathInfo, path: null});
      }
      setLoading(false);
    }
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageKey, isPreview])

  const dispatchReplace = (path, state) => {
    setPathInfo((prev) => ({...prev, path, state: state || {}}));
  }

  const dispatchGoBack = () => {
    if (pathInfo.history.length > 0) {
      setPathInfo((prev) => {
        const path = prev.history[prev.history.length - 1]
        const history = prev.history.slice(0, prev.history.length - 1)
        return {...prev, history, path, state: {}}
      });
    }
  }

  const dispatchPush = (path, state) => {
    setPathInfo((prev) => {
      const history = [...prev.history, prev.path];
      return {path, history, state: state || {}}
    });
  }

  const onMyChange = (value) => {
    if (onMyChangeRef.current) {
      clearTimeout(onMyChangeRef.current);
      onMyChangeRef.current = null;
    }
    onMyChangeRef.current = setTimeout(() => {
      if (onChange) onChange(value)
    }, AccountStore.ON_CHANGE_DELAY)
  }

  if (!hidden || designer) {
    if (loading) {
      return (
        <CircularProgress/>
      );
    } else if (!isEmpty(pathInfo.path) && pageKey) {
      const identity = getPageIdentity(pathInfo.path)
      let myConfig = tryParseJson(config);
      if (!myConfig) myConfig = {}
      myConfig.itemKey = itemKey;
      return (
        <PageRuntimeImpl
          pageKey={identity?.pageKey}
          type={identity?.type}
          hideVersion={hideVersion}
          dispatchReplace={dispatchReplace}
          dispatchGoBack={dispatchGoBack}
          dispatchPush={dispatchPush}
          onPageDataChange={onMyChange}
          initPageState={value}
          readOnly={readOnly}
          disabled={disabled}
          designer={designer}
          pathInfo={pathInfo}
          parentkeys={parentkeys}
          parentValues={parentValues}
          config={myConfig}
        />
      );
    } else {
      return (
        <Empty />
      );
    }
  } else {
    return null;
  }
};


export const SfpPage = ({
  doRef, form, condistyles, className, style, dbtn,
  hidden, hideInTable, tableColWidth, tableColTitle,
  itemKey, title, labelAlign, labelColStr, rules, volitate, skipcopy,
  inputref, styleStr, children, isSelectKey, parentkeys, config, ...otherProps }) => {
  const sls = convertStyleStr(styleStr);
  const lcs = convertStyleStr(labelColStr);
  const [fxr, setFxr] = useState({});
  const [parentValues, setParentValues] = useState([]);
  const newRules = convertRules(rules, 'object');
  const mounted = useRef();

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    }
  }, []);

  const refreshOptions = async (form, dependencies, name, args) => {
    if (form && dependencies && dependencies.length > 0) {
      const pValues = dependencies.map(d => form.getFieldValue(d));
      if (!equals(pValues, parentValues)) {
        if (mounted.current) setParentValues(pValues);
      }
    }
  }

  return (
    <SfPanelContext.Consumer>
      {ctx => {
        const name = ctx ? [...ctx.name, itemKey] : [itemKey];
        const parentname = getParentName(ctx);
        const dependencies = parentkeys ? parentkeys.map(p => {
          if (p.startsWith("main.")) {
            return [p.substr(5)];
          } else {
            return [...parentname, p];
          }
        }) : [];
        const doRefresh = (...args) => refreshOptions(form, dependencies, name, args);
        const fx = shouldUpdate({condistyles, ctx, form, style, setFxr, doRefresh});
        return (
          <Col ref={doRef} className={className} style={fxr.style || style} {...colSls(otherProps)}>
            <Form.Item name={name} label={title} placeholder={otherProps.placeholder}
               shouldUpdate={fx} hidden={fxr.hidden || (!doRef && hidden)} rules={fxr.hidden || hidden || otherProps.disabled ? null : newRules}
              labelAlign={labelAlign} labelCol={lcs} wrap>
              <MyPageEditor
                style={sls} disabled={fxr.disabled || otherProps.disabled}
                hidden={hidden} designer={!!doRef}
                parentkeys={parentkeys}
                config={config}
                itemKey={itemKey}
                parentValues={parentValues}
                {...otherProps}
              />
            </Form.Item>
            {dbtn}
          </Col>
        )
      }}
    </SfPanelContext.Consumer>
  );
}
const MyPageRenderer = ({
  value,
  record,
  index,
  form,
  tableKey,
  onRowChange,
  editInTable,
  itemKey,
  mask,
  tableColWidth,
  disabled,
}) => {
  const { locale } = settingsSignal;
  const lbl = useLbl(locale);
  return (
    <span className="cell-content clickable">
      {value && (
        <Popover content={<ReactJson src={value} />}>
          <Tag className="row-status">{lbl('system.form.object', 'Object')}</Tag>
        </Popover>
      )}
    </span>
  );
};

SfpPage.render = ({ editInTable, itemKey, mask, tableColWidth, disabled }, form, tableKey, onRowChange) => (value, record, index) => {
  const props = {value, record, index, form, tableKey, onRowChange, editInTable, itemKey, mask, tableColWidth, disabled};
  return <MyPageRenderer {...props}/>
}

SfPage.craft = {
  displayName: "Page",
  related: {
    settings: SfPageSetting
  }
}

SfPage.validate = (props, {parents, container, extraParams}) => {
  if (container.type.resolvedName === "SfMainPanel") {
    extraParams.dataClassConfig.columns.push({
      dataIndex: props.itemKey,
      index: Object.keys(parents).indexOf(props.itemKey),
      type: "json",
      title: props.tableColTitle || props.title,
      width: props.tableColWidth,
      sortable: true,
      editable: false,
      permission: props.permission,
      volitate: props.volitate,
      hiddenForReadOnly: props.hideInTable,
    });
    if (props.isSelectKey) {
      extraParams.selectConfig.optionTypes.push(props.itemKey);
    }
  }
}

SfPage.isSearchable = true;
SfPage.isRulable = true;

registerComponent({
  key:"SfPage",
  component: SfPage,
  runtimeComponent: SfpPage,
  template: <SfPage itemKey={getId('page')} className="sf-page wrap"
            title={"Page"} span={24} labelColStr="span:6" hideVersion={true}/>,
  title: <IntlMessages id="system.form.library.page" text="Page" />,
  icon: <SiPowerpages className="react-icons icon-bi"/>,
  type: "Component",
  sequence: 28,
});

export default SfPage;