import { Alert, Button, Checkbox, Col, DatePicker, InputNumber, message, Modal, Row, Select, Space, Switch, Table, Tag, Tooltip } from "antd";
import { Form } from "components/Form";

import moment from "moment";
import { dataExplorer, flowApi, formApi } from "parse-api";
import React, { useEffect, useRef, useState } from "react";

import { GoAlert } from "react-icons/go";
import IntlMessages from "util/IntlMessages";
import CircularProgress from "../../../components/CircularProgress";
import { AccountStore } from "../../../constants/Account";
import { useLbl } from "../../../lngProvider";
import { compare, delay, displayError, equals, flattenJSON, getArraysIntersection, getArraysSubtraction, getArraysUnion, getBaseUrl, getPropsMapping, isDate, log, replacePath, toAuthUserObj } from "../../../util/algorithm";
import { SystemSelect } from "../FormSetup/components/SfSelect";
import SearchTool from "../Widgets/DataEntryTools";
import { defaultPermissions, getRenderByProps } from "./components";
import { SfpUserAvatar } from "./components/SfUserAvatar";
import { authSignal, commonSignal, settingsSignal } from "../../../util/signal";

export const FlowDataModal = ({
  visible, onOk, onCancel,
  isPreview, flow, objectId, flowData, flowRecord }) => {
  const [form] = Form.useForm();
  const [tasks, setTasks] = useState([]);
  const mounted = useRef();
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    }
  }, [])
  const onFinish = async () => {
    try {
      const values = form.getFieldsValue(true);
      const notification = !!values.notification;
      await formApi.updateSystemFormFlowData(isPreview, flowRecord.formKey, objectId, flow, values, notification);
      if (onOk) onOk();
    } catch (e) {
      message.error(displayError(e));
    }
  }
  useEffect(() => {
    const fetchData = async () => {
      if (visible && objectId) {
        try {
          let val = { ...flowData };
          val = JSON.parse(JSON.stringify(val), (key, value) => {
            if (isDate(value)) {
              return moment(value);
            } else {
              return value;
            }
          })
          form.resetFields();
          form.setFieldsValue(val);
        } catch (e) {
          log("set record value error", e);
          message.error(displayError(e));
        }
      }
    }
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectId, visible, flowData]);

  useEffect(() => {
    if (flowRecord?.extraParams?.taskMap) {
      const taskMap = flowRecord.extraParams.taskMap;
      const tasks = Object.keys(taskMap);
      if (mounted.current) setTasks(tasks.map(t=>({value:t, label:taskMap[t].label})).sort((a, b) => compare(a.label, b.label)));
    }
  }, [flowRecord])

  return (
    <Modal width={600} title={<IntlMessages id={`flow.admin`} text={"Admin"} />} className="widget-dataentry-modal"
      visible={visible} onOk={onFinish} onCancel={onCancel}>
      <Form form={form} name={"AdminUpdateFormFlowData"} colon={false}>
        <Form.Item name="task" label="Task" labelCol={{ span: 6 }} >
          <Select options={tasks} />
        </Form.Item>
        <Form.Item name="assignee" label="Assignee" labelCol={{ span: 6 }} >
          <SystemSelect selectkey="User" isUseCode={true} showSearch allowClear/>
        </Form.Item>
        <Form.Item name="candidates" label="Candidates" labelCol={{ span: 6 }} >
          <SystemSelect selectkey="User" isUseCode={true} mode={"multiple"} showSearch allowClear/>
        </Form.Item>
        <Form.Item name="others" label="Others" labelCol={{ span: 6 }} >
          <SystemSelect selectkey="User" isUseCode={true} mode={"multiple"} showSearch allowClear/>
        </Form.Item>
        <Form.Item name="priority" label="Priority" labelCol={{ span: 6 }} >
          <InputNumber />
        </Form.Item>
        <Form.Item name="dueDate" label="Due Date" labelCol={{ span: 6 }} >
          <DatePicker showTime/>
        </Form.Item>
        <Form.Item name="notification" label="Notification" labelCol={{ span: 6 }} valuePropName="checked" >
          <Switch/>
        </Form.Item>
      </Form>
    </Modal>
  )
}

const sortChanges = (a, b) => {
  const aHasDot = a.key.indexOf(".") !== -1;
  const bHasDot = b.key.indexOf(".") !== -1;
  if (aHasDot === bHasDot) {
    return compare(a.key, b.key);
  } else if (aHasDot) {
    return 1;
  } else {
    return -1;
  }
};

const SKIP_COLUMNS_CLASS = [
  "ButtonArea", "Container", "EditPad", "RepeatedArea", "SfAdvanceSearch",
  "SfButton", "SfCanvas", "SfComment", "SfConditionalStyle", "SfDivider",
  "SfMainPanel", "SfRule", "SfSimpleSearch", "SfTabs",  "SfUserAvatar",
  "SfSubmit"];
const SYSTEM_FIELDS = ['flow.task', 'flow.assignee', 'flow.candidates', 'flow.others', 'flow.dueDate'];
const UPLOAD_ATTRS = /(name|type)$/;

export const DataChangeView = ({
  formRecord, flowRecord, record, oldRecord,
}) => {
  const [changes, setsChanges] = useState([]);
  const [columns, setColumns] = useState();
  const mounted = useRef();
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    }
  }, [])
  useEffect(() => {
    const newList = flattenJSON(record);
    const oldList = flattenJSON(oldRecord);
    const propsMapping = getPropsMapping(formRecord.data);
    const addedList = [];
    const deletedList = [];
    const changedList = [];
    compileChanges(newList, propsMapping, oldList, changedList, addedList);
    compileDelete(oldList, newList, propsMapping, deletedList);
    const allChanges = [...deletedList, ...changedList, ...addedList].filter(filterChanges)
    allChanges.sort(sortChanges);
    const titleFilters = compileTitleFilter(allChanges);
    if (mounted.current) setsChanges(allChanges);
    const columns = [
      {
        title: <IntlMessages id="form.history.field.key" text="Key"/>,
        dataIndex: "key",
      },
      {
        title: <IntlMessages id="form.history.field.name" text="Field"/>,
        dataIndex: "key",
        filters: titleFilters,
        filterSearch: true,
        onFilter: (value, record) => record.title.match(new RegExp(value,'i')),
        render: (key, record) => {
          return record.title;
        }
      },
      {
        title: <IntlMessages id="form.history.field.deleted" text="Deleted"/>,
        dataIndex: "deleted",
        render: (value, record, index) => {
          const pkey = record.key.replace(/\.\d+$/, '').replace(/\[[^\]]+\]/, '');
          const props = propsMapping[pkey];
          const renderer = props ? getRenderByProps(props) : null;
          if (renderer) {
            return renderer(value, record, index);
          } else {
            return renderGeneralText(value);
          }
        }
      },
      {
        title: <IntlMessages id="form.history.field.added" text="Added"/>,
        dataIndex: "added",
        render: (value, record, index) => {
          const pkey = record.key.replace(/\.\d+$/, '').replace(/\[[^\]]+\]/, '');
          const props = propsMapping[pkey];
          const renderer = props ? getRenderByProps(props) : null;
          if (renderer) {
            return renderer(value, record, index);
          } else {
            return renderGeneralText(value);
          }
        }
      },
    ];
    if (mounted.current) setColumns(columns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[formRecord, record, oldRecord])
  return (
    <Table
    className={"form-data-change-view"}
    columns={columns}
    dataSource={changes}
    pagination={{position: ['bottomLeft']}}
    size="small"
    />)
}

export const FormHistoryModal = ({
  messageApi, visible, onOk, onCancel,
  isPreview, formKey, formView, objectId, formRecord, flowRecord }) => {
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const mounted = useRef();
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    }
  }, [])
  const columns = [
    {
      title: <IntlMessages id="form.history.version" text="Version"/>,
      dataIndex: "versionStamp",
    },
    {
      title: <IntlMessages id="form.history.user" text="User"/>,
      dataIndex: "user",
      render: (userId) => <SfpUserAvatar userId={userId}/>
    },
    {
      title: <IntlMessages id="form.history.date-time" text="Date Time"/>,
      dataIndex: "updatedAt",
      render: (updatedAt) => updatedAt?.format("YYYY-MM-DD LT"),
    }
  ]
  const expandable = {
    indentSize: 0,
    expandedRowKeys,
    expandedRowRender : (record, index, indent, expended) => {
      if (expended) {
        return <DataChangeView formRecord={formRecord} flowRecord={flowRecord} record={record.data} oldRecord={record.oldData}/>
      } else {
        return null;
      }
    },
    expandIcon: ({ expanded, onExpand, record, expandable }) => {
      return <Tag  className="flow-status" onClick={e => {
        onExpand(record, e)
        if (!expanded) {
          if (mounted.current) setExpandedRowKeys([record.key]);
        } else {
          if (mounted.current) setExpandedRowKeys([]);
        }
      }}><IntlMessages id={`form.show-changes`} text={"Show Changes"}/></Tag>
    }
  }
  useEffect(() => {
    const fetchData = async () => {
      if (mounted.current) setLoading(true);
      if (visible && formKey && objectId) {
        try {
          let list = await formApi.getSystemFormDataHistory(isPreview, formKey, formView, objectId);
          log("get form history", list);
          list = list?.sort((a,b) => compare(a.versionStamp,b.versionStamp));
          list.forEach((l, index) => {
            if (index > 0) {
              l.oldData = list[index - 1].data;
            }
            l.key = index;
          });
          list = list.reverse();
          if (mounted.current) setHistory(list);
          if (mounted.current) setLoading(false);
        } catch (e) {
          log("get form history error", e);
          if (mounted.current) setLoading(false);
          message.error(displayError(e));
        }
      }
    }
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectId, visible, formKey, isPreview]);

  return (
    <Modal width={800} title={<IntlMessages id={`form.history`} text={"History"} />} className="form-history-modal"
      visible={visible} onOk={onOk} onCancel={onCancel} cancelButtonProps={{style: {display: "none"}}}  centered>
      {!loading && <Table
        className="form-change-history"
        scroll={{ x: true }}
        dataSource={history}
        columns={columns}
        expandable={expandable}
        pagination={{pageSize: 3}}
      />}
      {loading && <CircularProgress/>}
    </Modal>
  )
}

export const SelectFormSearch = ({
  messageApi, visible, onOk, onCancel, getContainer,
  isPreview, flow, formKey, formView, flowParent, multiple, defaultTitle }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [dataClassConfig, setDataClassConfig] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedRecords, setSelectedRecords] = useState({});
  const [title, setTitle] = useState(defaultTitle ? defaultTitle : "Select");
  const mounted = useRef();
  const locale = settingsSignal.locale;
  const lbl = useLbl(locale);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    }
  },[]);

  useEffect(() => {
    if (visible) {
      if (mounted.current) setSelectedIds([]);
      if (mounted.current) setSelectedRecords({});
      if (mounted.current) setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 100)
    }
  },[visible])

  useEffect(() => {
    const fetchData = async () => {
      if (mounted.current) setIsLoading(true);
      if (mounted.current) await doLoad();
      if (mounted.current) setIsLoading(false);
    }
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formKey, flow]);

  const doLoad = async () => {
    try {
      let dataClassConfig = null;
      const lFormRecord = await formApi.getSystemForm(isPreview, formKey);
      if (flow && lFormRecord) {
        dataClassConfig = await flowApi.registerDataClassConfig(isPreview, flow);
      } else if (lFormRecord) {
        dataClassConfig = await formApi.registerDataClassConfig(isPreview, formKey, formView);
      }
      if (dataClassConfig) {
        if (mounted) setDataClassConfig(dataClassConfig);
        if (mounted) setTitle(dataClassConfig.displayName);
      } else {
        throw new Error("Data not found!");
      }
    } catch (e) {
      message.error(displayError(e));
    }
  }

  const uncheckAll = (selected) => {
    if (selected) Object.values(selected).forEach(r => {
      if (r) {
        r.checked = false;
      }
    })
  }

  const setSelection = (checked, multiple, record) => {
    record.checked = checked;
    if (multiple) {
      setMultipleSelection(selectedRecords, checked, mounted, setSelectedIds, record, setSelectedRecords);
    } else {
      setSingleSelection(checked, mounted, setSelectedIds, record, setSelectedRecords, uncheckAll);
    }
  }

  const onDataChanged = () => {
    if (mounted.current) setSelectedIds([]);
    if (mounted.current) setSelectedRecords({});
  }

  const SelectedCheckbox = ({record}) => {
    const isChecked = record.checked;
    return <div className="table-action-centered clickable"><Checkbox onChange={(e)=>setSelection(e.target.checked, multiple, record)} checked={isChecked}/></div>;
  }
  const getSelectAction = (record) => <SelectedCheckbox record={record}/>

  const onFinish = async () => {
    try {
      log("selectedIds", selectedIds, selectedRecords);
      if (selectedIds.length === 0) {
        message.error(lbl("system.form.select.no-record-selected", "Please selected a record!"));
      } else {
        if (onOk) onOk(selectedIds);
      }
    } catch (e) {
      message.error(displayError(e));
    }
  }

  return (
    <Modal width={"80%"} centered title={<IntlMessages id={`form.${formKey}.select`} text={"Select "+title}/>} className="widget-dataentry-modal"
      visible={visible} onOk={onFinish} onCancel={onCancel} getContainer={getContainer}>
      <Row>
        <Col span="24" >
          {!isLoading && dataClassConfig && (
            <div className="">
              <SearchTool
                draggable={false}
                resizable={false}
                formKey={formKey}
                messageApi={messageApi}
                dataClassConfig={dataClassConfig}
                doExtAdd={null}
                doExtEdit={null}
                doExtView={null}
                doExtDelete={null}
                getExtAction={getSelectAction}
                forceShowAction={true}
                doExtExport={null}
                flowParent={flowParent}
                defaultPageSize={5}
                onDataChanged={onDataChanged}
              />
            </div>
          )}
        </Col>
      </Row>
    </Modal>
  )
}

export const FormRuntimeSearchImpl = ({
    match, location,
    apiRef, itemKey, keepSearchState, flow, type, formKey, formView, flowParent, dispatchReplace, nopaging,
    displaymode, paginationPosition, showTotal, showTitle, disabled, extAction, isMaxContent, sticky,
    extParams, extSort, extDescending, extReload, isEnableAdminAllowed,
    onBeforeSearch, isComponent }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [dataClassConfig, setDataClassConfig] = useState();
  const [formRecord, setFormRecord] = useState();
  const [viewPermissions, setViewPermissions] = useState();
  const [flowRecord, setFlowRecord] = useState();
  const [flowData, setFlowData] = useState();
  const [flowDataId, setFlowDataId] = useState();
  const [flowModalVisible, setFlowModalVisible] = useState(false);
  const [formHistoryId, setFormHistoryId] = useState();
  const [formHistoryModalVisible, setFormHistoryModalVisible] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [isReportReady, setIsReportReady] = useState(false);
  const [isEnableAdmin, setIsEnableAdmin] = useState(false);
  const [isFormAdmin, setIsFormAdmin] = useState(false);
  const [isFlowAdmin, setIsFlowAdmin] = useState(false);
  const [isExportEnabled, setIsExportEnabled] = useState(false);
  const [reportLink, setReportLink] = useState();
  const { authUser } = authSignal;
  const authUserObj = toAuthUserObj(authUser);
  const [hasPermission, setHasPermission] = useState(true);
  const [pageNotFound, setPageNotFound] = useState(false);
  const [locReload, setLocReload] = useState(1);
  const { width } = commonSignal;
  const mounted = useRef();

  useEffect(() => {
    mounted.current = true;
    log('FormRuntimeSearchImpl match location', {match, location})
    return () => {
      mounted.current = false;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    const fetchData = async () => {
      if (mounted.current) {
        if (mounted.current) setIsLoading(true);
        await doLoad();
        if (mounted.current) setIsLoading(false);
      }
    }
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formKey, flow]);

  useEffect(() => {
    if (mounted.current) setIsLoading(true);
    delay(500).then(() => {
      if (mounted.current) setIsLoading(false);
    })
  }, [isEnableAdmin])

  const doLoad = async () => {
    try {
      const isPreview = type === "preview";
      let dataClassConfig = null;
      const lFormRecord = await formApi.getSystemForm(isPreview, formKey);
      const exportTemplateMap = lFormRecord?.extraParams?.exportTemplate;
      const exportTemplate = exportTemplateMap ? exportTemplateMap[formView] : null;
      if (mounted.current) setIsExportEnabled(!!exportTemplate);
      if (mounted.current) setFormRecord(lFormRecord);
      if (lFormRecord?.admins && lFormRecord.admins.indexOf(authUserObj.username) !== -1) {
        if (mounted.current) setIsFormAdmin(true);
      } else if (lFormRecord?.adminRoles && getArraysIntersection(lFormRecord.adminRoles, authUserObj.roles).length > 0) {
        if (mounted.current) setIsFormAdmin(true);
      } else {
        if (mounted.current) setIsFormAdmin(false);
      }
      if (flow && lFormRecord) {
        dataClassConfig = await flowApi.registerDataClassConfig(isPreview, flow);
        const lFlowRecord = await flowApi.getSystemFlow(isPreview, flow);
        const startView = lFlowRecord.extraParams?.taskMap?.["start"].view;
        if (mounted.current) setFlowRecord(lFlowRecord);
        const lViewPermissions = lFormRecord.extraParams?.viewPermissions ? lFormRecord.extraParams.viewPermissions[startView] : null;
        const viewPermissions = lViewPermissions ? [...lViewPermissions, ...defaultPermissions] : defaultPermissions;
        if (mounted.current) setViewPermissions(viewPermissions);
        if (lFlowRecord?.admins && lFlowRecord.admins.indexOf(authUserObj.username) !== -1) {
          if (mounted.current) setIsFlowAdmin(true);
        } else if (lFlowRecord?.adminRoles && getArraysIntersection(lFlowRecord.adminRoles, authUserObj.roles).length > 0) {
          if (mounted.current) setIsFlowAdmin(true);
        } else {
          if (mounted.current) setIsFlowAdmin(false);
        }
      } else if (lFormRecord) {
        dataClassConfig = await formApi.registerDataClassConfig(isPreview, formKey, formView);
        const lViewPermissions = lFormRecord.extraParams?.viewPermissions ? lFormRecord.extraParams.viewPermissions[formView] : null;
        const viewPermissions = lViewPermissions ? [...lViewPermissions, ...defaultPermissions] : defaultPermissions;
        if (mounted.current) setViewPermissions(viewPermissions);
      }
      if (dataClassConfig) {
        const hasPermission = getArraysIntersection(dataClassConfig.reads, authUserObj.roles).length > 0;
        if (mounted.current) setHasPermission(hasPermission)
        if (mounted.current) setDataClassConfig(dataClassConfig);
      } else {
        if (mounted.current) setPageNotFound(true);
      }
    } catch (e) {
      message.error(displayError(e));
    }
  }

  const doReload = () => {
    setLocReload(val => val + 1)
  }

  const doExtAdd = (e, pagination) => {
    try {
      let newPath = null;
      if (flow) {
        if (flowRecord) {
          const taskView = flowRecord.extraParams?.taskMap?.["start"].view;
          const params = { flow, formKey: flowRecord.formKey, formView: taskView, type, flowParent: null };
          newPath = replacePath(match.path, params).replace("rts", "rt")+(flowParent ? "/flowParent/"+flowParent : "");
        }
      } else {
        const params = { flow, formKey, formView, type };
        newPath = replacePath(match.path, params).replace("rts", "rt");
      }
      if (newPath) {
        if(e?.ctrlKey || e?.metaKey) {
          window.open(getBaseUrl()+newPath.replace("/flow/", '/wrts/preview/'));
        } else {
          dispatchReplace(newPath, {pagination});
        }
      }
    } catch (e) {
      log(e);
      message.error(displayError(e));
    }
  }

  const getEditUrl = (record, e) => {
    e?.preventDefault();
    e?.stopPropagation();
    let editUrl = null;
    if (flow) {
      if (flowRecord && flowRecord.flowKey === record.flow?.flowKey) {
        const task = record.flow.task;
        const taskView = flowRecord.extraParams?.taskMap[task]?.view;
        if (taskView) {
          const params = { flow, formKey: flowRecord.formKey, formView: taskView, type, flowParent: null };
          editUrl = replacePath(match.path, params).replace("rts", "rt")+"/"+record.objectId+(flowParent ? "/fp" : "");
        } else {
          log("taskView not defined");
        }
      }
    } else {
      const params = { flow, formKey, formView, type };
      editUrl = replacePath(match.path, params).replace("rts", "rt")+"/"+record.objectId;
    }
    return editUrl;
  }

  const doExtEdit = (record, e, pagination) => {
    const editUrl = getEditUrl(record, e);
    if (editUrl) {
      if(e?.ctrlKey || e?.metaKey) {
        window.open(getBaseUrl()+editUrl);
      } else {
        dispatchReplace(editUrl, {pagination});
      }
    }
  }

  const doExtView = (record, e, pagination) => {
    const editUrl = getEditUrl(record, e);
    const viewUrl = editUrl ? editUrl.replace("/fm/","/vo/") : editUrl;
    if (viewUrl) {
      if(e?.ctrlKey || e?.metaKey) {
        window.open(getBaseUrl()+viewUrl);
      } else {
        dispatchReplace(viewUrl, {pagination});
      }
    }
  }

  const doExtDelete = async (record, e) => {
    try {
      const isPreview = type === "preview";
      if (flow) {
        if (flowRecord) {
          const task = record.flow.task;
          const taskView = flowRecord.extraParams?.taskMap[task].view;
          if (taskView) {
            await formApi.deleteSystemFormData(isPreview, flowRecord.formKey, taskView, record.objectId, record.versionStamp, true);
            doReload();
          } else {
            log("taskView not defined");
          }
        }
      } else {
        await formApi.deleteSystemFormData(isPreview, formKey, formView, record.objectId, record.versionStamp, true);
        doReload();
      }
    } catch (e) {
      log(e);
      message.error(displayError(e));
    }
  }

  const doExtAdmin = async (record) => {
    try {
      if (flow && flowRecord) {
        const flow = record.flow;
        if (mounted.current) setFlowData(flow);
        if (mounted.current) setFlowDataId(record.objectId);
        if (mounted.current) setFlowModalVisible(true);
      }
    } catch (e) {
      log(e);
      message.error(displayError(e));
    }
  }

  const doExtHistory = async (record) => {
    try {
      if (mounted.current) setFormHistoryId(record.objectId);
      if (mounted.current) setFormHistoryModalVisible(true);
    } catch (e) {
      log(e);
      message.error(displayError(e));
    }
  }

  const getExtAction = (record, enabled) => {
    if (type === "admin" || isEnableAdmin) {
      return <Space align="center">
        {isFlowAdmin && <Tag className="flow-status clickable" onClick={() => enabled && doExtAdmin(record)}><IntlMessages id={`flow.admin`} text={"Admin"} /></Tag>}
        {isFormAdmin && <Tag className="flow-status clickable" onClick={() => enabled && doExtHistory(record)}><IntlMessages id={`form.history`} text={"History"} /></Tag>}
      </Space>
    } else if (flowRecord && record.flow?.task && flowRecord.flowKey === record.flow?.flowKey) {
      const task = record.flow.task;
      const label = flowRecord.extraParams?.taskMap[task]?.label;
      const style = {};
      if (flowRecord.extraParams?.taskMap[task]?.color) style.color = flowRecord.extraParams.taskMap[task].color;
      if (flowRecord.extraParams?.taskMap[task]?.background) style.backgroundColor = flowRecord.extraParams.taskMap[task].background;
      let overdue = null;
      if (record.flow?.dueDate) {
        const overdueDate = moment(record.flow.dueDate)
        if (overdueDate.isBefore(moment())) {
          overdue = <Tooltip mouseEnterDelay={1} title={<IntlMessages id="system.flow.task-overdue" text="Overdue"/>}><span className="flow-overdue"><GoAlert/></span></Tooltip>;
        }
      }
      if (label) {
        let openFormAction = null;
        if (checkViewPermissions(viewPermissions, 'view')) {
          openFormAction = (e) => enabled && doExtView(record, e);
        } else if (checkViewPermissions(viewPermissions, 'update')) {
          openFormAction = (e) => enabled && doExtEdit(record, e)
        }
        const rtnVal = <Space align="center">
          {record.flow?.assignee &&
            <SfpUserAvatar username={record.flow.assignee} size="medium" onClick={openFormAction}/>
          }
          {openFormAction && (width >= AccountStore.MOBILE_BREAKPOINT) && <Tag className="flow-status listing" style={style} onClick={openFormAction}>
            {overdue}<IntlMessages id={`flow.${formKey}.${flow}.task.${record.flow.task}`} text={label} />
          </Tag>}
        </Space>
        return rtnVal;
      }
    }
    return null;
  }

  const doClickAction = (record, enabled) => {
    if (enabled) {
      if (checkViewPermissions(viewPermissions, 'view')) {
        doExtView(record);
      } else if (checkViewPermissions(viewPermissions, 'update')) {
        doExtEdit(record)
      }
    }
  }

  const doExtExport = async (dataClass, params, sort) => {
    try {
      const isPreview = type === "preview";
      const report = await formApi.generateExport(isPreview, formKey, formView, null, dataClass, params, sort, flowParent);
      if (report) {
        if (report.report) {
          const file = await dataExplorer.get("SystemFile", report.report);
          if (file) {
            if (mounted.current) setReportLink(file.url);
            if (mounted.current) setIsReportReady(true);
          }
        } else if (report.url) {
          if (mounted.current) setReportLink(report.url);
          if (mounted.current) setIsReportReady(true);
        }
      }
    } catch (e) {
      log(e);
      message.error(displayError(e));
    }
  }

  const handleCloseReportPopup = () => {
    if (mounted.current) setIsReportReady(false);
  }

  const checkViewPermissions = (viewPermissions, permission) => {
    let allowed = true;
    if (viewPermissions) {
      allowed = false;
      for (const element of viewPermissions) {
        const p = element;
        if (p === 'denyall') {
          break;
        }
        if (p === permission) {
          allowed = true;
          break;
        }
      }
    }
    return allowed;
  }

  const addButtonByPermission = (viewPermission, permission, button) => {
    const allowed = checkViewPermissions(viewPermission, permission);
    if (allowed) {
      return button;
    } else {
      return null;
    }
  }

  if (isLoading) {
    return (<Row>
      <Col span="24">
        {contextHolder}
        <CircularProgress />
      </Col>
    </Row>)
  } else if (pageNotFound || !hasPermission) {
    if (isComponent) {
      return <div style={{display: 'none'}}>{`You have no permission to access the requested component. Search Runtime (formKey=${formKey}, formView=${formView})`}</div>
    } else {
      return (
        <Row>
          <Col span="24" >
            {!hasPermission && <Alert type="error"
              message={<IntlMessages id="system.alert.error" text="Error"/>}
              description={<IntlMessages id="system.page.permission-error" text="You have no permission to access the requested page."/>}/>
            }
            {pageNotFound && <Alert type="error"
              message={<IntlMessages id="system.alert.error" text="Error"/>}
              description={<IntlMessages id="system.page.not-found" text="The requested page was not found."/>}/>
            }
          </Col>
        </Row>
      )
    }
  } else {
    return (
      <Row>
        <Col span="24" >
          {dataClassConfig && (
            <div className="">
              <SearchTool
                match={match}
                location={location}
                apiRef={apiRef}
                itemKey={itemKey}
                keepSearchState={keepSearchState}
                formKey={formKey}
                flowKey={flow}
                messageApi={messageApi}
                dataClassConfig={dataClassConfig}
                doExtAdd={addButtonByPermission(viewPermissions, 'add', doExtAdd)}
                doExtEdit={flow ? null : addButtonByPermission(viewPermissions, 'update', doExtEdit)}
                doExtView={(flow || type === "admin" || isEnableAdmin) ? null : addButtonByPermission(viewPermissions, 'view', doExtView)}
                doExtDelete={flow ? null : addButtonByPermission(viewPermissions, 'delete', doExtDelete)}
                getExtAction={extAction || ((flow || type === "admin" || isEnableAdmin) ? getExtAction : null)}
                doExtExport={flow || !isExportEnabled ? null : doExtExport}
                doClickAction={doClickAction}
                isAdmin={type === "admin" || isEnableAdmin}
                flowParent={flowParent}
                isEnableAdminAllowed={isEnableAdminAllowed === false ? false : (isFormAdmin || isFlowAdmin)}
                isEnableAdmin={isEnableAdmin}
                setIsEnableAdmin={setIsEnableAdmin}
                flowModalVisible={flowModalVisible}
                displaymode={displaymode}
                showTitle={showTitle}
                disabled={disabled}
                isMaxContent={isMaxContent}
                extParams={extParams}
                extSort={extSort}
                extDescending={extDescending}
                extReload={`${locReload}_${extReload}`}
                onBeforeSearch={onBeforeSearch}
                sticky={sticky}
                nopaging={nopaging}
                paginationPosition={paginationPosition}
                showTotal={showTotal}
              />
              <FlowDataModal
                messageApi={messageApi}
                visible={flowModalVisible}
                flowData={flowData} flowRecord={flowRecord}
                objectId={flowDataId}
                isPreview={type === "preview"}
                flow={flow}
                onOk={()=> setFlowModalVisible(false)}
                onCancel={()=>setFlowModalVisible(false)}
                />
              <FormHistoryModal
                messageApi={messageApi}
                visible={formHistoryModalVisible}
                formRecord={formRecord}
                flowRecord={flowRecord}
                objectId={formHistoryId}
                isPreview={type === "preview"}
                formKey={formKey}
                formView={formView}
                onOk={()=>setFormHistoryModalVisible(false)}
                onCancel={()=>setFormHistoryModalVisible(false)}
                />
            </div>
          )}
        </Col>
        <Modal
            visible={isReportReady}
            title={<IntlMessages id="system.form.report" text="Report"/>}
            onOk={handleCloseReportPopup}
            onCancel={handleCloseReportPopup}
            footer={[
              <Button key="cancel" onClick={handleCloseReportPopup}>
                <IntlMessages id="system.form.cancel" text="Cancel"/>
              </Button>,
              <Button
                key="link"
                href={reportLink}
                target="_blank"
                type="primary"
                onClick={handleCloseReportPopup}
              >
                <IntlMessages id="system.form.open" text="Open"/>
              </Button>,
            ]}
          >
            <p><IntlMessages id="system.form.file.ready" text="Your file is ready."/></p>
          </Modal>
      </Row>
    );
  }
};

function setSingleSelection(checked, mounted, setSelectedIds, record, setSelectedRecords, uncheckAll) {
  if (checked) {
    if (mounted.current)
      setSelectedIds([record.objectId]);
    if (mounted.current)
      setSelectedRecords((selectedRecords) => {
        uncheckAll(selectedRecords);
        setSelectedRecords({ [record.objectId]: record });
      });
  } else {
    if (mounted.current)
      setSelectedIds([]);
    if (mounted.current)
      setSelectedRecords({});
  }
}

function setMultipleSelection(selectedRecords, checked, mounted, setSelectedIds, record, setSelectedRecords) {
  const newSelectedRecords = { ...selectedRecords };
  if (checked) {
    if (mounted.current)
      setSelectedIds((selectedIds) => getArraysUnion(selectedIds, [record.objectId]));
  } else {
    if (mounted.current)
      setSelectedIds((selectedIds) => getArraysSubtraction(selectedIds, [record.objectId]));
  }
  newSelectedRecords[record.objectId] = checked ? record : null;
  if (mounted.current)
    setSelectedRecords(newSelectedRecords);
}

function compileDelete(oldList, newList, propsMapping, deletedList) {
  Object.keys(oldList).forEach(key => {
    if (!(key in newList)) {
      const pkey = key.replace(/\.\d+$/, '').replace(/\[[^\]]+\]/, '');
      const props = propsMapping[pkey];
      let title = props?.title;
      if (!title) title = propsMapping[pkey.split('.')[0]]?.title;
      let resolvedName = props?.resolvedName;
      if (!resolvedName) resolvedName = propsMapping[pkey.split('.')[0]]?.resolvedName;
      deletedList.push({
        key, deleted: oldList[key], action: 'deleted', props, title, resolvedName,
      });
    }
  });
}

function compileChanges(newList, propsMapping, oldList, changedList, addedList) {
  Object.keys(newList).forEach(key => {
    const pkey = key.replace(/\.\d+$/, '').replace(/\[[^\]]+\]/, '');
    const props = propsMapping[pkey];
    let title = props?.title
    if (!title) title = propsMapping[pkey.split('.')[0]]?.title;
    let resolvedName = props?.resolvedName;
    if (!resolvedName) resolvedName = propsMapping[pkey.split('.')[0]]?.resolvedName;
    if (key in oldList) {
      if (!equals(oldList[key], newList[key])) {
        changedList.push({
          key, added: newList[key], deleted: oldList[key], action: 'updated', props, title, resolvedName
        });
      }
    } else {
      addedList.push({
        key, added: newList[key], action: 'added', props, title, resolvedName
      });
    }
  });
}

function filterChanges(c) {
  let rtnVal = true;
  if (rtnVal) rtnVal = c.title || SYSTEM_FIELDS.indexOf(c.key) !== -1;
  if (rtnVal) rtnVal = SKIP_COLUMNS_CLASS.indexOf(c.props?.resolvedName) === -1;
  if (rtnVal) rtnVal = c.resolvedName !== 'SfUpload' || UPLOAD_ATTRS.test(c.key)
  return rtnVal;
}

function compileTitleFilter(allChanges) {
  let filters = [...new Set(allChanges.map(c => c.title))];
  filters.sort((a, b) => compare(a.title, b.title))
  return filters.map(t => ({text: t, value: t}));
}

function renderGeneralText(value) {
  if (value) {
    if (`${value}`.length > 100) {
      return `${value}`.substring(0, 100) + '...';
    } else {
      return value;
    }
  } else {
    return value;
  }
}