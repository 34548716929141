import { Alert, Button, Col, message, Modal, Row } from "antd";
import moment from "moment";
import { pageApi } from "parse-api";
import React, { useContext, useEffect, useMemo, useReducer, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { displayError, equals, log, notify } from "../../../util/algorithm";
import IntlMessages from "util/IntlMessages";
import { loadMenu, setInitUrl, switchLanguage, userSignOut } from "../../../appRedux/actions";
import { AccountStore } from "../../../constants/Account";
import { dataExplorer, formApi } from "../../../parse-api";
import { getRequestContext, registerRequestContextListener, unregisterRequestContextListener } from "../../../parse-api/config";
import { checkAnyPageStateChanged, cloneJson, getArraysIntersection, getBaseUrl, getLocalStorageItemObject, hideLoading, isLoopBack, msgHelper, registerPageComponentApis, runScript, setLocalStorageItemObject, showLoading, toAuthUserObj, tryParseJson, unregisterPageComponentApis } from "../../../util/algorithm";
import { importXLSX } from "../../../util/export";
import { SpPageContext, SppPage } from "./components";
import initPageData from "./defaultPage";
import { setupPage } from "./PageComponent";
import { useRendersCount, useSignal } from "../../../util/reactHook";
import { authSignal, commonSignal, settingsSignal, systemSignal } from "../../../util/signal";
import { FrontendApi } from "../../../util/frontendApi";

const DEFAULT_ACTION_BUTTON_VALUES = {
  openPage: null,
  closePage: null,
  cancelPage: false,
}

const RESERVED_API_KEYS = [
  'push', 'replace', 'open', 'openPage', 'cancelPage', 'closePage',
  'forceUpdate', 'printReport', 'exportReport', 'importXLSX'
]

const resetEvent = (values) => {
  if (!values) return;
  values = {...values}
  if (values) {
    Object.keys(values).forEach(key => {
      if (RESERVED_API_KEYS.includes(key) || key.match(/^(.*_clickedRowKey|.*_clicked)$/)) {
        values[key] = undefined;
      }
    });
  }
  return values
}

const getPageAction = (p, actionType) => {
  const selectedActions = p?.extraParams?.selectedActions ?  p?.extraParams?.selectedActions : null;
  const actions = []
  if (selectedActions) {
    for (const element of selectedActions) {
      if (element.actionTypes.indexOf(actionType) !== -1) {
        actions.push(element)
      }
    }
  }
  return actions;
}

const procesParentPageStateChange = (prev, next, onChange) => {
  let prevValue;
  let nextValue;
  if (prev?.value !== undefined) {
    prevValue = prev.value
  }
  if (next?.value !== undefined) {
    nextValue = next.value;
  }
  if (!equals(prevValue, nextValue)) {
    if (nextValue !== undefined) {
      if (onChange) onChange(nextValue)
    }
  }
  return next;
}

const reducePageData = (oldPageData, pageData) => {
  const actionType = "PageSetupAction";
  const actions = getPageAction(pageData, actionType);
  const requestContext = getRequestContext();
  const resultMap = {}
  for (const element of actions) {
    const script = element.data;
    const scriptKey = element.actionKey;
    const versionStamp = element.versionStamp;
    const frontendApi = new FrontendApi();
    runScript({script, versionStamp, scriptKey, actionType, oldPageData, pageData, resultMap, requestContext, frontendApi});
  }
  if (resultMap.pageData) {
    pageData = resultMap.pageData;
  }
  if (!pageData.config) pageData.config = oldPageData.config;
  return pageData;
}

const processSystemPageDataChange = (isPreview, pageData, page, oldPage, forceUpdate, doSetValues, mainPanelRef) => {
  const actionType = "PageActionFrontEnd";
  const actions = getPageAction(pageData, actionType);
  const requestContext = getRequestContext();
  const resultMap = {}
  page = cloneJson(page)
  const mainPanel = mainPanelRef.current;
  for (const element of actions) {
    const script = element.data;
    const scriptKey = element.actionKey;
    const versionStamp = element.versionStamp;
    const frontendApi = new FrontendApi(doSetValues);
    runScript({script, versionStamp, scriptKey, actionType, isPreview, pageData, page, oldPage, resultMap, forceUpdate, doSetValues, requestContext, frontendApi, mainPanel});
  }
  resultMap.page = page;
  return resultMap;
}

const updateLoadingStatus = (pageState, oldPageState) => {
  if (pageState?.loading !== oldPageState?.loading) {
    if (pageState.loading) {
      showLoading();
    } else {
      hideLoading();
    }
  }
}

const storePageState = ({pageData, pageState, pagestatekey}) => {
  const keepPageState = pageData?.extraParams?.keepPageState;
  if (pageState?.pageKey && keepPageState) {
    try {
      setLocalStorageItemObject(pagestatekey, pageState);
    } catch (err) {
      log('save pagestate error', err)
    }
  }
}

export const PageRuntimeImpl = ({
  type,
  pageKey,
  hideVersion,
  dispatchReplace,
  dispatchGoBack,
  dispatchPush,
  onClosePage,
  onCancelPage,
  onPageDataChange,
  pathInfo,
  initPageState,
  readOnly,
  disabled,
  designer,
  parentkeys,
  parentValues,
  config,
}) => {
  // const [requireProcessValueChange, setRequireProcessValueChange] = useState(false);
  // const [requireProcessValueChangeFrontEnd, setRequireProcessValueChangeFrontEnd] = useState(false);
  // const [lastChangedValue, setLastChangedValue] = useState();
  // const [isCyclic, setIsCyclic] = useState(false);
  // const [hasPermission, setHasPermission] = useState(true);
  // const [pageNotFound, setPageNotFound] = useState(false);
  // const [hideMyVersion, setHideMyVersion] = useState(hideVersion);
  const state = useSignal({
    requireProcessValueChange: false,
    requireProcessValueChangeFrontEnd: false,
    lastChangedValue: null,
    isCyclic: false,
    hasPermission: true,
    pageNotFound: false,
    hideMyVersion: hideVersion
  })

  const [pageData, dispatchPageData] = useReducer(reducePageData, {...(initPageData || {}), config});
  const [, forceUpdate] = useReducer(x => x + 1, 0);

  const [isLoading, setIsLoading] = useState(true);
  const [pageState, setPageState] = useState();

  const [openPageData, setOpenPageData] = useState();
  const [isReportReady, setIsReportReady] = useState(false);
  const [reportLink, setReportLink] = useState();

  const dispatch = useDispatch();
  const location = useLocation();
  const [api, contextHolder] = message.useMessage();

  const ctx = useContext(SpPageContext);
  const parent = useMemo(() => {
    return ctx ? [...ctx] : [];
  }, [ctx]);

  const mounted = useRef();
  const mainPanelRef = useRef();
  const onPageStateChangeRef = useRef();
  const pagestatekey = `page_pagestate_${pageKey}`;

  useRendersCount('PageRuntimeImpl', {pageKey})

  const requestContextListener = (change) => {
    if (firePageEvent && pageState?.requestContextListenerKeys) {
      let updated = false;
      for (const key of pageState.requestContextListenerKeys) {
        if (change.oldValue?.[key] !== change.newValue?.[key]) {
          updated = true;
          break;
        }
      }
      if (updated) {
        firePageEvent(pageState, 'requestContext_change', true);
      }
    }
  }
  registerRequestContextListener(pageKey, requestContextListener);

  useEffect(() => {
    mounted.current = true;
    registerPageComponentApis(pageKey, 'dispatchReplace', dispatchReplace);
    registerPageComponentApis(pageKey, 'dispatchGoBack', dispatchGoBack);
    registerPageComponentApis(pageKey, 'dispatchPush', dispatchPush);
    doLoad();
    return () => {
      mounted.current = false;
      unregisterPageComponentApis(pageKey, 'dispatchReplace', dispatchReplace);
      unregisterPageComponentApis(pageKey, 'dispatchGoBack', dispatchGoBack);
      unregisterPageComponentApis(pageKey, 'dispatchPush', dispatchPush);
      unregisterRequestContextListener(pageKey);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    if (parent && pageKey && parent.indexOf(pageKey) !== -1) {
      state.isCyclic = true;
    } else {
      state.isCyclic = false;
    }
  }, [pageKey, parent, state])

  useEffect(() => {
    if (initPageState) {
      const newState = {...initPageState, lastLoadingTime: new Date().getTime(), forceUpdate: false}
      const now = new Date().getTime()
      setPageState((prev) => {
        if ((typeof prev?.lastLoadingTime === 'undefined' ||
             now - prev?.lastLoadingTime > 1000) ||
            initPageState?.forceUpdate === true) {
          return checkAnyPageStateChanged(prev, newState)
        } else {
          return prev;
        }
      })
    }
  }, [initPageState])

  useEffect(() => {
    onPageStateChange(pageData, pageState, parentValues, isLoading);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageState, parentValues])

  useEffect(() => {
    storePageState({pageData, pageState, pagestatekey})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageState])

  const displayWarning = (values) => {
    if (values.errorMessage) {
      msgHelper.error(displayError(values.errorMessage));
      delete values.errorMessage;
    }
    if (values.warningMessage) {
      msgHelper.warning(displayError(values.warningMessage));
      delete values.warningMessage;
    }
    if (values.infoMessage) {
      msgHelper.info(displayError(values.infoMessage));
      delete values.infoMessage;
    }
  };

  const dataChangeTimer = useRef();
  const showMyLoading = () => {
    if (!dataChangeTimer.current && pageData.extraParams?.showLoading) {
      dataChangeTimer.current = setTimeout(() => {
        if (dataChangeTimer.current) {
          showLoading()
        }
      }, (pageData.extraParams?.showLoading * 1000))
    }
  }
  const hideMyLoading = () => {
    if (dataChangeTimer.current) {
      clearTimeout(dataChangeTimer.current);
      dataChangeTimer.current = null;
      hideLoading();
    }
  }
  const mergeValues = (values, others) => {
    if (!values) values = {}
    if (!others) others = {}
    return {...values, ...others}
  }

  const onPageStateChange = (pageData, pageState, parentValues, isLoading) => {
    if (!isLoading) {
      if (onPageStateChangeRef.current) {
        clearTimeout(onPageStateChangeRef.current);
        onPageStateChangeRef.current = null;
      }
      onPageStateChangeRef.current = setTimeout(() => {
        onPageStateChangeImpl(pageData, pageState, parentValues)
      }, AccountStore.ON_CHANGE_DELAY)
    }
  }

  const firePageEvent = (pageState, key, value) => {
    const state = {...pageState, [key]: value}
    onPageStateChangeImpl(pageData, state, parentValues, true)
  };

  const onPageStateChangeImpl = async (pageData, pageState, parentValues, isEvent) => {
    try {
      if (isLoopBack('onPageStateChange:'+pageKey) || isLoading) {
        return;
      }
      const values = {...pageState}
      if (state.requireProcessValueChange || state.requireProcessValueChangeFrontEnd) {
        const isPreview = type === "preview";
        showMyLoading();
        const lPage = mergeValues(values, {readOnly, disabled, designer, parentkeys, parentValues});
        const lOldPage = state.lastChangedValue ? {...state.lastChangedValue} : null;
        let newValues = lPage;
        let processed = false;
        let skipped = false;
        if (state.requireProcessValueChangeFrontEnd) {
          const resultMap = processSystemPageDataChange(isPreview, pageData, lPage, lOldPage, forceUpdate, doSetValues, mainPanelRef);
          newValues = resultMap.page;
          processed = resultMap.processed;
          skipped = resultMap.skipped;
        }
        newValues = (processed || !state.requireProcessValueChange) ? newValues : await pageApi.processSystemPageDataChange(isPreview, pageKey, newValues, lOldPage);
        if(newValues?.frontendJS || values?.frontendJS){
          let frontendJS = newValues?.frontendJS;
          if(!frontendJS){
            frontendJS = values?.frontendJS;
          }
          if(!frontendJS)frontendJS= '';
          try {
            // eslint-disable-next-line no-eval
            eval(frontendJS);
          } catch (error) {
            console.error(displayError(error))
          }
        }
        if (!equals(lPage, newValues)) {
          if (newValues.push) {
            dispatchPush(newValues.push);
          } else if (newValues.replace) {
            dispatchReplace(newValues.replace);
          } else if (newValues.open) {
            window.open(systemSignal.baseUrl + newValues.open);
            doUpdateValues({open: false}, true)
          } else if (newValues.reload) {
            log('reload trigger by action policy: onChange', newValues)
            window.location.reload(false);
          } else if (newValues.userSignOut) {
            log('signout trigger by action policy: onSave', newValues);
            dispatch(userSignOut())
          } else if (newValues.switchLanguage) {
            log('change system language: ', newValues);
            dispatch(switchLanguage(newValues.switchLanguage))
          } else {
            if (newValues.openPage) {
              console.log('openPage()', newValues.openPage)
              if (newValues.openPage.pageKey && newValues.openPage.modalKey) {
                const newOpenPageData = {...newValues.openPage}
                if (!newOpenPageData.width) newOpenPageData.width = 800;
                newOpenPageData.isPreview = !!newOpenPageData.isPreview;
                newOpenPageData.visible = true;
                setOpenPageData(newOpenPageData);
              }
              newValues.openPage = undefined;
            }
            if (newValues.closePage) {
              if (onClosePage) {
                onClosePage(newValues.closePage)
              }
              newValues.closePage = undefined;
            }
            if (newValues.cancelPage) {
              if (onCancelPage) {
                onCancelPage()
              }
              newValues.cancelPage = undefined;
            }
            if (newValues.forceUpdate) {
              forceUpdate();
              newValues.forceUpdate = undefined;
            }
            if (newValues.printReport) {
              callPrint(newValues.printReport);
              newValues.printReport = undefined;
            }
            if (newValues.exportReport) {
              callExport(newValues.exportReport);
              newValues.exportReport = undefined;
            }
            if (newValues.downloadFile) {
              callDownload(newValues.downloadFile);
              newValues.downloadFile = undefined;
            }
            if (newValues.reloadMenu) {
              dispatch(loadMenu());
              newValues.reloadMenu = undefined;
            }
            if (newValues.importXLSX) {
              const targetKey = newValues.importXLSX.target
              const data = await importXLSX(newValues.importXLSX.columns)
              newValues[targetKey] = data;
              newValues.importXLSX = undefined
            }
            displayWarning(newValues);
            if (!skipped) {
              doSetValues({data: newValues});
            }
          }
        } else if (!equals(values, resetEvent(values)) || isEvent) {
          doSetValues({data: values});
        }else{
          state.lastChangedValue = resetEvent(newValues);
        }

      } else if (systemSignal.headless) {
        notify('data-changed', values);
      }
      if (values.back_clicked) {
        dispatchGoBack();
      }
      hideMyLoading();
    } catch (e) {
      log(e);
      hideMyLoading();
      if (!isLoading && state.hasPermission) msgHelper.error(displayError(e));
    }
  }

  const doSetValues = async (data) => {
    try {
      const values = {...data.data};
      setPageState((prev) => {
        const newValues = resetEvent({...prev, ...values});
        updateLoadingStatus(newValues, prev);
        state.lastChangedValue = newValues;
        return procesParentPageStateChange(prev, newValues, onPageDataChange);
      });
      notify('set-values-result', data.data, 'success');
    } catch (error) {
      log("validateError", error);
      notify('set-values-result', data.data, 'fail', error);
      return;
    }
  }

  const doUpdateValues = (values, disableAllClicked) => {
    setPageState((prev) => {
      let newPageState = {...prev, ...values};
      if (disableAllClicked) {
        newPageState = resetEvent(newPageState)
      }
      if (equals(newPageState, prev)) {
        return prev;
      } else {
        state.lastChangedValue = newPageState;
        return procesParentPageStateChange(prev, newPageState, onPageDataChange);
      }
    })
  }

  const doLoad = async () => {
    const start = new Date().getTime();
    try {
      if (mounted.current) setIsLoading(true);
      const isPreview = type === "preview";
      const p = await pageApi.getSystemPage(isPreview, pageKey);
      if (p) {
        const pageActionsFrontEnd = getPageAction(p, "PageActionFrontEnd");
        if (pageActionsFrontEnd.length > 0) {
          state.requireProcessValueChangeFrontEnd = true;
        }
        const pageActions = getPageAction(p, "PageAction");
        if (pageActions.length > 0) {
          state.requireProcessValueChange = true;
        }
        const _hideVersion = !!p?.extraParams?.hideVersion;
        state.hideMyVersion = hideVersion || _hideVersion;
        const keepPageState = p?.extraParams?.keepPageState;
        let oldPageState = getLocalStorageItemObject(pagestatekey);
        oldPageState = resetEvent(oldPageState);
        const myPageState = initPageState || {};
        if (keepPageState && oldPageState) {
          setPageState((prev) => checkAnyPageStateChanged(prev,
            {
              ...oldPageState,
              ...myPageState,
              "window.location.pathname": pathInfo?.path || window.location.pathname,
              pathInfo,
              pageId: p?.objectId,
              pageKey: pageKey,
              pageIsPreview: isPreview,
              pageAdmins: p.admins,
              pageAdminRoles: p.adminRoles,
              pageWidth: commonSignal.width,
              username: toAuthUserObj(authSignal.authUser)?.username,
              languageId: settingsSignal.locale?.languageId,
              config: config,
              lastLoadingTime: initPageState ? new Date().getTime() : undefined,
              ...DEFAULT_ACTION_BUTTON_VALUES
            }
          ))
        } else {
          setPageState((prev) => checkAnyPageStateChanged(prev,
            {
              ...myPageState,
              pageId: p?.objectId,
              pageKey: pageKey,
              pageIsPreview: isPreview,
              pageAdmins: p.admins,
              pageAdminRoles: p.adminRoles,
              pageWidth: commonSignal.width,
              username: toAuthUserObj(authSignal.authUser)?.username,
              languageId: settingsSignal.locale?.languageId,
              config: config,
              lastLoadingTime: initPageState ? new Date().getTime() : undefined,
              "window.location.pathname": pathInfo?.path || window.location.pathname,
              pathInfo
            }
          ))
        }
        await authSignal.waitFor('authUser')
        const authUserObj = toAuthUserObj(authSignal.authUser);
        const hasPermission = getArraysIntersection(p.extraParams?.allowedRoles, authUserObj?.roles).length > 0;
        if (!hasPermission && authUserObj?.username === 'guest') {
          dispatch(setInitUrl(location.pathname + location.search));
          console.log('forward to login...')
          window.location.href = getBaseUrl() + '/signin';
        }
        if (mounted.current) dispatchPageData(p);
        if (mounted.current && !hasPermission) state.hasPermission = hasPermission;
      } else if (mounted.current) state.pageNotFound = true;
      if (mounted.current) setIsLoading(false);
      const end = new Date().getTime();
      if (mounted.current) setPageState((prev) => ({...prev, initializedInMs: end - start}));
    } catch (e) {
      log(e);
      api.error(displayError(e));
      const end = new Date().getTime();
      if (mounted.current) setPageState((prev) => ({...prev, initializedInMs: end - start}));
    }
  }

  const handleCloseReportPopup = () => {
    if (mounted.current) setIsReportReady(false);
  };

  const callPrint = async ({isPreview, formKey, formView, id}) => {
    try {
      const report = await formApi.generateReport(
        isPreview,
        formKey,
        formView,
        id
      );
      if (report) {
        const file = await dataExplorer.get("SystemFile", report.report);
        if (file) {
          if (mounted.current) setReportLink(file.url);
          if (mounted.current) setIsReportReady(true);
          doUpdateValues()
        }
      }
    } catch (e) {
      notify("callPrint", {isPreview, formKey, formView, id}, "fail", displayError(e));
      if (!systemSignal.headless) message.error(displayError(e));
    }
  };

  const callExport = async ({isPreview, formKey, formView, id, dataClass, params, sort, flowParent}) => {
    try {
      const report = await formApi.generateExport(
        isPreview, formKey, formView, id, dataClass, params, sort, flowParent
      );
      if (report) {
        if (report.report) {
          const file = await dataExplorer.get("SystemFile", report.report);
          if (file) {
            if (mounted.current) setReportLink(file.url);
            if (mounted.current) setIsReportReady(true);
          }
        } else if (report.url) {
          if (mounted.current) setReportLink(report.url);
          if (mounted.current) setIsReportReady(true);
        }
      }
    } catch (e) {
      notify("callExport", {isPreview, formKey, formView, id, dataClass, params, sort, flowParent}, "fail", displayError(e));
      if (!systemSignal.headless) message.error(displayError(e));
    }
  };

  const callDownload = async ({url}) => {
    if (mounted.current) setReportLink(url);
    if (mounted.current) setIsReportReady(true);
  };

  const configObj = tryParseJson(config);
  return (
    <Row className="page-main-panel" ref={mainPanelRef}>
      {contextHolder}
      {!isLoading &&
        state.hasPermission &&
        !state.pageNotFound &&
        type === "preview" &&
        pageData &&
        !state.hideMyVersion && (
          <Col span="24" className="page-main">
            <div className="craft-page-preview">
              <IntlMessages id="system.page.preview-indicator" text="PREVIEW" />
              <span>
                <IntlMessages id="system.page.page-version" text="VERSION" />{" "}
                {pageData.versionStamp}
              </span>
              <span>{moment(pageData.updatedAt).fromNow()}</span>
            </div>
          </Col>
        )}
      {!isLoading &&
        state.hasPermission &&
        !state.pageNotFound &&
        type !== "preview" &&
        pageData &&
        !state.hideMyVersion && (
          <Col span="24" className="page-main">
            <div className="craft-page-published">
              <span>
                <IntlMessages id="system.page.page-version" text="VERSION" />{" "}
                {pageData.versionStamp}
              </span>
            </div>
          </Col>
        )}
      <Col span="24" className={"page-main " + (configObj?.itemKey || "")}>
        {state.isCyclic && (
          <div className="cyclic-dependency-detected">
            Cyclic Dependency Detected!!! {[...parent, pageKey].join(" => ")}
          </div>
        )}
        {!state.hasPermission && (
          <Alert
            type="error"
            message={<IntlMessages id="system.alert.error" text="Error" />}
            description={
              <IntlMessages
                id="system.page.permission-error"
                text="You have no permission to access the requested page."
              />
            }
          />
        )}
        {state.pageNotFound && (
          <Alert
            type="error"
            message={<IntlMessages id="system.alert.error" text="Error" />}
            description={
              <IntlMessages
                id="system.page.not-found"
                text="The requested page was not found."
              />
            }
          />
        )}
        <SpPageContext.Provider value={[...parent, pageKey]}>
          {!state.isCyclic && !isLoading && state.hasPermission && (
            <div className="">
              {setupPage({
                data: pageData?.data,
                preview: type === "preview",
                pageState,
                setPageState,
                firePageEvent,
              })}
            </div>
          )}
        </SpPageContext.Provider>
        {openPageData?.visible && (
          <PageModal
            {...openPageData}
            onOk={(values) => {
              setOpenPageData({});
              setTimeout(() => {
                doUpdateValues(
                  {
                    [openPageData.modalKey]: { ...values },
                  },
                  true
                );
              }, 500);
            }}
            onCancel={() => {
              setOpenPageData({});
              setTimeout(() => {
                doUpdateValues(
                  {
                    [openPageData.modalKey]: null,
                  },
                  true
                );
              }, 500);
            }}
            getContainer={() => mainPanelRef.current}
          />
        )}
      </Col>
      <Modal
        visible={isReportReady}
        title={
          <IntlMessages id="system.form.report.download" text="Download" />
        }
        onOk={handleCloseReportPopup}
        onCancel={handleCloseReportPopup}
        footer={[
          <Button
            key="cancel"
            className="cancel-btn"
            onClick={handleCloseReportPopup}
          >
            <IntlMessages id="system.form.cancel" text="Cancel" />
          </Button>,
          <Button
            key="link"
            href={reportLink}
            target="_blank"
            className="open-btn"
            onClick={handleCloseReportPopup}
          >
            <IntlMessages id="system.form.open" text="Open" />
          </Button>,
        ]}
      >
        <p>
          <IntlMessages
            id="system.form.file.ready"
            text="Your file is ready."
          />
        </p>
      </Modal>
    </Row>
  );
};

export const PageModal = ({
  visible, onOk, onCancel,
  isPreview, pageKey, width, height, modalKey, title, getContainer, ...initPageState }) => {
  const [pageData, setPageData] = useState(initPageData);
  const mounted = useRef();
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    }
  }, [])
  const onClosePage = (values) => {
    try {
      if (onOk) onOk(values);
    } catch (e) {
      message.error(displayError(e));
    }
  }
  useEffect(() => {
    const fetchData = async () => {
      if (visible && pageKey) {
        try {
          const p = await pageApi.getSystemPage(isPreview, pageKey);
          setPageData(p)
        } catch (e) {
          log("set record value error", e);
          message.error(displayError(e));
        }
      }
    }
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, pageKey, isPreview]);

  const bodyStyle = {}
  if (height) bodyStyle.height = height + "px";
  return (
    <Modal width={width}  className="page-modal"
      title={<IntlMessages id={modalKey || `page.modal.${pageKey}`}
      text={title || pageData?.pageName}/>}
      visible={visible} onCancel={onCancel} centered={true} getContainer={getContainer}>
      <SppPage
        pageKey={pageKey}
        hideVersion={true}
        onClosePage={onClosePage}
        onCancelPage={onCancel}
        initPageState={initPageState}
        isPreview={isPreview}
      />
    </Modal>
  )
}

export default PageRuntimeImpl;