import { BarsOutlined, MenuOutlined, ProfileOutlined, RedoOutlined, ReloadOutlined, SaveOutlined, UndoOutlined } from "@ant-design/icons";
import { Element, Frame, useEditor, useNode } from "@craftjs/core";
import { Button, Menu as AntdMenu, message, Switch, Tooltip } from "antd";
import {
  loadMenu, saveMenu
} from "appRedux/actions";
import { Form, Input, Select } from "components/Form";
import { push } from "connected-react-router";
import { system } from "parse-api";
import React, { useEffect, useRef, useState } from "react";
import { AiOutlineLine } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { getBaseUrl, log, prepareText, uid } from "util/algorithm";
import IntlMessages from "util/IntlMessages";
import { IconSelect, prepareTitleElement } from "../../../components/Form/IconSelect";
import { AccountStore } from "../../../constants/Account";
import { NAV_STYLE_DEFAULT_HORIZONTAL, NAV_STYLE_FIXED } from "../../../constants/ThemeSetting";
import { useLbl } from "../../../lngProvider";
import { dataExplorer } from "../../../parse-api";
import { getCraftSelectedNodeId, isValidUrl, setSelectedMenu, tryParseJson } from "../../../util/algorithm";
import { settingsSignal, systemSignal } from "../../../util/signal";

const DEFAULT_ID = "96dj03";
export const getId = () => `${DEFAULT_ID}`;

export const updateDisplayName = (props, id) => {
  if (!props.custom) props.custom = {};
  if (props.props?.title) {
    props.custom.displayName = props.displayName + " (" + props.props?.title + ")";
  } else {
    props.custom.displayName = props.displayName;
  }
}

export const patchRealId = (json) => {
  json = json.replace(RegExp(DEFAULT_ID, "g"), (match) => {
    return uid();
  });
  const nodeMap = JSON.parse(json);
  const newNodeMap = {...nodeMap};
  const CANVAS = ["MenuItemGroup", "SubMenu"];
  for (const key in nodeMap) {
    if (nodeMap.hasOwnProperty(key)) {
      const currentNode = nodeMap[key];
      updateDisplayName(currentNode);
      const parentNode = currentNode.parent ? nodeMap[currentNode.parent] : null;
      if (currentNode) {
        if (currentNode.type.resolvedName === 'Container') {
          if (parentNode) {
            parentNode.nodes = currentNode.nodes;
            newNodeMap[key] = undefined;
          }
        }
        if (CANVAS.indexOf(currentNode.type.resolvedName) !== -1) {
          currentNode.isCanvas = true;
        }
        if (currentNode.linkedNodes) currentNode.linkedNodes = {};
        if (parentNode?.type.resolvedName === 'Container') {
          currentNode.parent = parentNode.parent;
        }
      }
    }
  }
  json = JSON.stringify(newNodeMap);
  return json;
}

const Library = ({editable}) => {
  const { connectors } = useEditor();
  return (
    <div className="craft-library">
      <Button className="lib-btn menu-group" disabled={!editable} ref={ref => connectors.create(ref, <Element canvas is={MenuItemGroup} menuKey={getId()} title="Menu Group" />)}><Tooltip mouseEnterDelay={1} title={<IntlMessages id="system.menu.library.menu-group" text="Menu Group" />}><ProfileOutlined/></Tooltip></Button>
      <Button className="lib-btn sub-menu" disabled={!editable} ref={ref => connectors.create(ref, <Element canvas is={SubMenu} menuKey={getId()} title="Sub-Menu" />)}><Tooltip mouseEnterDelay={1} title={<IntlMessages id="system.menu.library.sub-menu" text="Sub Menu" />}><BarsOutlined/></Tooltip></Button>
      <Button className="lib-btn menu" disabled={!editable} ref={ref => connectors.create(ref, <MenuItem menuKey={getId()} title="Menu" />)}><Tooltip mouseEnterDelay={1} title={<IntlMessages id="system.menu.library.menu" text="Menu" />}><MenuOutlined/></Tooltip></Button>
      <Button className="lib-btn divider" disabled={!editable} ref={ref => connectors.create(ref, <MenuDivider menuKey={getId()} title="Divider" />)}><Tooltip mouseEnterDelay={1} title={<IntlMessages id="system.menu.library.divider" text="Divider" />}><AiOutlineLine/></Tooltip></Button>
    </div>
  );
};

const openLink = (link, e, messageApi) => {
  e.preventDefault();
  e.stopPropagation();
  if (link) {
    (messageApi ? messageApi : message).info(`${link}`);
  }
  return false;
};

const isNodeHasChildren = (node) => {
  if (node.dom) {
    return (node.dom.innerHTML.match(/ant-menu-item/g)) ? true : false;
  } else {
    return false;
  }
}

const MenuNodeCollector = (node) => {
  const selected = node.events.selected;
  const style = selected ? { outline: "orange 3px solid", position: "relative" } : null;
  const hasChildren = isNodeHasChildren(node);
  return {
    selected: selected,
    style: style,
    hasChildren: hasChildren,
  };
}

const EditorCollector = (state, query) => {
  const currentNodeId = getCraftSelectedNodeId({state, query});
  let selectedNode;

  if (currentNodeId) {
    selectedNode = {
      id: currentNodeId,
      name: state.nodes[currentNodeId].data.name,
      displayName: state.nodes[currentNodeId].data.displayName,
      props: state.nodes[currentNodeId].data.props,
      settings: state.nodes[currentNodeId].related && state.nodes[currentNodeId].related.settings,
      isDeletable: query.node(currentNodeId).isDeletable()
    };
  }

  return {
    selectedNode
  }
}

const deleteButton = (selected, selectedNode, action) => {
  return selected && selectedNode?.isDeletable ? (
    <span onClick={() => action.delete(selectedNode.id)}>
      <i className={"icon icon-close craft-delete-button"} />
    </span>
  ) : null;
}

const Menu = ({ children, ...otherProps }) => {
  const { connectors: { connect, drag } } = useNode(MenuNodeCollector);
  return (
    <ul ref={ref => connect(drag(ref))} className="craft-frame design ant-menu ant-menu-dark ant-menu-root ant-menu-inline">
      {children}
    </ul>
  )
}

const Container = ({ children, ...otherProps }) => {
  const { connectors: { connect, drag } } = useNode();
  return (
    <ul ref={ref => connect(drag(ref))} {...otherProps} style={{ minHeight: "25px" }}>
      {children}
    </ul>
  )
}

Container.craft = {
  rules: {
    canMoveIn: function (incomingNode, currentNode) {
      let rtnVal = false;
      if (currentNode.data.props.type === "MenuItemGroup") {
        rtnVal = (incomingNode.data.name === "MenuItem" || incomingNode.data.name === "SubMenu" || incomingNode.data.name === "MenuDivider");
      }
      if (currentNode.data.props.type === "SubMenu") {
        rtnVal = (incomingNode.data.name === "MenuItem" || incomingNode.data.name === "SubMenu" || incomingNode.data.name === "MenuDivider");
      }
      return rtnVal;
    }
  }
}

const MenuItemGroup = ({ menuKey, title, children, ...otherProps }) => {
  const { connectors: { connect, drag }, selected, style } = useNode(MenuNodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  return (
    <li ref={ref => connect(drag(ref))} className="gx-menu-group ant-menu-item-group" style={style}>
      <div className="ant-menu-item-group-title">
        <span>{title}</span>
      </div>
      <ul {...otherProps} style={{ minHeight: "25px" }}>
        {children}
      </ul>
      {deleteButton(selected, selectedNode, actions)}
    </li>
  )
}

MenuItemGroup.craft = {
  rules: {
    canMoveIn: function (incomingNode, currentNode) {
      let rtnVal = false;
      rtnVal = (incomingNode.data.name === "MenuItem" || incomingNode.data.name === "SubMenu" || incomingNode.data.name === "MenuDivider");
      return rtnVal;
    }
  }
}

const SubMenu = ({ menuKey, title, icon, className, children, ...otherProps }) => {
  const { connectors: { connect, drag }, selected, style, hasChildren } = useNode(MenuNodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const titleElement = prepareTitleElement(title, icon);

  return (
    <li ref={ref => connect(drag(ref))} className="ant-menu-submenu ant-menu-submenu-inline ant-menu-submenu-open ant-menu-submenu-selected" style={style} >
      <div className="ant-menu-submenu-title" style={{ paddingLeft: "24px" }}>
        {titleElement}
        {hasChildren && <i className="ant-menu-submenu-arrow" />}
      </div>
      <ul {...otherProps} style={{ minHeight: "25px" }}>
        {children}
      </ul>
      {deleteButton(selected, selectedNode, actions)}
    </li>
  );
}

SubMenu.craft = {
  rules: {
    canMoveIn: function (incomingNode, currentNode) {
      let rtnVal = false;
      rtnVal = (incomingNode.data.name === "MenuItem" || incomingNode.data.name === "SubMenu" || incomingNode.data.name === "MenuDivider");
      return rtnVal;
    }
  }
}

const MenuItem = ({ menuKey, title, link, icon, className, ...otherProps }) => {
  const { connectors: { connect, drag }, selected, style } = useNode(MenuNodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled
  }));
  const titleElement = prepareTitleElement(title, icon);

  return (
    <li ref={ref => connect(drag(ref))} className="ant-menu-item" style={{ paddingLeft: "48px", ...style }}>
      <Button onClick={(e) => !enabled && openLink(link, e)} type="link" className={"craft-menu-item-button"}>
        {titleElement}
      </Button>
      {deleteButton(selected, selectedNode, actions)}
    </li>
  )
}

const MenuDivider = ({ menuKey, dashed, className, ...otherProps }) => {
  const { connectors: { connect, drag }, selected, style } = useNode(MenuNodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  return (
    <li ref={ref => connect(drag(ref))} className="ant-menu-item" style={{  paddingLeft: "48px", ...style }}>
      <span className="craft-menu-divider"><label>Divider</label></span>
      {deleteButton(selected, selectedNode, actions)}
    </li>
  )
}

const MenuSetting = () => {
  const mounted = useRef();
  const [allRoles, setAllRoles] = useState([]);
  const { ...collected } = useNode((node) => ({
    title: node.data.props.title,
    dashed: node.data.props.dashed,
    menuKey: node.data.props.menuKey,
    link: node.data.props.link,
    icon: node.data.props.icon,
    name: node.data.name,
  }));
  const [policyOptions, setPolicyOptions] = useState([]);

  useEffect(() => {
    mounted.current = true;
    const fetchData = async () => {
      const roles = await system.getAllRoles();
      if (mounted.current) setAllRoles(roles)
    }
    fetchData();
    return () => {
      mounted.current = false;
    }
  }, []);
  useEffect(()=>{
    const fetchData = async () => {
      const params = [{key:"dataTypes", operator:"=", value:"Menu"}];
      const list = await dataExplorer.searchAll("SystemDataPolicy", params, "dataName");
      if (mounted.current && list ) setPolicyOptions(list.map(p=>({value:p.dataKey,label:p.dataName})));
    }
    fetchData();
  },[])
  if (collected.name === "Menu") {
    return (
      <>
        <Form.Item name="mode" label="Mode">
          <Select placeholder="Mode" allowClear>
            <Select.Option key={"inline"} value={"inline"}><IntlMessages id={"system.menu.mode.inline"} text={"inline"} /></Select.Option>
            <Select.Option key={"vertical"} value={"vertical"}><IntlMessages id={"system.menu.mode.vertical"} text={"vertical"} /></Select.Option>
            <Select.Option key={"horizontal"} value={"horizontal"}><IntlMessages id={"system.menu.mode.horizontal"} text={"horizontal"} /></Select.Option>
          </Select>
        </Form.Item>
      </>
    )
  } else {
    return (
      <>
        <Form.Item name="menuKey" label="Menu ID">
          <Input />
        </Form.Item>
        {collected.name !== "MenuDivider" && <Form.Item name="title" label="Title">
          <Input />
        </Form.Item>}
        {(collected.name !== "MenuItemGroup" && collected.name !== "MenuDivider") &&
          <>
            <Form.Item name="icon" label="Icon">
              <IconSelect/>
            </Form.Item>
            {collected.name === "MenuItem" &&
              <>
                <Form.Item name="link" label="Link">
                  <Input />
                </Form.Item>
                <Form.Item name="dataPolicy" label="Data Policy">
                  <Select className="item-property" options={policyOptions} allowClear/>
                </Form.Item>
              </>
            }
          </>}
        <Form.Item name="roles" label="Roles">
          <Select placeholder="Roles" allowClear mode="multiple" intlPrefix="system.role.">
            {allRoles.map((r) => (
              <Select.Option key={r.name} value={r.name}><IntlMessages id={"system.role." + r.name} text={r.name} /></Select.Option>
            ))}
          </Select>
        </Form.Item>
      </>
    );
  }
}

Menu.craft = {
  displayName: "Menu Root",
  related: {
    settings: MenuSetting
  }
}

MenuItemGroup.craft = {
  displayName: "Menu Group",
  related: {
    settings: MenuSetting
  }
}

MenuItem.craft = {
  displayName: "Menu",
  related: {
    settings: MenuSetting
  }
}

MenuDivider.craft = {
  displayName: "Divider",
  related: {
    settings: MenuSetting
  }
}

SubMenu.craft = {
  displayName: "Sub-Menu",
  related: {
    settings: MenuSetting
  }
}
const setValueByFieldName = (props, allFields, name) => {
  for (const element of allFields) {
    if (element.name[0] === name) {
      props[name] = element.value;
      return;
    }
  }
}
const fieldsToActionProps = (props, allFields) => {
  setValueByFieldName(props, allFields, "mode");
  setValueByFieldName(props, allFields, "indent");
  setValueByFieldName(props, allFields, "menuKey");
  setValueByFieldName(props, allFields, "title");
  setValueByFieldName(props, allFields, "dashed");
  setValueByFieldName(props, allFields, "icon");
  setValueByFieldName(props, allFields, "link");
  setValueByFieldName(props, allFields, "dataPolicy");
  setValueByFieldName(props, allFields, "roles");
}
const SettingsPanel = () => {
  const [form] = Form.useForm();
  const { actions, selectedNode } = useEditor(EditorCollector);
  const fields = selectedNode ? [
    { name: ["selectedId"], value: `${selectedNode.displayName} (${selectedNode.id})` },
    { name: ["mode"], value: selectedNode.props.mode },
    { name: ["indent"], value: selectedNode.props.indent },
    { name: ["menuKey"], value: selectedNode.props.menuKey },
    { name: ["title"], value: selectedNode.props.title },
    { name: ["dashed"], value: selectedNode.props.dashed },
    { name: ["icon"], value: selectedNode.props.icon },
    { name: ["link"], value: selectedNode.props.link },
    { name: ["dataPolicy"], value: selectedNode.props.dataPolicy },
    { name: ["roles"], value: selectedNode.props.roles },
  ] : [];
  return selectedNode ? (
    <>
      <Form form={form} size={"small"} name="menusetup" colon={false} fields={fields} onFieldsChange={(_, allFields) => {
        try {
          actions.setProp(selectedNode.id, props => {
            fieldsToActionProps(props, allFields);
          });
        } catch (e) {
          log(e);
        }
      }}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
      >
        <Form.Item name="selectedId" label="Name">
          <Input className="selectedId" readOnly></Input>
        </Form.Item>
        {
          selectedNode.settings && React.createElement(selectedNode.settings)
        }
      </Form>
    </>
  ) : false;
};

export const getNavStyle = (menu, width) => {
  let navStyle = NAV_STYLE_FIXED;
  const mode = getMenuMode(menu, width);
  if (mode === 'horizontal') {
    navStyle = NAV_STYLE_DEFAULT_HORIZONTAL;
  }
  return navStyle;
}

export const getMenuMode = (menu, width) => {
 const nodeMap = tryParseJson(menu);
  if (width < AccountStore.MOBILE_BREAKPOINT) {
    return "vertical";
  } else if (nodeMap) {
    const node = nodeMap["ROOT"];
    let mode = node?.props?.mode ? node.props.mode : "vertical";
    return mode;
  } else {
    return "vertical";
  }
}

export const setupMenu = ({
  menu, preview, defaultOpenKeys, selectedKeys, theme, roles,
  messageApi, urlParams, onClick, dispatch, lbl, locale,
  location, width}) => {
  const nodeMap = tryParseJson(menu);
  if (nodeMap) {
    const node = nodeMap["ROOT"];
    // convert selected keys from pathname to internal keys
    selectedKeys = Object.values(nodeMap).filter(n => {
      if (selectedKeys && n?.props?.link) {
        let isMatch = selectedKeys.indexOf(n.props.link) !== -1
        if (isMatch) {
          setSelectedMenu(n);
        }
        return isMatch;
      } else {
        return false;
      }
    }).map(n => n.props.menuKey);
    let mode = node?.props?.mode ? node.props.mode : "vertical";
    if (width < AccountStore.MOBILE_BREAKPOINT) {
      mode = "vertical";
    }
    const menuStyle = {}
    if (mode !== 'horizontal') {
      theme = "dark";
    } else {
      menuStyle.width = `${(width - 400)}px`
      if (width < AccountStore.MOBILE_BREAKPOINT) {
        menuStyle.width = `${(width - 120)}px`
      }
      theme = "light"
    }
    if (location === 'sidebar' && mode === 'horizontal') {
      return null;
    }
    if (location === 'topbar' && mode !== 'horizontal') {
      return <span className='gx-header-menu-placeholder gx-d-none gx-d-lg-block'/>;
    }
    const indent = node?.props?.indent ? node.props.indent : 0;
    const linksMap = {};
    const moreCss = `craft-${mode}`
    const items = processNodeArray({nodeMap, node, preview, roles, messageApi, urlParams, onClick, dispatch, indent, linksMap, lbl, moreCss});
    console.log('setupMenu()', {location, mode, node, width, items})
    return <AntdMenu onClick={(item)=> {
        const n = linksMap[item.key];
        const link = n?.props?.link
        if (n && link) {
          if (isValidUrl(link)) {
            window.open(link);
          } else {
            if(item.domEvent.ctrlKey || item.domEvent.metaKey) {
              setSelectedMenu(item);
              window.open(getBaseUrl()+link);
            } else {
              onClick(n);
              setSelectedMenu(item);
              dispatch(push(link));
            }
          }
        }
      }}
      forceSubMenuRender={true}
      className={"craft-frame"} theme={theme} mode={mode}
      style={menuStyle}
      defaultOpenKeys={defaultOpenKeys} selectedKeys={selectedKeys}
      items={items[0].children}
    />;
  } else {
    if (location === 'topbar') {
      return <span className='gx-header-menu-placeholder gx-d-none gx-d-lg-block'/>;
    }
    return <AntdMenu
      className={"craft-frame"} theme={theme} mode={"inline"}
      defaultOpenKeys={defaultOpenKeys} selectedKeys={selectedKeys}>
    </AntdMenu>;
  }
}

const isRoleValid = (currentRoles, menuRoles) => {
  if (menuRoles && menuRoles.length > 0) {
    if (currentRoles && currentRoles.length > 0) {
      return menuRoles.filter(role => currentRoles.includes(role)).length > 0;
    } else {
      // no role available, restricted
      return false;
    }
  } else {
    // menu is not restricted by role
    return true;
  }
}

const processNodeArrayMenuItem = ({
  preview, roles, n, lbl, urlParams, linksMap,
  onClick, dispatch, indent, messageApi, moreCss}) => {
  if (preview || isRoleValid(roles, n.props.roles)) {
    const titleElement = prepareTitleElement(lbl("menu." + n.props.menuKey, n.props.title), n.props.icon)
    let link = n.props.link;
    if (link && urlParams) {
      link = prepareText(link, urlParams);
      n.props.link = link;
    }
    if (linksMap) {
      linksMap[n.props.menuKey] = n;
    }
    let contentElement = link ? <Link to={link}>{titleElement}</Link> : <span>{titleElement}</span>
    if (onClick && dispatch) {
      contentElement = <span onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        if (isValidUrl(link)) {
          window.open(link);
        } else {
          if(e?.ctrlKey || e?.metaKey) {
            window.open(getBaseUrl()+link);
          } else {
            onClick(n, link);
            dispatch(push(link));
          }
        }
      }}>{titleElement}</span>
    }
    if (preview) {
      contentElement = <span onClick={(e) => openLink(link, e, messageApi)}>{titleElement}</span>
    }
    const style = indent ? {"paddingLeft":indent+"px"} : null;
    return {
      key: n.props.menuKey,
      style: style,
      label: contentElement
    };
  } else {
    return null;
  }
}

const flattenMenuList = (menuList) => {
  const fMenuList = [];
  for (const menu of menuList) {
    if (Array.isArray(menu)) {
      for (const innerMenu of menu) {
        fMenuList.push(innerMenu);
      }
    } else {
      fMenuList.push(menu);
    }
  }
  return fMenuList;
}

const processNodeArray = ({
  nodeMap, node, preview, roles, messageApi, urlParams, onClick,
  dispatch, indent, linksMap, lbl, moreCss}) => {
  const nodes = node.nodes;

  const linkedNodes = Object.values(node.linkedNodes);
  if (nodes && nodes.length > 0) {
    const menuList = nodes.map(id => {
      const n = nodeMap[id];
      if (n.type.resolvedName === "MenuItemGroup") {
        if (preview || isRoleValid(roles, n.props.roles)) {
          return {
            type: "group",
            key: n.props.menuKey,
            label: lbl("menu." + n.props.menuKey, n.props.title),
            className: "gx-menu-group",
            children: processNodeArray({nodeMap, node: n, preview, roles, messageApi, urlParams, onClick, dispatch, indent, linksMap, lbl, moreCss})
          };
        } else {
          return null;
        }
      } else if (n.type.resolvedName === "Container") {
        return processNodeArray({nodeMap, node: n, preview, roles, messageApi, urlParams, onClick, dispatch, indent, linksMap, lbl, moreCss});
      } else if (n.type.resolvedName === "SubMenu") {
        if (preview || isRoleValid(roles, n.props.roles)) {
          const titleElement = prepareTitleElement(lbl("menu." + n.props.menuKey, n.props.title), n.props.icon, moreCss)
          const style = indent ? {"paddingLeft":indent+"px"} : null;
          return {
            label: titleElement,
            key: n.props.menuKey,
            style: style,
            children: processNodeArray({nodeMap, node: n, preview, roles, messageApi, urlParams, onClick, dispatch, indent, linksMap, lbl, moreCss}),
          };
        } else {
          return null;
        }
      } else if (n.type.resolvedName === "MenuItem") {
        if (n.props.dataPolicyData) {
          return n.props.dataPolicyData.map(dpd => {
            return processNodeArrayMenuItem({
            preview, roles, n: dpd, lbl, urlParams, linksMap,
            onClick, dispatch, indent, messageApi, moreCss
          })
          })
        } else {
          return processNodeArrayMenuItem({
            preview, roles, n, lbl, urlParams, linksMap,
            onClick, dispatch, indent, messageApi, moreCss
          })
        }
      } else if (n.type.resolvedName === "MenuDivider") {
        if (preview || isRoleValid(roles, n.props.roles)) {
          const style = indent ? {"paddingLeft":indent+"px"} : null;
          return {
            key: n.props.menuKey,
            style: style,
            dashed: n.props.dashed,
            type: "divider",
          };
        } else {
          return null;
        }
      } else {
        return null
      }
    });
    return flattenMenuList(menuList)
  } else if (linkedNodes && linkedNodes.length > 0) {
    const n = nodeMap[linkedNodes[0]];
    return processNodeArray({nodeMap, node: n, preview, roles, messageApi, urlParams, onClick, dispatch, indent, linksMap, lbl, moreCss});
  } else {
    return null;
  }
}


const TheFrame = ({ editable, showProperty}) => {
  const { selectedNode } = useEditor(EditorCollector);
  const { menu } = systemSignal;
  const locale = settingsSignal.locale;
  const lbl = useLbl(locale);
  const prevSelectedNode = useRef();
  useEffect(() => {
    if (prevSelectedNode.current !== selectedNode) {
      if (selectedNode) showProperty();
    }
    prevSelectedNode.current = selectedNode;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectedNode])

  const json = menu;
  if (editable) {
    return <Frame data={json}>
      <Element is={Menu} canvas>
        <MenuItemGroup title="Menu Item Group" />
      </Element>
    </Frame>
  } else {
    const preview = true;
    return setupMenu({menu, preview, lbl, location: 'preview'});
  }
}

const Topbar = ({ editable, setEditable }) => {
  const dispatch = useDispatch();
  const { menu } = systemSignal;
  const { actions, query, enabled, canUndo, canRedo } = useEditor((state, query) => {
    return {
      enabled: state.options.enabled,
      canUndo: query.history.canUndo(),
      canRedo: query.history.canRedo()
    }
  });

  const saveAction = () => {
    const menu = query.serialize();
    const patchedMenu = patchRealId(menu);
    dispatch(saveMenu(patchedMenu));
  }

  const loadAction = () => {
    dispatch(loadMenu());
  }

  useEffect(() => {
    if (menu) {
      actions.history.ignore().deserialize(menu);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menu])

  return (
    <div className="craft-top-bar">
      <input type="hidden" value={menu} />
      <Switch checked={editable} onChange={(checked) => {
        setEditable(checked);
      }} checkedChildren={"Edit"} unCheckedChildren={"Preview"} />
      <Button onClick={() => actions.history.undo()} disabled={!enabled || !canUndo}><UndoOutlined /></Button>
      <Button onClick={() => actions.history.redo()} disabled={!enabled || !canRedo}><RedoOutlined /></Button>
      <Button onClick={saveAction} disabled={!enabled} className="craft-top-bar-right"><SaveOutlined /></Button>
      <Button onClick={loadAction}><ReloadOutlined /></Button>
    </div>
  )
};

export {
  Library, Container, Menu, MenuItemGroup, SubMenu, MenuItem, MenuDivider, SettingsPanel, Topbar, TheFrame
};
