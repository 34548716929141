import Parse from 'parse/dist/parse.min.js';
import { AccountStore } from '../constants/Account';
import { delay, equals, getLocalStorageItem, getLocalStorageItemObject, getLocalStoragePrefix, isDateLongerThanMinutes, log, purifyJson, removeLocalStorageItem, setLocalStorageItem, setLocalStorageItemObject, sortKeysByLastUpdateDate, tryParseJson, waitForCondition } from "../util/algorithm";

import { message } from 'antd';
import { openDB } from 'idb';
import { getLbl, getUserLocale } from '../lngProvider';
import { clientConfigInitializedSignal, parseServerInitializedSignal, windowIdentitySignal } from '../util/signal';
import system from './system';
import { keycloakApi } from './keycloak-sso';
import { DataRetrievalLock } from '../util/singleton';

const localLastUpdateDateMap = 'localLastUpdateDateMap';

const CACHED_KEYS = [
  'getSystemVersion',
  'registerDataClassConfig',
  'dataExplorer_getDefaultDataClasses',
  'dataExplorer_getAllDataClasses',
  'dataExplorer_getActiveByKey{"dataClass":"SystemForm"',
  'dataExplorer_getActiveByKey{"dataClass":"SystemFlow"',
  'dataExplorer_getActiveByKey{"dataClass":"SystemPage"',
  'dataExplorer_getDataByKey{"dataClass":"SystemSelect"',
  'dataExplorer_getDataByKey{"dataClass":"SystemParameter"',
  'dataExplorer_getDataByKey{"dataClass":"User"',
  'getSystemPageActionPolicies',
  'getSystemFormActionPolicies',
  'dataExplorer_getAllSearch',
  'dataExplorer_getAllSearchResult',
  'getDataPolicyData',
]

const SELECT_KEYS = [
  'prepareSystemSelectOption',
  localLastUpdateDateMap,
]

const RELOAD_PAGE_KEYS = [
  'registerDataClassConfig',
  'dataExplorer_getActiveByKey{"dataClass":"SystemForm"',
  'dataExplorer_getActiveByKey{"dataClass":"SystemFlow"',
  'dataExplorer_getActiveByKey{"dataClass":"SystemPage"',
]

const ACTIVE_DATA_KEYS = [
  'registerDataClassConfig',
  'dataExplorer_getDefaultDataClasses',
  'dataExplorer_getAllDataClasses',
  'dataExplorer_getActiveByKey{"dataClass":"SystemForm"',
  'dataExplorer_getActiveByKey{"dataClass":"SystemFlow"',
  'dataExplorer_getActiveByKey{"dataClass":"SystemPage"',
  'dataExplorer_getDataByKey{"dataClass":"SystemSelect"',
  'dataExplorer_getDataByKey{"dataClass":"SystemParameter"',
  'dataExplorer_getDataByKey{"dataClass":"User"',
  'getSystemPageActionPolicies',
  'getSystemFormActionPolicies',
]

const SHARED_DATA_KEYS = [
  'dataExplorer_getAllSearch',
  'dataExplorer_getAllSearchResult',
]

const RELOAD_DATA_KEYS = [
  'getDataPolicyData',
]

const isMatchedCacheKey = (key, keys) => {
  let matched = false;
  for (const element of keys) {
    if (key.startsWith(element)) {
      matched = true;
      break;
    }
  }
  return matched;
}

const isActiveDataKey = (key) => {
  return isMatchedCacheKey(key, ACTIVE_DATA_KEYS);
}

const isSharedDataKey = (key) => {
  return isMatchedCacheKey(key, SHARED_DATA_KEYS);
}

const isReloadDataKey = (key) => {
  return isMatchedCacheKey(key, RELOAD_DATA_KEYS);
}

const isReloadPageKey = (key) => {
  return isMatchedCacheKey(key, RELOAD_PAGE_KEYS);
}

const isSelectKey = (key) => {
  return isMatchedCacheKey(key, SELECT_KEYS);
}

const getKeyStore = (key) => {
  if (key.includes('"isPreview":true')) return null;
  if (key.includes('"action":"save"')) return null;
  if (key.includes('"action":"delete"')) return null;

  let store = null;
  if (isMatchedCacheKey(key, CACHED_KEYS)) {
    store = "cache";
  }
  if (isMatchedCacheKey(key, SELECT_KEYS)) {
    store = "select";
  }
  return store;
}

const dbPromise = openDB('cache-store', 1, {
  upgrade(db) {
    db.createObjectStore('cache');
    db.createObjectStore('select');
  },
});

const MemoryStore = new Map();

function mdb_store(store) {
  let cache = MemoryStore.get(store);
  if (!cache) {
    cache = new Map();
    MemoryStore.set(store, cache);
  }
  return cache;
}

function mdb_get(store, key) {
  if (store) {
    const cache = mdb_store(store);
    return cache.get(key);
  } else {
    return null;
  }
}

function mdb_set(store, key, val) {
  if (store) {
    const cache = mdb_store(store);
    cache.set(key, val);
  }
}

function mdb_del(store, key) {
  if (store) {
    const cache = mdb_store(store);
    cache.delete(key);
  }
}


function mdb_clear(store) {
  const cache = mdb_store(store);
  return cache.clear();
}

export async function idb_get(key) {
  try {
    const store = getKeyStore(key);
    if (store) {
      const val = mdb_get(store, key);
      if (val) {
        return val;
      } else {
        return (await dbPromise).get(store, key);
      }
    } else {
      return null;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function idb_set(key, val) {
  try {
    const store = getKeyStore(key);
    if (store) {
      mdb_set(store, key, val);
      return (await dbPromise).put(store, val, key);
    } else {
      return null;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function idb_partialUpdate(key, record, dataIndex) {
  try {
    const val = await idb_get(key);
    if (val && Array.isArray(val)) {
      const index = val.findIndex((v => v[dataIndex] === record[dataIndex]));
      if (index !== -1) {
        val[index] = record;
        await idb_set(key, [...val]);
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function idb_del(key) {
  try {
    const store = getKeyStore(key);
    if (store) {
      mdb_del(store, key);

      return (await dbPromise).delete(store, key);
    } else {
      return null;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function idb_clear(store) {
  mdb_clear(store);
  return (await dbPromise).clear(store);
}

export async function idb_allKeys(store) {
  try {
    if (store) {
      return (await dbPromise).getAllKeys(store);
    } else {
      return null;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
}

Parse.Object.disableSingleInstance();

export const parse = Parse;
const acl = new Parse.ACL();
acl.setPublicReadAccess(true);
acl.setRoleWriteAccess("Administrator", true);

export const initializeParseSDK = async () => {
  console.log('initializeParseSDK()...')
  await clientConfigInitializedSignal.waitFor('value', true);
  Parse.initialize(AccountStore.PARSE_APPLICATION_ID);
  Parse.serverURL = AccountStore.PARSE_HOST_URL;
  Parse.enableEncryptedUser();
  Parse.secret = AccountStore.PARSE_USER_SECRET_KEY;
  parseServerInitializedSignal.value = true;
}

export const getACL = () => {
  const acl = new Parse.ACL();
  acl.setPublicReadAccess(true);
  acl.setRoleWriteAccess("Administrator", true);
  acl.setWriteAccess(Parse.User.current(), true);
  return acl;
}

export const excludedFields = [
  "messageKey","objectId","ACL","createdAt","updatedAt","className",
]

const fetchedData = {};

export const getData = (name, id) => {
  const map = fetchedData[name];
  let record = null;
  if (map) {
    record = map[id];
  }
  return record;
}

export const setData = (name, id, record) => {
  let map = fetchedData[name];
  if (!map) {
    map = {};
    fetchedData[name] = map;
  }
  map[id] = record;
}

export const loadData = async (name, key, id, selects) => {
  try {
    const type = Parse.Object.extend(name);
    const query = new Parse.Query(type);
    query.equalTo(key, id);
    if (selects) query.selects(selects);
    const results = await query.findAll();
    if (results && results.length > 0) {
      if (!fetchedData[name]) fetchedData[name] = {};
      fetchedData[name][id] = results[0];
      return results[0];
    } else {
      return undefined;
    }
  } catch (e) {
    return undefined;
  }
}

export const saveData = async (name, key, id, record, custAcl) => {
  const oldRecord = await loadData(name, key, id);
  record[key] = id;
  if (oldRecord) {
    record['className'] = name;
    record['objectId'] = oldRecord.id;
    const converted = Parse.Object.fromJSON(record, true, true);
    patchParseValue(record, converted);
    return converted.save();
  } else {
    const RecordType = Parse.Object.extend(name);
    const newRecord = new RecordType();
    newRecord.setACL(custAcl || getACL());
    return newRecord.save({
      ...record
    });
  }
}

const patchParseValue = (form, record) => {
  Object.keys(form).forEach(k => {
    const v = form[k];
    if (excludedFields.indexOf(k) === -1) {
      const parsed = JSON.parse(JSON.stringify(v));
      record.set(k, parsed);
    }
  });
}

export const addData = async (name, key, id, record, custAcl) => {
  const oldRecord = await loadData(name, key, id);
  record[key] = id;
  if (oldRecord) {
    log('record already exists, skipped', record);
    return oldRecord;
  } else {
    const RecordType = Parse.Object.extend(name);
    const record = new RecordType();
    record.setACL(custAcl || acl);
    return record.save({
      ...record
    });
  }
}

export const deleteData = async (name, key, id) => {
  const oldRecord = await loadData(name, key, id);
  if (oldRecord) {
    log('deleting record...', oldRecord);
    await oldRecord.destroy();
    return oldRecord;
  } else {
    log('fail to find record for delete.');
  }
}

export const fetch = async (name, key, selects ) => {
  try {
    const type = Parse.Object.extend(name);
    const query = new Parse.Query(type);
    if (selects) query.selects(selects);
    const results = await query.findAll();
    const resultMap = {};
    for (const element of results) {
      const record = element;
      const id = record.get(key);
      resultMap[id] = record;
    }
    fetchedData[name] = resultMap;
    return resultMap;
  } catch (e) {
    log(`fail to fetch data ${name} ${key}`, e);
    fetchedData[name] = {};
    return {};
  }
}

export const isParseObject = (obj) => {
  return obj ? (obj.toJSON !== undefined) : false;
}

export const convertToPOJO = (valueMap) => {
  if (!valueMap) {
    return valueMap;
  } else if (isParseObject(valueMap)) {
    return convertToPOJOSingle(valueMap);
  } else {
    const valueList = Object.values(valueMap);
    return valueList.map((vo => {
      return convertToPOJOSingle(vo);
    }));
  }
}

export const convertToPOJOSingle = (vo) => {
  if (!vo) {
    return vo;
  } else if (vo.toJSON) {
    const json = vo.toJSON();
    return json;
  } else {
    return vo;
  }
}

export const loadDataVersion = async (name, key, id, version) => {
  try {
    const type = Parse.Object.extend(name);
    const query = new Parse.Query(type);
    query.equalTo(key, id);
    if (version) {
      query.equalTo("documentVersion", version);
    } else {
      query.descending("documentVersion");
    }
    const results = await query.find();
    if (results && results.length > 0) {
      return results[0];
    } else {
      return undefined;
    }
  } catch (e) {
    return undefined;
  }
}

export const saveDataVersion = async (name, key, id, form) => {
  const RecordType = Parse.Object.extend(name);
  const oldRecord = await loadDataVersion(name, key, id);

  form[key] = id;
  let oldVersion = null;
  if (oldRecord) {
    // copy old form
    oldVersion = oldRecord.get("documentVersion");
    let oldForm = oldRecord.toJSON();
    oldForm = purifyJson(oldForm, excludedFields);
    oldForm['objectId'] = null;
    if (!oldVersion) {
      oldVersion = 1;
      oldForm["documentVersion"] = oldVersion;
    }

    const copyRecord = new RecordType();
    copyRecord.setACL(getACL());

    await copyRecord.save({
      ...oldForm
    });

    form['className'] = name;
    form['objectId'] = oldRecord.id;
    form['documentVersion'] = oldVersion + 1;
    const converted = Parse.Object.fromJSON(form, true, true);
    patchParseValue(form, converted);
    return converted.save();
  } else {
    const newRecord = new RecordType();
    newRecord.setACL(getACL());
    return newRecord.save({
      ...form, documentVersion: 1
    });
  }
}

export const deleteDataVersion = async (name, key, id, version) => {
  const oldRecord = await loadDataVersion(name, key, id, version);
  if (oldRecord) {
    await oldRecord.destroy();
    return oldRecord;
  } else {
    log('fail to find record for delete.');
  }
}

export const getBrowserTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

const timezone = getBrowserTimezone();
const RELOAD_QUEUE = new Map();

setInterval(async () => {
  const keys = []
  for (const pair of RELOAD_QUEUE) {
    const [key, fn] = pair;
    if (keys.length <= 20) {
      try {
        await fn();
      } catch (err) {
        console.log('error in reloading cache', err, key);
      }
      keys.push(key);
    }
  }
  keys.forEach(k => RELOAD_QUEUE.delete(k));
}, 60000);

setTimeout(async () => {
  console.log('start reload all cache');
  await initReloadAllCache();
  console.log('finished reload all cache');
}, 5000)

export const setRequestContext = (context) => {
  let rc = getLocalStorageItemObject('REQUEST_CONTEXT', {});
  if (context) {
    rc = {...rc, ...context}
  }
  setLocalStorageItemObject('REQUEST_CONTEXT', rc);
}

export const getRequestContext = () => {
  const userLocale = getUserLocale();
  const accessToken = keycloakApi.keycloak?.token;
  const windowIdentity = windowIdentitySignal.value;
  const installationId = windowIdentitySignal.installationId;
  const rcText = getLocalStorageItem('REQUEST_CONTEXT');
  let rc = tryParseJson(rcText) || {}
  return {...rc, timezone, userLocale, accessToken, windowIdentity, installationId}
}

const RequestContextListener = {};

export const registerRequestContextListener = (key, listener) => {
  RequestContextListener[key] = ({key, listener})
}

export const unregisterRequestContextListener = (key, listener) => {
  RequestContextListener[key] = undefined;
}

const fireRequestContextChange = (event) => {
  const oldValue = tryParseJson(event.oldValue) || {};
  const newValue = tryParseJson(event.newValue) || {};
  Object.values(RequestContextListener).forEach((rcl) => {
    if (rcl?.listener) {
      try {
        rcl.listener({ oldValue, newValue });
      } catch (error) {
        console.log("failed to execute request context listener", rcl, error);
      }
    }
  });
}

console.log('register storage listener...');
window.addEventListener('storage', function(event){
  console.log('storage event', event.key, event)
  if (event.key === getLocalStoragePrefix() + 'REQUEST_CONTEXT') {
    fireRequestContextChange(event);
  }
});

export const addCtx = (params) => {
  const requestContext = getRequestContext()
  return {...params, requestContext}
}

export const CloudRunWithoutCache = async (f, param) => {
  await parseServerInitializedSignal.waitFor('value', true);
  return Parse.Cloud.run(f, addCtx(param));
};

export const prepareIdbCacheKey = (f, param) => {
  const {locale, ...newParam} = param || {};
  const key = f+JSON.stringify(newParam);
  return key;
}

let isReloading = false;
let isInitialized = false;
const initReloadAllCache = async () => {
  if (!isInitialized) {
    isInitialized = true;
    await reloadAllCache();
  }
}

const getReloadDataDecision = async () => {
  const lastModified = await system.getActiveDataLastModified();
  const sharedLastModified = await system.getSharedDataLastModified();
  const oldLastModified = getLocalStorageItem("ActiveDataLastModified")
  const oldSharedLastModified = getLocalStorageItemObject("SharedDataLastModified")
  const noReloadActiveData = lastModified === oldLastModified;
  const noReloadSharedData = equals(sharedLastModified, oldSharedLastModified);
  const decision = {
    lastModified,
    sharedLastModified,
    oldLastModified,
    oldSharedLastModified,
    noReloadActiveData,
    noReloadSharedData};
  return decision;
}

const reloadAllCache = async () => {
  try {
    if (isReloading) {
      await waitForCondition(() => !isReloading, {label: 'reloadAllCache'})
    } else {
      const decision = await getReloadDataDecision();
      log("getReloadDataDecision()", {decision});
      const keyMap = await idb_get(localLastUpdateDateMap) || {};
      isReloading = true;
      const keys = await idb_allKeys('cache');
      if (keys) {
        const sortedKeys = sortKeysByLastUpdateDate(keyMap, keys);
        for (const key of sortedKeys) {
          if(isCacheNeedReload({key, ...decision})) {
            idb_del(key);
          }
        }
        for (const key of sortedKeys) {
          const lastUpdate = keyMap[key];
          if (!lastUpdate || isDateLongerThanMinutes(lastUpdate, 10)) {
            await reloadAllCacheOne({key, ...decision})
            keyMap[key] = new Date();
            idb_set(localLastUpdateDateMap, keyMap);
          }
        }
        if (!decision.noReloadActiveData) setLocalStorageItem("ActiveDataLastModified", `${decision.lastModified}`);
        if (!decision.noReloadSharedData) setLocalStorageItemObject("SharedDataLastModified", decision.sharedLastModified);
      }
    }
    isReloading = false;
  } catch (e2) {
    isReloading = false;
    console.log('reloadAllCache() error', e2)
  }
}

const getLocalDataUpdatedAt = (old) => {
  let updatedAt = null;
  if (old) {
    if (Array.isArray(old)) {
      for (const o in old) {
        if (!updatedAt || o?.updatedAt > updatedAt) {
          updatedAt = o?.updatedAt;
        }
      }
    } else {
      updatedAt = old.updateAt;
    }
  }
  return updatedAt;
}

const isActiveDataNeedReload = async ({
  key, needReload, noReloadActiveData, lastModified
}) => {
  if (isActiveDataKey(key)) {
    if (noReloadActiveData) {
      needReload = false;
    } else {
      const old = await idb_get(key)
      const updatedAt = getLocalDataUpdatedAt(old);
      if (updatedAt && updatedAt > lastModified) {
        needReload = false;
      }
    }
  }
  return needReload;
}

const isSharedDataNeedReload = async ({
  key, needReload, noReloadActiveData, noReloadSharedData, lastModified, sharedLastModified
}) => {
  if (isSharedDataKey(key)) {
    if (noReloadActiveData && noReloadSharedData) {
      needReload = false;
    } else {
      const old = await idb_get(key);
      const updatedAt = getLocalDataUpdatedAt(old);
      if (updatedAt && updatedAt > lastModified && updatedAt > sharedLastModified) {
        needReload = false;
      }
    }
  }
  return needReload;
}

const isCacheNeedReload = ({
  key, lastModified, sharedLastModified,
  noReloadActiveData, noReloadSharedData}) => {
  const params = {key, noReloadActiveData,
    noReloadSharedData, lastModified, sharedLastModified}
  if (!key.includes('"isPreview":true')) {
    params.needReload = true;
    if (params.needReload && !isActiveDataNeedReload(params)) params.needReload = false;
    if (params.needReload && !isSharedDataNeedReload(params)) params.needReload = false;
    return params.needReload;
  } else {
    idb_del(key);
    return false;
  }
}

const reloadAllCacheOne = async ({
  key, lastModified, sharedLastModified,
  noReloadActiveData, noReloadSharedData}) => {
  const params = {key, noReloadActiveData,
    noReloadSharedData, lastModified, sharedLastModified}
  try {
    if (!key.includes('"isPreview":true')) {
      params.needReload = true;
      if (params.needReload && !isActiveDataNeedReload(params)) params.needReload = false;
      if (params.needReload && !isSharedDataNeedReload(params)) params.needReload = false;
      if (params.needReload) {
        await reloadAllCacheOneImpl({key})
      }
    } else {
      idb_del(key);
    }
  } catch (e1) {
    if (`${e1}`.includes('NOTFOUND')) {
      idb_del(key);
    } else {
      console.log('reloadAllCache() error', key, e1)
    }
  }
}

const reloadAllCacheOneImpl = async ({key}) => {
  const start = key.indexOf('{');
  const fn = key.substring(0, start);
  const pm = key.substring(start);
  const param = JSON.parse(pm);
  const cachedData = await idb_get(key);
  if (cachedData?.versionStamp) {
    param.currentVersionStamp = cachedData.versionStamp;
  }
  const result = await CloudRunWithCacheImpl(fn, param, true);
  if (result && result !== "NO_UPDATE") {
    idb_set(key, result);
  }
}

const performPageReloadCondition = async ({key, cachedData, result}) => {
  if (isReloading) return;
  const reloadCacheKey = 'reload_cache';
  if (cachedData?.versionStamp && result?.versionStamp) {
    if (isReloadPageKey(key)) {
      if (cachedData?.versionStamp !== result?.versionStamp) {
        console.log("local cache updated trigger", {cachedData, result})
        await reloadAllCache();
        message.info({
          content: getLbl(
            "system.new_version_cache_available.description",
            "A new version is loaded into cache."
          ),
          key: reloadCacheKey,
        });
      }
    }
  }
}

const relelaseLock = (lock, releaseKey, data, error) => {
  if (releaseKey) {
    lock.setData(releaseKey, data, error);
    delay(10000).then(() => {
      lock.release(releaseKey, data, error);
    })
  }
}

export const CloudRunWithCache = async (f, param, nocache, defaultValue) => {
  const key = prepareIdbCacheKey(f, param);
  let cachedData = null;
  let releaseKey = null;
  const lock = DataRetrievalLock.getLock(key);

  const data = await lock.acquire(30000, nocache);
  if (data) {
    releaseKey = DataRetrievalLock.getReleaseKey(data);
    if (releaseKey) {
      console.log('CloudRunWithCache releaseKey', releaseKey, key)
    }
    if (!releaseKey) {
      return data;
    }
  }

  if (!nocache) {
    cachedData = await idb_get(key);
  }

  await waitForCondition(() => !isReloading, {timeout: 100, label: 'CloudRunWithCache'})

  if (cachedData) {
    if (isReloadDataKey(key) || isReloadPageKey(key) || isSelectKey(key)) {
      RELOAD_QUEUE.set(key, async () => {
        try {
          const result = await CloudRunWithCacheImpl(f, param, defaultValue);
          idb_set(key, result);
          performPageReloadCondition({key, cachedData, result});
        } catch (error) {
          console.log("error", error);
        }
      })
    }
    relelaseLock(lock, releaseKey, cachedData);
    return cachedData;
  } else {
    let result = null;
    try {
      result = await CloudRunWithCacheImpl(f, param, defaultValue);
      idb_set(key, result);
      relelaseLock(lock, releaseKey, result);
    } catch (error) {
      console.log("error", error);
      relelaseLock(lock, releaseKey, null, error);
      throw error;
    }
    return result;
  }
}

export const CloudRunWithCacheImpl = async (f, param, defaultValue) => {
  let serverOffline = getLocalStorageItem('server_offline');
  if (serverOffline) {
    const current = new Date().getMilliseconds();
    if (current - serverOffline > (1000 * 60)) {
      removeLocalStorageItem('server_offline');
      serverOffline = null;
    }
  }
  if (serverOffline) {
    return defaultValue;
  }
  try {
    await parseServerInitializedSignal.waitFor('value', true);
    const data = await Parse.Cloud.run(f, addCtx(param));
    return data;
  } catch (error) {
    if (updateServerOfflineStatus(error)) {
      // is offline
      return defaultValue;
    } else {
      throw error;
    }
  }
}

export const getServerOffline = async () => {
  try {
    const param = {};
    await CloudRunWithoutCache("getSystemVersion", param);
    updateServerOfflineStatus(null);
    return false;
  } catch (error) {
    return !!(updateServerOfflineStatus(error));
  }
};

const updateServerOfflineStatus = (error) => {
  if (error) {
    if (!`${error}`.match(/(Unable to connect to the Parse API|has been blocked by CORS policy)/)) {
      return false;
    } else {
      setLocalStorageItem('server_offline', new Date().getTime());
      return true;
    }
  } else {
    removeLocalStorageItem('server_offline');
    return false;
  }
}

initializeParseSDK();

export default parse;

