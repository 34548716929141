import { useNode } from "@craftjs/core";
import { Row } from "antd";
import React from "react";
import { convertStyleStr, getElements, NodeCollector, registerComponent } from "./common";

export const Container = ({...props}) => {
  const { connectors: { connect, drag }, style } = useNode(NodeCollector);
  return (
    <SfpContainer doRef={ref => connect(drag(ref))} style={style} {...props}/>
  )
}

export const SfpContainer = ({
  doRef, style, dbtn, itemKey, noStyle, children, styleStr, className, ...otherProps }) => {
   if (noStyle) {
    return (
      <div ref={doRef}>
        {children}
      </div>
    )
  } else {
    let sls = convertStyleStr(styleStr);
    let cls = "craft-form-container";
    if (className) cls = cls + " " + className;
    if (style) sls = sls ? {...sls, ...style} : style;
    return (
      <Row ref={doRef} {...otherProps} className={cls} style={sls}>
        {children}
      </Row>
    )
  }
}

const LOCKED_LIST = ["SfButton"];
const ACCEPTED_LIST = ["SfTabsPane"];

Container.craft = {
  rules: {
    canMoveIn: function (incomingNode, currentNode) {
      const REJECTED_LIST = getElements("Container");
      if (currentNode.data.props.type === "SfPanelList") {
        return false;
      } else if (currentNode.data.props.type === "SfTabs") {
        let rtnVal = false;
        rtnVal = (ACCEPTED_LIST.indexOf(incomingNode.data.name) !== -1);
        return rtnVal;
      } else if (currentNode.data.props.type === "SfTable" || currentNode.data.props.type === "SfTableControl") {
        return false;
      } else if ((currentNode.data.props.type === "SfTabsPane"  ||  currentNode.data.props.type === "SfGroup") && incomingNode.data.name === "SfTable") {
        return true;
      }  else {
        let rtnVal = false;
        rtnVal = (REJECTED_LIST.indexOf(incomingNode.data.name) === -1) &&
                 (LOCKED_LIST.indexOf(incomingNode.data.name) === -1);
        return rtnVal;
      }
    },
    canMoveOut: function(outgoingNode, currentNode) {
      let rtnVal = false;
      rtnVal = (LOCKED_LIST.indexOf(outgoingNode.data.name) === -1);
      return rtnVal;
    },
  }
}

registerComponent({
  key:"Container",
  component: Container,
  runtimeComponent: SfpContainer,
  type: "Container",
});

export default Container;