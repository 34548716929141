import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { useEditor, useNode } from "@craftjs/core";
import { Button, Comment, InputNumber, List, message, Popover, Space, Switch, Typography, Upload } from "antd";
import { Form, FormContext, Input, Select } from "components/Form";
import UserAvatar from "components/Form/UserAvatar";
import { push } from 'connected-react-router';
import { AccountStore } from "constants/Account";
import { getMessage } from "lngProvider";
import moment from "moment";
import { comment, dataExplorer, system } from "parse-api";
import { handleUploadFile } from "parse-api/file";
import React, { useEffect, useRef, useState } from "react";
import { BiCommentDetail } from "react-icons/bi";
import { BsBell } from "react-icons/bs";
import { BsBellFill } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { getArraysIntersection, log, uid } from "util/algorithm";
import IntlMessages from "util/IntlMessages";
import { toAuthUserObj } from "../../../../util/algorithm";
import { convertStyleStr, deleteButton, EditorCollector, getId, NodeCollector, registerComponent } from "./common";
import { MyMarkdown } from "./SfMarkdown";
import { MyQuillEditor } from "./SfQuillEditor";
import { MyCKEditor } from "./SfTextEditor";
import { SfpUserAvatar } from "./SfUserAvatar";
import { authSignal, settingsSignal } from "../../../../util/signal";

const SfCommentSetting = () => {
  const [allEmailOptions, setAllEmailOptions] = useState([]);
  const [allSmsOptions, setAllSmsOptions] = useState([]);
  const mounted = useRef();

  useEffect(() => {
    mounted.current = true;
    const fetchData = async() => {
      const emailPolicies = await dataExplorer.searchAll("SystemEmailPolicy", null, "emailName");
      setAllEmailOptions(emailPolicies.filter(p => {
        if (p.dataTypes) {
          if (getArraysIntersection(p.dataTypes, ['newcomment']).length > 0) {
            return true;
          }
        }
        return false;
      }).map(p=>({label:p.emailName,value:p.emailKey})));
      const smsPolicies = await dataExplorer.searchAll("SystemSmsPolicy", null, "smsName");
      setAllSmsOptions(smsPolicies.filter(p => {
        if (p.dataTypes) {
          if (getArraysIntersection(p.dataTypes, ['newcomment']).length > 0) {
            return true;
          }
        }
        return false;
      }).map(p=>({label:p.smsName,value:p.smsKey})))
    }
    fetchData();
    return () => {
      mounted.current = false;
    }
  }, [])
  return (
    <>
      <Form.Item name="title" label="title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="bordered" label="bordered" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="editor" label="editor">
        <Select className="item-property">
          <Select.Option value="textarea">textarea</Select.Option>
          <Select.Option value="markdown">markdown</Select.Option>
          <Select.Option value="richtext">richtext</Select.Option>
          <Select.Option value="quill">quill</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="imageBase64" label="image base64" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="highlight" label="highlight" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="attachment" label="attachment" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="maxCount" label="max count">
        <InputNumber min="1" className="item-property" />
      </Form.Item>
      <Form.Item name="accept" label="accept">
        <Space>
          <Form.Item name="accept" label="accept" noStyle={true}>
            <Input className="item-property" />
          </Form.Item>
          <Typography.Link
            target="_blank" rel="noopener noreferrer"
            href="https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#accept">Need Help?</Typography.Link>
        </Space>
      </Form.Item>
      <Form.Item name="acceptFileTypes" label="file types">
        <Select className="item-property" mode="tags"/>
      </Form.Item>
      <Form.Item name="acceptFileExtensions" label="file exts">
        <Select className="item-property" mode="tags"/>
      </Form.Item>
      <Form.Item name="defaultSharing" label="default sharing">
        <Select className="item-property">
          <Select.Option value="public-image-protected-file">public-image-protected-file</Select.Option>
          <Select.Option value="public">public</Select.Option>
          <Select.Option value="protected">protected</Select.Option>
          <Select.Option value="private">private</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="alwaysEnable" label="always enable" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="toggleEnable" label="toggle enable" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="emailAlert" label="email alert">
        <Select className="item-property" options={allEmailOptions} allowClear showSearch />
      </Form.Item>
      <Form.Item name="smsAlert" label="sms alert">
        <Select className="item-property" options={allSmsOptions} allowClear showSearch />
      </Form.Item>
      <Form.Item name="allowSubscribe" label="watch" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="editorStyleStr" label="editor style">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="pageSize" label="page size">
        <InputNumber className="item-property" min={3} max={20} />
      </Form.Item>
    </>
  );
}

export const SfComment = ({ ...props }) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  return (
    <SfpComment doRef={ref => connect(drag(ref))} style={style} dbtn={dbtn} {...props}></SfpComment>
  )
}

const rowKey = (record) => {
  if (record.rowKey) return record.rowKey;
  log("row has no id", record);
  return null;
}

const CommentList = ({ comments, textarea, richtext, markdown, quill, reply, ...otherProps }) => {
  const dispatch = useDispatch();
  const onDownload = (file) => {
    if (file.url && file.url.startsWith(AccountStore.PARSE_HOST_URL+'/files/')) {
      dispatch(push(file.url.replace(AccountStore.PARSE_HOST_URL+'/files/', '/parse/files/')))
    } else {
      log("file is not host in this server", file);
    }
  }
  const prepareContent = (props) => {
    const fileList = props.fileList;
    return (
      <>
        {markdown && <MyMarkdown readOnly={true} value={props.content} />}
        {richtext && <MyCKEditor readOnly={true} value={props.content} />}
        {quill && <MyQuillEditor readOnly={true} value={props.content} />}
        {textarea && <p>{props.content}</p>}
        {fileList && fileList.length > 0 && <Upload fileList={fileList} showUploadList={{ showDownloadIcon: true, showRemoveIcon: false }} onDownload={onDownload} />}
      </>
    )
  }
  return (
    <List
      dataSource={comments}
      rowKey={rowKey} {...otherProps}
      header={`${comments.length} ${comments.length > 1 ? 'replies' : 'reply'}`}
      itemLayout="horizontal"
      renderItem={props => {
        const actions = [];
        if (reply) {
          actions.push(<span onClick={() => reply(props)}><IntlMessages id="system.form.comment.reply" text="Reply" /></span>);
        }
        return (
          <Comment
            author={props.author?.nickname}
            avatar={<UserAvatar userObj={props.author}/>}
            datetime={props.datetime?.calendar()}
            actions={actions}
            content={prepareContent(props)}></Comment>
        )
      }}
    />
  )
};

const getUploadButton = (listType) => {
  if (listType === "picture-card") {
    return (
      <div>
        <PlusOutlined />
        <div className="upload-div" style={{ marginTop: 8 }}><IntlMessages id="system.form.library.upload" text="Upload" /></div>
      </div>
    );
  } else {
    return (
      <Button className="upload-btn" icon={<UploadOutlined />}><IntlMessages id="system.form.library.upload" text="Upload" /></Button>
    );
  }
}

const Editor = ({ editorRef, style, editorStyle,
  onChange, onSubmit, onCancel, submitting, value, textarea, richtext, markdown, quill,
  attachment, maxCount, imageBase64, highlight, accept,
  beforeUpload, onRemoveAttachment, uploading, fileList, editing, toggleEnable }) => {
  const [toggle, setToggle] = useState(false)
  if (toggleEnable && !toggle) {
    return (
      <Form.Item>
        <Button onClick={()=> setToggle(true)} className="add-comment">
          <IntlMessages id="system.form.comment.add-comment" text="Add Comment" />
        </Button>
      </Form.Item>
    )
  } else {
    return (
      <>
        <Form.Item>
          {markdown &&
            <MyMarkdown editorRef={editorRef} onChange={onChange} value={value} minHeight={100} />
          }
          {richtext &&
            <MyCKEditor editorRef={editorRef} editorStyle={editorStyle} onChange={onChange} value={value} imageBase64={imageBase64} highlight={highlight}/>
          }
          {quill &&
            <MyQuillEditor editorRef={editorRef} editorStyle={editorStyle} onChange={onChange} value={value} imageBase64={imageBase64} highlight={highlight}/>
          }
          {textarea &&
            <Input.TextArea ref={editorRef} autoSize={true} onChange={(e) => onChange(e.target.value)} value={value} />
          }
        </Form.Item>
        {attachment && <Form.Item>
          <Upload
            accept={accept}
            beforeUpload={beforeUpload}
            onRemove={onRemoveAttachment}
            fileList={fileList}
            maxCount={maxCount}>
            {getUploadButton()}
          </Upload>
        </Form.Item>}
        <Form.Item>
          <Button loading={submitting} disabled={uploading || !editing} onClick={onSubmit} className="add-comment">
            <IntlMessages id="system.form.comment.add-comment" text="Add Comment" />
          </Button>
          <Button disabled={uploading || !editing} onClick={onCancel} className="cancel">
            <IntlMessages id="system.form.comment.cancel" text="Cancel" />
          </Button>
        </Form.Item>
      </>
    )
  }
};

const MyComment = ({
  objectId, className, itemKey, commentKey, textarea, richtext, markdown, quill,
  attachment, maxCount, imageBase64, highlight, accept, acceptFileTypes, acceptFileExtensions, defaultSharing,
  demo, style, editorStyle, disabled, toggleEnable, setIsSubmitting, emailAlert, smsAlert,
  bell, setBell, subscribers, setSubscribers, fireStateEvent,
  ...otherProps }) => {
  const { locale } = settingsSignal;
  const [systemComment, setSystemComment] = useState();
  const [comments, setComments] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState(null);
  const [localFileList, setLocalFileList] = useState([]);
  const [uploading, setUploading] = useState(false);

  const [api, contextHolder] = message.useMessage();
  const editorRef = useRef();
  const { authUser } = authSignal;
  const authUserObj = toAuthUserObj(authUser);
  const replyText = getMessage(locale.languageId, "system.form.comment.reply", "Reply");
  const mounted = useRef();
  const subject = useRef();
  const subjectKey = useRef();

  const shouldDisable = disabled && !editing;

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
      if (subject.current) {
        system.unsubscribe(subject.current, subjectKey.current);
        subject.current = null;
        subjectKey.current = null;
      }
    }
  }, []);

  useEffect(() => {
    if (localFileList.length === 0 && (!value || value === '<p><br></p>')) {
      if (editing) {
        log('isEditing', false);
        setEditing(false);
        if (setIsSubmitting) setIsSubmitting({});
      }
    } else {
      if (!editing) {
        log('isEditing', true);
        setEditing(true);
        if (setIsSubmitting) setIsSubmitting({any:true});
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, localFileList]);

  useEffect(() => {
    fireStateEvent(itemKey + '_changed', comments)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comments])

  const onRemoveAttachment = async (file) => {
    const index = localFileList.indexOf(file);
    const newFileList = localFileList.slice();
    newFileList.splice(index, 1);
    setLocalFileList(newFileList);
  }

  const beforeUpload = async (file) => {
    const fileList = [...localFileList];
    let newFileList = null;
    if (maxCount) {
      if (maxCount > 1) {
        if (fileList.length >= maxCount) {
          api.error(<IntlMessages id="system.form.upload_limit" text="Reached max file count ({maxCount})" values={{ maxCount: maxCount }} />);
        } else {
          newFileList = [...fileList, file];
        }
      } else if (maxCount === 1) {
        newFileList = [file];
      }
    } else {
      newFileList = [...fileList, file];
    }

    if (newFileList) {
      if (mounted.current) setLocalFileList(newFileList);
    }
    return false;
  }

  useEffect(() => {
    localFileList.forEach(f => {
      if (!f.status) {
        f.permission = defaultSharing;
        if (mounted.current) setUploading(true);
        handleUploadFile(f.name, f, (file, progressValue, info, parseFile, error) => {
          let changed = false;
          let completed = false;
          if (info) {
            const { type } = info;
            if (type === "upload" && progressValue !== null) {
              file.percent = progressValue * 100;
              file.status = "uploading";
              changed = true;
            }
            if (type === "download") {
              file.status = 'done';
              changed = true;
            }
          } else if (parseFile) {
            const index = localFileList.indexOf(file);
            const newFile = { ...file };
            newFile.url = parseFile.url();
            newFile.fileKey = parseFile.name();
            newFile.name = file.name;
            newFile.originFileObj = undefined;
            localFileList[index] = newFile;
            changed = true;
            completed = true;
          } else if (error) {
            if (React.isValidElement(error)) {
              api.error(error);
            } else {
              api.error(`${error}`);
            }
            file.status = 'error';
            changed = true;
            completed = true;
          }
          if (changed) {
            const newFileList = [...localFileList];
            if (mounted.current) setLocalFileList(newFileList);
            if (completed) {
              if (mounted.current) setUploading(false);
            }
          }
        }, acceptFileTypes, acceptFileExtensions);
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localFileList])

  useEffect(() => {
    const fetchData = async () => {
      if (className && objectId && itemKey && commentKey) {
        try {
          const systemComment = await comment.getSystemComment(className, itemKey, objectId, commentKey);
          setSystemComment(systemComment);
          setComments(systemComment.comments ? systemComment.comments : []);
          if (setBell) setBell(systemComment.subscribers && systemComment.subscribers.indexOf(authUserObj.username) !== -1);
        } catch (error) {
          log("failed to get comment", error);
          api.error(<IntlMessages id="system.form.get-comment-failed" text="Failed to get comment. Please reload the page."/>);
        }
      }
    }
    fetchData();
    const handleNotification = async () => {
      const newSubject = `${className}_${itemKey}_${objectId}`;
      log('subject', newSubject);
      if (className && itemKey && objectId) {
        if (subject.current !== newSubject) {
          if (subject.current) {
            await system.unsubscribe(subject.current, subjectKey.current);
            subject.current = null;
          }
          subjectKey.current = await system.subscribe(newSubject, (notifyId) => {
            log("fetch data...", notifyId);
            fetchData();
            system.received(notifyId);
          })
          subject.current = newSubject;
        }
      }
    }
    requestAnimationFrame(handleNotification);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectId, itemKey, className, commentKey])

  useEffect(() => {
    doSyncBellValue();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bell]);

  useEffect(() => {
    if (setSubscribers) setSubscribers(systemComment?.subscribers ? systemComment.subscribers.sort() : []);
  }, [setSubscribers, systemComment])

  const doSyncBellValue = async () => {
    if (systemComment) {
      let subscribed = false;
      if (systemComment.subscribers) {
        subscribed = systemComment.subscribers.indexOf(authUserObj.username) !== -1;
      }
      if (subscribed !== bell) {
        const newSystemComment = await comment.subscribeSystemComment(className, itemKey, objectId, commentKey, !!bell);
        log("comments", newSystemComment);
        setSystemComment(newSystemComment);
      }
    }
  }

  const handleSubmit = async () => {
    try {
      if (!value) {
        return;
      }
      if (mounted) setSubmitting(true);
      if (demo) {
        await new Promise(r => setTimeout(r, 1000));
        if (mounted) setComments([...comments, {
          rowKey: uid(),
          author: authUserObj,
          content: value,
          richtext: richtext || quill,
          markdown: markdown,
          quill: quill,
          fileList: localFileList.filter(f => f.status === 'done'),
          datetime: moment(),
        }]);
      } else if (className && objectId && itemKey) {
        const newComment = {
          content: value,
          richtext: richtext || quill,
          markdown: markdown,
          fileList: localFileList.filter(f => f.status === 'done'),
          datetime: moment(),
        };
        try {
          const newSystemComment = await comment.addSystemComment(className, itemKey, objectId, commentKey, newComment, emailAlert, smsAlert, window.location.href);
          log("comments", newSystemComment);
          const newSubject = `${className}_${itemKey}_${objectId}`;
          system.notify(newSubject);
          setSystemComment(newSystemComment);
          setComments(newSystemComment.comments ? newSystemComment.comments : []);
        } catch (error) {
          log("failed to add comment", error);
          api.error(<IntlMessages id="system.form.add-comment-failed" text="Failed to add comment. Please reload the page."/>);
        }
      } else {
        api.error(<IntlMessages id="system.form.comment.missing-parameter" text="missing parameter" />);
      }
      if (mounted) setValue(null);
      if (mounted) setSubmitting(false);
      if (mounted) setLocalFileList([]);
    } catch (e) {
      console.error("fail to save system comment", e);
      api.error(`${e}`);
    }
  };

  const handleCancel = () => {
    setValue(null);
    setLocalFileList([]);
  };

  const handleChange = (value) => {
    setValue(value);
  };

  const reply = (props) => {
    let val = props.content;
    if (val) {
      const datetime = props.datetime;
      const replyHeader =
        (replyText ? replyText : "Reply") + " " +
        props.author.nickname + " " +
        datetime.format('MMMM Do YYYY, h:mm:ss a');
      if (richtext || quill) {
        const newVal = "<p>"+replyHeader+"</p><blockquote>"+val+"</blockquote><p>&nbsp;</p>";
        val = newVal;
      } else {
        const lines = val.split("\n")
        const qouted = [];
        qouted.push(replyHeader);
        lines.forEach(l => {
          qouted.push(">" + l);
        })
        qouted.push("");
        const newVal = qouted.join("\n");
        val = newVal;
      }
    }
    setValue(val);
    if (editorRef.current?.focus) {
      editorRef.current.focus({ cursor: "end" });
    }
  }

  if (demo || (objectId && className)) {
    return (
      <>
        {contextHolder}
        {comments.length > 0 &&
          <CommentList
            comments={comments}
            textarea={textarea}
            richtext={richtext}
            markdown={markdown}
            quill={quill}
            reply={!shouldDisable ? reply : undefined}
            {...otherProps}
          />}
        {!shouldDisable && <Editor
            editorRef={editorRef}
            onChange={handleChange}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            submitting={submitting}
            textarea={textarea}
            richtext={richtext}
            markdown={markdown}
            quill={quill}
            editorStyle={editorStyle}
            value={value}
            attachment={attachment}
            maxCount={maxCount}
            imageBase64={imageBase64}
            highlight={highlight}
            onRemoveAttachment={onRemoveAttachment}
            fileList={localFileList}
            beforeUpload={beforeUpload}
            uploading={uploading}
            editing={editing}
            toggleEnable={toggleEnable}
            accept={accept}
          />}
      </>
    );
  } else {
    return null;
  }
}

export const SfpComment = ({
  formKey, formview, doRef, style, dbtn, children, itemKey, className, styleStr, title,
  defaultSharing, bordered, pageSize, editor, editorStyleStr, attachment, maxCount,
  imageBase64, highlight, disabled, alwaysEnable, toggleEnable, emailAlert, smsAlert,
  allowSubscribe, fireStateEvent, ...otherProps }) => {
  const cls = "ant-card ant-card-bordered " + (className ? className : "");
  const [bell, setBell] = useState(false);
  const [subscribers, setSubscribers] = useState([]);
  let sls = convertStyleStr(styleStr);
  const parentObjectId = Form.useWatch("objectId");
  const editorStyle = convertStyleStr(editorStyleStr);
  if (style) sls = sls ? { ...sls, ...style } : style;

  if (!doRef) {
    if (alwaysEnable) {
      disabled = false;
    }
  }

  const pagination = pageSize ? { pageSize: pageSize } : null
  const richtext = editor === "richtext";
  const markdown = editor === "markdown";
  const quill = editor === "quill";
  const textarea = editor === "textarea";
  const content = (
    <Space direction="horizontal">
      {subscribers.map(s => {
        return <SfpUserAvatar key={s} username={s} size="small" />
      })}
    </Space>
  );
  return parentObjectId ? (
    <div ref={doRef} className={cls} style={sls}>
      <div className="ant-card-head">
        <div className="ant-card-head-wrapper">
          <div className="ant-card-head-title comment-title-bar">
          <span className="comment-title"><IntlMessages id={`form.${formKey}.${itemKey}`} text={title}/></span>
          {allowSubscribe && <span className="comment-subscribe-button">
            <Popover title={<IntlMessages id="system.form.comment.subscribers" text="Subscribers"/>} content={content} placement="bottomRight">
              <Button size="small" shape="circle" type={"text"} className={bell ? 'subscribed' : 'non-subscribed'} icon={bell ? <BsBellFill/> : <BsBell/>} onClick={e => setBell(!bell)}/>
            </Popover>
          </span>}
          </div>
        </div>
      </div>
      <div className="ant-card-body">
        <FormContext.Consumer>
          {(formCtx) => {
            const form = formCtx?.formInstance;
            const setIsSubmitting = formCtx?.setIsSubmitting;
            const objectId = form ? form.getFieldValue(["objectId"]) : null;
            const className = form ? form.getFieldValue(["className"]) : null;
            const commentKey = form ? form.getFieldValue([itemKey]) : null;
            return (
              <MyComment
                objectId={objectId}
                itemKey={itemKey}
                className={className}
                bordered={bordered}
                textarea={textarea}
                richtext={richtext}
                markdown={markdown}
                quill={quill}
                style={sls}
                editorStyle={editorStyle}
                pagination={pagination}
                demo={doRef != null}
                attachment={attachment}
                maxCount={maxCount}
                imageBase64={imageBase64}
                highlight={highlight}
                disabled={disabled}
                toggleEnable={toggleEnable}
                commentKey={commentKey}
                defaultSharing={defaultSharing}
                setIsSubmitting={setIsSubmitting}
                emailAlert={emailAlert}
                smsAlert={smsAlert}
                bell={bell} setBell={setBell}
                subscribers={subscribers} setSubscribers={setSubscribers}
                fireStateEvent={fireStateEvent}
              />
            )
          }}
        </FormContext.Consumer>
      </div>
      {dbtn}
    </div>
  ) : null;
}
SfpComment.enableStateEvent = true;

SfComment.craft = {
  displayName: "Comment",
  related: {
    settings: SfCommentSetting
  }
}

SfComment.validate = (props, {extraParams, formData}) => {
  props.formKey = formData.formKey;
}

registerComponent({
  key: "SfComment",
  component: SfComment,
  runtimeComponent: SfpComment,
  template: <SfComment itemKey={getId('comment')} className="sf-panel sf-comment" title={"Comment"} editor="textarea" editorStyleStr={"width:100%,minHeight:100px,borderWidth:1,borderStyle:solid,borderColor:lightgray"} defaultSharing={"public-image-protected-file"}/>,
  title: <IntlMessages id="system.form.library.comment" text="Comment" />,
  icon: <BiCommentDetail className="react-icons icon-bi" />,
  type: "Component",
  subtype: "sys",
  sequence: 1,
});

export default SfComment;