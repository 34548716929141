import { compare, delay, getFormKey, log } from '../util/algorithm';
import { CloudRunWithCache } from "./config";
import dataExplorer from './data-explorer';

const loadSystemSelect = async () => {
  try {
    return await dataExplorer.searchAll("SystemSelect", null, "name");
  } catch (error) {
    console.error("load system select failed", error);
    return null;
  }
};

const getSystemSelect = async (selectKey) => {
  try {
    if (!selectKey) return null;
    return await dataExplorer.getByKey("SystemSelect", selectKey, false);
  } catch (error) {
    console.error("get system formatter failed", error);
    return null;
  }
};

const prepareSystemSelectOption = async (locale, selectKey, isUseCode, parentOptionKey, selectText) => {
  try {
    if (!selectKey) {
      return [];
    } else if (selectKey === 'SelfReference') {
      return parentOptionKey;
    } else {
      const systemSelect = await getSystemSelect(selectKey);
      if (systemSelect.dataPolicy) {
        const dataKey = systemSelect.dataPolicy;
        const isPreview = false;
        const formKey = getFormKey();
        const params = {locale, selectKey, isUseCode, parentOptionKey, formKey, selectText};
        const dataType = "SystemSelect";
        const param = {dataKey, dataType, isPreview, locale, params}
        return await CloudRunWithCache("getDataPolicyData", param);
      } else {
        const param = {locale, selectKey, isUseCode, parentOptionKey, selectText};
        return await CloudRunWithCache("prepareSystemSelectOption", param);
      }
    }
  } catch (error) {
    console.error("list data failed", error);
    throw error;
  }
}

const getSystemSelectOptionData = async (locale, selectKey, isUseCode, parentOptionKey, value) => {
  try {
    const param = {locale, selectKey, isUseCode, parentOptionKey, value};
    return await CloudRunWithCache("getSystemSelectOptionData", param);
  } catch (error) {
    console.error("list data failed", error);
    throw error;
  }
}

let FETCHED_SYSTEM_OPTION = {};
let FETCHED_SYSTEM_OPTION_MAP = {};

const clearCache = (key) => {
  log("select: clearCache", key);
  if (key) {
    FETCHED_SYSTEM_OPTION[key] = null;
    FETCHED_SYSTEM_OPTION_MAP[key] = null;
  } else {
    FETCHED_SYSTEM_OPTION = {};
    FETCHED_SYSTEM_OPTION_MAP = {};
  }
}

const getFetchSystemSelectOptionKey = async (locale, selectKey, isUseCode, parentOptionKey, filter) => {
  const systemSelect = await getSystemSelect(selectKey);
  if (selectKey && systemSelect) {
    const rootOptionKey = systemSelect.rootOptionKey ? systemSelect.rootOptionKey : "X";
    parentOptionKey = parentOptionKey ? parentOptionKey : rootOptionKey;
    const localeKey = locale && locale.languageId ? locale.languageId : 'default';
    const useCodeKey = isUseCode ? "Y" : "N";
    let key = `${selectKey}_${parentOptionKey}_${localeKey}_${useCodeKey}`;
    if (filter) key = `${selectKey}_${parentOptionKey}_${localeKey}_${useCodeKey}_${filter}`;
    return key;
  } else {
    console.error("select info is missing", selectKey, systemSelect);
    return null;
  }
}

const CACHE_TIMEOUT = 1000 * 60 * 10;
const updateSystemSelectOptionCach = (key, list) => {
  const map = {};
  if (!Array.isArray(list)) list = [];
  list.forEach(l => {
    map[l.value] = l;
  });
  FETCHED_SYSTEM_OPTION[key] = list.sort((a, b) => compare(a.displayOrder || a.label, b.displayOrder || b.label));
  FETCHED_SYSTEM_OPTION_MAP[key] = map;
  delay(CACHE_TIMEOUT).then(()=>{
    // clear cache
    FETCHED_SYSTEM_OPTION[key] = null;
    FETCHED_SYSTEM_OPTION_MAP[key] = null;
  });
}

const fetchSystemSelectOption = async (locale, selectKey, isUseCode, parentOptionKey, selectText, filter) => {
  try {
    const key = await getFetchSystemSelectOptionKey(locale, selectKey, isUseCode, parentOptionKey, filter);
    if (key) {
      let list = await prepareSystemSelectOption(locale, selectKey, isUseCode, parentOptionKey, selectText);
      if (filter) list = list.filter(l => l.optionType === filter);
      if (!selectText && list) updateSystemSelectOptionCach(key, list);
      return list || [];
    } else {
      console.error("load system select option failed");
      return [];
    }
  } catch (error) {
    console.error("load system select option failed", error);
    return [];
  }
}

const getCachedSystemSelectOption = async ({locale, selectKey, isUseCode, parentOptionKey, value, filter}) => {
  try {
    if (!selectKey) return null;
    const key = await getFetchSystemSelectOptionKey(locale, selectKey, isUseCode, parentOptionKey, filter);
    if (key) {
      let map = FETCHED_SYSTEM_OPTION_MAP[key];
      if (!map) await fetchSystemSelectOption(locale, selectKey, isUseCode, parentOptionKey, undefined, filter);
      map = FETCHED_SYSTEM_OPTION_MAP[key];
      if (map) {
        const option = map[value];
        if (option) {
          return option;
        }
      }
    }
    return [];
  } catch (error) {
    console.error("load system select option failed", error);
    return [];
  }
}

const getCachedSystemSelectOptionLabel = async (locale, selectKey, parentOptionKey) => {
  try {
    const systemSelect = await getSystemSelect(selectKey);
    if (systemSelect?.labelOptionKey) {
      parentOptionKey = systemSelect.labelOptionKey + "_" + parentOptionKey;
      const labelObj = await getCachedSystemSelectOption({
        locale, selectKey, isUseCode: true, parentOptionKey: systemSelect.labelOptionKey, value: parentOptionKey});
      if (labelObj) {
        return labelObj.label;
      } else {
        return null;
      }
    }
  } catch (error) {
    console.error("load system select option failed", error);
    return null;
  }
}

const getParentOptionKey = async (locale, selectKey, isUseCode, values) => {
  if (!selectKey) {
    return null;
  } else if (selectKey === "SelfReference") {
    return values && values.length > 0 ? values[0] : null;
  } else {
    const systemSelect = await getSystemSelect(selectKey);
    if (systemSelect.dataPolicy) {
      const allNull = values?.every(v => v == null);
      return allNull ? null : values;
    } else {
      if (values && values.length > 0) {
        let parentOptionKey = null;
        for(const element of values) {
          const currValue = element;
          if (currValue) {
            const option = await getCachedSystemSelectOption(
              {locale, selectKey, isUseCode, parentOptionKey, value: currValue})
            parentOptionKey = option?.optionKey;
          } else {
            return null;
          }
        }
        return parentOptionKey;
      } else {
        return null;
      }
    }
  }
}

const selectApi = {
  loadSystemSelect,
  getSystemSelect,
  fetchSystemSelectOption,
  getSystemSelectOptionData,
  getParentOptionKey,
  getCachedSystemSelectOption,
  getCachedSystemSelectOptionLabel,
  clearCache,
}

export default selectApi;