import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { useEditor, useNode } from "@craftjs/core";
import { Button, Col, Image, InputNumber, message, Modal, Skeleton, Space, Switch, Typography, Upload } from "antd";
import { Form, FormContext, Input, Select } from "components/Form";
import { handleUploadFile } from "parse-api/file";
import React, { useState } from "react";
import IntlMessages from "util/IntlMessages";
import {
  colSls, convertRules, convertStyleStr, deleteButton, EditorCollector, getId, NodeCollector, registerComponent, SfLayoutSetting, SfPanelContext, shouldUpdate
} from "./common";
import { push } from 'connected-react-router';
import { useDispatch } from "react-redux";
import { AccountStore } from "constants/Account";
import { log } from "util/algorithm";
import { systemSignal } from "../../../../util/signal";

const SfUploadSetting = () => {
  return (
    <>
      <Form.Item name="title" label="title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="listType" label="list type">
        <Select className="item-property">
          <Select.Option value="text">text</Select.Option>
          <Select.Option value="picture">picture</Select.Option>
          <Select.Option value="picture-card">picture-card</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="directory" label="directory" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="multiple" label="multiple" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="preview" label="preview" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="autoremove" label="auto remove" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="maxCount" label="max count">
        <InputNumber min="1" className="item-property" allowClear />
      </Form.Item>
      <Form.Item name="accept" label="accept">
        <Space>
          <Form.Item name="accept" label="accept" noStyle={true}>
            <Input className="item-property" allowClear/>
          </Form.Item>
          <Typography.Link
            target="_blank" rel="noopener noreferrer"
            href="https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#accept">Need Help?</Typography.Link>
        </Space>
      </Form.Item>
      <Form.Item name="acceptFileTypes" label="file types">
        <Select className="item-property" mode="tags" allowClear/>
      </Form.Item>
      <Form.Item name="acceptFileExtensions" label="file exts">
        <Select className="item-property" mode="tags" allowClear/>
      </Form.Item>
      <Form.Item name="defaultSharing" label="default sharing">
        <Select className="item-property">
          <Select.Option value="public-image-protected-file">public-image-protected-file</Select.Option>
          <Select.Option value="public">public</Select.Option>
          <Select.Option value="protected">protected</Select.Option>
          <Select.Option value="private">private</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="disabled" label="disabled" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="hideInTable" label="hide col." valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="hideInMobile" label="hide mob." valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="tableColWidth" label="col width">
        <InputNumber min="1" className="item-property" />
      </Form.Item>
      <Form.Item name="tableColTitle" label="col title">
        <Input className="item-property" />
      </Form.Item>
      <SfLayoutSetting />
    </>
  )
}

export const SfUpload = ({ ...props }) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  return (
    <SfpUpload doRef={ref => connect(drag(ref))} style={style} dbtn={dbtn} {...props} />
  )
}

const getUploadButton = (listType, disabled) => {
  if (listType === "picture-card") {
    return (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}><IntlMessages id="system.form.library.upload" text="Upload" /></div>
      </div>
    );
  } else {
    return (
      <Button icon={<UploadOutlined />} disabled={disabled}><IntlMessages id="system.form.library.upload" text="Upload" /></Button>
    );
  }
}

const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

const onRemove = async (file) => {
  return true;
}

export const MyUpload = ({
  preview, ...otherProps
}) => {
  const { headless } = systemSignal;
  const dispatch = useDispatch();
  const [previewState, setPreviewState] = useState({ image: null, visible: false, title: null });
  const openPreview = async file => {
    if (file.type && file.type.indexOf("image") !== -1) {
      console.log("openPreview() file", file)
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }

      setPreviewState({
        image: file.url || file.preview,
        visible: true,
        title: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
      });
    } else {
      message.error(<IntlMessages id="system.form.upload_preview_error" text="We are not able to preview this file!" />);
    }
  };
  const closePreview = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setPreviewState({ visible: false, image: null });
  }
  const onDownload = (file) => {
    if (file.url && file.url.startsWith(AccountStore.PARSE_HOST_URL+'/files/')) {
      if (headless) {
        window.open(file.url.replace(AccountStore.PARSE_HOST_URL+'/files/', '/parse/files/')+'?headless=true');
      } else {
        dispatch(push(file.url.replace(AccountStore.PARSE_HOST_URL+'/files/', '/parse/files/')))
      }
    } else {
      log("file is not host in this server", file);
    }
  }
  return (
    <Upload {...otherProps}
      onPreview={preview ? openPreview : null} onRemove={onRemove}
      onDownload={onDownload}
      >
      {getUploadButton(otherProps.listType, otherProps.disabled)}
      <Modal
        visible={previewState.visible}
        title={previewState.title}
        footer={null}
        onCancel={closePreview}
      >
        <Image alt="preview" style={{ width: '100%' }}
          preview={false} placeholder={true}
          src={previewState.image} key={previewState.image} />
      </Modal>
    </Upload>
  )
}

export const SfpUpload = ({
  doRef, form, condistyles, className, style, dbtn, hideInTable, tableColWidth, tableColTitle,
  itemKey, title, labelAlign, labelColStr, rules, volitate, skipcopy, defaultSharing,
  inputref, styleStr, preview, disabled, children, autoremove, accept, acceptFileTypes, acceptFileExtensions, ...otherProps }) => {
  const [api, contextHolder] = message.useMessage();
  const [previewState, setPreviewState] = useState({ image: null, visible: false, title: null });
  const [localFileList, setLocalFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const { headless } = systemSignal;
  const sls = convertStyleStr(styleStr);
  const lcs = convertStyleStr(labelColStr);
  const [fxr, setFxr] = useState({});
  const dispatch = useDispatch();
  const newRules = convertRules(rules);
  const openPreview = async file => {
    if (file.type && file.type.indexOf("image") !== -1) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }

      setPreviewState({
        image: file.url || file.preview,
        visible: true,
        title: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
      });
    } else {
      api.error(<IntlMessages id="system.form.upload_preview_error" text="We are not able to preview this file!" />);
    }
  };

  const onDownload = (file) => {
    if (file.url && file.url.startsWith(AccountStore.PARSE_HOST_URL+'/files/')) {
      if (headless) {
        window.open(file.url.replace(AccountStore.PARSE_HOST_URL+'/files/', '/parse/files/')+'?headless=true');
      } else {
        dispatch(push(file.url.replace(AccountStore.PARSE_HOST_URL+'/files/', '/parse/files/')))
      }
    } else {
      log("file is not host in this server", file);
    }
  }

  const closePreview = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setPreviewState({ visible: false, image: null });
  }

  const uploadRules =  rules ? [...rules] : [];
  uploadRules.push(
    {
      validator: () =>
      (uploading ? Promise.reject(<IntlMessages id="system.form.upload_not_complete" text="Please wait a moment..."/>) : Promise.resolve())
    }
  );

  const beforeUpload = async (form, name, file) => {
    let fileList = [];
    if (form) {
      fileList = form.getFieldValue(name);
    } else {
      fileList = [...localFileList];
    }

    let newFileList = null;
    const maxCount = otherProps.maxCount;
    if (maxCount) {
      if (maxCount > 1) {
        if (fileList.length >= maxCount) {
          api.error(<IntlMessages id="system.form.upload_limit" text="Reached max file count ({maxCount})" values={{maxCount:maxCount}} />);
        } else {
          newFileList = [...fileList, file];
        }
      } else if (maxCount === 1) {
        newFileList = [file];
      }
    } else {
      newFileList = [...fileList, file];
    }

    if (newFileList) {
      if (form) {
        form.setFields([{
          name: name,
          value: newFileList
        }]);
      } else {
        setLocalFileList(newFileList);
      }
    }
    return false;
  }

  const autoRemoveErrorFile = (fileList) => {
    if(!fileList) fileList = []
    const oldFileList =  [...fileList]
    return oldFileList.filter(i=>i.status !== 'error')
  }

  const getValueFromEvent = (form, name, args) => {
    let fileList = []
    if (args.length > 0) {
      const event = args[0];
      if (Array.isArray(event)) {
        fileList = event;
      } else {
        fileList = event.fileList;
      }
    }
    fileList.forEach(f => {
      if (!f.status) {
        f.permission = defaultSharing;
        setUploading(true);
        handleUploadFile(f.name, f, (file, progressValue, info, parseFile, error) => {
          let changed = false;
          let completed = false;
          if (info) {
            const { type } = info;
            if (type === "upload" && progressValue !== null) {
              file.percent = progressValue * 100;
              file.status = "uploading";
              changed = true;
            }
            if (type === "download") {
              file.status = 'done';
              changed = true;
            }
          } else if (parseFile) {
            const index = fileList.indexOf(file);
            const newFile = { ...file };
            newFile.url = parseFile.url();
            newFile.fileKey = parseFile.name();
            newFile.name = file.name;
            newFile.originFileObj = undefined;
            fileList[index] = newFile;
            changed = true;
            completed = true;
          } else if (error) {
            if (React.isValidElement(error)) {
              api.error(error);
            } else {
              api.error(`${error}`);
            }
            file.status = 'error';
            changed = true;
            completed = true;
          }
          if (changed) {
            if (file.status === 'error' && autoremove)
              fileList = autoRemoveErrorFile(fileList);
            const newFileList = [...fileList];
            if (form) {
              form.setFields([{
                name: name,
                value: newFileList
              }]);
            } else {
              setLocalFileList(newFileList);
            }
            if (completed) {
              setUploading(false);
              if (form && name) {
                form.validateFields([name]);
              }
            }
          }
        }, acceptFileTypes, acceptFileExtensions);
      }
    })
    return fileList;
  }

  if (doRef) {
    return (
      <SfPanelContext.Consumer>
        {ctx => {
          const name = ctx ? [...ctx.name, itemKey] : [itemKey];
          const fx = shouldUpdate({condistyles, ctx, form, style, setFxr});
          return (
            <Col ref={doRef} className={className} style={fxr.style || style} {...colSls(otherProps)}>
              {contextHolder}
              <Form.Item name={name} label={title}
                shouldUpdate={fx} hidden={fxr.hidden} rules={fxr.hidden || otherProps.disabled ? null : newRules}
                labelAlign={labelAlign} labelCol={lcs} valuePropName="fileList"
                getValueFromEvent={(...args) => getValueFromEvent(null, null, args)} wrap>
                  <Upload inputref={inputref} style={sls} {...otherProps}
                  //disabled={disabled || previewState.visible || uploading}
                  disabled={true}
                  beforeUpload={(file) => beforeUpload(null, null, file)}
                  onPreview={preview ? openPreview : null} onRemove={onRemove}
                  >
                  {getUploadButton(otherProps.listType, disabled)}
                  <Modal
                    visible={previewState.visible}
                    title={previewState.title}
                    footer={null}
                    onCancel={closePreview}
                  >
                    <Skeleton.Image />
                    <Image alt="preview" style={{ width: '100%' }}
                      preview={false} placeholder={true}
                      src={previewState.image} key={previewState.image} />
                  </Modal>
                </Upload>
              </Form.Item>
              {dbtn}
            </Col>
          )
        }}
      </SfPanelContext.Consumer>
    );
  } else {
    return (
      <SfPanelContext.Consumer>
        {ctx => {
          const name = ctx ? [...ctx.name, itemKey] : [itemKey];
          const fx = shouldUpdate({condistyles, ctx, form, style, setFxr});
          return (
            <FormContext.Consumer>
              {(formCtx) => {
                return (
                  <Col className={className} style={fxr.style || style} {...colSls(otherProps)}>
                    {contextHolder}
                    <Form.Item name={name} label={title}
                      shouldUpdate={fx} hidden={fxr.hidden} rules={fxr.hidden || otherProps.disabled ? null : uploadRules}
                      labelAlign={labelAlign} labelCol={lcs} valuePropName="fileList"
                      getValueFromEvent={(...args) => getValueFromEvent(formCtx?.formInstance, name, args)} wrap>
                      <Upload inputref={inputref} style={sls} {...otherProps}
                        accept={accept}
                        disabled={disabled || previewState.visible || uploading}
                        beforeUpload={(file) => beforeUpload(formCtx?.formInstance, name, file)}
                        onPreview={preview ? openPreview : null} onRemove={onRemove}
                        onDownload={onDownload}
                        showUploadList={{
                          showPreviewIcon: true,
                          showDownloadIcon: true,
                          showRemoveIcon: true,
                        }}
                        >
                        {getUploadButton(otherProps.listType, disabled)}
                        <Modal
                          visible={previewState.visible}
                          title={previewState.title}
                          footer={null}
                          onCancel={closePreview}
                        >
                          <Image alt="preview" style={{ width: '100%' }}
                            preview={false} placeholder={true}
                            src={previewState.image} key={previewState.image} />
                        </Modal>
                      </Upload>

                    </Form.Item>
                  </Col>)
              }}
            </FormContext.Consumer>
          )
        }}
      </SfPanelContext.Consumer>
    );
  }
}


SfpUpload.render = ({ itemKey, className }) => (value, record, index) => {
  const cls = className ? className + " table-col" : " table-col";
  return <div key={itemKey + "_" + index}><MyUpload fileList={value} className={cls} listType={"picture-card"} preview={true} showUploadList={{showRemoveIcon:false}} disabled={true}/></div>
}

SfUpload.craft = {
  displayName: "Upload",
  related: {
    settings: SfUploadSetting
  }
}

SfUpload.validate = (props, {parents, container, extraParams}) => {
  if (container.type.resolvedName === "SfMainPanel") {
    extraParams.dataClassConfig.columns.push({
      dataIndex: props.itemKey,
      index: Object.keys(parents).indexOf(props.itemKey),
      type: "file[]",
      listType: props.listType,
      preview: props.preview,
      title: props.tableColTitle || props.title,
      width: props.tableColWidth,
      sortable: false,
      editable: false,
      permission: props.permission,
      volitate: props.volitate,
      hiddenForReadOnly: props.hideInTable,
    });
  }
}

registerComponent({
  key: "SfUpload",
  component: SfUpload,
  runtimeComponent: SfpUpload,
  template: <SfUpload itemKey={getId('upload')} className="sf-upload wrap"
    title={"Upload"} span={24} labelColStr="span:6" defaultSharing={"public-image-protected-file"} />,
  title: <IntlMessages id="system.form.library.upload" text="Upload" />,
  icon: <UploadOutlined  className="react-icons icon-antd"/>,
  type: "Component",
  sequence: 17,
});

export default SfUpload;