import { LoadingOutlined } from '@ant-design/icons';
import { useEditor, useNode } from "@craftjs/core";
import { Avatar, Col, Empty, InputNumber, Space, Spin, Switch, Tag, Tooltip } from "antd";
import { Form, Input, Select } from "../../../../components/Form";
import { selectApi } from "../../../../parse-api";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { CgSelectR } from "react-icons/cg";
import { compare, equals, getArrayElementByAttribute, getArrayUnique, getText, isEmpty, log } from "util/algorithm";
import IntlMessages from "util/IntlMessages";
import { AccountStore } from "../../../../constants/Account";
import { colSls, convertRules, convertSelectOptionLabel, convertStyleStr, deleteButton, EditorCollector, generateTooltip, getId, getParentName, NodeCollector, registerComponent, SelectCollector, SfLayoutSetting, SfPanelContext, shouldUpdate, updateTableCell } from "./common";
import { SfpUserAvatar } from './SfUserAvatar';
import { settingsSignal } from '../../../../util/signal';
import { useSignalValue } from '../../../../util/reactHook';

const SfSelectSetting = () => {
  const { localSelects, mainSelects } = useEditor(SelectCollector);

  const [systemSelectList, setSystemSelectList] = useState([]);
  const [selects, setSelects] = useState([]);
  const [selected, setSelected] = useState({});
  const [filter, setFilter] = useState([]);
  const mounted = useRef();
  const selectedKey = useRef();
  useEffect(() => {
    mounted.current = true;
    const fetchData = async () => {
      const list = await selectApi.loadSystemSelect();
      setSystemSelectList(list);
      // convert to option
      const options = list?.map(l => {
        return {
          value: l.selectKey,
          label: l.name,
        }
      })
      if (mounted.current) setSelects(options);
    }
    fetchData();
    return () => {
      mounted.current = false;
    }
  }, [])

  const onSelectKeyChange = (value) => {
    refreshOptionTypes();
    selectedKey.current = value;
  }

  const refreshOptionTypes = () => {
    if (selectedKey.current && systemSelectList?.length > 0) {
      if (selected && selected?.selectKey !== selectedKey.current) {
        log("refreshOptionTypes", selected?.selectKey, selectedKey.current);
        const sel = getArrayElementByAttribute(systemSelectList, "selectKey", selectedKey.current);
        setSelected(sel);
        if (sel && sel.optionTypes) {
          if (mounted.current) setFilter(sel.optionTypes.map(f => ({label:f, value:f})));
        } else {
          if (mounted.current) setFilter([]);
        }
      }
    }
  }

  const SelectKey = ({value, onChange, ...props}) => {
    useEffect(()=> {
      onSelectKeyChange(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[value])
    return <Select {...props} value={value} onChange={onChange} />
  }

  return (
    <>
      <Form.Item name="title" label="title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="placeholder" label="placeholder">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="selectkey" label="select">
        <SelectKey className="item-property" options={selects} onChange={onSelectKeyChange} allowClear showSearch filterOption={dfo}/>
      </Form.Item>
      <Form.Item name="filter" label="filter">
        <Select className="item-property" options={filter} allowClear/>
      </Form.Item>
      <Form.Item name="parentkeys" label="parents">
        <Select className="item-property" options={[...mainSelects,...localSelects]} mode="multiple" />
      </Form.Item>
      <Form.Item name="autohide" label="auto hide" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="autofill" label="auto fill" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="tiptext" label="tooltip text" >
        <Input.TextArea
          className="item-property"
          autoSize allowClear
        />
      </Form.Item>
      <Form.Item name="tiplink" label="tooltip link" >
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="mode" label="mode">
        <Select className="item-property" allowClear>
          <Select.Option value="multiple">multiple</Select.Option>
          <Select.Option value="tags">tags</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name='open' label='hide open' valuePropName='checked'>
        <Switch className='item-property' />
      </Form.Item>
      <Form.Item name="size" label="size">
        <Select className="item-property">
          <Select.Option value="large">large</Select.Option>
          <Select.Option value="middle">middle</Select.Option>
          <Select.Option value="small">small</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="maxTagCount" label="max tag">
        <InputNumber min="1" className="item-property" />
      </Form.Item>

      <Form.Item name="isUseCode" label="use code" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="isHideCode" label="hide code" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="isShowIcon" label="show icon" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="showSearch" label="show search" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="allowClear" label="allow clear" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="showArrow" label="show arrow" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="bordered" label="bordered" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="dropdownMatchSelectWidth" label="match width" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="disabled" label="disabled" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="defaultHidden" label="hidden" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="hideInTable" label="hide col." valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="hideInMobile" label="hide mob." valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="statusInTable" label="status col." valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="avatarInTable" label="avatar col." valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="editInTable" label="edit col." valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="tableColWidth" label="col width">
        <InputNumber min="1" className="item-property" />
      </Form.Item>
      <Form.Item name="tableColTitle" label="col title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="isSelectKey" label="select key" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="datasec" label="data sec." valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="useAlternativePopupContaier" label="alter cntr" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="datasectype" label="datasec type">
        <Select className="item-property" allowClear>
          <Select.Option value="owners">owners</Select.Option>
          <Select.Option value="users">users</Select.Option>
          <Select.Option value="userRoles">userRoles</Select.Option>
        </Select>
      </Form.Item>
      <SfLayoutSetting />
    </>
  )
}

export const SfSelect = ({ ...props }) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  return (
    <SfpSelect doRef={ref => connect(drag(ref))} style={style} dbtn={dbtn} {...props} />
  )
}

const dfo = (input, option) => {
  return getText(option).toLowerCase().indexOf(input.toLowerCase()) >= 0;
}

export const MySelect = ({value, noupdate, disabled, loading, open, onChange, hideInMobile, ...props}) => {
  const ref = useRef();
  if (ref.current === undefined) ref.current = noupdate && value !== undefined;
  props.skipcopy = undefined;
  const onMyChange = (value, event) => {
    if (value === undefined) value = null;
    if (onChange) onChange(value, event);
  }
  if(open){
    return <Select open={!open} value={loading ? undefined : value} onChange={onMyChange} disabled={disabled || ref.current} loading={loading} {...props}/>
  }
  return <Select value={loading ? undefined : value} onChange={onMyChange} disabled={disabled || ref.current} loading={loading} {...props}/>
}

export const SystemSelect = ({
  className, selectkey, filter, isShowIcon, isUseCode, isHideCode, size, style,
  value, onChange, onBlur, parentkeys, parentValues, mode, open, disabled,
  dropdownMatchSelectWidth, showArrow, allowClear, showSearch, placeholder, onAddItem}) => {
  const { locale } = settingsSignal;
  const [options, setOptions] = useState([]);
  const mounted = useRef();
  const fetchDataRef = useRef();
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    }
  },[])
  useEffect(() => {
    if (fetchDataRef.current) {
      clearTimeout(fetchDataRef.current);
      fetchDataRef.current = null;
    }
    const fetchData = async () => {
      let list = [];
      if (parentkeys && parentkeys.length > 0) {
        const parentOptionKey = await selectApi.getParentOptionKey(locale, selectkey, isUseCode, parentValues);
        if (parentOptionKey) {
          list = await selectApi.fetchSystemSelectOption(locale, selectkey, isUseCode, parentOptionKey);
        }
      } else {
        list = await selectApi.fetchSystemSelectOption(locale, selectkey, isUseCode);
      }
      if (filter) list = list.filter(l => l.optionType === filter);
      list = getArrayUnique(list, "value")
      list = list.map(l => {
        if (isShowIcon) {
          return <Select.Option className={"select-option"} key={l.value} value={l.value} ><i className={"select-icon " + l.icon}></i><span className="icon-text">{convertSelectOptionLabel(l.label,isUseCode,isHideCode)}</span></Select.Option>
        } else {
          return <Select.Option key={l.value} value={l.value} >{convertSelectOptionLabel(l.label,isUseCode,isHideCode)}</Select.Option>
        }
      })
      if (mounted.current) setOptions(list);
    }
    if (selectkey) {
      fetchDataRef.current = setTimeout(fetchData, AccountStore.ON_CHANGE_DELAY)
    }
  }, [selectkey, isUseCode, isHideCode, isShowIcon, filter, parentkeys, parentValues, locale])
  if(open) {
    return <Select open={!open} className={className} style={style} value={value} onChange={onChange} onBlur={onBlur}
    mode={mode} showSearch={!!showSearch} allowClear={!!allowClear} showArrow={!!showArrow} size={size}
    dropdownMatchSelectWidth={!!dropdownMatchSelectWidth} placeholder={placeholder} filterOption={dfo}
    disabled={disabled}
    onAddItem={onAddItem}>{options}</Select>
  }
  return <Select className={className} style={style} value={value} onChange={onChange} onBlur={onBlur}
    mode={mode} showSearch={!!showSearch} allowClear={!!allowClear} showArrow={!!showArrow} size={size}
    dropdownMatchSelectWidth={!!dropdownMatchSelectWidth} placeholder={placeholder} filterOption={dfo}
    disabled={disabled}
    onAddItem={onAddItem}
    getPopupContainer={getDefaultPopupContainer}
    >{options}</Select>
}

const VALID_CLASS = /(ant-select-selector)/i
const EXCLUDED_CLASS = /(savedFlowKey|savedFormKey|savedPageKey)/i
const getPopupContainer = (trigger) => {
  if ((trigger?.className || '').match(VALID_CLASS)) {
    if (trigger?.parentElement) {
      if ((trigger.parentElement.className || '').match(EXCLUDED_CLASS)) {
        return document.body;
      }
    }
    return trigger?.parentElement || document.body;
  } else {
    return document.body;
  }
}

const getDefaultPopupContainer = (trigger) => {
  const cntr = trigger?.parentElement.closest('.appframe-container');
  if (cntr) {
      return cntr;
  } else {
    return document.body;
  }
}

const transformSelectedValues = ({mode, list, form, mounted, autofill, name, cValue}) => {
  if (mode === 'multiple') {
    if (cValue && !list.find(l => l.value === cValue)) {
      cValue = [...cValue];
    }
    if (!cValue && mounted.current && autofill && list.length === 1) {
      cValue = [list[0].value];
    }
    if (!cValue && mounted.current && list.length > 1) {
      cValue = [];
    }
  } else {
    if (!cValue && mounted.current && autofill && list.length === 1) {
      cValue = list[0].value;
    }
    if (!cValue && mounted.current && list.length > 1) {
      cValue = null;
    }
  }
  return cValue;
}

export const SfpSelect = ({
  doRef, form, condistyles, className, style, dbtn, defaultHidden, hideInTable, statusInTable, avatarInTable, editInTable, tableColWidth, tableColTitle,
  itemKey, title, labelAlign, labelColStr, rules, disabled, volitate, skipcopy, open, tiptext, tiplink,
  selectkey, avatar, filter, parentkeys, isUseCode, isHideCode, isShowIcon, autohide, autofill, datasec, datasectype,
  inputref, styleStr, children, isSelectKey, dropdownMatchSelectWidth, useAlternativePopupContaier, ...otherProps }) => {
  const { locale } = settingsSignal;
  const [fetching, setFetching] = useState(false);
  const [list, setList] = useState([]);
  const [options, setOptions] = useState([]);
  const [parentValues, setParentValues] = useState([]);
  const [hidden, setHidden] = useState((parentkeys && parentkeys.length > 0 && autohide && !doRef) || (defaultHidden && !doRef));
  const [label, setLabel] = useState(title);
  const [fxr, setFxr] = useState({});
  const sls = convertStyleStr(styleStr);
  const lcs = convertStyleStr(labelColStr);
  const mounted = useRef();
  const newRules = convertRules(rules);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    }
  }, []);

  useEffect(() => {
    let options = [];
    if (Array.isArray(list)) {
      options = list.sort((a, b) => compare(a.displayOrder || a.label, b.displayOrder || b.label))
      .filter(l => filter ? l.optionType === filter : true);
    }
    options = getArrayUnique(options, "value");
    options = options
    .map(l => {
      if (isShowIcon) {
        return <Select.Option key={l.value} value={l.value} ><i className={"select-icon " + l.icon}></i><span className="icon-text">{convertSelectOptionLabel(l.label,isUseCode,isHideCode)}</span></Select.Option>
      } else if (avatar) {
        return <Select.Option key={l.value} value={l.value} ><SfpUserAvatar username={l.value} size={"small"}/><span className="icon-text">{convertSelectOptionLabel(l.label,isUseCode,isHideCode)}</span></Select.Option>
      } else {
        return <Select.Option key={l.value} value={l.value} >{convertSelectOptionLabel(l.label,isUseCode,isHideCode)}</Select.Option>
      }
    })
    if (mounted.current) setOptions(options);
  }, [list, filter, isShowIcon, isUseCode, isHideCode, avatar]);

  const refreshOptions = async (form, dependencies, name, autohide, autofill, args) => {
    if (mounted.current) setFetching(true);
    if (selectkey && form && dependencies && dependencies.length > 0) {
      const pValues = dependencies.map(d => form.getFieldValue(d));
      const hasNull = pValues.some(v => v == null);
      let list = [];
      if (hasNull) {
        if (mounted.current && !doRef && autohide) setHidden(true);
        if (mounted.current) setParentValues(pValues);
        form.setFields([{ name: name, value: null }]);
      } else {
        if (!equals(pValues, parentValues)) {
          if (mounted.current) setParentValues(pValues);
          const parentOptionKey = await selectApi.getParentOptionKey(locale, selectkey, isUseCode, pValues);
          if (parentOptionKey) {
            list = await selectApi.fetchSystemSelectOption(locale, selectkey, isUseCode, parentOptionKey);
            if (list && list.length > 0) {
              if (mounted.current && !doRef && !!autohide) setHidden(false);
            } else {
              if (mounted.current && !doRef && !!autohide) setHidden(true);
            }
            let lbl = await selectApi.getCachedSystemSelectOptionLabel(locale, selectkey, parentOptionKey);
            if (lbl) {
              lbl = lbl.replace(/\(.*\)/,"");
              if (mounted.current) setLabel(lbl);
            } else {
              if (mounted.current) setLabel(title);
            }
          } else {
            if (mounted.current && !doRef && !!autohide) setHidden(true);
            if (mounted.current) setLabel(title);
          }
          if (filter) list = list.filter(l => l.optionType === filter);
          if (mounted.current) setList(list);
          let cValue = form.getFieldValue(name);
          const mode = otherProps.mode;
          cValue = transformSelectedValues({mode, list, form, mounted, autofill, name, cValue})
          form.setFields([{ name: name, value:  cValue }]);
        }
      }
    } else if (selectkey && options.length === 0) {
      let list = await selectApi.fetchSystemSelectOption(locale, selectkey, isUseCode);
      if (filter) list = list.filter(l => l.optionType === filter);
      if (mounted) setList(list);
      if (form) {
        let cValue = form.getFieldValue(name);
        if (!cValue && mounted.current && autofill && list.length === 1) {
          if (otherProps.mode === 'multiple') cValue = [list[0].value]
          else cValue = list[0].value
          form.setFields([{ name: name, value: cValue }]);
        }
      }
    }
    if (mounted) setFetching(false);
  }
  return (
    <SfPanelContext.Consumer>
      {ctx => {
        const name = ctx ? [...ctx.name, itemKey] : [itemKey];
        const parentname = getParentName(ctx);
        let targetForm = form;
        const dependencies = parentkeys ? parentkeys.map(p => {
          if (p.startsWith("main.")) {
            targetForm = ctx?.parentForm ||  targetForm;
            return [p.substr(5)];
          } else {
            return [...parentname, p];
          }
        }) : [];
        const doRefresh = (...args) => refreshOptions(targetForm, dependencies, name, autohide, autofill, args);
        const fx = shouldUpdate({condistyles, ctx, form, style, setFxr, hidden, doRefresh});
        return (
          <Col ref={doRef} className={className} style={fxr.style || style} {...colSls(otherProps)}>
            <Form.Item name={name} label={doRef ? title : label} placeholder={otherProps.placeholder} tooltip={generateTooltip(tiptext,tiplink)}
              shouldUpdate={fx} hidden={fxr.hidden || hidden} rules={fxr.hidden || hidden || disabled ? null : newRules}
              labelAlign={labelAlign} labelCol={lcs} wrap>
              <MySelect open={!!open} inputref={inputref} className={"with-icon"} style={sls} {...otherProps}
                onClick={doRefresh} filterOption={dfo} disabled={fxr.disabled || disabled}
                loading={fetching}
                notFoundContent={fetching ? <Spin size="small" /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                dropdownMatchSelectWidth={!!dropdownMatchSelectWidth}
                onClear={() => selectkey && selectApi.clearCache(selectkey)}
                getPopupContainer={useAlternativePopupContaier ? getPopupContainer : getDefaultPopupContainer}
              >
                {options}
              </MySelect>
            </Form.Item>
            {dbtn}
          </Col>
        )
      }}
    </SfPanelContext.Consumer>
  );
}

const getParentValue = ({form, record, key}) => {
  if (key.startsWith("main.") && form) {
    key = key.substr(5);
    return form.getFieldValue(key);
  } else {
    return record[key];
  }
}

export const EditableSelectRenderer = ({value, record, index, form, tableKey, onRowChange, editInTable, itemKey, tableColWidth, disabled, selectkey, statusInTable, isUseCode, isHideCode, parentkeys, filter, dropdownMatchSelectWidth, showArrow, showSearch, allowClear}) => {
  const [editing, setEditing] = useState(false);
  const [myValue, setMyValue] = useState(value);
  const leditInTable = editInTable && !disabled;
  const mounted = useRef();
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    }
  }, [])
  useEffect(() => {
    setMyValue(value);
  }, [value]);
  const onClick = (event) => {
    setEditing(true);
    event.preventDefault();
    event.stopPropagation();
  }
  if (editing) {
    const onChange = (value) => {
      try {
        setMyValue(value);
        const event = null;
        updateTableCell({form, tableKey, record, itemKey, myValue: value, onRowChange, setEditing, event, index});
        setEditing(false);
      } catch (e) {
        console.error(e);
      }
    }
    const onBlur = () => {
      setEditing(false);
    }
    const className = "row-editing table-cell-select"
    const parentValues = parentkeys ? parentkeys.map(key => getParentValue({form, record, key})) : null;
    const props = {value: myValue, record, index, selectkey, statusInTable, isUseCode, isHideCode, parentkeys, parentValues, filter, className, onChange, onBlur, dropdownMatchSelectWidth, showArrow, showSearch, allowClear};
    if (tableColWidth) props.style = {"minWidth": `${tableColWidth}px`,"maxWidth": `${tableColWidth}px`}
    return <SystemSelect {...props} />
  } else {
    const editingProps = leditInTable ? {value: myValue, record, index, form, selectkey, statusInTable, isUseCode, isHideCode, parentkeys, filter, className: "row-editable table-cell-select", onClick} : {};
    if (tableColWidth) editingProps.style = {"minWidth": `${tableColWidth}px`, "maxWidth": `${tableColWidth}px`}
    return <SelectRenderer {...editingProps}/>;
  }
}

const convertName = (value) => {
  return value ? value.replace(/[^a-zA-Z]/,"").toLowerCase() : "";
}
const generateStyle = (value, colors, bgColors) => {
  const style = {};
  if (colors[value]) style.color = colors[value];
  if (bgColors[value]) style.backgroundColor = bgColors[value];
  return style;
}

const generateStatus = (value, colors, bgColors) => {
  if (value) {
    const list = Array.isArray(value) ? [...value] : [value];
    return <Space className="row-status-container">{list.map(l => <Tag key={l} className={"row-status "+convertName(l)} style={generateStyle(l, colors, bgColors)}>{l}</Tag>)}</Space>
  } else {
    return <div>&nbsp;</div>;
  }
}

const generateAvatar = (value) => {
  if (value) {
    const list = Array.isArray(value) ? [...value] : [value];
    return (
      <Avatar.Group maxCount={3}>
        {list.map((l) => (
          <SfpUserAvatar key={l} username={l} size="medium" />
        ))}
      </Avatar.Group>
    );
  } else {
    return <div>&nbsp;</div>;
  }
}

const generateContent = (value) => {
  if (value) {
    const list = Array.isArray(value) ? [...value] : [value];
    if (list.length > 5) {
      return <Tooltip mouseEnterDelay={1} title={list.join(', ')}>{list.filter((_,index)=>(index <= 5)).join(', ')+ " ... "}</Tooltip>;
    } else {
      return <span>{list.join(', ')}</span>;
    }
  } else {
    return <div>&nbsp;</div>;
  }
}

export const SelectRenderer = ({className, style, onClick, value, record, index, form, selectkey, filter, avatarInTable, statusInTable, isUseCode, isHideCode, parentkeys}) => {
  const locale = useSignalValue(settingsSignal, 'locale');
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [colors, setColors] = useState({});
  const [bgColors, setBgColors] = useState({});
  const mounted = useRef();
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    }
  }, []);

  useEffect(() => {
    if (value && !avatarInTable) {
      const fetchData = async () => {
        try {
          if (mounted.current) setLoading(true);
          const parentValues = parentkeys ? parentkeys.map(key => getParentValue({form, record, key})) : null;
          const parentOptionKey = await selectApi.getParentOptionKey(locale, selectkey, isUseCode, parentValues);
          const list = [];
          const bgColors = {};
          const colors = {};
          if (Array.isArray(value)) {
            for (const element of value) {
              const v = element;
              const option = await selectApi.getCachedSystemSelectOption(
                {locale, selectKey: selectkey, isUseCode, parentOptionKey, value: v, filter});
              if (option) {
                list.push(option.label ? convertSelectOptionLabel(option.label,isUseCode,isHideCode) : v);
              } else {
                list.push(v);
              }
            }
          } else {
            let option = await selectApi.getCachedSystemSelectOption(
              {locale, selectKey: selectkey, isUseCode, parentOptionKey, value, filter});
            if (option) {
              const val = option.label ? convertSelectOptionLabel(option.label, isUseCode, isHideCode) : value;
              list.push(val);
              if (option.backgroundColor) {
                bgColors[val] = option.backgroundColor;
              }
              if (option.color) {
                colors[val] = option.color;
              }
            } else {
              list.push(value);
            }
          }
          if (!equals(options, list)) {
            if (mounted.current) setOptions(list);
            if (mounted.current) setColors(colors);
            if (mounted.current) setBgColors(bgColors);
          }
          if (mounted.current) setLoading(false);
        } catch (e) {
          log("error", e);
          if (mounted.current) setLoading(false);
        }
      }
      fetchData();
    } else {
      setOptions([])
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const displayValue = useMemo(() => {
    if (loading) {
      return <LoadingOutlined style={{ fontSize: 10 }} spin />;
    } else {
      if (statusInTable) {
        return generateStatus(options, colors, bgColors);
      } else if (avatarInTable) {
        return generateAvatar(value);
      } else {
        return generateContent(options);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, loading, options])
  return <span className={className} style={style} onClick={onClick}>{displayValue}</span>;
}

SfpSelect.render = ({ editInTable, itemKey, mask, tableColWidth, disabled, selectkey, avatarInTable, isUseCode, isHideCode, parentkeys, statusInTable, dropdownMatchSelectWidth, showArrow, showSearch, allowClear, filter }, form, tableKey, onRowChange) => (value, record, index) => {
  const props = {editInTable, itemKey, mask, tableColWidth, disabled, selectkey, avatarInTable, isUseCode, isHideCode, parentkeys, statusInTable, dropdownMatchSelectWidth, showArrow, showSearch, allowClear, filter, value, record, index, form, tableKey, onRowChange}
  if (editInTable) {
    return <EditableSelectRenderer {...props}/>
  } else {
    return <SelectRenderer {...props}/>
  }
}

SfSelect.craft = {
  displayName: "Select",
  related: {
    settings: SfSelectSetting
  }
}

SfSelect.validate = (props, {parents, mainParents, container, extraParams}) => {
  const allowedParentTypes = ['SfSelect', 'SfSystemUser', 'SfDate', 'SfArray', 'SfInput', 'SfInputNumber'];
  const selectParentTypes = ['SfSelect', 'SfSystemUser'];
  const itemKey = props.itemKey;
  const parentkeys = props.parentkeys;
  const mode = props.mode;
  props.avatar = undefined;

  let isArrayParent = false;
  if (parentkeys) parentkeys.forEach(key => {
    if (key.startsWith('main.')) {
      key = key.substr(5);
      if (mainParents[key]?.type.resolvedName === "SfArray") {
        isArrayParent = true;
      }
    } else {
      if (parents[key]?.type.resolvedName === "SfArray") {
        isArrayParent = true;
      }
    }
  })
  if (isArrayParent && parentkeys.length > 1) {
    return <IntlMessages id="system.form.validate.select-one-and-only-one-array-parent" text="item ({itemKey}) - you could only select one and only one array parent." values={{itemKey:itemKey}}/>
  }
  if (isArrayParent) {
    props.selectkey = "SelfReference"
  } else {
    if (props.selectkey === "SelfReference") {
      return <IntlMessages id="system.form.validate.select-key-invalid" text="item ({itemKey}) - Self Reference is only for array parent." values={{itemKey:itemKey}}/>
    }
  }
  const selectkey = props.selectkey;

  if (!selectkey && mode !== 'tags') {
    return <IntlMessages id="system.form.validate.select-key-missing" text="item ({itemKey}) - select is missing." values={{itemKey:itemKey}}/>
  }
  if (parentkeys) {
    for (const element of parentkeys) {
      const key = element;
      if (!key.startsWith("main.")) {
        if (!parents[key]) {
          return <IntlMessages id="system.form.validate.parent-key-not-found" text="item ({itemKey}) - parent key not found ({parent})." values={{parent:key,itemKey:itemKey}}/>
        }
        const parentNode = parents[key].props;
        if (allowedParentTypes.indexOf(parents[key].type.resolvedName) === -1 || (selectParentTypes.indexOf(parents[key].type.resolvedName) !== -1 && !isEmpty(parentNode.mode))) {
          return <IntlMessages id="system.form.validate.parent-key-not-found" text="item ({itemKey}) - parent select ({parent}) is not single value select." values={{parent:key,itemKey:itemKey}}/>
        }
      }
      if (key.startsWith("main.")) {
        const mainKey = key.substr(5);
        if (!mainParents[mainKey]) {
          return <IntlMessages id="system.form.validate.parent-key-not-found" text="item ({itemKey}) - parent key not found ({parent})." values={{parent:key,itemKey:itemKey}}/>
        }
        const mainParentNode = mainParents[mainKey].props;
        if (allowedParentTypes.indexOf(mainParents[mainKey].type.resolvedName) === -1 || (selectParentTypes.indexOf(mainParents[mainKey].type.resolvedName) !== -1 && !isEmpty(mainParentNode.mode))) {
          return <IntlMessages id="system.form.validate.parent-key-not-found" text="item ({itemKey}) - parent select ({parent}) is not single value select." values={{parent:key,itemKey:itemKey}}/>
        }
      }
    }
  }

  if (container.type.resolvedName === "SfMainPanel") {
    extraParams.dataClassConfig.columns.push({
      dataIndex: props.itemKey,
      index: Object.keys(parents).indexOf(props.itemKey),
      type: "select",
      mode: mode,
      valueIndex: props.isUseCode ? "code" : "name",
      title: props.tableColTitle || props.title,
      width: props.tableColWidth,
      sortable: true,
      editable: false,
      permission: props.permission,
      volitate: props.volitate,
      statusInTable: props.statusInTable,
      avatarInTable: props.avatarInTable,
      hiddenForReadOnly: props.hideInTable,
      selectkey: props.selectkey,
      filter: props.filter,
      parentkeys: props.parentkeys,
      isUseCode: props.isUseCode,
      isHideCode: props.isHideCode,
      datasec: props.datasec,
      datasectype: props.datasectype,
      showArrow: props.showArrow,
      showSearch: props.showSearch,
      allowClear: props.allowClear,
      open:props.open
    });
    if (props.isSelectKey) {
      extraParams.selectConfig.optionTypes.push(props.itemKey);
    }
  }
}

SfSelect.isSearchable = true;
SfSelect.isRulable = true;

registerComponent({
  key: "SfSelect",
  component: SfSelect,
  runtimeComponent: SfpSelect,
  template: <SfSelect itemKey={getId('select')} className="sf-select wrap"
    title={"Select"} span={24} labelColStr="span:6" isUseCode={true}
    showSearch={true} allowClear={true} showArrow={true} dropdownMatchSelectWidth={true}/>,
  title: <IntlMessages id="system.form.library.select" text="Select" />,
  icon: <CgSelectR  className="react-icons icon-cg"/>,
  type: "Component",
  sequence: 5,
});

export default SfSelect;