/* eslint-disable no-template-curly-in-string */
import { Col, InputNumber, Row, Switch } from "antd";
import { ColorPicker, Form, Input, Select, PixelInput } from "components/Form";
import { selectApi } from "parse-api";
import React, { useEffect, useState } from "react";
import { compare, flattenJSON, getText, sortObjectByValue, uid, updateFlattenJSON, isEmpty, log } from "util/algorithm";
import IntlMessages from "util/IntlMessages";
import { deduceUndefined, getCraftSelectedNodeId, Logger } from "../../../../util/algorithm";
import {FaExternalLinkAlt } from "react-icons/fa";
import { getLbl, getMessage } from "../../../../lngProvider";

let currentSelectedNodeId = null;

const scrollIntoView = (selectedNodeId) => {
  if (currentSelectedNodeId !== selectedNodeId) {
    currentSelectedNodeId = selectedNodeId;
    setTimeout(() => {
      const elms = document.querySelectorAll("*[style]");
      let target = null;
      elms.forEach(el => {
        if (el.style.outline === "orange solid 3px") target = el;
      });
      if (target) {
        scrollIntoViewIfOutOfView(target)
      }
    }, 500);
  }
}

function scrollIntoViewIfOutOfView(el) {
  if (el.scrollIntoViewIfNeeded) {
    el.scrollIntoViewIfNeeded();
  } else {
    el.scrollIntoView(false);
  }
}

export const EditorCollector = (state, query) => {
  const currentNodeId = getCraftSelectedNodeId({state, query})
  let selectedNode;

  if (currentNodeId) {
    selectedNode = {
      id: currentNodeId,
      name: state.nodes[currentNodeId].data.name,
      displayName: state.nodes[currentNodeId].data.displayName,
      props: state.nodes[currentNodeId].data.props,
      settings: state.nodes[currentNodeId].related && state.nodes[currentNodeId].related.settings,
      isDeletable: query.node(currentNodeId).isDeletable()
    };

    scrollIntoView(currentNodeId);
  }

  const ancestors = selectedNode ? query.node(selectedNode.id).ancestors() : null;
  let sibling = [];
  let searchable = [];
  let rulable = [];
  if (ancestors && ancestors.length > 0) {
    sibling = query.node(ancestors[0]).childNodes();
    searchable = sibling.filter(sid => {
      const n = query.node(sid).get();
      const type = n?.data?.name;
      const UC = UserComponents[type];
      return !!(UC?.isSearchable);
    }).map(sid => {
      const n = query.node(sid).get();
      return n?.data?.props?.itemKey;
    }).map(s => {
      return { value: s, label: s };
    })
    rulable = sibling.filter(sid => {
      const n = query.node(sid).get();
      const type = n?.data?.name;
      const UC = UserComponents[type];
      return !!(UC?.isRulable);
    }).map(sid => {
      const n = query.node(sid).get();
      return n?.data?.props?.itemKey;
    }).map(s => {
      return { value: s, label: s };
    })
    sibling = sibling.filter(sid=>sid !== selectedNode.id).map(sid => {
      const n = query.node(sid).get();
      return n?.data?.props?.itemKey;
    });
  }
  const parents = sibling.map(s => {
    return { value: s, label: s };
  })
  return {
    selectedNode,
    ancestors,
    sibling,
    parents,
    searchable,
    rulable
  }
}

const allowedParentTypes = ['SfSelect', 'SfSystemUser', 'SfDate', 'SfArray', 'SfInput', 'SfInputNumber']

export const SelectCollector = (state, query) => {
  const currentNodeId = getCraftSelectedNodeId({state, query});
  let selectedNode;

  if (currentNodeId) {
    selectedNode = {
      id: currentNodeId,
      name: state.nodes[currentNodeId].data.name,
      displayName: state.nodes[currentNodeId].data.displayName,
      props: state.nodes[currentNodeId].data.props,
      settings: state.nodes[currentNodeId].related && state.nodes[currentNodeId].related.settings,
      isDeletable: query.node(currentNodeId).isDeletable()
    };
  }

  const ancestors = selectedNode ? query.node(selectedNode.id).ancestors() : null;
  let sibling = [];
  if (ancestors && ancestors.length > 0) {
    sibling = query.node(ancestors[0]).childNodes();
    sibling = sibling.filter(sid=> {
      if (sid !== selectedNode.id) {
        const n = query.node(sid).get();
        if (allowedParentTypes.indexOf(n?.data?.name) !== -1) {
          return true;
        }
      }
      return false;
    }).map(sid => {
      const n = query.node(sid).get();
      return n?.data?.props?.itemKey;
    });
  }
  const localSelects = sibling.map(s => {
    return { value: s, label: s };
  })
  const mainSelects = getMainSelects(query, selectedNode);
  return {
    localSelects,
    mainSelects,
  }
}

export const getMainSelects = (query, selectedNode) => {
  try {
    if (selectedNode) {
      const ancestor = query.node(selectedNode.id).ancestors()[0];
      let mainSibling = [];
      const root = query.node("ROOT");
      const rootSibling = root.childNodes();
      const mainNode = rootSibling.filter(sid => {
        const n = query.node(sid).get();
        return n.data.name === 'SfMainPanel';
      })[0];
      const mainContainer = query.node(mainNode).linkedNodes()[0];
      if (ancestor !== mainContainer) {
        mainSibling = query.node(mainContainer).childNodes();
        mainSibling = mainSibling.filter(sid => {
          const n = query.node(sid).get();
          return allowedParentTypes.indexOf(n?.data?.name) !== -1;
        }).map(sid => {
          const n = query.node(sid).get();
          return n?.data?.props?.itemKey;
        });
        const mainParents = mainSibling.map(s => {
          return { value: 'main.'+s, label: 'main.'+s };
        })
        return mainParents;
      }
    }
  } catch (error) {}
  return [];
}

export const NodeCollector = (node) => {
  const selected = node.events.selected;
  const style = selected ? { outline: "orange 3px solid", zIndex: 1 } : null;
  return {
    selected: selected,
    style: style,
  };
}

const LAYOUTATTR = ["span", "xs", "sm", "md", "lg", "xl", "xxl"];
export const SfLayoutSetting = () => {
  return (
    <>
      <Form.Item name="labelAlign" label="label align">
        <Select>
          <Select.Option value="left">left</Select.Option>
          <Select.Option value="right">right</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="labelColStr" label="label col">
        <Input className="item-property" />
      </Form.Item>
      <Row justify="start" className="sf-layout-setting" gutter={0} style={{ marginLeft: "-8px", marginRight: "18px" }}>
        {LAYOUTATTR.map((attr, index) => {
          return (
            <Col key={attr} span={8} className={(index % 3) === 0 ? "first" : null}>
              <Form.Item name={attr} label={attr} labelAlign={"right"} labelCol={{span:10}} wrapperCol={{span:10}}>
                <InputNumber min="0" max="24" style={{ width: "35px" }} controls={false} />
              </Form.Item>
            </Col>
          )
        })}
      </Row>
    </>
  );
}

export const deleteButton = (selected, selectedNode, action, deletedRef) => {
  return selected && selectedNode?.isDeletable ? (
    <span onClick={() => {
      action.delete(selectedNode.id);
      if (deletedRef) deletedRef.current = true;
    }}>
      <i className={"icon icon-close craft-form-delete-button"} />
    </span>
  ) : null;
}

export const convertStyleStr = (styleStr) => {
  if (styleStr) {
    try {
      const map = {}
      const token = styleStr.replace(/"/g, '').split(/[,;]/g);
      token.forEach(t => {
        let name = t;
        let val = true;
        if (t.indexOf(":") !== -1) {
          const index = t.indexOf(":");
          name = t.substr(0, index).trim();
          val = t.substr(index + 1).trim();
        }
        name = name.replace(/-\w/g, (c) => c.slice(1).toUpperCase());
        if (name.match(/^[a-zA-Z][a-zA-Z0-9]*$/)) {
          map[name] = val;
        }
      });
      return map;
    } catch (e) {
      log(e);
      return null;
    }
  } else {
    return null;
  }
}

export const colSls = (props) => {
  const layout = {};
  for (const element of LAYOUTATTR) {
    if (props[element]) {
      layout[element] = props[element];
    }
  }
  return layout;
}

export const SfPanelContext = React.createContext();
SfPanelContext.displayName = "Panel Context";

export const SfPanelListContext = React.createContext();
SfPanelContext.displayName = "Panel List Context";

export const SfTabsContext = React.createContext();
SfTabsContext.displayName = "Tabs Context";

export const SfTableContext = React.createContext();
SfTableContext.displayName = "Table Context";

export const persistentInfo = (table, name) => {
  if (table) {
    return (
      <>
        <Form.Item name={[...name, "className"]} label="className" hidden>
          <Input className="item-property" />
        </Form.Item>
        <Form.Item name={[...name, "objectId"]} label="objectId" hidden>
          <Input className="item-property" />
        </Form.Item>
        <Form.Item name={[...name, "rowKey"]} label="rowKey" hidden>
          <Input className="item-property" />
        </Form.Item>
        <Form.Item name={[...name, "createdAt"]} label="createdAt" hidden>
          <Input className="item-property" />
        </Form.Item>
        <Form.Item name={[...name, "updatedAt"]} label="updatedAt" hidden>
          <Input className="item-property" />
        </Form.Item>
      </>
    )
  } else {
    return (
      <>
        <Form.Item name={[...name, "rowKey"]} label="rowKey" hidden>
          <Input className="item-property" />
        </Form.Item>
      </>
    );
  }
}

export const Elements = {};
export const Buttons = {};
export const UserRuntimeComponents = {};
export const UserComponents = {};

export const registerComponent = ({
  key, component, runtimeComponent,
  template, title, icon, type, subtype, sequence }) => {
  UserComponents[key] = component;
  UserRuntimeComponents[key] = runtimeComponent;
  if (!type) type = "Component";
  const btntype = type + (subtype ? "-"+subtype : "");
  const buttons = Buttons[btntype] || {};
  const elements = Elements[type] || {};
  Buttons[btntype] = buttons;
  Elements[type] = elements;

  elements[key] = key;
  if (template) {
    buttons[key] = {
      key: key,
      template: template,
      title: title,
      icon: icon,
      sequence: sequence,
    };
  }
}

export const getAllButtons = () => {
  let result = [];
  Object.values(Buttons).forEach(map => {
    result = result.concat(Object.values(map));
  });
  result = result.sort((a, b) => compare(a.title, b.title));
  return result;
}

export const getAllButtonGroups = () => {
  let result = [];
  Object.values(Buttons).forEach(map => {
    result.push(Object.values(map));
  });
  result = result.sort((a, b) => compare(a.title, b.title));
  return result;
}

export const getButtons = (type) => {
  const map = Buttons[type] || {};
  let result = Object.values(map)
  result = result.sort((a, b) => compare(a,b,["sequence","title"]));
  return result;
}

export const getElements = (type) => {
  const map = Elements[type] || {};
  return Object.values(map);
}

export const getRender = (node, form, tableKey, onRowChange) => {
  const type = node.type.resolvedName;
  const UC = UserRuntimeComponents[type];
  if (UC.render) {
    return UC.render(node.props, form, tableKey, onRowChange);
  } else {
    return null;
  }
}

export const getRenderByProps = (props) => {
  const type = props.resolvedName;
  const UC = UserRuntimeComponents[type];
  if (UC.render) {
    return UC.render(props);
  } else {
    return null;
  }
}

export const getHeaderCell = (node, form, tableKey, onRowChange) => {
  const type = node.type.resolvedName;
  const UC = UserRuntimeComponents[type];
  if (UC.renderHeader) {
    return (props) => {
      props.columnHeaderComponent = UC.renderHeader(node.props, form, tableKey, onRowChange)
      return props
    };
  } else {
    return null;
  }
}

const DEFAULT_ID = "96dj03";
export const getRealId = (type) => `${type}${uid()}`;

export const patchRealId = (json) => {
  json = json.replace(RegExp(DEFAULT_ID, "g"), (match) => {
    return uid();
  });
  return json;
}

const ITEM_KEY_NOT_REQUIRED = [
  "SfCanvas", "Container","ButtonArea","RepeatedArea",
  "SfAdvanceSearch","SfSimpleSearch","SfDivider","SfButton",
  "EditPad"
]

const INVALID_KEY = /[. /]/;
const PREFERRED_KEY = /^[A-Z][A-Za-z0-9]+$/;
const RESERVED_WORDS = [
  /^(flowParent|flow|parent|relation|createdAt|updatedAt|ACL|id|objectId|deleteInd|rowKey|key|checked|translateMap|timezone|formState)$/,
  /^(push|replace|reload|open|openPage|cancelPage|closePage|forceUpdate|errorMessage|infoMessage|warningMessage|.*_OnChange|resetModified)$/
]

export const updateDisplayName = (props, id) => {
  if (!props.custom) props.custom = {};
  if (props.props?.itemKey) {
    props.custom.displayName = props.displayName + " (" + props.props?.itemKey + ")";
  } else {
    props.custom.displayName = props.displayName;
  }
}

export const validateFormInfo = (json, formData, allViews, allActionPolicies) => {
  const nodeMap = JSON.parse(json);
  const extraParams = {allViews};
  const itemKeyMap = {};
  const keyItemMap = {};
  const cntrMap = {};
  const parentMap = {};

  if (formData.formKey && formData.formKey.match(INVALID_KEY)) {
    return returnError("form key is invalid - ("+formData.formKey+")");
  }
  if (!formData.versionStamp && formData.formKey && !formData.formKey.match(PREFERRED_KEY)) {
    return returnError("form key should be camel case and start with capital letter - ("+formData.formKey+")");
  }

  if (formData.formKey && formData.formKey.match(INVALID_KEY)) {
    return returnError("form key is invalid - ("+formData.formKey+")");
  }

  for (const key in nodeMap) {
    if (nodeMap.hasOwnProperty(key)) {
      const node = nodeMap[key];
      const itemKey = node.props?.itemKey;
      if (itemKey) {
        if (ITEM_KEY_NOT_REQUIRED.indexOf(node.type.resolvedName) === -1) {
          if (!itemKey.match(/^[a-zA-Z][a-zA-Z_$0-9]*$/)) {
            return returnError("Item Key is invalid - ("+node.displayName+" - "+itemKey+")");
          }
          for (const pattern of RESERVED_WORDS) {
            if (itemKey.match(pattern)) {
              return returnError("Item Key is reserved word - ("+node.displayName+" - "+itemKey+")");
            }
          }
        }
        if (node.props?.table && !node.props.table.match(/^[a-zA-Z][a-zA-Z_$0-9]*$/)) {
          return returnError("Table is invalid - ("+node.displayName+" - "+itemKey+" - "+node.props.table+")");
        }
        keyItemMap[key] = itemKey;
        if (!itemKeyMap[itemKey]) {
          itemKeyMap[itemKey] = key;
        }
      } else {
        if (ITEM_KEY_NOT_REQUIRED.indexOf(node.type.resolvedName) === -1) {
          return returnError("Item Key is missing - ("+key+")");
        }
      }
      // clear rules
      if (node.props?.rules) delete node.props.rules;
      if (node.props?.noupdate && node.type.resolvedName !== 'SfRule') delete node.props.noupdate;
      if (node.props?.uniquetable) delete node.props.uniquetable;
      if (node.props?.condistyles) delete node.props.condistyles;
    }
  }

  let i;
  for (const key in nodeMap) {
    if (nodeMap.hasOwnProperty(key)) {
      const node = nodeMap[key];
      updateDisplayName(node, key);
      const nodes = node.nodes;
      if (nodes) {
        const parents = {};
        for(i = 0; i < nodes.length; i++) {
          const id = nodes[i];
          const key = keyItemMap[id];
          if (key && parents[key]) {
            return returnError("Item Key is duplicated - "+key);
          }
          parents[key] = nodeMap[id];
        }

        for(i = 0; i < nodes.length; i++) {
          const id = nodes[i];
          if (cntrMap[id]) {
            return returnError("Node found in two containers "+key+" and "+cntrMap[id]);
          } else {
            parentMap[id] = parents;
            cntrMap[id] = key;
          }
        }
      }
      let linkedNodes = node.linkedNodes;
      if (linkedNodes) {
        linkedNodes = Object.values(linkedNodes);
        for(i = 0; i < linkedNodes.length; i++) {
          const id = linkedNodes[i];
          if (cntrMap[id]) {
            return returnError("Node found in two containers "+key+" and "+cntrMap[id]);
          } else {
            cntrMap[id] = key;
          }
        }
      }
    }
  }

  const getCntr = (key) => {
    if (cntrMap[key]) {
      const k1 = cntrMap[key];
      if (k1) {
        const k2 = cntrMap[k1];
        const n = nodeMap[k2];
        if (n && n.props?.itemKey) {
          return n;
        } else {
          const k3 = cntrMap[k2];
          const m = nodeMap[k3];
          if (m) {
            return m;
          }
        }
      }
    }
    return null;
  }

  // init data class config
  const dataClassConfig = {
    key: formData.formKey,
    formKey: formData.formKey,
    table: null,
    displayName: null,
    simpleSearchIndex: [],
    advanceSearchIndex: [],
    columns: [
    ],
    reads: [],
    writes: [],
    useMasterKey: true,
  };
  dataClassConfig.columns.push(
    {dataIndex: "objectId", type: "string", title: "Object ID", sortable: false, editable: false, width: null}
  );

  const selectConfig = {
    selectKey: formData.formKey,
    optionTypes: [],
    name: null,
    labelKey: "",
    valueKey: "",
    dataClass: formData.formKey,
  }
  extraParams.dataClassConfig = dataClassConfig;
  extraParams.selectConfig = selectConfig;
  extraParams.relations = [];
  extraParams.submits = [];
  extraParams.translate = {
    enabled: false,
    items: [],
    recordKey: null,
  };

  let mainParents = [];
  for (const key in nodeMap) {
    if (nodeMap.hasOwnProperty(key)) {
      const node = nodeMap[key];
      const itemKey = node.props?.itemKey;
      if (itemKey) {
        const node = getCntr(key);
        if (node && node.type.resolvedName === 'SfMainPanel') {
          mainParents = parentMap[key] || [];
          break;
        }
      }
    }
  }

  for (const key in nodeMap) {
    if (nodeMap.hasOwnProperty(key)) {
      const node = nodeMap[key];
      const type = node.type.resolvedName;
      const parents = parentMap[key];
      const cntr = getCntr(key);
      const UC = UserComponents[type];
      const currentNode = node;
      // clear permission map
      if (node.props.permission) {
        const removeList = [];
        for(const k in node.props.permission) {
          if (allViews.indexOf(k) === -1) {
            removeList.push(k);
          }
        }
        removeList.forEach(k => {
          delete currentNode.props.permission[k];
        })
      }

      if (UC.validate) {
        const result = UC.validate(node.props, {parents: parents, mainParents: mainParents, container: cntr, extraParams, formData, allActionPolicies});
        if (result) {
          return returnError(result);
        }
      }
    }
  }
  if (extraParams.dataClassConfig?.columns) {
    sortObjectByValue(extraParams.dataClassConfig.columns, 'index');
  }
  dataClassConfig.columns.push(
    {dataIndex: "createdAt", type: "date", title: "Created At", sortable: true, editable: false, width: null}
  );
  dataClassConfig.columns.push(
    {dataIndex: "updatedAt", type: "date", title: "Updated At", sortable: true, editable: false, width: null}
  );
  dataClassConfig.columns.push(
    {dataIndex: "versionStamp", type: "number", title: "Version", sortable: true, editable: false, width: null}
  );

  const convertedJson = JSON.stringify(nodeMap, (key, value) => deduceUndefined(value));
  extraParams.propsMapping = undefined;

  if (extraParams.submits) {
    sortObjectByValue(extraParams.submits, 'index');
  }

  if (extraParams.translate.enabled) {
    if (isEmpty(extraParams.translate.recordKey) && extraParams.translate.items.length > 0) {
      return returnError(<IntlMessages id="system.form.validate.value-key-missing" text="item ({itemKey}) - value key is missing." values={{itemKey:extraParams.translate.items[0]?.key}}/>
      );
    }
  }

  return {
    error: null,
    json: convertedJson,
    extraParams: extraParams,
  };
}

const returnError = (error) => {
  return {
    error: error
  };
}

export const getId = (type) => `${type}${DEFAULT_ID}`;

export const defaultOptionFilter = (input, option) => {
  return getText(option).toLowerCase().indexOf(input.toLowerCase()) >= 0;
}

export const refreshOptions = async (locale, selectkey, isUseCode) => {
  const list = await selectApi.fetchSystemSelectOption(locale, selectkey, isUseCode);
  return list;
}

const typeTemplate = '${label} is not a valid ${type}';
const defaultValidateMessages = {
  "default": 'Field validation error for ${label}',
  required: 'Please provide a ${label}',
  "enum": '${label} must be one of [${enum}]',
  whitespace: '${label} cannot be a blank character',
  date: {
    format: '${label} date format is invalid',
    parse: '${label} cannot be converted to a date',
    invalid: '${label} is an invalid date'
  },
  types: {
    string: typeTemplate,
    method: typeTemplate,
    array: typeTemplate,
    object: typeTemplate,
    number: typeTemplate,
    date: typeTemplate,
    "boolean": typeTemplate,
    integer: typeTemplate,
    "float": typeTemplate,
    regexp: typeTemplate,
    email: typeTemplate,
    url: typeTemplate,
    hex: typeTemplate
  },
  string: {
    len: '${label} must be ${len} characters',
    min: '${label} must be at least ${min} characters',
    max: '${label} must be up to ${max} characters',
    range: '${label} must be between ${min}-${max} characters'
  },
  number: {
    len: '${label} must be equal to ${len}',
    min: '${label} must be minimum ${min}',
    max: '${label} must be maximum ${max}',
    range: '${label} must be between ${min}-${max}'
  },
  array: {
    len: 'Must be ${len} ${label}',
    min: 'At least ${min} ${label}',
    max: 'At most ${max} ${label}',
    range: 'The amount of ${label} must be between ${min}-${max}'
  },
  pattern: {
    mismatch: '${label} does not match the pattern ${pattern}'
  }
};

const defaultValidateMessagesMap = {}

export const getDefaultValidateMessage = (locale) => {
  const convertedMessage = JSON.parse(JSON.stringify(defaultValidateMessages));
  const flatten = flattenJSON(convertedMessage);
  const updated = {};
  const keys = Object.keys(flatten);
  for (const element of keys) {
    const lbl = getMessage(locale.languageId, "system.form."+element, flatten[element]);
    if (lbl) {
      updated[element] = lbl;
    }
  }
  updateFlattenJSON(convertedMessage, updated);
  defaultValidateMessagesMap[locale.languageId] = convertedMessage;
  return convertedMessage;
}

const ROW_JUSTIFY = ["start", "end", "center", "space-around", "space-between"];
const ROW_ALIGN = ["top", "middle", "bottom"];
const BORDER_STYLE = ["none", "dotted", "dashed", "solid"]
const BOX_SHADOW = "0 0 10px 10px rgb(0 0 0 / 3%)"
const FONT = [
  "Times New Roman, Times, serif",
  "Georgia, serif",
  "Garamond, serif",
  "Arial, Helvetica, sans-serif",
  "Tahoma, Verdana, sans-serif",
  "Trebuchet MS, Helvetica, sans-serif",
  "Georgia, Verdana, sans-serif",
  "Courier New, Courier, monospace",
  "Brush Script MT, cursive",
  "Copperplate, Papyrus, fantasy",
]
const TEXT_ALIGN = ["left", "center", "right", "justify"]
const BoxShadow = ({value, onChange}) => {
  const [enabled, setEnabled] = useState(false);
  useEffect(()=> {
    if (value) {
      if (value !== BOX_SHADOW) {
        if (onChange) onChange(BOX_SHADOW);
      }
      setEnabled(true);
    } else {
      setEnabled(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[value])
  const onSwitchChange = (value) => {
    setEnabled(value);
    if (value)  {
      if (onChange) onChange(BOX_SHADOW)
    } else {
      if (onChange) onChange(null)
    }
  }
  return (
    <Switch className="item-property" checked={enabled} onChange={onSwitchChange}/>
  )
}

export const SfStyleSetting = () => {
  return (
    <>
      <Form.Item name={["style", "color"]} label={"color"}>
        <ColorPicker />
      </Form.Item>
      <Form.Item name={["style", "backgroundColor"]} label={"background"}>
        <ColorPicker />
      </Form.Item>
      <Form.Item name={["style", "borderColor"]} label={"bder. color"}>
        <ColorPicker />
      </Form.Item>
      <Form.Item name={["style", "borderWidth"]} label={"bder. width"}>
        <PixelInput min={0} max={24}/>
      </Form.Item>
      <Form.Item name={["style", "borderStyle"]} label={"bder. style"}>
        <Select className="item-property" allowClear>
          {BORDER_STYLE.map(t => <Select.Option key={t} value={t}>{t}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name={["style", "boxShadow"]} label={"shadow"}>
        <BoxShadow/>
      </Form.Item>
      <Form.Item name={"margin"} label={"margin"}>
        <Form.Item name={["style", "marginTop"]} noStyle>
          <PixelInput min={0} max={24} style={{ width: "35px", marginRight: "1px" }} />
        </Form.Item>
        <Form.Item name={["style", "marginRight"]} noStyle>
          <PixelInput min={0} max={24} style={{ width: "35px", marginRight: "1px" }} />
        </Form.Item>
        <Form.Item name={["style", "marginBottom"]} noStyle>
          <PixelInput min={0} max={24} style={{ width: "35px", marginRight: "1px" }} />
        </Form.Item>
        <Form.Item name={["style", "marginLeft"]} noStyle>
          <PixelInput min={0} max={24} style={{ width: "35px", marginRight: "1px" }} />
        </Form.Item>
      </Form.Item>
      <Form.Item name={"padding"} label={"padding"} >
        <Form.Item name={["style", "paddingTop"]} noStyle>
          <PixelInput min={0} max={24} style={{ width: "35px", marginRight: "1px" }} />
        </Form.Item>
        <Form.Item name={["style", "paddingRight"]} noStyle>
          <PixelInput min={0} max={24} style={{ width: "35px", marginRight: "1px" }} />
        </Form.Item>
        <Form.Item name={["style", "paddingBottom"]} noStyle>
          <PixelInput min={0} max={24} style={{ width: "35px", marginRight: "1px" }} />
        </Form.Item>
        <Form.Item name={["style", "paddingLeft"]} noStyle>
          <PixelInput min={0} max={24} style={{ width: "35px", marginRight: "1px" }} />
        </Form.Item>
      </Form.Item>
      <Form.Item name={["style", "height"]} label={"height"}>
        <PixelInput min={0} />
      </Form.Item>
      <Form.Item name={["style", "minHeight"]} label={"min height"}>
        <PixelInput min={0} />
      </Form.Item>
      <Form.Item name={["style", "maxHeight"]} label={"max height"}>
        <PixelInput min={0} />
      </Form.Item>
      <Form.Item name={["style", "width"]} label={"width"}>
        <PixelInput min={0} />
      </Form.Item>
      <Form.Item name={["style", "minWidth"]} label={"min width"}>
        <PixelInput min={0} />
      </Form.Item>
      <Form.Item name={["style", "maxWidth"]} label={"max width"}>
        <PixelInput min={0} />
      </Form.Item>
      <Form.Item name={["justify"]} label={"row justify"}>
        <Select className="item-property" allowClear>
          {ROW_JUSTIFY.map(t => <Select.Option key={t} value={t}>{t}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name={["align"]} label={"row align"}>
        <Select className="item-property" allowClear>
          {ROW_ALIGN.map(t => <Select.Option key={t} value={t}>{t}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name={["style", "fontFamily"]} label={"font"}>
        <Select className="item-property" allowClear>
          {FONT.map(t => <Select.Option key={t} value={t}>{t}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name={["style", "fontSize"]} label={"font size"}>
        <PixelInput min={5} />
      </Form.Item>
      <Form.Item name={["style", "fontWeight"]} label={"font weight"}>
        <InputNumber min={100} max={900} step={100} />
      </Form.Item>
      <Form.Item name={["style", "textAlign"]} label={"text-align"}>
        <Select className="item-property" allowClear>
          {TEXT_ALIGN.map(t => <Select.Option key={t} value={t}>{t}</Select.Option>)}
        </Select>
      </Form.Item>
    </>
  );
}

export const getParentName = (ctx, parentkey) => {
  let parentname = null;
  if (ctx && parentkey) {
    if (ctx.itemKey) {
      parentname = [ctx.itemKey, ...ctx.name, parentkey];
    } else {
      if (ctx.name) {
        parentname = [...ctx.name, parentkey];
      } else {
        parentname = [parentkey];
      }
    }
  } else if (ctx) {
    if (ctx.itemKey) {
      parentname = [ctx.itemKey, ...ctx.name];
    } else {
      if(Array.isArray(ctx?.name))parentname = [...ctx.name];
      else parentname = [];
    }
  } else {
    parentname = [];
  }
  return parentname;
}

export const shouldUpdate = ({condistyles, ctx, form, style, setFxr, isHidden, doRefresh}) => {
  let su = doRefresh;
  if (condistyles && form) {
    su = (...args) => {
      if (doRefresh) doRefresh(...args);
      let hidden = false;
      let disabled = false;
      let merged = {...style};
      for (const element of condistyles) {
        const parentkey = element.parentkey;
        const parentkeyfullpath = element.parentkeyfullpath;
        let parentname =  getParentName(ctx, parentkey);
        if (!isEmpty(parentkeyfullpath)) parentname = parentkeyfullpath;
        const parentvalue = form.getFieldValue(parentname);
        let matched = false;
        if (element.empty && (isEmpty(parentvalue) || parentvalue === false)) {
          matched = true;
        }
        if(!element.empty && !(isEmpty(parentvalue) || parentvalue === false)){
          matched = true;
        }
        if (typeof parentvalue == 'number') {
          if (!matched) {
            if ((element.min && element.max &&
              parentvalue >= element.min &&
              parentvalue <= element.max) ||
              (element.min && parentvalue >= element.min) ||
              (element.max && parentvalue <= element.max)
            ) {
              matched = true;
            }
          }
        }
        if (parentvalue && element.pattern) {
          const str = `${parentvalue}`;
          if (str.match(new RegExp(element.pattern))) {
            matched = true;
          }
        }
        if (parentvalue && element.inversePattern) {
          const str = `${parentvalue}`;
          if (!str.match(new RegExp(element.inversePattern))) {
            matched = true;
          }
        }
        if (matched) {
          if (element.hidden) {
            hidden = true;
          } else {
            if (element.style) {
              merged = {...merged, ...element.style};
            }
            if (element.disabled) {
              disabled = true;
            }
          }
        }
      }
      if (isHidden) hidden = true;
      const fxr = {hidden, disabled, style: merged}
      setFxr(fxr);
      return true;
    }
  }
  return su;
}

const runValidationRulesImpl = async ({scriptKey, script, rule, ruleObj, getFieldValue, value}) => {
  return new Promise((resolve, reject) => {
    const logger = new Logger();
    const lbl = (messageKey, text, values) => {
      return getLbl(messageKey, text, values)
    };
    const validationRule = {rule, ruleObj, getFieldValue, value, scriptKey, logger, lbl, resolve, reject}
    const inputparams = {validationRule}
    // eslint-disable-next-line no-new-func
    const newFunction = new Function("inputparams", `
      "use strict";
      const {validationRule} = inputparams;
      try {
        ${script}
      } catch (error) {
        console.error(error);
        validationRule.reject(error);
      }
    `)
    newFunction(inputparams);
  })
};

const runValidationRules = async ({rules, rule, ruleObj, getFieldValue, value}) => {
  for (const r of rules) {
    await runValidationRulesImpl({scriptKey: r.actionKey, script: r.data, rule, ruleObj, getFieldValue, value})
  }
}

export const convertRules = (rules, type) => {
  if (rules) {
    const newRules = [];
    for (let element of rules) {
      if (element.required) {
        if (type) {
          element = {...element}
          element.type = type
        }
        newRules.push(element);
      } else if (element.validationRules && element.validationRules.length > 0) {
        const r = ({ getFieldValue }) => ({
          validator : async (rule, value) => {
            await runValidationRules({rules: element.validationRules, rule, ruleObj: element, getFieldValue, value})
          },
        });
        newRules.push(r);
      } else if (element.type === 'regexp') {
        if (typeof element.pattern == 'string' ) {
          const {pattern, message} = element;
          const r = {
            validator: function(_, value) {
              if (!value || value.match(new RegExp(pattern))) {
                return Promise.resolve();
              }
              return Promise.reject(message);
            },
          };
          newRules.push(r);
        } else {
          newRules.push(element);
        }
      } else {
        newRules.push(element);
      }
    }
    return newRules;
  } else {
    return rules;
  }
}

const _getRealIndex = (list, record, index) => {
  const key = record.key || record.rowKey;
  let readIndex = -1;
  for (let i = 0; i < list.length; i++) {
    const target = list[i];
    const k = target.key || target.rowKey;
    if (key === k) {
      readIndex = i;
      break;
    }
  }
  if (readIndex !== -1) {
    return readIndex;
  } else {
    return index;
  }
}

export const updateTableCell = ({form, tableKey, record, itemKey, myValue, onRowChange, setEditing, event, index, exclusive}) => {
  try {
    const list = form.getFieldValue(tableKey) || [];
    const newRecord = {...record}
    if (newRecord.key) newRecord.rowKey = newRecord.key;
    delete newRecord.key;
    newRecord[itemKey] = myValue;
    const newList = [...list];
    const realIndex = _getRealIndex(newList, record, index);
    if (exclusive) {
      newList.forEach(n => n[itemKey] = false);
    }
    newList[realIndex] = newRecord;
    const formData = {};
    formData[tableKey] = newList;
    form.setFieldsValue(formData);
    if (setEditing) setEditing(false);
    if (event) event.preventDefault();
    if (onRowChange) onRowChange();
  } catch (e) {
    console.error(e);
  }
}

export const defaultPermissions = [
  "add", "copy", "update", "delete", "print", "export", "reload", "close"
];

export const permissionOptions = [
  {label:"add",value:"add"},
  {label:"copy", value:"copy"},
  {label:"update", value:"update"},
  {label:"delete", value:"delete"},
  {label:"print", value:"print"},
  {label:"export", value:"export"},
  {label:"view", value:"view"},
  {label:"history", value:"history"},
  {label:"reload", value:"reload"},
  {label:"close", value:"close"},
];

export const convertSelectOptionLabel = (label, isUseCode, isHideCode) => {
  if (isUseCode && isHideCode && !isEmpty(label)) {
    return label.replace(/\s*\([^(]*\)$/,'');
  } else {
    return label;
  }
}

export const generateTooltip = (mes, link ) => {
  if(!mes) return undefined
  return <div className="sf-tooltip-content">{mes}{link ? <a className="sf-tooltip-link" href={link} target="_blank" rel="noreferrer"><FaExternalLinkAlt  /></a> : null }</div>
}