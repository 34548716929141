import { Button, Col, Collapse, Empty, Form, message, Popconfirm, Row, Switch, Typography } from "antd";
import { useLbl } from "lngProvider";
import moment from "moment";
import React, { useEffect, useReducer, useRef } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { ResizableTable } from "../../../components/Form/ResizableTable";
import Loading, { LoadingProps } from "../../../components/Loading";
import { AccountStore } from "../../../constants/Account";
import { dataExplorer, formApi, system } from "../../../parse-api";
import { cleanFilename, compare, decompressJsonData, delay, delayRun, displayError, equals, getArrayElementByAttribute, getArraysIntersection, getLocalStorageItemObject, getSortPrefix, isDate, isEmpty, log, prepareText, setLocalStorageItemObject, toAuthUserObj, uid, waitForCondition } from "../../../util/algorithm";
import { exportToXLSX, importFromXLSX } from "../../../util/export";
import IntlMessages from "../../../util/IntlMessages";
import { DEFAULT_COLUMNS, filterActionCol, getActionColumn, getExpandIcon, headerExpandIcon, RecordModal, RelationModal, runPostExportDataAction, runPreImportDataAction, SearchPanel, SearchPanelToolbar, SettingsModal, TextNode, viewNodeMapping } from "./DataEntryToolsLib";
import { DEFAULT_SETTINGS, EMPTY_SETTINGS } from "../../../parse-api/data-explorer";
import { authSignal, commonSignal, destorySignal, settingsSignal, tableDatachangedIndicators } from "../../../util/signal";
import { useSignalValue, useSignal, useConsolidateTrigger } from "../../../util/reactHook";

const isCellClickable = (target, level) => {
  if (level > 20) return false;
  if (!target) return false;
  try {
    if (target.className?.split(" ").indexOf("clickable") !== -1) return true;
    if (target.className?.split(" ").indexOf("ant-popover") !== -1) return true;
  } catch (error) {}
  return isCellClickable(target.parentElement, level ? level + 1 : 1);
}

const getPageSize = (pagination, nopaging) => {
  if (nopaging) {
    return 100000;
  } else {
    return pagination?.pageSize || 10
  }
}

const updateApiRef = (apiRef, apiName, api) => {
  if (apiRef) {
    if (!apiRef.current) apiRef.current = {};
    apiRef.current[apiName] = api;
  }
}

const deduceTitle = ({options, relation, selectedDataClass, allDataClasses, dataClassConfig}) => {
  let title = dataClassConfig?.displayName;
  if (!title || relation?.length > 0) {
    options.forEach(option => {
      let label = option.label;
      const rel = relation.length > 0 ? relation[relation.length - 1] : null;
      if (rel && option.value === selectedDataClass.key) {
        const ParentClass = getArrayElementByAttribute(allDataClasses, "key", rel.parentClass);
        if (ParentClass?.labelPattern && rel.record) label = ParentClass.displayName + " " + prepareText(ParentClass.labelPattern, rel.record);
        relation.forEach(r => {
          label = label + " / " + r.relatedClass;
        })
        title = label;
      } else if (!rel && option.value === selectedDataClass?.key) {
        title = label;
      }
    })
  }
  return title;
}

const getDefaultPagination = (location, defaultPageSize) => {
  return location?.state?.pagination || { current: 1, pageSize: defaultPageSize ? defaultPageSize : 10, total: 1 };
}

const updateExpandedRowKeys = ({state}) => {
  const {expandedAll, result} = state;
  const res = result?.result || [];
  const keys = expandedAll ? res.map(r => r.objectId) : [];
  state.expandedRowKeys = keys;
}

const doLoadDataClasses = async (state) => {
  try {
    const allDataClasses = await dataExplorer.getAllDataClasses();
    const defaultDataClasses = await dataExplorer.getDefaultDataClasses();
    state.allDataClasses = allDataClasses;
    const opts = defaultDataClasses.map(c => {
      return {
        label: c.displayName,
        value: c.key,
      }
    });
    //}).sort((a, b) => compare(a.label, b.label));
    state.options = opts;
  } catch (e) {
    message.error(displayError(e));
  }
}

const doFetchSavedSearch = async (state) => {
  try {
    if (state.selectedDataClass) {
      dataExplorer.getAllSearch(state.selectedDataClass.key).then(list=> {
        state.savedSearch = list;
      });
    }
  } catch (e) {
    message.error(displayError(e));
  }
}

const doSubscribeTableDataChange = async (state) => {
  const table = state.selectedDataClass?.table;
  if (table) {
    const subject = `${table}_datachanged`
    system.subscribe(subject, () => {
      console.log('table data changed, reset total', table)
      tableDatachangedIndicators[subject] = true
      console.log('updated indictors', tableDatachangedIndicators)
    })
  }
}

const getOldSearchParamsKey = ({dataClassConfig, dataKey, itemKey}) => {
  const dataClassKey = dataClassConfig?.key || dataKey;
  return "dataentry_searchparam_"+dataClassKey+(itemKey ? "_" + itemKey : "");
}

const doProcessSettings = async (state) => {
  try {
    const settings = state.settings;
    const dataClassConfig = state.cProps.dataClassConfig;
    const dataKey = state.cProps.dataKey;

    const defaultPagination = state.cProps.defaultPagination;
    const itemKey = state.cProps.itemKey;
    const keepSearchState = state.cProps.keepSearchState;

    const oldSearchParamKey = getOldSearchParamsKey({dataClassConfig, dataKey, itemKey});
    const oldSearchParam = (dataClassConfig) ? getLocalStorageItemObject(oldSearchParamKey) : null;

    log("update settings", settings, "not is empty", settings !== EMPTY_SETTINGS)
    if (settings !== EMPTY_SETTINGS) {
      const queryParams = new URLSearchParams(window.location.search);
      if (queryParams.has("search")) {
        const value = queryParams.get("search");
        state.pagination = { ...defaultPagination };
        state.sort = null;
        state.searchText = value;
        state.params = value;
      } else if (queryParams.has("query")) {
        const valueStr = queryParams.get("query");
        state.pagination = { ...defaultPagination };
        state.sort = null;
        state.searchText = null;
        try {
          const advanceSearchParams = [{ operator:"or", value: [{ operator:"and", value: [JSON.parse(valueStr)] }] }];
          onAdvanceSearch({state, value: advanceSearchParams, autoSearch: true, openSearchPanel: true});
        } catch (e) {
          log("failed to set advance search params", valueStr)
        }
      } else if (oldSearchParam && ((settings.keepLastSearch && !itemKey) || (keepSearchState && itemKey))) {
        let lSearchText = oldSearchParam.searchText;
        if (typeof oldSearchParam.params === 'string') {
          lSearchText = oldSearchParam.params;
        }
        state.searchText = lSearchText;
        state.advanceSearchParams = oldSearchParam.params;
        state.pagination = { ...oldSearchParam.pagination, total:undefined };
        state.sort = oldSearchParam.sort;
        state.descending = oldSearchParam.descending;
        state.params = oldSearchParam.params;
        log('restore old search params', oldSearchParam)
      } else {
        state.pagination = { ...defaultPagination };
        state.sort = settings.sort;
        state.descending = settings.descending;
        state.searchText = "";
        state.params = null;
        log('initialize search settings', settings, state.pagination)
      }

    } else {
      log('settings has not loaded just yet...');
    }
  } catch (e) {
    log('load settings error', e)
  }
  state.isSettingsReady = true;
}

const getSortOrder = (col, sort, descending) => {
  if (col && col.dataIndex === sort) {
    const value = descending ? 'descend' : 'ascend';
    return value;
  } else {
    return false;
  }
}

const generateColumns = async (state) => {
  const { authUser } = authSignal;
  const authUserObj = toAuthUserObj(authUser);

  const lbl = state.cActions.lbl;
  const doDelete = state.cActions.doDelete;
  const renderOnCell = state.cActions.renderOnCell;

  const settings = state.settings;
  const sort = state.sort;
  const descending = state.descending;
  const pagination = state.pagination;
  const selectedDataClass = state.selectedDataClass;
  const relation = state.relation;
  const isDefaultSetting = state.isDefaultSetting;
  const computeDisplayMode = state.computeDisplayMode;

  const defaultPagination = state.cProps.defaultPagination;
  const nopaging = state.cProps.nopaging;
  const formKey = state.cProps.formKey;
  const getExtAction = state.cProps.getExtAction;
  const doExtEdit = state.cProps.doExtEdit;
  const doExtDelete = state.cProps.doExtDelete;
  const doEdit = state.cProps.doEdit;
  const forceShowAction = state.cProps.forceShowAction;

  state.pagination = { ...defaultPagination, pageSize: getPageSize(pagination, nopaging)};

  const fKey = formKey ? formKey : selectedDataClass?.key.replace(/.preview$/, "");
  let columns = [...(selectedDataClass ? selectedDataClass.columns : [])];
  const readOnly = !formKey && getArraysIntersection(selectedDataClass?.writes||[], authUserObj.roles).length === 0;
  state.readOnly = readOnly;
  const isRelation = relation.length > 0;
  state.isRelation = isRelation;
  columns = columns.map((c) => ({...c, sorter: (c.sortable && !settings.nosort?.[c.dataIndex]), sortOrder: getSortOrder(c, sort, descending)}));
  if (selectedDataClass) {
    if (readOnly || isRelation) {
      columns = columns.filter(col => !col.hiddenForReadOnly);
    }
    columns = columns.map(({title, dataIndex, ...props}) => {
      title = lbl(`form.${fKey}.${dataIndex}`, title);
      return {...props, dataIndex,  title: title}
    });

    if (
      (getExtAction && computeDisplayMode.action) ||
      (doExtEdit && computeDisplayMode.edit) ||
      (doExtDelete && computeDisplayMode.delete) ||
      (!readOnly && computeDisplayMode.readOnly)
    ) {
      columns.unshift(
        getActionColumn({
          formKey,
          lbl,
          getExtAction,
          doExtEdit,
          doExtDelete,
          computeDisplayMode,
          readOnly,
          isRelation,
          pagination,
          doEdit,
          doDelete,
        })
      );
    }

    if (isDefaultSetting) {
      columns = columns.filter(col => !readOnly || !col.hiddenForReadOnly);
    } else if (settings.hidden) {
      columns = columns.filter(col => !settings.hidden?.[col.dataIndex] || (forceShowAction && col.dataIndex === 'action'));
    }
    if (settings.index) {
      columns.forEach(c => c.index = settings.index[c.dataIndex]);
      columns = columns.sort((a, b) => compare(a.index, b.index));
      if (forceShowAction) {
        const actionCols = columns.filter(col => col.dataIndex === 'action');
        const othersCols = columns.filter(col => col.dataIndex !== 'action');
        columns = [...actionCols, ...othersCols];
      }
    }
    if (settings.width) {
      columns = columns.map(col => ({...col, width: settings.width?.[col.dataIndex]}));
    }

    const sortCols = [];
    if (selectedDataClass.columns) {
      const readOnly = !formKey && getArraysIntersection(selectedDataClass.writes, authUserObj.roles).length === 0;
      for (const element of selectedDataClass.columns) {
        const col = element;
        if (col.sortable && !settings.nosort?.[col.dataIndex] && (!readOnly || !col.hiddenForReadOnly)) {
          sortCols.push({ value: col.dataIndex, label: col.title });
        }
      }
    }
    state.sortOptions = sortCols;
  }

  let mergedColumns = columns.map((col, index) => {
    return {
      ...col,
      onCell: (record) => {
        return renderOnCell(col, record);
      }
    };
  });
  let hiddenColumns =  [];
  if (state.isMobileRef) {
    hiddenColumns = mergedColumns.filter(col => !!settings.hiddenInMobile?.[col.dataIndex]);
    mergedColumns = mergedColumns.filter(col => !settings.hiddenInMobile?.[col.dataIndex]);
  }
  state.mergedColumns = mergedColumns;
  state.hiddenColumns = hiddenColumns;
}

const doUpdateRelation = ({state}) => {
  console.log("doUpdateRelation() start ", state.selectedDataClass, state.selectedRelation)
  const { authUser } = authSignal;
  const authUserObj = toAuthUserObj(authUser);

  let readOnly = true;
  let isRelation = false;
  let rel = null;

  const formKey = state.cProps.formKey;
  const defaultPagination = state.cProps.defaultPagination;

  const relation = state.relation;
  const allDataClasses = state.allDataClasses;

  if (relation.length > 0) {
    isRelation = true;
    rel = relation[relation.length - 1];

    const selected = getArrayElementByAttribute(allDataClasses, "key", rel.relatedClass);
    const parent = getArrayElementByAttribute(allDataClasses, "key", rel.parentClass);
    const col = parent ? getArrayElementByAttribute(parent.columns, "dataIndex", rel.dataIndex) : null;

    if (selected && parent && col) {
      readOnly = !formKey && getArraysIntersection(parent.writes, authUserObj.roles).length === 0;
      if (!readOnly && !col.editable) readOnly = true;
      state.selectedDataClass = selected;
      state.selectedRelation = rel;
      state.readOnly = readOnly;
    }
    state.isRelation = isRelation;
    state.pagination = { ...defaultPagination };
    state.sort = null;
    state.searchText = "";
    state.params = null;
    console.log("doUpdateRelation() end ", state.selectedDataClass, state.selectedRelation)
  }
}

const doComputeDisplayMode = ({state}) => {
  const displaymode = state.cProps.displaymode;
  const showTitle = state.cProps.showTitle;

  const uploadEnabled = !state.readOnly && !state.isRelation && state.settings.dataUpload;
  if (displaymode === 'search') {
    state.computeDisplayMode = {
      title: false,
      download: true,
      export: true,
      upload: false,
      add: false,
      view: false,
      edit: false,
      delete: false,
      readOnly: false,
      action: true,
      admin: false,
      settings: true,
      search: true,
      simplesearch: false,
      restrictedsearch: false,
    }
  } else if (displaymode === 'restrictedsearch') {
    state.computeDisplayMode = {
      title: false,
      download: true,
      export: true,
      upload: false,
      add: false,
      view: false,
      edit: false,
      delete: false,
      readOnly: false,
      action: false,
      admin: false,
      settings: true,
      search: true,
      simplesearch: false,
      restrictedsearch: true,
    }
  } else if (displaymode === 'table') {
    state.computeDisplayMode = {
      title: false,
      download: false,
      export: false,
      upload: false,
      add: false,
      view: false,
      edit: false,
      delete: false,
      readOnly: false,
      action: true,
      admin: false,
      settings: false,
      search: false,
      simplesearch: false,
      restrictedsearch: true,
    }
  } else if (displaymode === 'maintenance-wo-simple-search') {
    state.computeDisplayMode = {
      title: !displaymode || showTitle,
      download: true,
      export: true,
      upload: uploadEnabled,
      add: true,
      view: true,
      edit: true,
      delete: true,
      readOnly: true,
      action: true,
      admin: true,
      settings: true,
      search: true,
      simplesearch: false,
      restrictedsearch: false,
    }
  } else {
    state.computeDisplayMode = {
      title: !displaymode || showTitle,
      download: true,
      export: true,
      upload: uploadEnabled,
      add: true,
      view: true,
      edit: true,
      delete: true,
      readOnly: true,
      action: true,
      admin: true,
      settings: true,
      search: true,
      simplesearch: true,
      restrictedsearch: false,
    }
  }
}

const updateScrollConfig = ({state}) => {
  const isMaxContent = state.cProps.isMaxContent;

  const sconfig = {}
  const hasWidth = state.mergedColumns.filter(m => typeof m.width === 'number').length > 0;
  if (isMaxContent || hasWidth) {
    sconfig.x = 'max-content';
  } else {
    sconfig.x = true;
  }
  state.scrollConfig = sconfig;
}

const onAdvanceSearchByUser = ({state, value}) => {
  state.isSearchTriggeredByUser = true;
  return onAdvanceSearch({state, value});
}

const onAdvanceSearch = ({state, value, autoSearch, openSearchPanel, searchName}) => {
  console.log('onAdvanceSearch()', {value, autoSearch, openSearchPanel, searchName})

  const extParams = state.cProps.extParams;
  const onBeforeSearch = state.cProps.onBeforeSearch;
  const displaymode = state.cProps.displaymode;
  const nopaging = state.cProps.nopaging;
  const defaultPagination = state.cProps.defaultPagination;

  const sort = state.sort;
  const descending = state.descending;
  const isRelation = state.isRelation;
  const pagination = state.pagination;

  if (!extParams || !equals(value, extParams)) {
    value = onBeforeSearch ? onBeforeSearch(value, sort, descending) : value;
  }
  if (searchName && displaymode === 'restrictedsearch') {
    return;
  }
  state.isSearchTriggeredByUser = true;
  if (!isRelation) {
    if (typeof value === 'string') {
      state.searchText = value;
      state.advanceSearchParams = null;
    } else if (Array.isArray(value)) {
      state.searchText = "";
      state.advanceSearchParams = value;
    }
    if (autoSearch !== false) {
      state.params = value;
      state.pagination = { ...defaultPagination, current: 1, total: undefined, pageSize: getPageSize(pagination, nopaging)};
    }
    if (openSearchPanel) state.advanceSearch = true;
    if (searchName) state.advanceSearchName = searchName;

  }
}

export const DataEntryTools = ({
  match, location,
  apiRef, itemKey, keepSearchState,
  dataKey, messageApi, dataClassConfig, formKey, flowKey,
  doExtAdd, doExtEdit, doExtView, doExtDelete, doExtExport,
  getExtAction, forceShowAction, doClickAction, isAdmin, flowParent, defaultPageSize, onDataChanged,
  isEnableAdminAllowed, isEnableAdmin, setIsEnableAdmin, flowModalVisible,
  displaymode, showTitle, isMaxContent, sticky, nopaging,
  extParams, extSort, extDescending, extReload,
  onBeforeSearch, isDirectAccess, disabled, paginationPosition, showTotal,
  draggable, resizable,
}) => {

  const [form] = Form.useForm();
  const { authUser } = authSignal;
  const { locale } = settingsSignal;
  const width = useSignalValue(commonSignal, 'width');
  const authUserObj = toAuthUserObj(authUser);
  const defaultPagination = getDefaultPagination(location, defaultPageSize);
  const lbl = useLbl(locale);

  const [, forceUpdate] = useReducer(x => x + 1, 0);
  const [triggerSave, doTriggerSaveSearch] = useReducer(x => x + 1, 0);

  const isForm = dataClassConfig ? dataClassConfig.key.match(/^Ufx/) : false;
  const isPreview = dataClassConfig ? dataClassConfig.key.match(/^UfxPreview/) : false;

  const settingReloadOnlyRef = useRef();
  const searchTextInputRef = useRef();
  const currentPathRef = useRef();
  const forceUpdateRef = useRef();
  const stateUniqueName = useRef('state-' + uid());

  const state = useSignal({
    cProps: {},
    cActions: {lbl, forceUpdate},
    extParamsRef: null,
    computeDisplayMode: {},
    scrollConfig: {},
    isMobileRef: false,
    loading: false,
    allDataClasses: null,
    options: [],
    settings: EMPTY_SETTINGS,
    isSettingsReady: false,
    isDefaultSetting: true,
    mergedColumns: DEFAULT_COLUMNS,
    hiddenColumns: [],
    sortOptions: [],
    selectedDataClass: null,
    selectedRelation: null,
    readOnly: true,
    isRelation: false,
    relation: [],
    savedSearch: [],
    pagination: defaultPagination,
    params: null,
    advanceSearchParams: null,
    searchText: "",
    sort: "updatedAt",
    descending: true,
    advanceSearch: false,
    advanceSearchName: null,
    result: [],
    relationModalVisible: false,
    recordModalVisible: false,
    settingsModalVisible: false,
    selectedObjectId: null,
    isDownloading: false,
    isExporting: false,
    isUploading: false,
    isSearchTriggeredByUser: false,
    expandedRowKeys: [],
    expandedAll: false,
  }, stateUniqueName.current)

  // Define the setter functions for each attribute
  const setLoading = (loading) => state.loading = loading;
  const setSettings = (settings) => state.settings = settings;
  const setIsDefaultSetting = (isDefault) => state.isDefaultSetting = isDefault;
  const setSelectedDataClass = (dataClass) => state.selectedDataClass = dataClass;
  const setRelation = (relation) => state.relation = relation;
  const setSavedSearch = (savedSearch) => state.savedSearch = savedSearch;
  const setPagination = (pagination) => state.pagination = pagination;
  const setParams = (params) => state.params = params;
  const setAdvanceSearchParams = (advanceSearchParams) => state.advanceSearchParams = advanceSearchParams;
  const setSearchText = (searchText) => state.searchText = searchText;
  const setSort = (sort) => state.sort = sort;
  const setDescending = (descending) => state.descending = descending;
  const setAdvanceSearch = (advanceSearch) => state.advanceSearch = advanceSearch;
  const setAdvanceSearchName = (advanceSearchName) => state.advanceSearchName = advanceSearchName;
  const setResult = (result) => state.result = result;
  const setRelationModalVisible = (visible) => state.relationModalVisible = visible;
  const setRecordModalVisible = (visible) => state.recordModalVisible = visible;
  const setSettingsModalVisible = (visible) => state.settingsModalVisible = visible;
  const setSelectedObjectId = (objectId) => state.selectedObjectId = objectId;
  const setIsDownloading = (downloading) => state.isDownloading = downloading;
  const setIsExporting = (exporting) => state.isExporting = exporting;
  const setIsUploading = (uploading) => state.isUploading = uploading;
  const setExpandedRowKeys = (rowKeys) => state.expandedRowKeys = rowKeys;

  const forAnyChanges = async (oldState, newState, attributes, fn) => {
    let changed = false;
    let changedAttribute = null;
    for (const attr of attributes) {
      if (newState.hasOwnProperty(attr)) {
        const oldValue = oldState?.[attr];
        const newValue = newState?.[attr];
        if (!equals(oldValue, newValue)) {
          changed = true;
          changedAttribute = attr;
          break;
        }
      }
    }
    if (changed) {
      console.log('forAnyChanges()', changedAttribute, attributes, {newState}, fn)
      await fn({oldState, newState, state})
      return true
    } else {
      return false;
    }
  }

  const forAnyPropChanges = async (oldState, newState, attributes, fn) => {
    let changed = false;
    let changedAttribute = null;
    const oldProps = oldState.cProps;
    const newProps = newState.cProps;
    for (const attr of attributes) {

      if (newProps?.hasOwnProperty(attr)) {
        const oldValue = oldProps?.[attr];
        const newValue = newProps?.[attr];
        if (!equals(oldValue, newValue)) {
          changed = true;
          changedAttribute = attr;
          break;
        }
      }
    }
    if (changed) {
      console.log('forAnyPropChanges()', changedAttribute, attributes, {newProps}, fn)
      await fn({oldProps, newProps, state})
    };
  }

  state.register('stateChanged', async (o, n) => {
    await forAnyPropChanges(o, n, ['dataClassConfig', 'dataKey'], () => {
      refreshSelectedDataClass(state.cProps)
    })

    await forAnyChanges(o, n, ['selectedDataClass'], async () => {
      await doFetchSavedSearch(state);
      await doSubscribeTableDataChange(state);

      if (!state.isRelation) {
        state.readOnly = !state.cProps.formKey &&
          getArraysIntersection(state.selectedDataClass.writes, authUserObj.roles).length === 0;
      } else {
        state.readOnly = true;
      }
      forceUpdate();
    })

    await forAnyChanges(o, n, ['settings'], async () => {
      await doProcessSettings(state);
      forceUpdate();
    })

    await forAnyChanges(o, n, ['selectedDataClass', 'settings', 'sort', 'descending', 'locale'], async () => {
      await generateColumns(state);
      forceUpdate();
    })

    await forAnyPropChanges(o, n, ['getExtAction'], async () => {
      await generateColumns(state);
      forceUpdate();
    })

    await forAnyPropChanges(o, n, ['width'], async () => {
      if (state.isMobileRef !== (width < AccountStore.MOBILE_BREAKPOINT)) {
        state.isMobileRef = (width < AccountStore.MOBILE_BREAKPOINT);
        await generateColumns(state);
        forceUpdate();
      }
    })

    await forAnyPropChanges(o, n, ['displayMode', 'showTitle'], doComputeDisplayMode);
    await forAnyChanges(o, n, ['cProps', 'readOnly', 'isRelation', 'settings'], doComputeDisplayMode)

    await forAnyPropChanges(o, n, ['isMaxContent'], updateScrollConfig)
    await forAnyChanges(o, n, ['cProps', 'mergedColumns'], updateScrollConfig)

    await forAnyChanges(o, n, ['relation'], doUpdateRelation);

    await forAnyChanges(o, n, ['result'], () => {
      if (onDataChanged) onDataChanged(state.result);
    })

    await forAnyPropChanges(o, n, ['extParams', 'extSort', 'extDescending'], async () => {
      await state.waitFor('isSettingsReady', true);
      const extParams = state.cProps.extParams;
      const extSort = state.cProps.extSort;
      const extDescending = state.cProps.extDescending;
      if (extParams) {
        if(extSort) setSort(extSort)
        if(extSort && !isEmpty(extDescending)) setDescending(extDescending)
        onAdvanceSearch({state, value: extParams})
        log('trigger advance by extParams', extParams, extSort, extDescending)
      }
    })

    await forAnyChanges(
      o, n, [
        "result", "mergedColumns", "loading", "pagination", "computeDisplayMode",
        "relationModalVisible", "recordModalVisible", "settingsModalVisible",
        "isDownloading", "isExporting", "isUploading", "expandedRowKeys", "expandedAll",
        "advanceSearch", "scrollConfig"
      ],
      () => {
        forceUpdate();
      }
    );

    let isReloaded = false;
    isReloaded = await forAnyChanges(o, n, [
      'params', 'pagination', 'sort', 'descending',
      'isAdmin', 'flowModalVisible', 'disabled',
      'formKey', 'flowKey', 'selectedDataClass',
    ], doReload);
    if (!isReloaded) isReloaded = await forAnyPropChanges(o, n, ['extReload'], doReload);
    if (!isReloaded) {
      if (state.isSearchTriggeredByUser) {
        // state.isSearchTriggeredByUser = false;
        doReload()
        isReloaded = true;
      }
    }
    log('isReloaded', isReloaded)

    await forAnyChanges(o, n, ['expandedAll', 'result'], updateExpandedRowKeys);

    delayRun(forceUpdate, 100, forceUpdateRef)
  })

  const onExpandAll = (all) => state.expandedAll = all;

  const initialize = async () => {
    await doLoadDataClasses(state);
    await doReloadSettings();
  }

  const refreshSelectedDataClass = async ({dataClassConfig, dataKey}) => {
    await state.waitFor('allDataClasses')
    if (dataClassConfig || dataKey) {
      setRelation([]);
      if (dataClassConfig) {
        state.selectedDataClass = dataClassConfig;
      } else if (dataKey) {
        const selected = getArrayElementByAttribute(state.allDataClasses, "key", dataKey);
        if (selected) state.selectedDataClass = selected;
      }
    }
  }

  useEffect(() => {
    initialize();
    const suid = stateUniqueName.current;
    return () => {
      destorySignal(suid);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    state.cProps = {
      match,
      location,
      defaultPagination,
      apiRef,
      itemKey,
      keepSearchState,
      dataKey,
      messageApi,
      dataClassConfig,
      formKey,
      flowKey,
      doExtAdd,
      doExtEdit,
      doExtView,
      doExtDelete,
      doExtExport,
      getExtAction,
      forceShowAction,
      doClickAction,
      isAdmin,
      flowParent,
      defaultPageSize,
      onDataChanged,
      isEnableAdminAllowed,
      isEnableAdmin,
      setIsEnableAdmin,
      flowModalVisible,
      displaymode,
      showTitle,
      isMaxContent,
      sticky,
      nopaging,
      extParams,
      extSort,
      extDescending,
      extReload,
      onBeforeSearch,
      isDirectAccess,
      disabled,
      paginationPosition,
      showTotal,
      draggable,
      resizable,
      width,
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    match,
    location,
    apiRef,
    itemKey,
    keepSearchState,
    dataKey,
    messageApi,
    dataClassConfig,
    formKey,
    flowKey,
    doExtAdd,
    doExtEdit,
    doExtView,
    doExtDelete,
    doExtExport,
    getExtAction,
    forceShowAction,
    doClickAction,
    isAdmin,
    flowParent,
    defaultPageSize,
    onDataChanged,
    isEnableAdminAllowed,
    isEnableAdmin,
    setIsEnableAdmin,
    flowModalVisible,
    displaymode,
    showTitle,
    isMaxContent,
    sticky,
    nopaging,
    extParams,
    extSort,
    extDescending,
    extReload,
    onBeforeSearch,
    isDirectAccess,
    disabled,
    paginationPosition,
    showTotal,
    draggable,
    resizable,
    width,
  ]);

  const renderOnCell = (col, record) => {
    return ({
      record,
      dataType: col.type,
      dataTypeRender: col.typeRender,
      dataTypeProps: col.props,
      simpleOptions: col.simpleOptions,
      picker: col.picker,
      format: col.format,
      showTime: col.showTime,
      stringMode: col.stringMode,
      systemFormatter: col.systemFormatter,
      dataRequired: col.required,
      dataIndex: col.dataIndex,
      dataRelatedClass: col.relatedClass,
      title: col.title,
      selectkey: col.selectkey, // for select
      statusInTable: col.statusInTable, // for select
      avatarInTable: col.avatarInTable, // for select
      parentkeys: col.parentkeys, // for select
      isUseCode: col.isUseCode, // for select
      isHideCode: col.isHideCode, // for select
      width: col.width, // for image
      height: col.height, // for image
      listType: col.listType, // for file[]
      checkbox: col.checkbox,                    // switch
      checkedChildren: col.checkedChildren,      // switch
      unCheckedChildren: col.unCheckedChildren,  // switch
    })
  }
  state.cActions.renderOnCell = renderOnCell;

  const HeaderCell = ({
    forwardRef,
    children,
    ...restProps
  }) => {
    if (restProps.className?.includes('row-expand-icon-cell')) {
      return (
        <th ref={forwardRef} {...restProps}>
          {headerExpandIcon({expandedAll: state.expandedAll, onExpandAll, mergedColumns: state.mergedColumns})}
        </th>
      )
    } else {
      return (
        <th ref={forwardRef} {...restProps}>
          {children}
        </th>
      )
    }
  }

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    dataType,
    dataTypeRender,
    dataTypeProps,
    simpleOptions,
    picker,     // for date
    format,     // for date
    showTime,   // for date
    stringMode, // for date
    systemFormatter,
    dataRequired,
    dataRelatedClass,
    record,
    index,
    children,
    selectkey, // for select
    statusInTable, // for select
    avatarInTable, // for select
    parentkeys, // for select
    isUseCode, // for select
    isHideCode, // for select
    width, // for image
    height, // for image
    listType, // for file[]
    checkbox,          // for switch
    checkedChildren,   // for switch
    unCheckedChildren, // for switch
    ...restProps
  }) => {
    const viewNode = dataType ? viewNodeMapping[dataType] : TextNode;
    if (dataIndex === undefined) {
      return <td {...restProps}>
          {children}
        </td>;
    } else if (dataIndex === "action") {
      return (
        <td {...restProps}>
          {children}
        </td>
      );
    } else {
      return (
        <td {...restProps}>
          {record === undefined && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
          {
            viewNode({
              record: record,
              value: record ? record[dataIndex] : null,
              objectId: record?.objectId,
              dataIndex: dataIndex,
              dataType: dataType,
              dataTypeProps: dataTypeProps,
              dataTypeRender: dataTypeRender,
              simpleOptions: simpleOptions,
              picker: picker,
              format: format,
              showTime: showTime,
              stringMode: stringMode,
              systemFormatter: systemFormatter,
              title: title,
              relatedClass: dataRelatedClass,
              readOnly: true,
              isTableCell: true,
              allDataClasses: state.allDataClasses,
              selectkey, // for select
              statusInTable, // for select
              avatarInTable, // for select
              parentkeys, // for select
              isUseCode, // for select
              isHideCode, // for select
              width, // for image
              height, // for image
              listType: listType, // for file[]
              checkbox: checkbox,                   // for switch
              checkedChildren: checkedChildren,     // for switch
              unCheckedChildren: unCheckedChildren, // for switch
              onClick: (dataType === 'relation' ? () => {
                setRelation([...state.relation, { parentClass: state.selectedDataClass.key, relatedClass: dataRelatedClass, objectId: record?.objectId, dataIndex, record: record }]);
               } : null),
            })
          }
        </td>
      );
    }
  };

  const getStoreKeyForSearchState = () => {
    let key = null;
    let dataClass = dataClassConfig?.key;
    if (dataClass) {
      if (itemKey && keepSearchState) {
        key = "dataentry_searchparam_"+dataClass+"_"+itemKey;
      } else if (state.settings.keepLastSearch) {
        key = "dataentry_searchparam_"+dataClass;
      }
    }
    console.log("getStoreKeyForSearchState()", dataClass, dataClassConfig, itemKey, keepSearchState)
    return key;
  }
  const storeSearchState = (searchParams) => {
    const key = getStoreKeyForSearchState();
    if (key) {
      setLocalStorageItemObject(key, searchParams);
    }
  }

  const updateStoredSearchState = (update) => {
    const key = getStoreKeyForSearchState();
    if (key) {
      let state = getLocalStorageItemObject(key);
      if (!state) state = {}
      if (update) state = {...state, ...update}
      setLocalStorageItemObject(key, state);
    }
  }

  const doReloadRelation = async () => {
    if (!state.loading && state.relation.length > 0) {
      try {
        const selected = getArrayElementByAttribute(state.allDataClasses, "key", state.selectedRelation.relatedClass);
        const parent = getArrayElementByAttribute(state.allDataClasses, "key", state.selectedRelation.parentClass);
        const col = parent ? getArrayElementByAttribute(parent.columns, "dataIndex", state.selectedRelation.dataIndex) : null;
        if (!selected || !parent || !col) {
          throw new Error("Related Class not found - " + state.selectedRelation.relatedClass);
        }

        setLoading(true);
        const result = await dataExplorer.search({
          dataClass: selected.key,
          sort: state.sort ? getSortPrefix(state.descending) + state.sort : state.sort,
          pagination: state.pagination,
          parentDataClass: state.selectedRelation.parentClass,
          parentId: state.selectedRelation.objectId,
          parentRelation: state.selectedRelation.dataIndex});
        if (result?.pagination) {
          result.pagination.showSizeChanger = true;
          result.pagination.pageSizeOptions = [5, 10, 20, 50, 100];
          if (paginationPosition) result.pagination.position = paginationPosition
          if (showTotal) {
            result.pagination.showTotal = showTotal;
          } else if (!isForm) {
            result.pagination.showTotal = (total) => lbl('system.form.show-total', 'Total {total} items', {total})
          }
        }
        setResult(result);
        setLoading(false);
      } catch (e) {
        log("error when retrieving relation", e);
        message.error(displayError(e));
        setLoading(false);
      }
    }
  }

  const doReloadData = async () => {
    if (settingReloadOnlyRef.current) {
      settingReloadOnlyRef.current = false;
      return;
    }
    await state.waitFor('settings');
    await state.waitFor('selectedDataClass');
    if (!state.loading && state.selectedDataClass && state.relation.length === 0) {
      setLoading(true);
      try {
        const searchTextInputText = searchTextInputRef?.current?.input?.value || "";
        const searchParams = {params: state.params, sort: state.sort, descending: state.descending, pagination: {...state.pagination}, searchText: searchTextInputText};
        const table = state.selectedDataClass?.table;
        if (table) {
          const subject = `${table}_datachanged`
          console.log("do reload data ...", tableDatachangedIndicators[subject])
          if (tableDatachangedIndicators[subject]) {
            console.log('reset total...')
            searchParams.pagination.total = undefined;
            tableDatachangedIndicators[subject] = false;
          }
        }
        console.log('searchParams', searchParams)
        let result = [];
        if (!disabled) {
          let newParams = state.params;
          if (flowKey) {
            if (!state.params) {
              newParams = { text: '', flowKey: flowKey }
            } else if (typeof params === 'string') {
              newParams = { text: state.params, flowKey: flowKey }
            } else {
              newParams = [
                {key: 'flow.flowKey', value: flowKey},
                ...state.params
              ]
            }
          }
          const selectedColumns = [...state.mergedColumns.map(c => c.dataIndex), ...state.hiddenColumns.map(c => c.dataIndex)];
          
          result = await dataExplorer.search({
            dataClass: state.selectedDataClass.key,
            params: newParams,
            sort: state.sort ? getSortPrefix(state.descending) + state.sort : state.sort,
            pagination: searchParams.pagination, isAdmin, flowParent, locale, selectedColumns});
        }
        if (dataClassConfig && state.isSearchTriggeredByUser) {
          storeSearchState(searchParams);
        }
        state.isSearchTriggeredByUser = false;
        if (result?.pagination) {
          result.pagination.showSizeChanger = true;
          result.pagination.pageSizeOptions = [5, 10, 20, 50, 100];
          if (paginationPosition) result.pagination.position = paginationPosition
          if (showTotal) {
            result.pagination.showTotal = showTotal;
          } else if (!isForm) {
            result.pagination.showTotal = (total) => lbl('system.form.show-total', 'Total {total} items', {total})
          }
        }
        setResult(result);
        setLoading(false);
      } catch (e) {
        message.error(displayError(e));
        setLoading(false);
      }
    }
  }

  const doReloadImpl = async () => {
    if (state.isRelation) {
      await doReloadRelation();
    } else {
      await waitForCondition(() => state.mergedColumns !== DEFAULT_COLUMNS);
      await doReloadData();
    }
  }
  const doReload = useConsolidateTrigger(doReloadImpl, 400);

  const doReloadSearch = async () => {
    if (state.selectedDataClass) {
      const list = await dataExplorer.getAllSearch(state.selectedDataClass.key, true);
      setSavedSearch(list || []);
    } else {
      setSavedSearch([]);
    }
  }

  const doReloadSettings = async () => {
    await state.waitFor('selectedDataClass', undefined, -1)
    if (state.selectedDataClass) {
      const list = await dataExplorer.getAllSearchResult(state.selectedDataClass.key);
      const userSettings = getArrayElementByAttribute(list, "name", "userSettings");
      const systemSettings = getArrayElementByAttribute(list, "name", "systemSettings");
      let savedSettings = null;
      let systemSettingsData = null;
      if (systemSettings) {
        savedSettings = systemSettings;
        systemSettingsData = JSON.parse(systemSettings.value);
      }
      if (!isPreview && (!isForm || systemSettingsData?.userSettings) && userSettings) {
        savedSettings = userSettings;
      }
      if (savedSettings) {
        const json = savedSettings.value;
        const newSettings = JSON.parse(json);
        if (systemSettingsData) {
          // ensure userSetting will not violate system system
          newSettings.userSettings = systemSettingsData.userSettings;
          newSettings.nosort = systemSettingsData.nosort;
          newSettings.nosearch = systemSettingsData.nosearch;
          newSettings.dataUpload = systemSettingsData.dataUpload;
          newSettings.dataDownload = systemSettingsData.dataDownload;
          newSettings.keepLastSearch = systemSettingsData.keepLastSearch;
        }
        log("update saved setting", newSettings)
        setIsDefaultSetting(false);
        setSettings(newSettings);
      } else {
        setSettings(DEFAULT_SETTINGS);
      }
    }
  }

  const doDownloadData = async () => {
    try {
      setLoading(true);
      let result = null;
      if (state.isRelation) {
        const selected = getArrayElementByAttribute(state.allDataClasses, "key", state.selectedRelation.relatedClass);
        const parent = getArrayElementByAttribute(state.allDataClasses, "key", state.selectedRelation.parentClass);
        const col = parent ? getArrayElementByAttribute(parent.columns, "dataIndex", state.selectedRelation.dataIndex) : null;
        if (!selected || !parent || !col) {
          throw new Error("Related Class not found - " + state.selectedRelation.relatedClass);
        }
        result = await dataExplorer.searchAll(selected.key, null, state.sort ? getSortPrefix(state.descending) + state.sort : state.sort, state.selectedRelation.parentClass, state.selectedRelation.objectId, state.selectedRelation.dataIndex);
      } else {
        result = await dataExplorer.searchAll(state.selectedDataClass.key, state.params,
          state.sort ? getSortPrefix(state.descending) + state.sort : state.sort);
      }
      setLoading(false);
      return result;
    } catch (e) {
      log("error when retrieving relation", e);
      message.error(displayError(e));
      setLoading(false);
    }
  }

  const onExpandedRowsChange = (expandedRows) => {
    setExpandedRowKeys(expandedRows);
  }

  const expandedRowRender = (record, index, indent, expanded) => {
    const list = state.hiddenColumns.map(c => {
      const title = lbl(`form.${fKey}.${c.dataIndex}`, c.title);
      return (
        <tr key={`${c.dataIndex}_${index}`}>
          <td className='label' span={8}>{title}</td>
          <EditableCell {...renderOnCell(c, record)}/>
        </tr>
      )
    })
    return <table className='widget-dataentry-nested-table'><tbody>{list}</tbody></table>
  }

  const doBack = () => {
    const index = state.relation.length - 1
    if (index >= 0) {
      const rel = state.relation[index];
      let selected = null;
      for (const element of state.allDataClasses) {
        if (element.key === rel.parentClass) {
          selected = element;
        }
      }
      if (!selected) {
        throw new Error("Related Class not found!");
      } else {
        setRelation(state.relation.slice(0, index));
        setSelectedDataClass({...selected});
      }
    }
  }

  const doUpload = async (file) => {
    try {
      setIsUploading(true);
      if (state.selectedDataClass) {
        if (state.readOnly || state.isRelation) {
          throw new Error("Permission denied!");
        }
        const columns = filterActionCol(state.mergedColumns);
        ensureColumnExists(columns, 'objectId');
        ensureColumnExists(columns, 'versionStamp');
        columns.push({dataIndex:'deleteInd', title:'Delete'});
        const resultMap = await runPreImportDataAction(state.selectedDataClass.key, file, columns);
        let data = resultMap.data;
        if (!data) {
          data = await importFromXLSX(file, columns);
        }
        await dataExplorer.saveAll(state.selectedDataClass.key, data);
        message.info(<IntlMessages id="system.data_import.completed" text="Import completed."/>);
        doReload();
        setIsUploading(false);
        return false;
      }
    } catch (e) {
      message.error(displayError(e));
      setIsUploading(false);
      return false;
    }
  }

  const doExport = async () => {
    try {
      if (state.selectedDataClass) {
        setIsExporting(true);
        if (doExtExport) {
          await doExtExport(state.selectedDataClass.key, state.params, state.sort);
          setIsExporting(false);
        }
      }
    } catch (e) {
      message.error(displayError(e));
      setIsExporting(false);
    }
  }

  const ensureColumnExists = (columns, dataIndex) => {
    const allColumns = [...(state.selectedDataClass ? state.selectedDataClass.columns : [])];
    if (columns.filter(c => c.dataIndex === dataIndex).length === 0) {
      const col = allColumns.filter(c => c.dataIndex === dataIndex);
      if (col.length > 0) {
        columns.push(col[0]);
      }
    }
  }

  const doDownload = async (result, filename) => {
    try {
      await state.waitFor('selectedDataClass');
      await state.waitFor('computeDisplayMode');

      if (state.selectedDataClass) {
        setIsDownloading(true);
        if (!filename) result = await doDownloadData();
        if (result) {
          const columns = filterActionCol(state.mergedColumns);
          if (state.computeDisplayMode.upload) {
            ensureColumnExists(columns, 'objectId');
            ensureColumnExists(columns, 'versionStamp');
            columns.push({dataIndex:'deleteInd', title:'Delete'});
          }
          if (!filename) {
            filename = currentPathRef.current;
            if (!filename && state.selectedDataClass.displayName) filename = state.selectedDataClass.displayName;
            if (!filename) filename = "Export";
            if (typeof params === 'string') {
              filename = filename + " - " + state.params;
            } else if (state.advanceSearchName) {
              filename = filename + " - " + state.advanceSearchName;
            } else {
              // get first value
              let firstValue = null;
              JSON.parse(JSON.stringify(state.params || ''), (key, value) => {
                if (!firstValue && key === 'value') {
                  if (isDate(value)) {
                    firstValue = moment(value).format('YYYYMMDD');
                  } else if (typeof value === 'string'){
                    firstValue = value;
                  } else if (typeof value === 'number') {
                    firstValue = `${value}`;
                  }
                }
              })
              if (firstValue) filename = filename + " - " + firstValue;
            }
          }
          const resultMap = await runPostExportDataAction(state.selectedDataClass.key, result, columns);
          log("export result", result, columns, resultMap);
          exportToXLSX(resultMap.result, cleanFilename(filename), resultMap.columns);
        } else {
          log("no data return");
        }
        setIsDownloading(false);
      }
    } catch (e) {
      message.error(displayError(e));
      setIsDownloading(false);
    }
  }

  updateApiRef(apiRef, 'doDownload', async ({params: myParams, filename}) => {
    const result = await dataExplorer.searchAll(state.selectedDataClass.key, myParams,
      state.sort ? getSortPrefix(state.descending) + state.sort : state.sort);
    doDownload(result, filename);
  })

  const onDataClassChange = (value) => {
    const selected = value ? getArrayElementByAttribute(state.allDataClasses, "key", value) : value;
    setParams(null);
    setAdvanceSearch(false);
    setAdvanceSearchParams(null);
    setSearchText("");
    setRelation([]);
    setSelectedDataClass(selected);
    doReloadSettings();
  }

  const onSortChange = (value) => {
    setSort(value);
    if (!value) setDescending(false);
  }

  const onSearch = async (value) => {
    const isSearchTriggeredByUser = state.isSearchTriggeredByUser
    console.log('onSearch()', {value, isSearchTriggeredByUser})
    if (state.isSearchTriggeredByUser) {
      state.isSearchTriggeredByUser = false;
      await delay(500);
    }
    state.isSearchTriggeredByUser = true;
    if (!state.isRelation) {
      if (value && value.length > 0) {
        setParams(value);
      } else {
        setParams(null);
      }
      setPagination({ ...defaultPagination, current: 1, total: undefined, pageSize: getPageSize(state.pagination, nopaging)});
      setAdvanceSearchName(null);
    }
  }

  const doAdd = () => {
    setSelectedObjectId(null);
    if (state.isRelation) {
      setRelationModalVisible(true);
    } else {
      setRecordModalVisible(true);
    }
  }

  const doEdit = (record) => {
    if (state.isRelation) {
      setSelectedObjectId(null);
    } else {
      setSelectedObjectId(record.objectId);
      setRecordModalVisible(true);
    }
  };

  const finishAddRelation = () => {
    if (state.isRelation) {
      setRelationModalVisible(false);
      doReloadRelation();
      setSelectedObjectId(null);
    }
  }

  const finishSaveRecord = () => {
    if (!state.isRelation) {
      setRecordModalVisible(false);
      doReloadData();
      setSelectedObjectId(null);
    }
  }

  const doUpdateSettings = () => {
    setSettingsModalVisible(true);
  }

  const finishSaveSettings = (newSettings) => {
    setSettingsModalVisible(false);
    const updates = {
      sort: newSettings.sort,
      descending: newSettings.descending
    }
    updateStoredSearchState(updates);
    if (state.sort === newSettings.sort && state.descending === newSettings.descending) {
      settingReloadOnlyRef.current = true;
    }
    doReloadSettings();
  }

  const doDelete = async (record) => {
    try {
      if (state.isRelation) {
        const ids = [record.objectId];
        const rel = state.relation.length > 0 ? state.relation[state.relation.length - 1] : null;
        if (rel) {
          const dataClass = rel.parentClass;
          const objectId = rel.objectId;
          const dataIndex = rel.dataIndex;
          await dataExplorer.removeRelation(dataClass, objectId, dataIndex, ids);
          doReloadRelation();
        }
      } else {
        await dataExplorer.destory(state.selectedDataClass.key, record);
        forceUpdate();
        doReload();
      }
    } catch (e) {
      message.error(displayError(e));
    }
  };
  state.cActions.doDelete = doDelete;

  const doRemoveSearch = async (name) => {
    try {
      if (state.selectedDataClass && name) {
        await dataExplorer.deleteSearch(state.selectedDataClass.key, name);
        doReloadSearch();
      }
    } catch (e) {
      message.error(displayError(e));
    }
  }

  const doOpenAdvanceSearch = () => {
    setAdvanceSearch(!state.advanceSearch);
  }

  const doPublish = async () => {
    try {
      await formApi.publishAllSharedSearch(formKey);
      await formApi.publishAllSharedSearchResult(formKey);
      message.info("Search Settings published successfully!");
    } catch (error) {
      message.error(displayError(error));
    }
  }

  const onTableChanged = (pagination, filter, sorter, action) => {
    if (action?.action === 'sort') {
      let s = state.sort;
      let d = state.descending;
      if (sorter && sorter.field) {
        s = sorter.field
        if(sorter.column){
          if (sorter.order === 'descend') {
            d = true;
          } else if (sorter.order === 'ascend') {
            d = false;
          } else {
            s = null;
            d = false;
          }
        } else{
          s = null;
          d = false;
        }
      } else {
        s = null;
        d = false;
      }
      setSort(s);
      setDescending(d);
      if (onBeforeSearch) {
        onBeforeSearch(state.params, s, d);
      }
    }
    if (pagination) setPagination(pagination);
  }

  const onColumnResized = async (e) => {
    if (e?.width || e?.index) {
      const data = await dataExplorer.deduceSearchTableSettings(state.selectedDataClass.key, isPreview);
      if (data?.newSettings) {
        if (e?.width) {
          if (!data.newSettings.width) data.newSettings.width = {};
          data.newSettings.width = {...data.newSettings.width, ...e.width}
        }
        if (e?.index) {
          if (!data.newSettings.index) data.newSettings.index = {};
          data.newSettings.index = {...data.newSettings.index, ...e.index}
        }
        await dataExplorer.updateSearchTableSettings(state.selectedDataClass.key, isPreview, data.newSettings);
        settingReloadOnlyRef.current = true;
        doReloadSettings();
      }
    }
  }

  const fKey = formKey ? formKey : state.selectedDataClass?.key.replace(/.preview$/, "");

  const MyTag = ({name, onClick, onClose, confirm}) => {
    return (
      <span className="widget-dataentry-saved-search-tag" >
        <span className="widget-dataentry-saved-search-tag-name" onClick={onClick}>
          <IntlMessages id={`form.${fKey}.savedsearch.${name}`} text={name}/>
        </span>
        {onClose && <Popconfirm title={lbl('system.savedsearch.confirm.delete',confirm)} onConfirm={onClose}>
          <Button className="widget-dataentry-saved-search-tag-close-btn" size="small" type="text"  ><AiOutlineClose/></Button>
        </Popconfirm>}
        {!onClose && <span className="widget-dataentry-saved-search-tag-close-btn placeholder"></span>}
      </span>
    )
  }

  let contentStyle = { width: "calc(100% - 2px)", height: "calc(100vh - 330px)" }
  if (isDirectAccess) {
    contentStyle = { width: "calc(100% - 2px)", height: "calc(100vh - 250px)" }
  }

  const title = deduceTitle({options: state.options, relation: state.relation, selectedDataClass: state.selectedDataClass, allDataClasses: state.allDataClasses, dataClassConfig})
  let myPagination = nopaging ? false : state.result?.pagination;
  let tableLoading = state.loading || state.mergedColumns === DEFAULT_COLUMNS;
  if (tableLoading) tableLoading = LoadingProps;
  return (
    <div className="widget-dataentry-panel">
      <Row>
        {dataClassConfig && state.computeDisplayMode.title && (
          <Col span={24} className={"function-title"}>
            <Typography.Title level={4}>
              {lbl(`form.${fKey}.title`, title)}
            </Typography.Title>
          </Col>
        )}
        {dataKey &&
          state.selectedDataClass?.displayName &&
          state.computeDisplayMode.title && (
            <Col span={24} className={"function-title"}>
              <Typography.Title level={4}>
                {lbl(`system.${dataKey}.title`, title)}
              </Typography.Title>
            </Col>
          )}
        <Col span={24} className="widget-dataentry-saved-search">
          {state.savedSearch
            ?.sort((a, b) => {
              const s1 = a.shared ? "1" : "2";
              const s2 = a.shared ? "1" : "2";
              const name1 = s1 + "_" + a.name;
              const name2 = s2 + "_" + b.name;
              return compare(name1, name2);
            })
            .map((s) => {
              return (
                <MyTag
                  key={s.objectId}
                  onClick={() =>
                    onAdvanceSearch({
                      state,
                      value: [JSON.parse(decompressJsonData(s.value))],
                      autoSearch: s.autoSearch !== false,
                      openSearchPanel: s.openSearchPanel === true,
                      searchName: s.name
                    })
                  }
                  onClose={s.shared ? null : () => doRemoveSearch(s.name)}
                  name={s.name}
                  confirm={"Are you sure to delete this saved search?"}
                ></MyTag>
              );
            })}
          {isEnableAdminAllowed && state.computeDisplayMode.admin && (
            <span style={{ float: "right" }}>
              <Switch
                className="is-admin"
                checkedChildren={
                  <IntlMessages id="system.form.enable-admin" text="Admin" />
                }
                unCheckedChildren={
                  <IntlMessages id="system.form.enable-admin" text="Admin" />
                }
                checked={isEnableAdmin}
                onChange={setIsEnableAdmin}
              />
            </span>
          )}
        </Col>
        {isPreview && (
          <Col span="24">
            <div className="craft-form-preview">
              <IntlMessages id="system.form.preview-indicator" text="PREVIEW" />{" "}
              <span></span>
            </div>
          </Col>
        )}
        {state.computeDisplayMode.search && (
          <Col span={24}>
            <SearchPanelToolbar
              {...{
                advanceSearch: state.advanceSearch,
                allDataClasses: state.allDataClasses,
                computeDisplayMode: state.computeDisplayMode,
                currentPathRef,
                dataClassConfig,
                dataKey,
                descending: state.descending,
                disabled,
                doAdd,
                doBack,
                doDownload,
                doExport,
                doExtAdd,
                doExtExport,
                doOpenAdvanceSearch,
                doTriggerSaveSearch,
                doUpdateSettings,
                doUpload,
                isDownloading: state.isDownloading,
                isExporting: state.isExporting,
                isForm,
                isPreview,
                isRelation: state.isRelation,
                isUploading: state.isUploading,
                lbl,
                onDataClassChange,
                onSearch,
                onSortChange,
                options: state.options,
                pagination: state.pagination,
                readOnly: state.readOnly,
                relation: state.relation,
                searchText: state.searchText,
                searchTextInputRef,
                selectedDataClass: state.selectedDataClass,
                setDescending,
                setSearchText,
                settings: state.settings,
                sort: state.sort,
                sortOptions: state.sortOptions,
                formKey,
                doPublish,
              }}
            />
          </Col>
        )}
        {state.computeDisplayMode.search && (
          <Col span={24}>
            <Collapse
              className={`widget-dataentry-advance-search-container ${
                state.advanceSearch ? "" : "hidden"
              } ${state.computeDisplayMode.restrictedsearch ? "restricted" : ""}`}
              activeKey={
                state.advanceSearch || state.computeDisplayMode.restrictedsearch
                  ? ["advanceSearch"]
                  : []
              }
            >
              <Collapse.Panel key="advanceSearch">
                <div className="widget-dataentry-advance-search">
                  <SearchPanel
                    dataClass={state.selectedDataClass?.key}
                    selectedDataClass={state.selectedDataClass}
                    allDataClasses={state.allDataClasses}
                    onOk={(value) => onAdvanceSearchByUser({state, value})}
                    onSave={doReloadSearch}
                    params={state.advanceSearchParams}
                    settings={state.settings}
                    triggerSave={triggerSave}
                    displayMode={state.computeDisplayMode}
                  />
                </div>
              </Collapse.Panel>
            </Collapse>
          </Col>
        )}
        <Col span={24} className="widget-dataentry-advance-table-container">
          <Form form={form} component={false}>
            {state.loading && !state.selectedDataClass && (
              <div className="content-panel" style={contentStyle}>
                <Loading />
              </div>
            )}
            {!state.loading && !state.selectedDataClass && (
              <div className="content-panel" style={contentStyle}>
                <Empty />
              </div>
            )}
            {state.selectedDataClass && (
              <ResizableTable
                draggable={draggable}
                resizable={resizable}
                onChangeSettings={onColumnResized}
                className="search-result-table"
                scroll={state.scrollConfig}
                sticky={sticky}
                components={{
                  header: {
                    cell: HeaderCell,
                  },
                  body: {
                    cell: EditableCell,
                  },
                }}
                size="small"
                rowKey={"objectId"}
                bordered
                dataSource={state.result?.result}
                expandable={
                  width < AccountStore.MOBILE_BREAKPOINT && state.hiddenColumns.length > 0
                    ? {
                        expandedRowRender,
                        onExpandedRowsChange,
                        expandedRowKeys: state.expandedRowKeys,
                        expandIcon: getExpandIcon(expandedRowRender, state.mergedColumns)
                      }
                    : undefined
                }
                columns={
                  state.settings !== EMPTY_SETTINGS ? state.mergedColumns : DEFAULT_COLUMNS
                }
                rowClassName={
                  (doExtView || doExtEdit) &&
                  (state.computeDisplayMode.view || state.computeDisplayMode.edit)
                    ? "editable-row clickable-row"
                    : "editable-row"
                }
                loading={tableLoading}
                onChange={onTableChanged}
                pagination={myPagination}
                onRow={(record) => ({
                  onClick: (e) => {
                    if (!isCellClickable(e.target)) {
                      if (e?.target?.nodeName !== "svg") {
                        if (doExtView && state.computeDisplayMode.view) {
                          doExtView(record, e, state.pagination);
                        } else if (doExtEdit && state.computeDisplayMode.edit) {
                          doExtEdit(record, e, state.pagination);
                        } else if (doClickAction) {
                          doClickAction(record, state.computeDisplayMode.action);
                        } else if (!getExtAction) {
                          doEdit(record);
                        }
                      } else {
                        e.preventDefault();
                      }
                    } else {
                      e.preventDefault();
                    }
                  },
                })}
              />
            )}
            {nopaging && <div>&nbsp;</div>}
          </Form>
          <RelationModal
            messageApi={messageApi}
            visible={state.relationModalVisible}
            relation={
              state.relation.length > 0 ? state.relation[state.relation.length - 1] : null
            }
            allDataClasses={state.allDataClasses}
            onOk={() => finishAddRelation()}
            onCancel={() => setRelationModalVisible(false)}
          />
          <RecordModal
            messageApi={messageApi}
            visible={state.recordModalVisible}
            objectId={state.selectedObjectId}
            dataClass={state.selectedDataClass?.key}
            allDataClasses={state.allDataClasses}
            onOk={() => finishSaveRecord()}
            onCancel={() => setRecordModalVisible(false)}
          />
          <SettingsModal
            messageApi={messageApi}
            visible={state.settingsModalVisible}
            isPreview={isPreview}
            dataClass={state.selectedDataClass?.key}
            readOnly={state.readOnly}
            allDataClasses={state.allDataClasses}
            onOk={(newSettings) => finishSaveSettings(newSettings)}
            onCancel={() => setSettingsModalVisible(false)}
          />
        </Col>
      </Row>
    </div>
  );
};

export default DataEntryTools;

