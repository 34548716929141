import { Alert, Button, Col, Divider, Empty, Input, Menu, message, Modal, notification, Popover, Row, Space, Spin, Tag, Timeline, Tooltip } from "antd";
import { userSignOut } from "appRedux/actions/Auth";
import CircularProgress from "components/CircularProgress";
import { Form } from "components/Form";
import { registerMessage, useLbl } from "lngProvider";
import moment from "moment";
import { dataExplorer, flowApi, formApi, system } from "parse-api";
import React, { useEffect, useReducer, useRef, useState } from "react";
import { AiOutlineEllipsis } from "react-icons/ai";
import { GiHamburgerMenu } from "react-icons/gi";
import { GoAlert } from "react-icons/go";
import { MdAddCircleOutline, MdApps, MdClose, MdRefresh } from "react-icons/md";
import { useDispatch } from "react-redux";
import IntlMessages from "util/IntlMessages";
import ModifiedChecker from "util/ModifiedChecker";
import { loadMenu, startFormTranslate } from "../../../appRedux/actions";
import { getRequestContext } from "../../../parse-api/config";
import { cloneJson, compare, compressJsonData, decompressJsonData, displayError, equals, getArrayElementByAttribute, getArraysIntersection, hideLoading, isEmpty, log, msgHelper, notify, prepareText, registerPostMessageAction, replacePath, runScript, showLoading, toAuthUserObj, unregisterAllPostMessageAction, deleteEmptyProperty } from "../../../util/algorithm";
import { importXLSX } from "../../../util/export";
import { PageModal } from "../PageSetup/runtimeimpl";
import { defaultPermissions } from "./components/common";
import { SfpUserAvatar } from "./components/SfUserAvatar";
import initFormData from "./defaultForm";
import { createButtonBar, createButtonBarForVo, setupForm } from "./FormComponent";
import { FormHistoryModal, SelectFormSearch } from "./runtimesearch";
import { AccountStore } from "../../../constants/Account";
import { authSignal, scriptsSignal, settingsSignal, systemSignal } from "../../../util/signal";
import { FrontendApi } from "../../../util/frontendApi";

const getFormAction = (f, actionType) => {
  const selectedActions = f?.extraParams?.selectedActions ?  f?.extraParams?.selectedActions : null;
  const actions = []
  if (selectedActions) {
    for (const element of selectedActions) {
      if (element.actionTypes.indexOf(actionType) !== -1) {
        actions.push(element)
      }
    }
  }
  return actions;
}

const compressFormDataAsId = (data) => {
  const jsonStr = JSON.stringify(data);
  const str = compressJsonData(jsonStr);
  return str.replace('/', '_');
}

const decompressIdAsFormData = (id) => {
  const str = id.replace('_', '/');
  const jsonStr = decompressJsonData(str);
  return JSON.parse(jsonStr);
}

const getSaveAction = (action, record) => {
  let messageAction = null;
  if (action) {
    messageAction = action;
  } else if (record.objectId) {
    messageAction = "save";
  } else {
    messageAction = "create";
  }
  return messageAction;
}

const reduceFormData = (oldFormData, formData) => {
  const actionType = "FormSetupAction";
  const actions = getFormAction(formData, actionType);
  const requestContext = getRequestContext();
  const resultMap = {}
  formData = {...oldFormData, ...formData}
  for (const element of actions) {
    const script = element.data;
    const scriptKey = element.actionKey;
    const versionStamp = element.versionStamp;
    const frontendApi = new FrontendApi();
    runScript({script, versionStamp, scriptKey, actionType, oldFormData, formData, resultMap, requestContext, frontendApi});
  }
  let newFormData = null;
  if (resultMap.formData) {
    newFormData = resultMap.formData;
  } else {
    newFormData = formData;
  }
  if (newFormData && !newFormData.allActions) {
    newFormData.allActions = [];
  }
  if (newFormData && !newFormData.allSubmits) {
    newFormData.allSubmits = [];
  }
  return newFormData;
}

const processSystemFormDataChange = (isPreview, formData, form, oldForm, forceUpdate, doSetValues, mainPanelRef, setIsModified) => {
  const actionType = "OnChangeActionFrontEnd";
  const actions = getFormAction(formData, actionType);
  const requestContext = getRequestContext();
  const resultMap = {}
  form = cloneJson(form)
  const mainPanel = mainPanelRef.current;
  scriptsSignal.isRunning = true;
  for (const element of actions) {
    const script = element.data;
    const scriptKey = element.actionKey;
    const versionStamp = element.versionStamp;
    const frontendApi = new FrontendApi(doSetValues, setIsModified);
    runScript({script, versionStamp, scriptKey, actionType, isPreview, formData, form, oldForm, resultMap, forceUpdate, doSetValues, requestContext, frontendApi, mainPanel, setIsModified});
  }
  scriptsSignal.isRunning = false;
  resultMap.form = form;
  return resultMap;
}

const updateLoadingStatus = (formState, oldFormState) => {
  if (formState?.loading !== oldFormState?.loading) {
    if (formState.loading) {
      showLoading();
    } else {
      hideLoading();
    }
  }
}

const checkIsSubstituteAction = (substitute, action) => {
  if (!Array.isArray(action)) action = [action]
  return getArraysIntersection(substitute, action).length > 0;
}

const getUniqueKeyValueFromUrl = (match, baseUrl, direct) => {
  const pathname = window.location.pathname;
  const paramStr = pathname.substring(
    match.url.length + baseUrl.length + 1
  );
  const params = paramStr.split("/").filter((p) => p !== "");
  if (direct) params.unshift(direct);
  log("params", params);
  return params;
}

const checkIsActionee = ({record, authUserObj, formData}) => {
  return (!record?.flow?.assignee ||
      record?.flow?.assignee === authUserObj?.username) &&
    formData.allActions.length > 0;
}

const mergeFormState = (oldForm, newForm, formState) => {
  let oldFormState = oldForm?.formState || {};
  let currFormState = formState || {};
  let newFormState = newForm?.formState || {};
  const mergedFormState = {...oldFormState, ...currFormState, ...newFormState}
  return mergedFormState;
}

const isValidInitialData = (data) => {
  if (data?.relation?.length > 0) {
    const rel = data.relation[0];
    if (rel.type === 'parent-form') {
      return true;
    }
  }
  return false;
}

export const FormRuntimeImpl = ({
  match,
  location,
  flow,
  type,
  formKey,
  formView,
  id,
  uniqueKeyValues,
  direct,
  hideVersion,
  hideDraft,
  hideFlowInfo,
  dispatchReplace,
  dispatchGoBack,
  dispatchPush,
  isPageModifiedRef,
  isComponent,
  extClose,
  extResetClose,
  }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState({});
  const [isModified, setIsModifiedImpl] = useState(false);
  const [formData, dispatchFormData] = useReducer(reduceFormData, initFormData);
  const [requireProcessValueChange, setRequireProcessValueChange] =
    useState(false);
  const [requireProcessValueChangeFrontEnd, setRequireProcessValueChangeFrontEnd] =
    useState(false);
  const [lastChangedValue, setLastChangedValue] = useState();
  const [parentForm, setParentForm] = useState();
  const [flowRecord, setFlowRecord] = useState();
  const [currentTask, setCurrentTask] = useState();
  const [taskHistory, setTaskHistory] = useState([]);
  const [flowParent, setFlowParent] = useState();
  const [record, setRecord] = useState({});
  const [draft, setDraft] = useState();
  const [form] = Form.useForm();
  const [api, contextHolder] = message.useMessage();
  const { baseUrl, headless } = systemSignal;
  const locale = settingsSignal.locale;
  const { authUser } = authSignal;
  const authUserObj = toAuthUserObj(authUser);
  const [isReportReady, setIsReportReady] = useState(false);
  const [reportLink, setReportLink] = useState();
  const [isCopying, setIsCopying] = useState(false);
  const [relationMenu, setRelationMenu] = useState();
  const [isCreateRelation, setIsCreateRelation] = useState(false);
  const [isRefreshRelation, setIsRefreshRelation] = useState(false);
  const [filterRelation, setFilterRelation] = useState({});
  const [selectedRelationTemplate, setSelectedRelationTemplate] = useState();
  const [selectedRelation, setSelectedRelation] = useState();
  const [selectedChildFormId, setSelectedChildFormId] = useState();
  const [formHistoryModalVisible, setFormHistoryModalVisible] = useState(false);
  const [submitButtonClickedCache, setSubmitButtonClicedCache] = useState([]);
  const [hideMyVersion, setHideMyVersion] = useState(hideVersion);
  const lbl = useLbl(locale);
  const dispatch = useDispatch();
  const mounted = useRef();
  const currentActionRef = useRef();
  const subject = useRef();
  const subjectKey = useRef();
  const [formUpdatedNotifyId, setFormUpdatedNotifyId] = useState(null);
  const [, forceUpdate] = useReducer(x => x + 1, 0);
  const [hasPermission, setHasPermission] = useState(true);
  const [pageNotFound, setPageNotFound] = useState(false);
  const [openPageData, setOpenPageData] = useState();
  const formStateRef = useRef();
  const extCloseRef = useRef();
  const extResetCloseRef = useRef();
  const disableModifyCheck = useRef();
  const mainPanelRef = useRef();

  let decodedFormData = null
  if (id && id.length > 10) {
    try {
      decodedFormData = decompressIdAsFormData(id);
      console.log("decodedFormData", decodedFormData)
      if (isValidInitialData(decodedFormData)) {
        id = null;
      } else {
        decodedFormData = null;
      }
    } catch (error) {
      console.log('failed to decode id', id)
    }
  }

  const setIsModified = (isModified) => {
    if (isPageModifiedRef) isPageModifiedRef.current = isModified;
    setIsModifiedImpl(isModified);
  }

  const getFormState = () => {
    if (!formStateRef.current) formStateRef.current = {}
    formStateRef.current.formKey = formKey;
    if (!formStateRef.current.formView) formStateRef.current.formView = getFormView();
    return cloneJson(formStateRef.current);
  }

  const getFormView = () => {
    return formStateRef.current?.formView || formView;
  }

  const setFormState = (f) => {
    if (f) {
      const val = f(getFormState());
      formStateRef.current = cloneJson(val || {});
      formStateRef.current.formKey = formKey;
      if (!formStateRef.current.formView)
        formStateRef.current.formView = getFormView();
    }
  }

  useEffect(() => {
    mounted.current = true;
    log('FormRuntimeImpl match location', {match, location})
    return () => {
      mounted.current = false;
      unregisterAllPostMessageAction("form-");
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (extClose) {
      if (extCloseRef.current && extClose !== extCloseRef.current) {
        if (isClosable()) {
          doClose();
        }
      }
      extCloseRef.current = extClose;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extClose]);

  useEffect(() => {
    if (extResetClose) {
      if (extResetCloseRef.current && extResetClose !== extResetCloseRef.current) {
        if (isClosable()) {
          console.log("resetClose...")
          doResetClose();
        }
      }
      extResetCloseRef.current = extResetClose;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extResetClose]);

  useEffect(() => {
    if (mounted.current) {
      setIsLoading(true);
      doLoad();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formKey, flow, id]);

  useEffect(() => {
    const fetchData = async () => {
      if (mounted.current) {
        console.log('record set form field value', record);
        form.setFieldsValue({ ...record });
        form.validateFields();
        if (mounted.current) setIsModified(false);

        const isPreview = type === "preview";
        const isActionee = checkIsActionee({record, authUserObj, formData});
        if (record.objectId && isActionee && currentTask?.allowSaveDraft) {
          const draft = await formApi.getSystemFormDataDraft(
            isPreview,
            formKey,
            getFormView(),
            record.objectId
          );
          if (draft) {
            if (mounted.current) setDraft(draft);
          }
        }
        if (record.flow && record.flow.flowKey === flow) {
          let currTask = null;
          if (!flowRecord) {
            const lFlowRecord = await flowApi.getSystemFlow(
              isPreview,
              record.flow.flowKey
            );
            if (mounted.current) setFlowRecord(lFlowRecord);
            currTask = lFlowRecord.extraParams.taskMap[record.flow.task];
          } else {
            currTask = flowRecord.extraParams.taskMap[record.flow.task];
          }
          if (mounted.current) setCurrentTask(currTask);
          if (mounted.current && currTask)
            dispatchFormData({
              allActions: currTask.actions
                .sort((a, b) =>
                  compare(a.displayOrder || a.label, b.displayOrder || b.label)
                )
                .filter((act) => {
                  if (act.hidden) {
                    return false;
                  } else {
                    const result = getArraysIntersection(
                      act.roles,
                      authUserObj.roles
                    );
                    return result.length > 0;
                  }
                })
            });
          if (record?.flow?.flowKey && id) {
            const taskHistory = await formApi.getSystemFlowDataHistory(
              isPreview,
              record.flow.flowKey,
              formKey,
              getFormView(),
              id
            );
            setTaskHistory(taskHistory);
          }
        }
        if (record.relation) {
          const parentForm = getArrayElementByAttribute(
            record?.relation,
            "type",
            "parent-form"
          );
          if (parentForm) {
            const p = await formApi.getSystemForm(isPreview, parentForm.form);
            setParentForm(p);
          }
        }
      }
    };
    fetchData();
    const handleNotification = async () => {
      if (record?.objectId) {
        const newSubject = `${formKey}_${record.objectId}`;
        log("subject", newSubject);
        if (subject.current !== newSubject) {
          if (subject.current) {
            await system.unsubscribe(subject.current, subjectKey.current);
            subject.current = null;
          }
          subjectKey.current = await system.subscribe(
            newSubject,
            (notifyId) => {
              log("fetch data...", notifyId);
              if (mounted.current) setFormUpdatedNotifyId(notifyId);
            }
          );
          subject.current = newSubject;
        }
      }
    };
    requestAnimationFrame(handleNotification);
    registerTranslateMessage(record);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record]);

  useEffect(() => {
    if (formUpdatedNotifyId) {
      const btn = (
        <Button
          type="primary"
          size="small"
          onClick={() => {
            doReload();
            setFormUpdatedNotifyId(null);
            notification.close(formUpdatedNotifyId);
          }}
        >
          {lbl("system.form.confirm", "Confirm")}
        </Button>
      );
      notification.open({
        message: lbl(
          "system.form.form_updated_notification_title",
          "Form Updated"
        ),
        description: lbl(
          "system.form.form_updated_notification",
          "Another user or a system process had updated this form. Please reload to continue!"
        ),
        btn,
        key: formUpdatedNotifyId,
        duration: 0,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formUpdatedNotifyId]);

  useEffect(() => {
    if (mounted.current && draft) {
      form.setFieldsValue({ ...draft.values });
      form.validateFields();
      if (mounted.current) setIsModified(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draft]);

  useEffect(() => {
    if (mounted.current && selectedChildFormId) {
      if (selectedRelation?.type === "parent-form") {
        openRelatedForm();
      } else {
        openRelatedForm(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRelation, selectedChildFormId]);

  useEffect(() => {
    let parentMenu = null;
    if (parentForm) {
      const parent = getArrayElementByAttribute(
        record?.relation,
        "type",
        "parent-form"
      );
      if (parent?.data && parent.data.length > 0) {
        const parentData = parent.data[0];
        parentMenu = (
          <Menu.Item key={parentData.objectId}>
            <Space>
              <span className="menu-icon">{<MdApps />}</span>
              <span className="menu-title">
                <IntlMessages
                  id={`form.${parentForm.formKey}.title`}
                  text={parentForm.formName}
                />{" "}
                {parentData.label}
              </span>
            </Space>
          </Menu.Item>
        );
      }
    }

    let childMenu = null;
    // console.log('relations', formData.extraParams?.relations)
    if (
      formData?.extraParams?.relations &&
      formData.extraParams.relations.length > 0
    ) {
      childMenu = formData.extraParams.relations.map((r) => {
        let menuReadOnly = false;
        let menuHidden = false;
        let menuDisabled = false;
        let refreshAllowed = false;
        let enableFilter = r.filter;
        const enableSort = r.sort;
        const maxDisplay = r.maxDisplay;

        if (r.permission) {
          if (r.permission[getFormView()] === "hidden") {
            menuHidden = true;
          } else if (r.permission[getFormView()] === "read-only") {
            menuReadOnly = true;
          }
        }
        if (r.roles) {
          menuDisabled =
            getArraysIntersection(r.roles, authUserObj.roles).length === 0;
        }
        if (r.parentkeys && r.childkeys) {
          if (r.parentkeys.length > 0 && r.childkeys.length > 0) {
            refreshAllowed = true;
          }
        }
        if (r.selectedActions) {
          r.selectedActions.forEach((ra) => {
            if (ra?.actionTypes) {
              ra.actionTypes.forEach((rat) => {
                if (rat === "RefreshRelationAction") {
                  refreshAllowed = true;
                }
              });
            }
          });
        }
        const childForm = getArrayElementByAttribute(
          record?.relation,
          "type",
          "child-form-" + r.itemKey
        );
        let noDataFound = false;
        if (!childForm?.data || childForm.data.length === 0) {
          noDataFound = true;
        }
        if (childForm?.data && childForm.data.length < 10) enableFilter = false;
        if (menuHidden) {
          return null;
        } else {
          const moreItemIndicator = (
            <Space>
              <AiOutlineEllipsis />
            </Space>
          );
          const filter1 = (c, index) => {
            let show = true;
            if (
              !isEmpty(filterRelation[r.itemKey]) &&
              c.label.indexOf(filterRelation[r.itemKey]) === -1
            ) {
              show = false;
            }
            return show;
          };
          const filter2 = (c, index) => {
            let show = true;
            if (maxDisplay && index > maxDisplay) {
              show = false;
            }
            return show;
          };
          console.log('prepare meun', {menuHidden, noDataFound, menuReadOnly, menuDisabled, refreshAllowed})
          return (
            <Menu.SubMenu
              key={r.itemKey}
              title={
                <IntlMessages
                  id={`form.${formKey}.relation.${r.itemKey}`}
                  text={r.title}
                />
              }
              disabled={menuDisabled}
            >
              {!menuDisabled && !menuReadOnly && (
                <Menu.Item key={`add-${r.itemKey}`}>
                  <Space>
                    <span className="menu-icon">{<MdAddCircleOutline />}</span>
                    <span className="menu-title">
                      <IntlMessages
                        id={`system.form.relation.add`}
                        text={"New"}
                      />
                    </span>
                  </Space>
                </Menu.Item>
              )}
              {!menuDisabled && enableFilter && (
                <Menu.Item
                  className={"form-relation-filter"}
                  key={`filter-${r.itemKey}`}
                  disabled
                >
                  <Input
                    placeholder={lbl(`system.form.relation.filter`, "Filter")}
                    onChange={(e) => {
                      const filter = {
                        ...filterRelation,
                        [r.itemKey]: e.target.value,
                      };
                      setFilterRelation(filter);
                    }}
                  />
                </Menu.Item>
              )}
              {!menuDisabled &&
                enableSort &&
                childForm?.data
                  ?.filter(filter1)
                  .filter(filter2)
                  .sort((a, b) => compare(a.label, b.label))
                  .map((f, index) => (
                    <Menu.Item key={f.objectId} disabled={index >= maxDisplay}>
                      {index >= maxDisplay ? moreItemIndicator : f.label}
                    </Menu.Item>
                  ))}
              {!menuDisabled &&
                !enableSort &&
                childForm?.data
                  ?.filter(filter1)
                  .filter(filter2)
                  .map((f, index) => (
                    <Menu.Item key={f.objectId} disabled={index >= maxDisplay}>
                      {index >= maxDisplay ? moreItemIndicator : f.label}
                    </Menu.Item>
                  ))}
              {!menuDisabled && noDataFound && (
                <Menu.Item key={"no_data_found"}>
                  <IntlMessages
                    id="system.form.relation.no-data"
                    text="No Data"
                  />
                </Menu.Item>
              )}
              {refreshAllowed && (
                <Menu.Item key={`refresh-${r.itemKey}`}>
                  <Space>
                    <span className="menu-icon">
                      {isRefreshRelation ? (
                        <Spin indicator={<MdRefresh />} />
                      ) : (
                        <MdRefresh />
                      )}
                    </span>
                    <span className="menu-title">
                      <IntlMessages
                        id={`system.form.relation.refresh`}
                        text={"Refresh"}
                      />
                    </span>
                  </Space>
                </Menu.Item>
              )}
            </Menu.SubMenu>
          );
        }
      });
      console.log('childMenu', childMenu)
    }

    let relationMenu = null;
    if ((record.objectId || record.relation) && (parentMenu || childMenu)) {
      relationMenu = (
        <>
          <Menu
            className={"relation-menu"}
            onClick={onClickRelation}
            mode="horizontal"
            forceSubMenuRender={true}
            subMenuOpenDelay={0.5}
            triggerSubMenuAction={'click'}
          >
            <Menu.SubMenu key="relation" icon={<GiHamburgerMenu />}>
              {parentMenu}
              {childMenu}
            </Menu.SubMenu>
          </Menu>
          <Divider type="vertical" />
        </>
      );
    }
    setRelationMenu(relationMenu);
    console.log('relationMenu', relationMenu)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record, formData, parentForm, formKey, filterRelation, isModified]);

  const isClosable = () => {
    return (
      (!direct || direct === "fp" || id === "flowParent")
    );
  };
  const createDefaultRecord = (f) => {
    const json = f?.extraParams?.defaultValues || "{}";
    let record = {};
    try {
      record = JSON.parse(json);
    } catch (err) {
      log("failed to set initial value", err);
    }
    if (decodedFormData) {
      record = {...record, ...decodedFormData}
    }
    console.log("createDefaultRecord()", record);
    return record;
  };
  const doLoad = async () => {
    try {
      disableModifyCheck.current = true;
      let lFlowRecord = Promise.resolve(null);
      if (flow) {
        const isPreview = type === "preview";
        if (isPreview) await flowApi.registerDataClassConfig(isPreview, flow);
        lFlowRecord = flowApi.getSystemFlow(isPreview, flow);
      }
      const isPreview = type === "preview";
      let f = formApi.getSystemForm(isPreview, formKey);
      const isDirect = direct && direct !== "=" && direct !== "fp";
      let record =
        id && !isDirect
          ? formApi.getSystemFormData(isPreview, formKey, getFormView(), id)
          : Promise.resolve({});
      const results = await Promise.all([lFlowRecord, f, record]);
      lFlowRecord = results[0];
      f = results[1];
      record = results[2];
      if (mounted.current && lFlowRecord) setFlowRecord(lFlowRecord);
      if (mounted.current) dispatchFormData(f);
      if (f) {
        const hasPermission = getArraysIntersection(f.extraParams?.allowedRoles?.[getFormView()], authUserObj.roles).length > 0;
        if (mounted.current) setHasPermission(hasPermission)
        const hideVersion = !!f?.extraParams?.hideVersion;
        setHideMyVersion(hideVersion);
        const reportTemplateMap = f?.extraParams?.reportTemplate;
        const reportTemplate = reportTemplateMap
          ? reportTemplateMap[getFormView()]
          : null;
        const exportTemplateMap = f?.extraParams?.exportTemplate;
        const exportTemplate = exportTemplateMap
          ? exportTemplateMap[getFormView()]
          : null;

        if (f.extraParams?.submits && f.extraParams?.submits.length > 0) {
          const allSubmits = f.extraParams.submits.map((s) => {
            if (!s.forCreate && !record.objectId) {
              return { ...s, hidden: true };
            } else if (!s.forUpdate && record.objectId) {
              return { ...s, hidden: true };
            } else if (s.isPrint && !reportTemplate) {
              return { ...s, hidden: true };
            } else if (s.isExport && !exportTemplate) {
              return { ...s, hidden: true };
            } else if (
              !isEmpty(s.forView) &&
              !getFormView()?.match(RegExp(s.forView))
            ) {
              return { ...s, hidden: true };
            } else if (s.permission) {
              if (s.permission[getFormView()]) {
                if (s.permission[getFormView()] === "hidden") {
                  return { ...s, hidden: true };
                } else if (s.permission[getFormView()] === "read-only") {
                  return { ...s, readOnly: true };
                }
              }
            } else if (s.roles) {
              if (
                getArraysIntersection(authUserObj.roles, s.roles).length === 0
              ) {
                return { ...s, readOnly: true };
              }
            }
            return { ...s };
          });
          dispatchFormData({allSubmits});
        }
        const uniqueKeys = f?.extraParams?.uniqueKeys;
        const formActionsFrontEnd = getFormAction(f, "OnChangeActionFrontEnd");
        if (formActionsFrontEnd.length > 0) {
          setRequireProcessValueChangeFrontEnd(true);
        }
        const formActions = getFormAction(f, "OnChangeAction");
        if (formActions.length > 0) {
          setRequireProcessValueChange(true);
        }
        if (!id) {
          // form not yet created.
          const record = createDefaultRecord(f);
          if (mounted.current) setRecord(record);
          notify("load", record, "success");
          // new form, no action
        } else if (uniqueKeys?.[id]) {
          const key = id;
          const uk = uniqueKeys[id].keys;
          let params = uniqueKeyValues;
          if (!params) {
            params = getUniqueKeyValueFromUrl(match, baseUrl, direct);
          }
          if (params.length === uk.length) {
            const isPreview = type === "preview";
            const objectId = await formApi.getSystemFormIdWithUniqueKey(
              isPreview,
              formKey,
              key,
              params
            );
            if (objectId) {
              const params = {
                flow,
                type,
                formKey,
                formView: getFormView(),
                id: objectId,
                direct: "=",
              };
              log("record found params", params);
              doReplace(replacePath(match.path, params));
            } else {
              // form not yet created.
              const record = createDefaultRecord(f);
              const types = uniqueKeys[id].types;
              for (let i = 0; i < uk.length; i++) {
                if (types[i] === "number") {
                  record[uk[i]] = parseFloat(params[i]);
                } else {
                  record[uk[i]] = params[i];
                }
              }
              if (mounted.current) setRecord(record);
              notify("load", record, "success");
            }
          } else {
            throw new Error("Unique Key length is not match!");
          }
        } else if (id === "flowParent" && direct) {
          setFlowParent(direct);
          const record = createDefaultRecord(f);
          if (mounted.current) setRecord(record);
          notify("load", record, "success");
        } else {
          if (record.flow && lFlowRecord) {
            const currentTask = lFlowRecord.extraParams.taskMap[record.flow.task];
            if (currentTask?.view) {
              const currentView = currentTask.view;
              if (currentView !== getFormView()) {
                const roles = f.extraParams.allowedRoles[currentView];
                if (getArraysIntersection(authUserObj.roles, roles).length > 0) {
                  const params = {
                    flow,
                    type,
                    formKey,
                    formView: currentView,
                    id: record.objectId,
                    direct: null,
                  };
                  doReplace(replacePath(match.path, params));
                  return;
                } else {
                  setHasPermission(false);
                }
              }
            }
            if (record.flow.parent && direct) {
              setFlowParent(record.flow.parent);
            }
          }
          if (mounted.current) setRecord(record);
          notify("load", record, "success");
        }
        if (mounted.current) setIsLoading(false);
      } else {
        if (mounted.current) setPageNotFound(true);
        if (mounted.current) setIsLoading(false);
      }
    } catch (e) {
      if (mounted.current) setIsLoading(false);
      log(e);
      const msg = `${e}`;
      if (
        msg.indexOf("Invalid session token") !== -1 ||
        msg.indexOf("Validation failed. Please login to continue.") !== -1
      ) {
        dispatch(userSignOut());
      } else {
        notify("load", record, "fail", displayError(e));
        if (`${e}`.indexOf("PERMISSIONDENIED") !== -1) {
          setHasPermission(false);
        } else if (!headless) message.error(displayError(e));
        if (mounted.current) setIsLoading(false);
      }

      if (msg.indexOf("form not found")) {
        if (isClosable()) {
          doClose();
        } else {
          setPageNotFound(true);
        }
      }
    }
    setTimeout(() => {
      disableModifyCheck.current = false;
    }, 2000)
  };

  const doClose = () => {
    const myType = type === "vo" ? "fm" : type;
    const params = {
      flow,
      type: myType,
      formKey,
      formView: getFormView(),
      id: flowParent,
      direct: null,
    };
    notify("close", record);
    doReplace(replacePath(match.path, params).replace("rt", "rts"));
  };

  const doResetClose = () => {
    const myType = type === "vo" ? "fm" : type;
    const params = {
      flow,
      type: myType,
      formKey,
      formView: getFormView(),
      id: flowParent,
      direct: null,
    };
    notify("close", record);
    doResetReplace(replacePath(match.path, params).replace("rt", "rts"));
  };

  const doReplace = (url) => {
    dispatchReplace(url, location?.state);
  }

  const doResetReplace = (url) => {
    dispatchReplace(url);
  }

  const doPrint = async () => {
    if (mounted.current) setIsSubmitting({ printing: true, any: true });
    try {
      const isPreview = type === "preview";
      const report = await formApi.generateReport(
        isPreview,
        formKey,
        getFormView(),
        id
      );
      if (report) {
        const file = await dataExplorer.get("SystemFile", report.report);
        if (file) {
          if (mounted.current) setReportLink(file.url);
          if (mounted.current) setIsReportReady(true);
        }
      }
    } catch (e) {
      notify("print", record, "fail", displayError(e));
      if (!headless) message.error(displayError(e));
    }
    if (mounted.current) setIsSubmitting({});
  };

  const doExport = async () => {
    if (mounted.current) setIsSubmitting({ exporting: true, any: true });
    try {
      const isPreview = type === "preview";
      const report = await formApi.generateExport(
        isPreview,
        formKey,
        getFormView(),
        id
      );
      if (report) {
        if (report.report) {
          const file = await dataExplorer.get("SystemFile", report.report);
          if (file) {
            if (mounted.current) setReportLink(file.url);
            if (mounted.current) setIsReportReady(true);
          }
        } else if (report.url) {
          if (mounted.current) setReportLink(report.url);
          if (mounted.current) setIsReportReady(true);
        }
      }
    } catch (e) {
      notify("export", record, "fail", displayError(e));
      if (!headless) message.error(displayError(e));
    }
    if (mounted.current) setIsSubmitting({});
  };

  const handleCloseReportPopup = () => {
    if (mounted.current) setIsReportReady(false);
  };

  const callPrint = async ({isPreview, formKey, formView, id}) => {
    try {
      const report = await formApi.generateReport(
        isPreview,
        formKey,
        getFormView(),
        id
      );
      if (report) {
        const file = await dataExplorer.get("SystemFile", report.report);
        if (file) {
          if (mounted.current) setReportLink(file.url);
          if (mounted.current) setIsReportReady(true);
        }
      }
    } catch (e) {
      notify("callPrint", {isPreview, formKey, formView: getFormView(), id}, "fail", displayError(e));
      if (!headless) message.error(displayError(e));
    }
  };

  const callExport = async ({isPreview, formKey, formView, id, dataClass, params, sort, flowParent}) => {
    try {
      const report = await formApi.generateExport(
        isPreview, formKey, getFormView(), id, dataClass, params, sort, flowParent
      );
      if (report) {
        if (report.report) {
          const file = await dataExplorer.get("SystemFile", report.report);
          if (file) {
            if (mounted.current) setReportLink(file.url);
            if (mounted.current) setIsReportReady(true);
          }
        } else if (report.url) {
          if (mounted.current) setReportLink(report.url);
          if (mounted.current) setIsReportReady(true);
        }
      }
    } catch (e) {
      notify("callExport", {isPreview, formKey, formView: getFormView(), id, dataClass, params, sort, flowParent}, "fail", displayError(e));
      if (!headless) message.error(displayError(e));
    }
  };

  const callDownload = async ({url}) => {
    if (mounted.current) setReportLink(url);
    if (mounted.current) setIsReportReady(true);
  };

  const doReload = () => {
    const params = { flow, type, formKey, formView: getFormView(), id, direct };
    notify("reload", record);
    doReplace(replacePath(match.path, params));
  };

  const doDelete = async () => {
    try {
      if (mounted.current) setIsSubmitting({ destory: true, any: true });
      const isPreview = type === "preview";
      await formApi.deleteSystemFormData(
        isPreview,
        formKey,
        getFormView(),
        id,
        record.versionStamp,
        true
      );
      if (mounted.current) setIsModified(false);
      notify("delete", record, "success");
      if (isClosable() && checkViewPermissions("close")) {
        const params = {
          flow,
          type,
          formKey,
          formView: getFormView(),
          id: null,
          direct: null,
        };
        doReplace(replacePath(match.path, params).replace("rt", "rts"));
      } else if (parentForm) {
        openParentRelation();
      }
    } catch (e) {
      if (mounted.current) setIsSubmitting({});
      log(e);
      notify("delete", record, "fail", displayError(e));
      if (!headless) message.error(displayError(e));
    }
  };

  const onFinish = async () => {
    await scriptsSignal.waitFor('isRunning', false);
    await doSave(currentActionRef.current);
  };

  const onChange = async () => {
    const values = form.getFieldsValue(true);
    const {formState, ...compareValue} = values;
    const disabled =
      (record?.objectId && flow && formData.allActions.length === 0) || type === "vo";
    if (!isModified && !disabled) {
      if (draft) {
        const {formState, ...compareDraft} = draft.value;
        deleteEmptyProperty(compareValue, Object.keys(compareValue));
        deleteEmptyProperty(compareDraft, Object.keys(compareDraft));
        if (!disableModifyCheck.current && compare(compareValue, compareDraft, Object.keys({...compareValue, ...compareDraft})) !== 0) {
          // console.log('isModified', {values, compareValue, draft:draft.value, compareDraft, disableModifyCheck})
          if (mounted.current) setIsModified(true);
        }
      } else if (record) {
        const {formState, ...compareRecord} = record;
        deleteEmptyProperty(compareValue, Object.keys(compareValue));
        deleteEmptyProperty(compareRecord, Object.keys(compareRecord));
        if (!disableModifyCheck.current && compare(compareValue, compareRecord, Object.keys({...compareValue, ...compareRecord})) !== 0) {
          // console.log('isModified', {values, compareValue, record, compareRecord, disableModifyCheck})
          if (mounted.current) setIsModified(true);
        }
      } //else if (mounted.current) setIsModified(true);
    }
    if (requireProcessValueChange || requireProcessValueChangeFrontEnd) {
      submitButtonClickedCache.forEach((b) => delete values[b]);
      processDataChange(values);
    } else if (headless) notify("data-changed", values);
  };

  const dataChangeTimer = useRef();
  const showMyLoading = () => {
    if (!dataChangeTimer.current && formData.extraParams?.showLoading) {
      dataChangeTimer.current = setTimeout(() => {
        if (dataChangeTimer.current) {
          showLoading()
        }
      }, (formData.extraParams?.showLoading * 1000))
    }
  }
  const hideMyLoading = () => {
    if (dataChangeTimer.current) {
      clearTimeout(dataChangeTimer.current);
      dataChangeTimer.current = null;
      hideLoading();
    }
  }

  const fireStateEvent = (key, value) => {
    const values = form.getFieldsValue(true);
    const formState = values.formState || {};
    formState[key] = value;
    processDataChange(values)
  };

  const processDataChange = async (values) => {
    try {
      const isPreview = type === "preview";
      showMyLoading();
      const myValue = {...values};
      if (!myValue.formState) myValue.formState = getFormState();
      const myOldValue = lastChangedValue ? {...lastChangedValue} : null;
      let newValues = myValue;
      let processed = false;
      let skipped = false;
      if (requireProcessValueChangeFrontEnd) {
        const resultMap = processSystemFormDataChange(isPreview, formData, myValue, myOldValue, forceUpdate, doSetValues, mainPanelRef, setIsModified);
        newValues = resultMap.form;
        processed = resultMap.processed;
        skipped = resultMap.skipped;
      }
      newValues = (processed || !requireProcessValueChange) ? newValues :
        await formApi.processSystemFormDataChange(
        isPreview,
        formKey,
        getFormView(),
        newValues,
        lastChangedValue
      );
      if (!newValues) return;
      if (newValues.frontendJS || values.frontendJS) {
        let frontendJS = newValues.frontendJS;
        if (!frontendJS) {
          frontendJS = values.frontendJS;
        }
        if (!frontendJS) frontendJS = "";
        try {
          // eslint-disable-next-line no-eval
          eval(frontendJS);
        } catch (error) {
          console.error(error);
        }
      }
      if (!equals(myValue, newValues)) {
        if (newValues.resetModified) {
          setIsModified(false);
        }
        if (newValues.push) {
          dispatchPush(newValues.push);
        } else if (newValues.replace) {
          doReplace(newValues.replace);
        } else if (newValues.close) {
          if (isClosable()) {
            doClose();
          }
        } else if (newValues.open) {
          window.open(baseUrl + newValues.open);
        } else if (newValues.openFullURL) {
          window.open(newValues.openFullURL);
        } else if (newValues.reload) {
          log('reload trigger by action policy: onChange', newValues)
          window.location.reload(false);
          log('signout trigger by action policy: onSave', newValues);
        } else if (newValues.userSignOut) {
          dispatch(userSignOut())
        } else {
          if (newValues.openPage) {
            if (newValues.openPage.pageKey && newValues.openPage.modalKey) {
              const newOpenPageData = {...newValues.openPage}
              if (!newOpenPageData.width) newOpenPageData.width = 800;
              newOpenPageData.isPreview = !!newOpenPageData.isPreview;
              newOpenPageData.visible = true;
              setOpenPageData(newOpenPageData);
            }
            newValues.openPage = undefined;
          }
          if (newValues.printReport) {
            callPrint(newValues.printReport);
            newValues.printReport = undefined;
          }
          if (newValues.exportReport) {
            callExport(newValues.exportReport);
            newValues.exportReport = undefined;
          }
          if (newValues.downloadFile) {
            callDownload(newValues.downloadFile);
            newValues.downloadFile = undefined;
          }
          if (newValues.forceUpdate) {
            newValues.forceUpdate = undefined;
            setTimeout(() => {
              forceUpdate();
            }, 500)
            newValues.forceUpdate = undefined;
          }
          if (newValues.reloadMenu) {
            dispatch(loadMenu());
            newValues.reloadMenu = undefined;
          }
          if (newValues.importXLSX) {
            const targetKey = newValues.importXLSX.target
            const data = await importXLSX(newValues.importXLSX.columns)
            if (!newValues.formState) newValues.formState = {};
            newValues.formState[targetKey] = data;
            newValues.importXLSX = undefined
          }
          if (newValues.doSave) {
            doSave(newValues.doSave);
            newValues.doSave = undefined;
          }
          displayWarning(newValues);
          submitButtonClickedCache.forEach((b) => delete newValues[b]);
          if (!skipped) {
            doSetValues({ data: newValues });
          }
        }
      } else {
        setLastChangedValue(newValues);
        hideLoading()
      }
    } catch (e) {
      const errorMessage = displayError(e);
      if (!headless) msgHelper.error(errorMessage);
      hideMyLoading();
      setFormState((oldState) => {
        if (oldState) {
          const newState = JSON.parse(JSON.stringify(oldState, (key, value) => {
            if (key.match(/_loading$/)) {
              return false;
            } else {
              return value;
            }
          }));
          doUpdateValues('formState', newState);
          return newState;
        } else {
          return oldState;
        }
      })
    }
  }

  const onSubmitButtonClicked = async (submitKey) => {
    if (submitKey) {
      const buttonCache = [...submitButtonClickedCache];
      if (buttonCache.indexOf(submitKey) === -1) {
        buttonCache.push(submitKey);
        setSubmitButtonClicedCache(buttonCache);
      }
      const values = form.getFieldsValue(true);
      buttonCache.forEach((b) => delete values[b]);
      values[submitKey] = true;
      values["window.location.pathname"] = window.location.pathname;
      if (requireProcessValueChange || requireProcessValueChangeFrontEnd) {
        processDataChange(values);
      } else if (headless) notify("data-changed", values);
    }
  };

  const displayWarning = (values) => {
    if (values.errorMessage) {
      notify("onChange", record, "error", displayError(values.errorMessage));
      if (!headless) msgHelper.error(displayError(values.errorMessage));
      delete values.errorMessage;
    }
    if (values.warningMessage) {
      notify(
        "onChange",
        record,
        "warning",
        displayError(values.warningMessage)
      );
      if (!headless) msgHelper.warning(displayError(values.warningMessage));
      delete values.warningMessage;
    }
    if (values.infoMessage) {
      notify("onChange", record, "info", displayError(values.infoMessage));
      if (!headless) msgHelper.info(displayError(values.infoMessage));
      delete values.infoMessage;
    }
  };

  const doValidate = async () => {
    try {
      await form.validateFields();
      notify("valiate", record, "success");
    } catch (validateError) {
      log("validateError", validateError);
      notify("valiate", record, "fail", validateError);
      return;
    }
  };

  const doSetValues = async (data) => {
    try {
      const values = form.getFieldsValue(true);
      const mergedFormState = mergeFormState(values, data.data, getFormState())
      const {resetModified, ...newFormData} = {...values, ...data.data, formState: mergedFormState};
      await form.setFieldsValue(newFormData);
      let requireProcessDataChange = false;
      if (mergedFormState) {
        setFormState((oldFormState) => {
          if (!equals(oldFormState || {}, mergedFormState)) {
            requireProcessDataChange = true;
            updateLoadingStatus(mergedFormState, oldFormState);
            return mergedFormState;
          } else {
            return oldFormState;
          }
        });
      }
      if (requireProcessDataChange) {
        setTimeout(() => {
          doUpdateValues('formState', mergedFormState);
        }, [500])
      }
      notify("set-values-result", newFormData, "success");
      setLastChangedValue(newFormData);
      if (resetModified) {
        setIsModified(false);
      }
    } catch (error) {
      log("validateError", error);
      notify("set-values-result", data.data, "fail", error);
      return;
    }
  };

  const doUpdateValues = (key, value) => {
    const values = form.getFieldsValue(true);
    submitButtonClickedCache.forEach((b) => delete values[b]);
    values[key] = value;
    doSetValues({data: values});
    processDataChange(values);
  }

  const doSave = async (action) => {
    if (typeof action !== 'string') {
      action = null;
    }
    if (!action && flow && currentTask?.allowSave) {
      action = "save";
    }
    currentActionRef.current = action;
    try {
      const formState = form.getFieldValue('formState') || getFormState();
      formState.isSaveAction = true;
      form.setFieldsValue('formState', formState);
      await form.validateFields();
    } catch (validateError) {
      log("validateError", validateError);
      if (validateError?.errorFields?.length > 0) {
        form.scrollToField(validateError.errorFields[0].name);
        setTimeout(() => {
          mainPanelRef.current?.querySelector('.ant-form-item-explain-error')?.scrollIntoView()
        }, AccountStore.ON_CHANGE_DELAY)
      }
      return false;
    }
    const values = form.getFieldsValue(true);
    submitButtonClickedCache.forEach((b) => delete values[b]);
    const messageAction = getSaveAction(action, record);
    try {
      if (mounted.current) {
        if (action) setIsSubmitting({ [action]: true, any: true });
        if (!action) setIsSubmitting({ save: true, any: true });
      }
      const isPreview = type === "preview";
      let result = null;
      if (flow) {
        result = await formApi.saveSystemFormAndFlowData(
          isPreview,
          formKey,
          getFormView(),
          values,
          flow,
          action,
          flowParent
        );
      } else {
        result = await formApi.saveSystemFormData(
          isPreview,
          formKey,
          getFormView(),
          values,
          action
        );
      }
      notify(
        getSaveAction(action, record),
        result,
        "success"
      );
      const autoStart = isAutoStart(result);
      if (!headless && !autoStart && !result.calculationOnly)
        message.info(lbl("system.form.save-success", "Saved Successfully!"));
      if (draft) {
        await formApi.deleteSystemFormDataDraft(
          isPreview,
          formKey,
          getFormView(),
          id
        );
      }
      postSaveAction(result);
      registerTranslateMessage(result);
    } catch (e) {
      if (mounted.current) setIsSubmitting({});
      log(e);
      if (
        `${e}`.indexOf('Object not found.') !== -1 ||
        `${e}`.indexOf('form not found') !== -1) {
        // form deleted.
        log('form has been deleted');
        if (isClosable()) {
          doClose();
        }
      } else {
        notify(messageAction, values, "fail", displayError(e));
        if (!headless) message.error(displayError(e));
      }
    }
  };

  const registerTranslateMessage = (values) => {
    // handle translate
    if (formData.extraParams?.translate?.enabled) {
      if (formData.extraParams.translate.recordKey) {
        const recordKey = prepareText(
          formData.extraParams.translate.recordKey,
          values
        );
        if (
          recordKey !== formData.extraParams.translate.recordKey &&
          !isEmpty(recordKey)
        ) {
          for (
            let i = 0;
            i < formData.extraParams.translate.items?.length;
            i++
          ) {
            const item = formData.extraParams.translate.items[i];
            if (values[item.key]) {
              const type = item.type ? item.type : "input";
              const text = values[item.key];
              const messageKey = `form.${formKey}.${recordKey}.${item.key}`;
              const messageLabel = item.label;
              const parentKey = `form.${formKey}.${item.key}`;
              const params = { flow, type, formKey, formView: getFormView(), id, direct };
              const translatePath = replacePath(match.path, params);
              const order = item.index;
              registerMessage(messageKey, text, type, messageLabel, parentKey, translatePath, order);
            }
          }
        }
      }
    }
  };

  const isAutoStart = (result) => {
    if (result?.flow?.task === 'start') {
      const currentTask = flowRecord.extraParams.taskMap[result.flow.task];
      if (currentTask.actions?.length === 1) {
        if (currentTask.actions[0].autoStart && currentTask.actions[0].action) {
          return true;
        }
      }
    }
    return false;
  }

  const postSaveAction = (result) => {
    if (result) {
      if (result.calculationOnly) {
        if (result.resetModified) {
          setIsModified(false);
        }
        if (result.push) {
          dispatchPush(result.push);
        } else if (result.replace) {
          doReplace(result.replace);
        } else if (result.open) {
          window.open(baseUrl + result.open);
        } else if (result.reload) {
          log('reload trigger by action policy: onSave', result);
          window.location.reload(false);
        } else if (result.userSignOut) {
          log('signout trigger by action policy: onSave', result);
          dispatch(userSignOut())
        } else {
          if (result.openPage) {
            if (result.openPage.pageKey && result.openPage.modalKey) {
              const newOpenPageData = {...result.openPage}
              if (!newOpenPageData.width) newOpenPageData.width = 800;
              newOpenPageData.isPreview = !!newOpenPageData.isPreview;
              newOpenPageData.visible = true;
              setOpenPageData(newOpenPageData);
            }
            result.openPage = undefined;
          }
          if (result.printReport) {
            callPrint(result.printReport);
          }
          if (result.exportReport) {
            callExport(result.exportReport);
          }
          if (result.forceUpdate) {
            result.forceUpdate = undefined;
            setTimeout(() => {
              forceUpdate();
            }, 500)
          }
          if (mounted.current) setRecord(result);
          if (mounted.current) setIsSubmitting({});
        }
        if (mounted.current) setIsSubmitting({});
      } else {
        if (mounted.current) setRecord(result);
        if (mounted.current) setIsModified(false);
        if (result.flow) {
          const currentTask = flowRecord.extraParams.taskMap[result.flow.task];
          if (isAutoStart(result)) {
            return doSave(currentTask.actions[0].action)
          }
          if (currentTask?.view) {
            const currentView = currentTask.view;
            const roles = formData.extraParams.allowedRoles[currentView];
            if (getArraysIntersection(authUserObj.roles, roles).length > 0) {
              const params = {
                flow,
                type,
                formKey,
                formView: currentView,
                id: result.objectId,
                direct: direct,
              };
              doReplace(replacePath(match.path, params));
              return;
            } else {
              if (!direct) {
                doClose();
              }
              return;
            }
          }
        }
        if (result.objectId) {
          if (isClosable() && checkViewPermissions("close")) {
            if (formData?.extraParams?.autoClose) {
              doClose();
            } else {
              const params = {
                flow,
                type,
                formKey,
                formView: getFormView(),
                id: result.objectId,
                direct: direct,
              };
              doReplace(replacePath(match.path, params));
            }
          } else {
            const params = {
              flow,
              type,
              formKey,
              formView: getFormView(),
              id: result.objectId,
              direct: direct,
            };
            doReplace(replacePath(match.path, params));
          }
        }
      }
    }
  };

  const doSaveDraft = async () => {
    let values = null;
    try {
      if (id) {
        if (mounted.current) setIsSubmitting({ saveDraft: true, any: true });
        values = form.getFieldsValue(true);
        const isPreview = type === "preview";
        await formApi.saveSystemFormDataDraft(
          isPreview,
          formKey,
          getFormView(),
          id,
          values
        );
        notify("save-draft", values, "success");
        if (mounted.current) setIsModified(false);
        const params = { flow, type, formKey, formView: getFormView(), id, direct: null };
        doReplace(replacePath(match.path, params));
      }
    } catch (e) {
      if (mounted.current) setIsSubmitting({});
      log(e);
      notify("save-draft", values, "fail", displayError(e));
      if (!headless) message.error(displayError(e));
    }
  };

  const doDiscardDraft = async () => {
    try {
      if (draft) {
        if (mounted.current) setIsSubmitting({ discardDraft: true, any: true });
        const isPreview = type === "preview";
        await formApi.deleteSystemFormDataDraft(
          isPreview,
          formKey,
          getFormView(),
          id
        );
        notify("discard-draft", record, "success");
        if (mounted.current) setIsModified(false);
        const params = { flow, type, formKey, formView: getFormView(), id, direct: null };
        doReplace(replacePath(match.path, params));
      }
    } catch (e) {
      if (mounted.current) setIsSubmitting({});
      log(e);
      notify("discard-draft", record, "fail", displayError(e));
      if (!headless) message.error(displayError(e));
    }
  };

  const doCopySelect = () => {
    setIsCopying(true);
  };

  const doCopy = async (selectedIds) => {
    if (selectedIds && selectedIds.length > 0) {
      const isPreview = type === "preview";
      const id = selectedIds[0];
      const isCopy = true;
      const copyValue = await formApi.getSystemFormData(
        isPreview,
        formKey,
        getFormView(),
        id,
        isCopy
      );
      await form.setFieldsValue({ ...copyValue });
      setIsCopying(false);
      processDataChange(copyValue);
      fireStateEvent("lastCopyDateTime", new Date().getTime());
    } else {
      message.error(lbl("system.form.select.no-record-selected", "Please selected a record!"));
    }
  };

  const doCancelCopy = () => {
    setIsCopying(false);
  };

  const doToggleEdit = () => {
    const params = { flow, type: "fm", formKey, formView: getFormView(), id, direct };
    notify("reload", record);
    doReplace(replacePath(match.path, params));
  };

  const doTranslate = () => {
    const recordKey = prepareText(
      formData.extraParams.translate.recordKey,
      record
    );
    if (
      recordKey !== formData.extraParams.translate.recordKey &&
      !isEmpty(recordKey)
    ) {
      const filterKeyPrefix = `form.${formKey}.${recordKey}.`;
      const params = { flow, type, formKey, formView: getFormView(), id, direct };
      const translatePath = replacePath(match.path, params);
      dispatch(startFormTranslate({filterKeyPrefix, translatePath}));
    } else {
      message.error(lbl("system.form.translate.no-record-key", "Please save first!"));
    }
  };

  const doShowHistory = () => {
    if (mounted.current) setFormHistoryModalVisible(true);
  };

  const reportTemplateMap = formData?.extraParams?.reportTemplate;
  const reportTemplate = reportTemplateMap ? reportTemplateMap[getFormView()] : null;
  const exportTemplateMap = formData?.extraParams?.exportTemplate;
  const exportTemplate = exportTemplateMap ? exportTemplateMap[getFormView()] : null;

  const checkViewPermissions = (permission) => {
    const lViewPermissions = formData?.extraParams?.viewPermissions
      ? formData.extraParams.viewPermissions[getFormView()]
      : null;
    const viewPermissions = lViewPermissions
      ? [...lViewPermissions, ...defaultPermissions]
      : defaultPermissions;
    let allowed = true;
    if (viewPermissions) {
      allowed = false;
      for (const element of viewPermissions) {
        const p = element;
        if (p === "denyall") {
          break;
        }
        if (p === permission) {
          allowed = true;
          break;
        }
      }
    }
    if (allowed) {
      const formState = getFormState();
      if (formState?.overridePermission) {
        if (formState.overridePermission[permission] === false) {
          allowed = false;
        }
      }
    }
    return allowed;
  };

  const checkButtonBar = (permission) => {
    let allowed = checkViewPermissions(permission);
    if (allowed && formData.allSubmits) {
      if (
        formData.allSubmits.filter(
          (s) => s.subsitute && s.subsitute.indexOf(permission) !== -1
        ).length > 0
      ) {
        allowed = false;
      }
    }
    return allowed;
  };

  const checkButtonPermission = (permission) => {
    let allowed = false;
    for (const element of formData.allSubmits.map(c => c.itemKey)) {
      if (element === permission) {
        allowed = true;
        break;
      }
    }
    if (allowed) {
      const formState = getFormState();
      if (formState?.overridePermission) {
        if (formState.overridePermission[permission] === false) {
          allowed = false;
        }
      }
    }
    return allowed;
  };

  unregisterAllPostMessageAction("form-");
  registerPostMessageAction("form-validate", null, doValidate);
  registerPostMessageAction("set-values", null, doSetValues);
  const isActionee =
    (!record?.flow?.assignee ||
      record?.flow?.assignee === authUserObj?.username) &&
    formData.allActions.length > 0;
  const buttonBarForVoRef = useRef();
  const buttonBarForVo = createButtonBarForVo({
    ref: buttonBarForVoRef,
    record,
    authUserObj,
    formData,
    flow,
    currentTask,
    checkButtonBar,
    lbl,
    isSubmitting,
    doToggleEdit,
    checkIsSubstituteAction,
    formKey,
    doPrint,
    doExport,
    doClose,
    reportTemplate,
    exportTemplate,
    doShowHistory,
    doReload,
    isClosable,
  });
  const buttonBarRef = useRef();
  const buttonBar = createButtonBar({
    ref: buttonBarRef,
    record,
    authUserObj,
    formData,
    flow,
    currentTask,
    checkButtonBar,
    checkButtonPermission,
    lbl,
    isSubmitting,
    doSave,
    doDelete,
    id,
    formKey,
    checkIsSubstituteAction,
    doCopySelect,
    doPrint,
    doExport,
    doShowHistory,
    doReload,
    doClose,
    onSubmitButtonClicked,
    doTranslate,
    doSaveDraft,
    draft,
    doDiscardDraft,
    reportTemplate,
    exportTemplate,
    isClosable,
  });

  const flowStatuStyle = {};
  if (currentTask?.color) flowStatuStyle.color = currentTask?.color;
  if (currentTask?.background)
    flowStatuStyle.background = currentTask?.background;
  let overdue = null;
  let overdueDate = null;
  if (record?.flow?.dueDate) {
    overdueDate = moment(record.flow.dueDate);
    if (overdueDate.isBefore(moment())) {
      overdue = (
        <Tooltip
          mouseEnterDelay={1}
          title={<IntlMessages id="system.flow.task-overdue" text="Overdue" />}
        >
          <span className="flow-overdue">
            <GoAlert />
          </span>
        </Tooltip>
      );
    }
  }
  let lastUpdateDate = null;
  if (record.updatedAt) {
    lastUpdateDate = moment(record.updatedAt);
  }

  const onClickRelation = (e) => {
    const keyPath = e.keyPath;
    if (keyPath) {
      if (keyPath.length === 2) {
        openParentRelation();
      } else if (keyPath.length === 3) {
        const relationKey = keyPath[1];
        const target = keyPath[0];
        const relationTemplate = getArrayElementByAttribute(
          formData?.extraParams?.relations,
          "itemKey",
          relationKey
        );
        const relation = getArrayElementByAttribute(
          record?.relation,
          "type",
          "child-form-" + relationKey
        );
        if (relationTemplate) {
          setSelectedRelationTemplate(relationTemplate);
          setSelectedRelation(relation);
          if (target.startsWith("add-")) {
            setIsCreateRelation(true);
          } else if (target.startsWith("refresh-")) {
            handleRefreshRelation(relationTemplate);
          } else if (relation) {
            setSelectedChildFormId(target);
          }
        }
      }
    }
  };

  const openParentRelation = () => {
    if (record?.relation) {
      const relation = getArrayElementByAttribute(
        record?.relation,
        "type",
        "parent-form"
      );
      if (relation?.data?.length) {
        const target = relation.data[0].objectId;
        if (
          location?.state?.from &&
          location?.state?.from.indexOf(target) !== -1
        ) {
          dispatchGoBack();
        } else {
          setSelectedRelation(relation);
          setSelectedChildFormId(target);
        }
      }
    }
  };

  const handleConfirmCreateRelationPopup = async () => {
    if (mounted.current) {
      try {
        setIsLoading(true);
        const isPreview = type === "preview";
        const childForm = await formApi.createChildSystemFormData(
          isPreview,
          formKey,
          getFormView(),
          id,
          selectedRelationTemplate.itemKey
        );
        notify("create-child", childForm, "success");
        if (childForm.objectId) {
          setSelectedChildFormId(childForm.objectId);
          setIsCreateRelation(false);
        } else {
          const childFormId = compressFormDataAsId(childForm);
          console.log("compressedChildForm", {childFormId})
          setSelectedChildFormId(childFormId);
          setIsCreateRelation(false);
        }
      } catch (e) {
        if (mounted.current) setIsLoading(false);
        notify("create-child", record, "fail", displayError(e));
        if (!headless) message.error(displayError(e));
      }
    }
  };

  const handleCloseCreateRelationPopup = () => {
    if (mounted.current) setIsCreateRelation(false);
  };

  const handleRefreshRelation = async (selectedRelationTemplate) => {
    if (mounted.current) {
      if (isModified) {
        notify("refresh-child", form, "fail", "Please save change first!");
        if (!headless)
          message.error(lbl("system.form.relation.message.save-change-first","Please save change first!"));
      } else {
        try {
          setIsRefreshRelation(true);
          setIsLoading(true);
          const isPreview = type === "preview";
          const newForm = await formApi.refreshChildSystemFormData(
            isPreview,
            formKey,
            getFormView(),
            id,
            selectedRelationTemplate.itemKey
          );
          notify("refresh-child", newForm, "success");
          doReload();
          if (mounted.current) setIsRefreshRelation(false);
        } catch (e) {
          if (mounted.current) setIsRefreshRelation(false);
          notify("refresh-child", record, "fail", displayError(e));
          if (!headless) message.error(displayError(e));
        }
      }
    }
  };

  const openRelatedForm = (keepHistory) => {
    const params = {
      type,
      flow: selectedRelation?.flow || selectedRelationTemplate?.flow,
      formKey: selectedRelation?.form || selectedRelationTemplate?.form,
      formView: selectedRelation?.view || selectedRelationTemplate?.view,
      id: selectedChildFormId,
      direct: keepHistory ? "=" : null,
    };
    if (params.flow && params.formKey && params.formView) {
      if (!record.flow) params.formKey = params.flow + "/" + params.formKey;
      let newPath = replacePath(match.path, params);
      newPath = newPath.replace("/rt/", "/wrt/");
      if (keepHistory) {
        dispatchPush(newPath, {
          from: window.location.pathname,
        });
      } else {
        doReplace(newPath);
      }
    } else if (params.formKey && params.formView) {
      let newPath = replacePath(match.path, params);
      newPath = newPath.replace("/wrt/", "/rt/");
      if (keepHistory) {
        dispatchPush(newPath, {
          from: window.location.pathname,
        });
      } else {
        doReplace(newPath);
      }
    } else {
      log("invalid relation type", selectedRelation);
    }
  };

  let timeline = null;
  if (taskHistory) {
    const tl = [];
    let ttc = null;
    let tac = null;
    taskHistory.forEach((h) => {
      if (h.status === "Completed") {
        ttc = flowRecord.extraParams.taskMap[h.taskFrom];
        tac = getArrayElementByAttribute(ttc?.actions, "action", h.action);
        tl.push({
          createdAt: h.createdAt,
          actionee: h.actionee,
          itemKey: ttc?.itemKey,
          status: ttc?.status,
          action: h.action,
          actionLabel: tac?.label,
        });
      }
    });
    if (record?.flow?.task && record.flow.task !== "end") {
      ttc = flowRecord?.extraParams?.taskMap?.[record.flow.task];
      tl.push({
        createdAt: moment(),
        actionee: record?.flow?.assignee,
        itemKey: record.flow.task,
        status: ttc?.status,
      });
    }
    if (tl.length > 0) {
      const endded = record?.flow?.task === "end";
      const addTLTS = (value, id, label) => {
        if (value) {
          if (label) {
            return (
              <>
                {" "}
                &middot; <IntlMessages id={id} text={label} />
              </>
            );
          } else {
            return <> &middot; {id}</>;
          }
        } else {
          return null;
        }
      };
      timeline = (
        <div className={"flow-timeline " + (endded ? "endded" : "")}>
          <Timeline pending={!endded}>
            {tl.map((tlc, index) => {
              return (
                <Timeline.Item key={index + 1}>
                  {moment(tlc.createdAt).format("LLL")}
                  {addTLTS(
                    tlc.status,
                    `flow.${formKey}.status.${tlc.itemKey}`,
                    tlc.status
                  )}
                  {addTLTS(tlc.actionee, tlc.actionee)}
                  {addTLTS(
                    tlc.action,
                    tlc.action === "admin"
                      ? `system.flow.admin`
                      : `flow.${formKey}.action.${tlc.action}`,
                    tlc.action === "admin" ? "Admin" : tlc.actionLabel
                  )}
                </Timeline.Item>
              );
            })}
          </Timeline>
        </div>
      );
    } else {
      timeline = <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
    }
  }

  if (isLoading) {
    return (<Row>
      <Col span="24">
        {contextHolder}
        <CircularProgress />
      </Col>
    </Row>)
  } else if (pageNotFound || !hasPermission) {
    if (isComponent) {
      return <div style={{display: 'none'}}>{`You have no permission to access the requested component. Form Runtime (formKey=${formKey}, formView=${getFormView()})`}</div>
    } else {
      return (
        <Row>
          <Col span="24" >
            {!hasPermission && <Alert type="error"
              message={<IntlMessages id="system.alert.error" text="Error"/>}
              description={<IntlMessages id="system.page.permission-error" text="You have no permission to access the requested page."/>}/>
            }
            {pageNotFound && <Alert type="error"
              message={<IntlMessages id="system.alert.error" text="Error"/>}
              description={<IntlMessages id="system.page.not-found" text="The requested page was not found."/>}/>
            }
          </Col>
        </Row>
      )
    }
  } else {
    return (
      <Row className="form-main-panel" ref={mainPanelRef}>
        {contextHolder}
        {type === "preview" && formData && (
          <Col span="24" className="form-main">
            <div className="craft-form-preview">
              <IntlMessages id="system.form.preview-indicator" text="PREVIEW" />{" "}
              <span>
                <IntlMessages id="system.form.form-version" text="VERSION" />{" "}
                {formData.versionStamp}
              </span>
              <span>{moment(formData.updatedAt).fromNow()}</span>
            </div>
          </Col>
        )}
        {draft && !hideDraft && (
          <Col span="24" className="form-main">
            <div className="craft-form-draft">
              <IntlMessages id="system.form.draft-indicator" text="DRAFT" />{" "}
              <span>{moment(draft.updatedAt).fromNow()}</span>
            </div>
          </Col>
        )}
        {type !== "preview" && formData && (!hideMyVersion || (isClosable() && checkViewPermissions("close"))) && (
          <Col span="24" className="form-main">
            <div className="craft-form-published">
              {!hideMyVersion && <span>
                <IntlMessages id="system.form.form-version" text="VERSION" />{" "}
                {formData.versionStamp}
              </span>}
              {isClosable() && checkViewPermissions("close") && (
                <Button
                  type={"text"}
                  icon={<MdClose />}
                  onClick={doClose}
                ></Button>
              )}
            </div>
          </Col>
        )}
        {flowRecord && !hideFlowInfo && (
          <Col span="24" className="form-main">
            <div className="craft-form-flow">
              {relationMenu}
              <Popover content={timeline} placement="bottomLeft">
                <span className="flow-icon">
                  <i className={"icon icon-timeline-new"} />
                </span>
              </Popover>
              <Divider type="vertical" />
              <span>
                <IntlMessages
                  id={`flow.${flowRecord?.flowKey}.title`}
                  text={flowRecord?.flowName}
                />
              </span>
              {record.flow && currentTask && (
                <>
                  {overdueDate && (
                    <Divider className="flow-due-date-dvdr" type="vertical" />
                  )}
                  {overdueDate && (
                    <span className="flow-due-date">
                      <Tooltip
                        mouseEnterDelay={1}
                        title={
                          <IntlMessages
                            id="system.flow.due-date"
                            text="Due Date"
                          />
                        }
                      >
                        {overdueDate.calendar()}
                      </Tooltip>
                    </span>
                  )}
                  <Divider type="vertical" />
                  <span className="flow-description">
                    <IntlMessages
                      id={`flow.${formKey}.${flowRecord?.flowKey}.task.${record.flow?.task}`}
                      text={currentTask?.description || currentTask?.label}
                    />
                  </span>
                  <Divider className="flow-description-dvdr" type="vertical" />
                  <span className="last">
                    <Tag style={flowStatuStyle}>
                      {overdue}
                      <IntlMessages
                        id={`flow.${formKey}.status.${record.flow?.task}`}
                        text={currentTask?.status}
                      />
                    </Tag>
                  </span>
                  {record.flow?.assignee && (
                    <>
                      <Divider type="vertical" />
                      <SfpUserAvatar
                        username={record.flow.assignee}
                        size="medium"
                      />
                    </>
                  )}
                </>
              )}
            </div>
          </Col>
        )}
        {!flowRecord && (
          <Col span="24" className="form-main">
            <div className="craft-form-flow">
              {relationMenu}
              <span>
                <IntlMessages
                  id={`form.${formKey}.title`}
                  text={formData?.formName}
                />
              </span>
              {lastUpdateDate && (
                <Divider className="flow-due-date-dvdr" type="vertical" />
              )}
              {lastUpdateDate && (
                <span className="flow-due-date">
                  <Tooltip
                    mouseEnterDelay={1}
                    title={
                      <IntlMessages
                        id="system.form.updated-at"
                        text="Updated Date"
                      />
                    }
                  >
                    {lastUpdateDate.calendar()}
                  </Tooltip>
                </span>
              )}
              <Divider type="vertical" />
            </div>
          </Col>
        )}
        <Col span="24" className="form-main">
          <div className="form-main-inner">
            {setupForm({
              data: formData?.data,
              preview: type === "preview",
              formView: getFormView(),
              buttonBar: type === "vo" ? buttonBarForVo : buttonBar,
              form: form,
              onFinish: onFinish,
              onChange: onChange,
              setIsSubmitting: setIsSubmitting,
              onSubmitButtonClicked: onSubmitButtonClicked,
              disabled:
                (record?.objectId && flow && (!isActionee || formData.allActions.length === 0)) ||
                isSubmitting["any"] ||
                type === "vo",
              formState: getFormState(),
              fireStateEvent: fireStateEvent,
            })}
          </div>
        </Col>
        <Modal
          visible={isReportReady}
          title={<IntlMessages id="system.form.report.download" text="Download" />}
          onOk={handleCloseReportPopup}
          onCancel={handleCloseReportPopup}
          footer={[
            <Button
              key="cancel"
              className="cancel-btn"
              onClick={handleCloseReportPopup}
            >
              <IntlMessages id="system.form.cancel" text="Cancel" />
            </Button>,
            <Button
              key="link"
              href={reportLink}
              target="_blank"
              className="open-btn"
              onClick={handleCloseReportPopup}
            >
              <IntlMessages id="system.form.open" text="Open" />
            </Button>,
          ]}
        >
          <p>
            <IntlMessages
              id="system.form.file.ready"
              text="Your file is ready."
            />
          </p>
        </Modal>
        <Modal
          visible={isCreateRelation}
          title={
            <IntlMessages id="system.form.relation.confirm" text="Confirm" />
          }
          onOk={handleConfirmCreateRelationPopup}
          onCancel={handleCloseCreateRelationPopup}
          footer={[
            <Button
              key="cancel"
              className="cancel-btn"
              onClick={handleCloseCreateRelationPopup}
            >
              <IntlMessages id="system.form.cancel" text="Cancel" />
            </Button>,
            <Button
              key="confirm"
              className="create-btn"
              onClick={handleConfirmCreateRelationPopup}
            >
              <IntlMessages id="system.form.create" text="Create" />
            </Button>,
          ]}
        >
          {selectedRelationTemplate && (
            <>
              {selectedRelationTemplate?.type === "child-form" && (
                <p>
                  <IntlMessages
                    id="system.form.relation.confirm.child-form"
                    text="You are about to create a child form ({title}) with the current form information."
                    values={{ title: selectedRelationTemplate?.title }}
                  />
                </p>
              )}
              {selectedRelationTemplate?.type === "child-flow" && (
                <p>
                  <IntlMessages
                    id="system.form.relation.confirm.child-flow"
                    text="You are about to create a child flow ({title}) with the current form information."
                    values={{ title: selectedRelationTemplate?.title }}
                  />
                </p>
              )}
            </>
          )}
        </Modal>
        <SelectFormSearch
          messageApi={api}
          visible={isCopying}
          onOk={doCopy}
          onCancel={doCancelCopy}
          isPreview={type === "preview"}
          flow={flow}
          formKey={formKey}
          formView={getFormView()}
          flowParent={null}
          multiple={false}
        />
        <FormHistoryModal
          messageApi={api}
          visible={formHistoryModalVisible}
          formRecord={formData}
          objectId={id}
          isPreview={type === "preview"}
          formKey={formKey}
          formView={getFormView()}
          onOk={() => setFormHistoryModalVisible(false)}
          onCancel={() => setFormHistoryModalVisible(false)}
        />
        {openPageData?.visible && <PageModal
            {...openPageData}
            onOk={(values) => {
              setOpenPageData({})
              setTimeout(() => {
                doUpdateValues(openPageData.modalKey, values)
                setTimeout(() => {
                  forceUpdate();
                }, 500)
              }, 500)
            }}
            onCancel={() => {
              setOpenPageData({})
              setTimeout(() => {
                doUpdateValues(openPageData.modalKey, null)
              }, 500)
            }}
          />}
        <ModifiedChecker isModified={isModified} />
        {isLoading && <CircularProgress />}
      </Row>
    );
  }
};
