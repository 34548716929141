import { MinusSquareOutlined, PlusSquareOutlined, ProfileOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Popconfirm,
  Popover,
  Row,
  Select,
  Space,
  Switch,
  Tabs,
  Tag,
  TimePicker,
  Tooltip,
  Typography,
  Upload,
} from "antd";
import { push } from "connected-react-router";
import { useLbl } from "lngProvider";
import { isEmpty } from "lodash";
import { flowApi, formatterApi, selectApi } from "parse-api";
import dataExplorer from "parse-api/data-explorer";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { AiOutlineSave, AiOutlineSearch } from "react-icons/ai";
import { BiEditAlt, BiExport, BiMinusCircle, BiTrashAlt } from "react-icons/bi";
import { GrAscend, GrDescend } from "react-icons/gr";
import { MdAdd, MdArrowBack, MdDownload, MdOutlineFilterList, MdOutlinePublic, MdSettings, MdUpload } from "react-icons/md";
import ReactJson from "react-json-view";
import { useDispatch } from "react-redux";
import {
  compare,
  compressJsonData,
  displayError,
  getArrayElementByAttribute,
  log,
  momentDate,
  momentTime,
} from "util/algorithm";
import { getBase64, saveBase64 } from "util/export";
import IntlMessages from "util/IntlMessages";
import { SortableTable } from "../../../components/Form";
import { DEFAULT_SETTINGS } from "../../../parse-api/data-explorer";
import { dateMoment, dateText, equals, getFlowKey, getFormType, getText, isValidUrl, momentTimezoneDate, prepareText, runScript, uid } from "../../../util/algorithm";
import { convertSelectOptionLabel, createFormatter, getRender, JsonArrayEditor, JsonObjectEditor, MyQuillEditor, MySwitch, SfpSelect } from "../FormSetup/components";
import { settingsSignal } from "../../../util/signal";
import { getRequestContext } from "../../../parse-api/config";
import { FrontendApi } from "../../../util/frontendApi";

const dfo = (input, option) => {
  return getText(option).toLowerCase().indexOf(input.toLowerCase()) >= 0;
}

const detectDataTypeRender = (value) => {
  let type = undefined;
  let parsed = value;
  if (value) {
    let json = null;
    try {
      json = JSON.parse(value)
    } catch (e) {
      // ignore error
    }
    if (json) {
      if (Array.isArray(json)) {
        type = 'json[]';
      } else {
        type = 'json';
      }
      parsed = json
    } else {
      const url = isValidUrl(value)
      if (url) {
        type = 'url';
        parsed = url;
      }
    }
  }
  return {type, parsed}
}

const jsonTextOnChange = (value, onChange) => {
  if (onChange) {
    if (value) {
      return onChange(JSON.stringify(value));
    } else {
      return onChange(value);
    }
  }
}

export const InputNode = ({ value, onChange, simpleOptions, dataTypeRender, readOnly }) => {
  if (simpleOptions) {
    return (
      <Select
        value={value}
        options={simpleOptions}
        onChange={onChange}
        allowClear
        showSearch
      />
    );
  } else {
    let detected = {}
    if (dataTypeRender === 'auto') {
      detected = detectDataTypeRender(value);
      dataTypeRender = detected.type;
      value = detected.parsed;
    }
    if (dataTypeRender === 'textarea') {
      return <Input.TextArea value={value} onChange={onChange} readOnly={readOnly} autoSize />;
    } else if (dataTypeRender === 'htmltext') {
      return <MyQuillEditor value={value} onChange={onChange} readOnly={readOnly} autoSize />;
    } else if (dataTypeRender === 'json') {
      return <JsonObjectEditor value={value} onChange={(v) => jsonTextOnChange(v, onChange)} readOnly={readOnly} autoSize />;
    } else if (dataTypeRender === 'json[]') {
      return <JsonArrayEditor value={value} onChange={(v) => jsonTextOnChange(v, onChange)} readOnly={readOnly} autoSize />;
    } else if (dataTypeRender === 'password') {
      return <Input.Password value={value} onChange={onChange} readOnly={readOnly} />;
    } else {
      return <Input value={value} onChange={onChange} readOnly={readOnly} />;
    }
  }
};

export const InputNumberNode = ({ value, onChange }) => {
  return <InputNumber value={value} onChange={onChange} />;
};

export const Relation = ({ title, onClick }) => {
  return (
    <span className="cell-content clickable">
      <Tag onClick={onClick}>{title}</Tag>
    </span>
  );
};

export const FileNode = ({ value, listType }) => {
  return (
    <Upload
      className="table-col"
      listType={listType}
      fileList={value}
      showUploadList={{ showRemoveIcon: false }}
    ></Upload>
  );
};

export const Base64Node = ({
  value,
  onChange,
  disabled,
  readOnly,
  isTableCell,
}) => {
  const [filename, setFilename] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [base64, setBase64] = useState();
  const mounted = useRef();
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);
  useEffect(() => {
    if (value) {
      const data = JSON.parse(value);
      if (mounted.current) setFilename(data.filename);
      if (mounted.current) setBase64(data.base64);
    } else {
      if (mounted.current) setFilename("");
      if (mounted.current) setBase64(null);
    }
  }, [value]);
  const doUpload = async (file) => {
    if (mounted.current) setIsUploading(true);
    try {
      const content = await getBase64(file);
      if (mounted.current) setFilename(file.name);
      const data = { filename: file.name, base64: content };
      const value = JSON.stringify(data);
      if (onChange) onChange(value);
    } catch (e) {
      log("read file error", e);
    }
    if (mounted.current) setIsUploading(false);
  };
  const doDownload = async () => {
    if (base64 && filename) {
      saveBase64(base64, filename);
    }
  };
  if (isTableCell) {
    return <span>{filename}</span>;
  } else {
    return (
      <div className="widget-dataentry-tool-base64">
        <Input
          className={"filename-download"}
          value={filename}
          readOnly
          disabled={disabled}
          onClick={doDownload}
        />
        {!disabled && !readOnly && (
          <Upload
            className="base64-upload"
            showUploadList={false}
            beforeUpload={doUpload}
          >
            <Button
              loading={isUploading}
              icon={
                <span className="tool-icon">
                  <MdUpload />
                </span>
              }
              className="widget-dataentry-tool-btn"
            />
          </Upload>
        )}
      </div>
    );
  }
};

export const AvatarCell = ({ value }) => {
  return (
    <span className="cell-content">{value && <Avatar src={value} />}</span>
  );
};

export const ImageCell = ({ value, width, height, alt }) => {
  return value ? (
    <span className="cell-content">
      <img
        src={value}
        style={{ width: width, height: height }}
        alt={alt ? alt : "image"}
      />
    </span>
  ) : null;
};

export const ColorCell = ({ value }) => {
  return value ? (
    <span className="cell-content color">
      <div
        title={value}
        style={{ backgroundColor: value }}
      />
    </span>
  ) : null;
};

export const TextNode = ({ value, simpleOptions, dataTypeRender, dataTypeProps }) => {
  let label = value;
  if (simpleOptions) {
    const opt = getArrayElementByAttribute(simpleOptions, "value", value);
    if (opt) {
      label = opt.label;
    }
  }
  let detected = {}
  if (dataTypeRender === 'auto') {
    detected = detectDataTypeRender(value);
    dataTypeRender = detected.type;
    value = detected.parsed;
  }
  if (dataTypeRender === 'textarea') {
    const rows = dataTypeProps?.rows;
    const style = {}
    if (rows > 0) {
      style.maxHeight = (rows + 1)+"rem";
    }
    return (
      <span className="widget-table-cell-textarea" style={style}>
        <Input.TextArea value={value} readOnly autoSize
          bordered={false} />
      </span>
    )
  } else if (dataTypeRender === 'htmltext') {
    return (<div className="ck-content" dangerouslySetInnerHTML={{__html: value}} />)
  } else if (dataTypeRender === 'json') {
    return (<JsonNode value={value} dataType={dataTypeRender} isTableCell/>)
  } else if (dataTypeRender === 'json[]') {
    return (<JsonArrayNode value={value} dataType={dataTypeRender} isTableCell/>)
  } else if (dataTypeRender === 'url') {
    return (<UrlNode value={value}/>)
  } else if (dataTypeRender === 'password') {
    return <span>{"************"}</span>;
  } else {
    return <span>{label}</span>;
  }
};

const FORMATTER = {}

export const TextNumberNode = ({ value, systemFormatter }) => {
  const [systemFormatterData, setSystemFormatterData] = useState(FORMATTER[systemFormatter]);
  const mounted = useRef();
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      if (systemFormatter && !systemFormatterData) {
        const sf = await formatterApi.getSystemFormatter(systemFormatter);
        if (mounted.current) setSystemFormatterData(sf);
        FORMATTER[systemFormatter] = sf;
      }
    };
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [systemFormatter]);
  const displayValue = useMemo(() => {
    if (systemFormatterData) {
      const f = createFormatter(systemFormatterData);
      return f(value);
    } else {
      return value;
    }
  }, [value, systemFormatterData])
  return displayValue;
};

export const ClobNode = ({ value, readOnly }) => {
  if (readOnly) {
    return (
      <span className="cell-content">
        <Tag>CLOB</Tag>
      </span>
    );
  } else {
    return <Input.TextArea rows="5" />;
  }
};

export const DatePickerNode = ({
  value,
  onChange,
  readOnly,
  picker,
  format,
  showTime,
  stringMode,
  withTimezone,
  dataIndex,
  ...props
}) => {
  if (!format) {
    if (dataIndex === 'updatedAt' || dataIndex === 'createdAt') {
      format = "YYYY-MM-DD hh:mm:ss";
    } else {
      format = "YYYY-MM-DD";
    }
  }
  const dateval = dateMoment(value, format, stringMode);
  if (picker === "year") format = "YYYY";
  const onMyChange = (value) => {
    if (value) {
      if (stringMode) {
        value = value.format(format)
      } else if (withTimezone) {
        value = momentTimezoneDate(value, picker)
      } else {
        value = momentDate(value, picker);
      }
    }
    if (onChange) onChange(value);
  };
  if (readOnly) {
    return (
      <span>
        {dateText(value, format)}
      </span>
    );
  } else {
    return (
      <DatePicker
        format={format}
        value={dateval}
        onChange={onMyChange}
        picker={picker}
        {...props}
      />
    );
  }
};

export const TimePickerNode = ({
  value,
  onChange,
  readOnly,
  format,
  stringMode,
  withTimezone,
  ...props
}) => {
  const dateval = dateMoment(value, format, stringMode);
  if (!format) format = "HH:mm:ss";
  const onMyChange = (value) => {
    if (value) {
      if (stringMode) {
        value = value.format(format)
      } else if (withTimezone) {
        value = value.format();
      } else {
        value = momentTime(value, format);
      }
    }
    if (onChange) onChange(value);
  };
  if (readOnly) {
    return (
      <span>
        {dateText(value, format)}
      </span>
    );
  } else {
    return (
      <TimePicker
        format={format}
        value={dateval}
        onChange={onMyChange}
        {...props}
      />
    );
  }
};

export const SwitchNode = ({ value, isTableCell, relatedClass, ...props }) => {
  if (isTableCell) {
    if (value) {
      return (
        <span className="cell-content">
          <MySwitch size="small" checked={true} {...props} />
        </span>
      );
    } else {
      return null;
    }
  } else {
    return <Switch size="small" checked={value} {...props} />;
  }
};

export const PointerArrayNode = ({ ...props }) => {
  return PointerNode({ ...props, multiple: true });
};

export const PointerNode = ({
  isTableCell,
  readOnly,
  relatedClass,
  value,
  onChange,
  multiple,
}) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const mode = multiple ? "multiple" : null;
  const mounted = useRef();
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      if (relatedClass) {
        try {
          if (mounted) setLoading(true);
          const dataClass = relatedClass;
          const list = await dataExplorer.list(dataClass);
          const options = list.sort((a, b) => compare(a.label, b.label));
          if (mounted) setOptions(options);
          if (mounted) setLoading(false);
        } catch (e) {
          log("onEffect relation error", e);
          if (mounted) setLoading(false);
        }
      }
    };
    fetchData();
  }, [relatedClass]);

  const onSelectChange = (newValue) => {
    if (value && !newValue) {
      newValue = null;
    }
    if (onChange) onChange(newValue);
  };

  if (loading) {
    return null;
  } else {
    if (isTableCell) {
      if (value) {
        let label = "";
        if (Array.isArray(value)) {
          for (let i = 0; i < value.length; i++) {
            const val = value[i];
            let lbl = getArrayElementByAttribute(options, "value", val);
            if (!lbl) lbl = value;
            if (lbl) lbl = lbl.label;
            if (i > 0) {
              label = label + ",";
            }
            label = label + lbl;
          }
          return label;
        } else {
          label = getArrayElementByAttribute(options, "value", value);
          if (!label) label = value;
          if (label) label = label.label;
          return label;
        }
      } else {
        return value;
      }
    } else {
      return (
        <Select
          readOnly={readOnly}
          mode={mode}
          value={value}
          options={options}
          onChange={onSelectChange}
          allowClear
          showSearch
        />
      );
    }
  }
};

export const JsonArrayNode = ({ ...props }) => {
  return JsonNode({ ...props, multiple: true });
};

const emptyJson = (multiple) => {
  return multiple ? [] : {}
}

export const UrlNode = ({
  value,
}) => {
  return (
    <span className="cell-content clickable">
      {value && (
        <Button type='link' className='widget-dataentry-link-btn'
          onClick={()=> window.open(value.href)}>
          <Tag className="row-status"><IntlMessages id="system.form.link" text="Link" /> : {value.hostname}</Tag>
        </Button>
      )}
    </span>
  )
}

export const JsonNode = ({
  isTableCell,
  readOnly,
  dataType,
  value,
  onChange,
  multiple,
  dataIndex
}) => {
  const dispatch = useDispatch();
  const [myValue, setMyValue] = useState("");
  const [jsonValue, setJsonValue] = useState(
    value ? value : emptyJson(multiple)
  );
  const ref = useRef();
  const mounted = useRef();
  const { locale } = settingsSignal;
  const lbl = useLbl(locale);
  const getLabel = (dataType) => {
    if (dataType === 'json') {
      return lbl('system.form.object', 'Object');
    } else if (dataType === 'json[]') {
      return lbl('system.form.array', 'Array');
    } else {
      return dataType;
    }
  }
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);
  useEffect(() => {
    if (value) {
      if (multiple) {
        if (Array.isArray(value)) {
          if (mounted.current) setMyValue(JSON.stringify(value, null, 3));
          if (mounted.current) setJsonValue(value);
        } else {
          if (mounted.current) setJsonValue([]);
        }
      } else {
        if (typeof value == "object") {
          if (mounted.current) setMyValue(JSON.stringify(value, null, 3));
          if (mounted.current) setJsonValue(value);
        } else {
          if (mounted.current) setJsonValue({});
        }
      }
    } else {
      if (mounted.current) setMyValue("");
    }
  }, [multiple, value]);
  const onBlur = () => {
    if (onChange) {
      try {
        if (myValue && myValue.length > 0) {
          const value = JSON.parse(myValue);
          if (multiple) {
            if (Array.isArray(value)) {
              onChange(value);
            } else {
              throw new Error("Object is not an array.");
            }
          } else {
            if (!Array.isArray(value)) {
              onChange(value);
            } else {
              throw new Error("Object should not be an array.");
            }
          }
        } else {
          onChange(null);
        }
      } catch (e) {
        message.error(`${e}`);
        ref.current.focus();
      }
    }
  };
  const updateJsonValue = (e) => {
    let newValue = e.updated_src;
    if (mounted) setJsonValue(newValue);
    if (multiple) {
      if (newValue.length === 0) newValue = null;
    } else {
      if (Object.keys(newValue).length === 0) newValue = null;
    }
    if (onChange) onChange(newValue);
  };
  if (isTableCell) {
    if (value?.renderConfig) {
      const i = dataIndex || uid();
      const c = value.renderConfig
      const v = value.dataValue;
      const form = {[i]: v}
      if (v) {
        if (typeof c === 'string') {
          if(c === 'HTML'){
            return <div dangerouslySetInnerHTML = {{ __html: v}}></div>
          } else if(c === 'BUTTON'){
            return <Button onClick={() => dispatch(push(v.url))}>{v.text}</Button>
          } else if(c === 'DATE'){
            const {format, ...props} = c;
            return <div {...props}>{dateText(v, format)}</div>;
          } else{
            log("unknown renderConfig", c);
            return v
          }
        } else if (typeof c === 'object') {
          return getRender(c, form, i)(v, form, i);
        } else {
          return v;
        }
      } else {
        return null;
      }
    } else {
      return (
        <span className="cell-content clickable">
          {value && (
            <Popover trigger="click" content={<ReactJson src={value} />}>
              <Tag className="row-status">{getLabel(dataType)}</Tag>
            </Popover>
          )}
        </span>
      );
    }
  } else {
    return (
      <Tabs>
        <Tabs.TabPane
          tab={<IntlMessages id="system.form.json.normal" text="Normal" />}
          key="Normal"
        >
          <ReactJson
            src={jsonValue}
            onEdit={updateJsonValue}
            onAdd={updateJsonValue}
            onDelete={updateJsonValue}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={<IntlMessages id="system.form.json.advance" text="Advance" />}
          key="Advance"
        >
          <Input.TextArea
            ref={ref}
            readOnly={readOnly}
            value={myValue}
            autoSize
            onChange={(e) => setMyValue(e.target.value)}
            onBlur={onBlur}
          />
        </Tabs.TabPane>
      </Tabs>
    );
  }
};

export const SelectNode = ({
  record,
  value,
  selectkey,
  statusInTable,
  avatarInTable,
  parentkeys,
  isUseCode,
  isHideCode,
}) => {
  return SfpSelect.render({ selectkey, statusInTable, avatarInTable, isUseCode, isHideCode, parentkeys })(value, record);
};

export const inputNodeMapping = ({dataIndex, ...props }) => ({
  number: <InputNumberNode {...props} />,
  string: <InputNode {...props} />,
  clob: <ClobNode {...props} />,
  bool: <SwitchNode {...props} />,
  date: <DatePickerNode {...props} />,
  time: <TimePickerNode {...props} />,
  moment: <DatePickerNode {...props} />,
  relation: <Relation {...props} />,
  pointer: <PointerNode {...props} />,
  "pointer[]": <PointerArrayNode {...props} />,
  "file[]": <FileNode {...props} />,
  base64: <Base64Node {...props} />,
  json: <JsonNode {...props} dataIndex={dataIndex} />,
  file: <JsonNode {...props} />,
  "json[]": <JsonArrayNode {...props} />,
  image: <ImageCell {...props} />,
  avatar: <AvatarCell {...props} />,
});

export const TaskStatusSelect = ({flowKey, isPreview, ...props}) =>  {
  const [tasks,  setTasks] = useState([]);
  const mounted = useRef();
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    }
  }, [])
  useEffect(() => {
    const fetchData = async() => {
      if (flowKey) {
        const flowRecord = await flowApi.getSystemFlow(isPreview, flowKey);
        if (flowRecord?.extraParams?.taskMap) {
          const taskMap = flowRecord.extraParams.taskMap;
          const tasks = Object.keys(taskMap);
          if (mounted.current) setTasks(tasks.map(t=>({value:t, label:taskMap[t].label})).sort((a, b) => compare(a.label, b.label)));
        }
      }
    }
    fetchData();
  }, [flowKey, isPreview])
  return <Select options={tasks} {...props}/>
}

export const viewNodeMapping = {
  number: TextNumberNode,
  string: TextNode,
  clob: ClobNode,
  bool: SwitchNode,
  date: DatePickerNode,
  time: TimePickerNode,
  moment: DatePickerNode,
  relation: Relation,
  pointer: PointerNode,
  "pointer[]": PointerArrayNode,
  "file[]": FileNode,
  base64: Base64Node,
  file: JsonNode,
  json: JsonNode,
  "json[]": JsonArrayNode,
  image: ImageCell,
  avatar: AvatarCell,
  select: SelectNode,
  color: ColorCell,
};

export const RelationModal = ({
  messageApi,
  visible,
  onOk,
  onCancel,
  relation,
  allDataClasses,
}) => {
  const [form] = Form.useForm();
  const [options, setOptions] = useState([]);
  const [label, setLabel] = useState(["Relation"]);
  const mounted = useRef();
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);
  const onFinish = async () => {
    try {
      const ids = form.getFieldValue(["ids"]);
      const dataClass = relation.parentClass;
      const objectId = relation.objectId;
      const dataIndex = relation.dataIndex;
      await dataExplorer.addRelation(dataClass, objectId, dataIndex, ids);
      if (onOk) onOk();
    } catch (e) {
      log("onFinish error", e);
      message.error(displayError(e));
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      if (visible) {
        try {
          const dataClass = relation.relatedClass;
          const DataClassConfig = getArrayElementByAttribute(
            allDataClasses,
            "key",
            dataClass
          );
          const list = await dataExplorer.list(dataClass);
          const options = list.sort((a, b) => compare(a.label, b.label));
          form.setFieldsValue({ ids: [] });
          if (mounted.current) setOptions(options);
          if (mounted.current) setLabel(DataClassConfig.displayName);
        } catch (e) {
          log("onEffect relation error", e);
          message.error(displayError(e));
        }
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);
  return (
    <Modal
      title={<IntlMessages id="system.form.add-relation" text="Add Relation" />}
      className="widget-dataentry-modal"
      visible={visible}
      onOk={onFinish}
      onCancel={onCancel}
    >
      <Form form={form} name={"AddRelation"}>
        <Row>
          <Col span="24">
            <Form.Item name="ids" label={label}>
              <Select
                options={options}
                mode="multiple"
                showArrow
                filterOption={dfo}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

const INTERNALFIELDS = ['objectId']

export const RecordModal = ({
  messageApi,
  visible,
  onOk,
  onCancel,
  dataClass,
  objectId,
  allDataClasses,
}) => {
  const [form] = Form.useForm();
  const [columns, setColumns] = useState([]);
  const mounted = useRef();
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);
  const onFinish = async () => {
    try {
      const values = form.getFieldsValue(true);
      await dataExplorer.save(dataClass, values);
      if (onOk) onOk();
    } catch (e) {
      log("onFinish error", e);
      message.error(displayError(e));
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      if (visible) {
        try {
          let DataClassConfig = getArrayElementByAttribute(
            allDataClasses,
            "key",
            dataClass
          );
          if (!DataClassConfig)
            DataClassConfig = await dataExplorer.getDataClass(dataClass);
          if (DataClassConfig) {
            let columns = DataClassConfig.columns;
            columns = columns.filter((c) => !INTERNALFIELDS.includes(c.dataIndex) && (c.editable || c.required || !c.hiddenForReadOnly));
            if (mounted.current) setColumns(columns);
            const defaultVal = {};
            columns.forEach((c) => {
              defaultVal[c.dataIndex] = null;
            });

            if (objectId) {
              const r = await dataExplorer.get(dataClass, objectId);
              const val = { ...defaultVal, ...r };
              form.resetFields();
              form.setFieldsValue(val);
            } else {
              form.resetFields();
              form.setFieldsValue(defaultVal);
            }
          }
        } catch (e) {
          log("set record value error", e);
          message.error(displayError(e));
        }
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectId, dataClass, visible]);

  return (
    <Modal
      width={600}
      title={
        objectId ? (
          <IntlMessages id="system.form.edit" text="Edit" />
        ) : (
          <IntlMessages id="system.form.add" text="Add" />
        )
      }
      className="widget-dataentry-modal"
      visible={visible}
      onOk={onFinish}
      onCancel={onCancel}
      centered={true}
    >
      <Form form={form} name={"AddEditRecord"} colon={false}>
        <Form.Item name="objectId" hidden>
          <Input />
        </Form.Item>
        {columns.map((col) => {
          const dataIndex = col.dataIndex;
          const readOnly = !(col.editable || (!objectId && col.required));
          const dataType = col.type;
          const dataTypeRender = col.typeRender;
          const title = col.title;
          const relatedClass = col.relatedClass;
          const picker = col.picker;
          const simpleOptions = col.simpleOptions;
          return (
            <Form.Item
              key={col.dataIndex}
              name={col.dataIndex}
              label={col.title}
              labelCol={{ span: 6 }}
            >
              {
                inputNodeMapping({
                  dataType,
                  dataTypeRender,
                  simpleOptions,
                  readOnly,
                  title,
                  relatedClass,
                  allDataClasses,
                  picker,
                  dataIndex,
                })[col.type]
              }
            </Form.Item>
          );
        })}
      </Form>
    </Modal>
  );
};

export const DEFAULT_COLUMNS = [
  {
    dataIndex:'placeholder',
    title: '...'
  }
]

const InputNumberRenderer = ({value, onChange, editInTable, tableColWidth, min, max, step, disabled}) => {
  const [editing, setEditing] = useState(false);
  const [myValue, setMyValue] = useState(value);
  const leditInTable = editInTable && !disabled;
  const ref = useRef();
  const mounted = useRef();
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    }
  }, [])
  useEffect(() => {
    setMyValue(value);
  }, [value]);
  const onClick = (event) => {
    setEditing(true);
    event.preventDefault();
    event.stopPropagation();
  }
  if (editing) {
    const onMyChange = (value) => {
      try {
        setMyValue(value);
      } catch (e) {
        console.error(e);
      }
    }
    const onBlur = () => {
      onChange(myValue);
    }
    const onKeyDown = (e) => {
      if (e.keyCode === 13) {
        e.preventDefault();
        e.stopPropagation();
        ref.current.blur();
      }
    }
    const controls = true;
    const className = "row-editing table-cell-inputnumber"
    const props = {value: myValue, className, onBlur, onChange: onMyChange, ref, onKeyDown, min, max, step, controls};
    if (tableColWidth) props.style = {"minWidth": `${tableColWidth}px`,"maxWidth": `${tableColWidth}px`}
    return <InputNumber autoFocus {...props} />
  } else {
    const editingProps = leditInTable ? {className: "row-editable table-cell-inputnumber", onClick} : {};
    if (tableColWidth) editingProps.style = {"minWidth": `${tableColWidth}px`, "maxWidth": `${tableColWidth}px`}
    let displayValue = myValue;
    if (displayValue) displayValue = Number(displayValue);
    if (isNaN(displayValue)) displayValue = myValue;
    return <span {...editingProps}>{displayValue}</span>;
  }
}

const prepareSettingsModalActionCol = ({lbl}) => {
  return {
    title: lbl('system.form.action', 'Action'),
    dataIndex: 'action',
    width: 20,
  };
}

export const SettingsModal = ({
  visible,
  onOk,
  onCancel,
  isPreview,
  dataClass,
  readOnly,
  allDataClasses,
}) => {
  const mounted = useRef();
  const [dataColumns, setDataColumns] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [indexes, setIndexes] = useState({});
  const [defaultSettings, setDefaultSettings] = useState(DEFAULT_SETTINGS);
  const [settings, setSettings] = useState(DEFAULT_SETTINGS);
  const [reSet, setReSet] = useState(false);
  const { locale } = settingsSignal;
  const lbl = useLbl(locale);

  const onValueChanged = (type, value) => {
    const newSettings = {...settings};
    newSettings[type] = value;
    if (type === 'dataUpload' && value) {
        newSettings.dataDownload = true;
    }
    if (type === 'dataDownload' && !value) {
        newSettings.dataUpload = false;
    }
    if (mounted.current) setSettings(newSettings);
  }
  const attrs = ["hidden", "hiddenInMobile", "nosort", "nosearch", "defaultAscending", "defaultDescending", "index", "width"];
  const SKIPPED_COLUMNS = ["flow"];
  const getDataSource = (dataSource, settings, needSort) => {
    setReSet(false);
    const newDataSource = dataSource.filter(d => SKIPPED_COLUMNS.indexOf(d.dataIndex) === -1).map(d => ({...d}));
    newDataSource.forEach(d => {
      const k = d.dataIndex;
      attrs.forEach(attr => {
        if (!settings[attr]) settings[attr] = {};
        d[attr] = settings[attr][k];
      })
    });
    if (needSort || settings.isReset) {
      const sortedDataSource = newDataSource.sort((a, b) => compare(a.index, b.index));
      return sortedDataSource;
    } else {
      return newDataSource;
    }
  }
  const onSwitchChanged = (value, type, record) => {
    if (mounted.current) {
      const newSettings = {...settings};
      if (type === 'defaultAscending' || type === 'defaultDescending') {
        newSettings.defaultAscending = {};
        newSettings.defaultDescending = {};
        if (!value) {
          newSettings.defaultDescending = {"updatedAt":true};
          newSettings.sort = "updatedAt";
          newSettings.descending = true;
        } else {
          if (type === 'defaultAscending') {
            newSettings.sort = record.dataIndex;
            newSettings.descending = false;
          }
          if (type === 'defaultDescending') {
            newSettings.sort = record.dataIndex;
            newSettings.descending = true;
          }
          newSettings['nosort'][record.dataIndex] = false;
        }
      }
      if (!newSettings['hidden']) newSettings['hidden'] = {};
      if (!newSettings['hiddenInMobile']) newSettings['hiddenInMobile'] = {};
      if (!newSettings['nosearch']) newSettings['nosearch'] = {};
      if (!newSettings['width']) newSettings['width'] = {};
      newSettings[type][record.dataIndex] = value;
      newSettings.index = indexes;
      setSettings(newSettings);
      setIndexes(newSettings.index);
    }
  };
  const getRender = (type) => (value, record) => {
    if (type === 'title') {
      return <span className="title-max-width" title={value}>{value}</span>
    } else if (type === 'width') {
      const props = {min: 50, value, onChange: (value) => onSwitchChanged(value, type, record), editInTable: true}
      return <InputNumberRenderer {...props}/>
    } else if (type === 'hiddenInMobile') {
      const disabled = record.hidden
      return <Switch disabled={disabled} checked={record.hidden || !!value} onChange={(value) => onSwitchChanged(value, type, record)}/>
    } else if (type === 'nosearch') {
      const disabled = !record.isSearchable
      return <Switch disabled={disabled} checked={record.isSearchable && !!value} onChange={(value) => onSwitchChanged(value, type, record)}/>
    } else {
      return <Switch checked={!!value} onChange={(value) => onSwitchChanged(value, type, record)}/>
    }
  }
  const nonUserEditable = ["nosort", "nosearch"]
  const columns = [
    {
      title: lbl("system.form.settings.title", "Title"),
      dataIndex: "title",
      className: "drag-visible",
      render: getRender('title'),
    },
    {
      title: lbl("system.form.settings.hidden", "Hidden"),
      dataIndex: "hidden",
      width: 100,
      render: getRender('hidden'),
    },
    {
      title: lbl("system.form.settings.hidden_in_mobile", "Hidden in mobile"),
      dataIndex: "hiddenInMobile",
      width: 100,
      render: getRender('hiddenInMobile'),
    },
    {
      title: lbl("system.form.settings.no_search", "No Search"),
      dataIndex: "nosearch",
      width: 100,
      render: getRender('nosearch'),
    },
    {
      title: lbl("system.form.settings.no_sort", "No Sort"),
      dataIndex: "nosort",
      width: 100,
      render: getRender('nosort'),
    },
    {
      title: lbl("system.form.settings.ascending", "Ascending"),
      dataIndex: "defaultAscending",
      width: 100,
      render: getRender('defaultAscending'),
    },
    {
      title: lbl("system.form.settings.descending", "Descending"),
      dataIndex: "defaultDescending",
      width: 100,
      render: getRender('defaultDescending'),
    },
    {
      title: lbl("system.form.settings.width", "Width"),
      dataIndex: "width",
      width: 100,
      render: getRender('width'),
    },
  ].filter(c => isPreview || nonUserEditable.indexOf(c.dataIndex) === -1);

  const onSort = (sortedDataSource, indexes) => {
    if (mounted.current) setIndexes(indexes);
  }
  const onReset = async () => {
    let DataClassConfig = getArrayElementByAttribute(
      allDataClasses,
      "key",
      dataClass
    );
    if (!DataClassConfig)
      DataClassConfig = await dataExplorer.getDataClass(dataClass);
    if (DataClassConfig) {
      const actionColumn = prepareSettingsModalActionCol({lbl});
      const columns = [actionColumn, ...DataClassConfig.columns];
      const dataSource = [];
      for (let i = 0; i < columns.length; i++) {
        const row = {...columns[i]};
        row.key = row.dataIndex;
        row.index = i;
        if (row.dataIndex === 'updatedAt') {
          row.defaultDescending = true;
        }
        row.isSearchable = DataClassConfig.advanceSearchIndex?.indexOf(row.key) !== -1;
        if (row.tableColTitle) row.title = row.tableColTitle;
        dataSource.push(row);
      }
      const list = await dataExplorer.getAllSearchResult(dataClass, false);
      const systemSettings = getArrayElementByAttribute(list, "name", "systemSettings");
      const userSettings = getArrayElementByAttribute(list, "name", "userSettings");
      console.log('SettingsModal reset', {systemSettings, userSettings})
      let savedSettings = null;
      let systemSettingsData = null;
      if (systemSettings) {
        savedSettings = systemSettings;
        systemSettingsData = JSON.parse(systemSettings.value);
      }
      if (!isPreview && (systemSettingsData?.userSettings) && userSettings) {
        savedSettings = userSettings;
      }
      if (savedSettings) {
        const json = savedSettings.value;
        const newSettings = JSON.parse(json);
        if (systemSettingsData) {
          // ensure userSetting will not violate system system
          newSettings.userSettings = systemSettingsData.userSettings;
          newSettings.nosort = systemSettingsData.nosort;
          newSettings.nosearch = systemSettingsData.nosearch;
          newSettings.dataUpload = systemSettingsData.dataUpload;
          newSettings.dataDownload = systemSettingsData.dataDownload;
          newSettings.keepLastSearch = systemSettingsData.keepLastSearch;
          newSettings.index = systemSettingsData.index;
          newSettings.hidden = systemSettingsData.hidden;
          newSettings.hiddenInMobile = systemSettingsData.hiddenInMobile;
          newSettings.width = systemSettingsData.width;
        }
        setReSet(true);
        if (mounted.current) setSettings(newSettings);
        if (mounted.current) setIndexes(newSettings.index);
        if (mounted.current) setDataSource(getDataSource(dataSource, newSettings, true));
      } else {
        setReSet(true);
        if (mounted.current) setSettings(defaultSettings);
        if (mounted.current) setIndexes(defaultSettings.index);
        if (mounted.current) setDataSource(getDataSource(dataSource, defaultSettings, true));
      }
    }
  }
  const onFinish = async () => {
    try {
      const list = await dataExplorer.getAllSearchResult(dataClass, false);
      const systemSettings = getArrayElementByAttribute(list, "name", "systemSettings");

      const newSettings = {...settings};
      newSettings.index = indexes;
      dataColumns.forEach(c => {
        if (newSettings.defaultAscending[c.dataIndex]) {
          newSettings.sort = c.dataIndex;
          newSettings.descending = false;
        } else if (newSettings.defaultDescending[c.dataIndex]) {
          newSettings.sort = c.dataIndex;
          newSettings.descending = true;
        }
      })
      const json = JSON.stringify(newSettings)
      const name = isPreview ? "systemSettings" : "userSettings";
      if (!isPreview && json === systemSettings?.value) {
        await dataExplorer.deleteSearchResult(dataClass, name);
      } else {
        await dataExplorer.saveSearchResult(dataClass, name, json);
      }
      await dataExplorer.getAllSearchResult(dataClass, true);
      if (onOk) onOk(newSettings);
    } catch (e) {
      log("onFinish error", e);
      message.error(displayError(e));
    }
  };
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);
  useEffect(() => {
    if (!reSet) {
      const newSettings = {...settings};
      let newDataSource = null;
      if (settings.isReset) {
        newDataSource = getDataSource(dataSource, newSettings, false);
      } else {
        const needSort = !equals(indexes, settings.index);
        newSettings.index = indexes;
        newDataSource = getDataSource(dataSource, newSettings, needSort);
      }
      if (!equals(dataSource, newDataSource)) {
        setDataSource(newDataSource);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexes, settings])
  useEffect(() => {
    const fetchData = async () => {
      if (visible) {
        try {
          let DataClassConfig = getArrayElementByAttribute(
            allDataClasses,
            "key",
            dataClass
          );
          if (!DataClassConfig)
            DataClassConfig = await dataExplorer.getDataClass(dataClass);
          if (DataClassConfig) {
            const actionColumn = prepareSettingsModalActionCol({lbl});
            const columns = [actionColumn, ...DataClassConfig.columns];
            setDataColumns(columns);
            const dataSource = [];
            for (let i = 0; i < columns.length; i++) {
              const row = {...columns[i]};
              row.key = row.dataIndex;
              row.index = i;
              if (row.dataIndex === 'updatedAt') {
                row.defaultDescending = true;
              }
              row.isSearchable = DataClassConfig.advanceSearchIndex?.indexOf(row.key) !== -1;
              if (row.tableColTitle) row.title = row.tableColTitle;
              dataSource.push(row);
            }
            const data = await dataExplorer.deduceSearchTableSettings(dataClass, isPreview, columns);
            if (data.defaultSettings !== DEFAULT_SETTINGS) {
              setDefaultSettings(data.defaultSettings);
            }
            if (mounted.current) setSettings(data.newSettings);
            if (mounted.current) setIndexes(data.newSettings.index);
            if (mounted.current) setDataSource(getDataSource(dataSource, data.newSettings, true));
          }
        } catch (e) {
          log("set record value error", e);
          message.error(displayError(e));
        }
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataClass, visible]);

  const generateSetting = (key, label, labelKey, type) => {
    return <Space size={"small"}>
      &nbsp;
      <IntlMessages id={labelKey} text={label} />
      {(!type || type === 'switch') && <Switch checked={!!settings[key]} onChange={(value)=>onValueChanged(key, value)}/>}
      {(type === 'number') && <InputNumber value={settings[key]} defaultValue={10} onChange={(value)=>onValueChanged(key, value)}/>}
    </Space>;
  }

  return (
    <Modal
      width={1000}
      title={<IntlMessages id="system.form.settings" text="Settings" />}
      className="widget-dataentry-modal"
      visible={visible}
      onCancel={onCancel}
      centered
      footer={[
          <Button key="reset" onClick={onReset}>
            <IntlMessages id="system.form.reset" text="Reset"/>
          </Button>,
          <Button key="cancel" onClick={onCancel}>
            <IntlMessages id="system.form.cancel" text="Cancel"/>
          </Button>,
          <Button key="save" type="primary" onClick={onFinish}>
            <IntlMessages id="system.form.save" text="Save"/>
          </Button>,
        ]}
    >
        {isPreview && <Space size="large" wrap>
          {isPreview && !readOnly && generateSetting("dataUpload", "Data Upload", "system.form.data-upload")}
          {isPreview && generateSetting("dataDownload", "Data Download", "system.form.data-download")}
          {isPreview && generateSetting("keepLastSearch", "Save State", "system.form.keep-last-search")}
          {isPreview && generateSetting("userSettings", "User Settings", "system.form.user-settings")}
        </Space>}
        <p/>
        <SortableTable
          bordered size="small"
          dataSource={dataSource}
          columns={columns}
          scroll={{x: 'max-content'}}
          onSort={onSort}
        />
    </Modal>
  );
};

const operatorRequired = ({ columns, form, parentName, name }) => {
  const values = form.getFieldValue(["value", parentName, "value", name]);
  if (values?.key) {
    const key = values.key;
    const col = getArrayElementByAttribute(columns, "dataIndex", key);
    if (col) {
      return true;
    }
  }
  return false;
};

const valueRequired = ({ columns, form, parentName, name }) => {
  return false;
};

const MySelect = ({
  className,
  selectkey,
  filter,
  isUseCode,
  isHideCode,
  style,
  value,
  onChange,
  parentkeys,
  parentValues,
  mode,
}) => {
  const { locale } = settingsSignal;
  const [options, setOptions] = useState([]);
  const mounted = useRef();
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      let list = [];
      if (parentkeys && parentkeys.length > 0) {
        const parentOptionKey = await selectApi.getParentOptionKey(
          locale,
          selectkey,
          isUseCode,
          parentValues
        );
        if (parentOptionKey) {
          list = await selectApi.fetchSystemSelectOption(
            locale,
            selectkey,
            isUseCode,
            parentOptionKey
          );
        }
      } else {
        list = await selectApi.fetchSystemSelectOption(
          locale,
          selectkey,
          isUseCode
        );
      }
      if (filter) list = list.filter((l) => l.optionType === filter);
      list = list.map((l) => ({ value: l.value, label: convertSelectOptionLabel(l.label,isUseCode,isHideCode) }));
      if (mounted.current) setOptions(list);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectkey, isUseCode, isHideCode, parentkeys, parentValues]);
  return (
    <Select
      className={className}
      style={style}
      value={value}
      onChange={onChange}
      options={options}
      showSearch
      allowClear
      filterOption={dfo}
      mode={mode}
    />
  );
};

const SELECT_OPERATORS = ["=", "!=", "in", "missing", "has"];
const DATE_OPERATORS = ["=", "!=", ">", "<", ">=", "<=", "missing", "has"];
const NUMBER_OPERATORS = [
  "=",
  "!=",
  ">",
  "<",
  ">=",
  "<=",
  "missing",
  "has",
  "in",
];
const BOOLEAN_OPERATORS = ["="];

const JSON_OPERATORS = ["=", "!=", "matches", "missing"];

const OperatorField = ({
  columns,
  form,
  parentName,
  name,
  options,
  value,
  placeholder,
  onChange,
}) => {
  const values = form.getFieldValue(["value", parentName, "value", name]);
  const keyRef = useRef();
  if (values && values.key) {
    const key = values.key;
    if (keyRef.current && keyRef.current !== key) {
      if (onChange && value) onChange(null);
    }
    keyRef.current = key;
    const col = getArrayElementByAttribute(columns, "dataIndex", key);
    let list = null;
    if (col) {
      list = options;
      if (col.type === "select") {
        list = list.filter((l) => SELECT_OPERATORS.indexOf(l.value) !== -1);
      } else if (col.type === "date") {
        list = list.filter((l) => DATE_OPERATORS.indexOf(l.value) !== -1);
      } else if (col.type === "number") {
        list = list.filter((l) => NUMBER_OPERATORS.indexOf(l.value) !== -1);
      } else if (col.type === "bool") {
        list = list.filter((l) => BOOLEAN_OPERATORS.indexOf(l.value) !== -1);
      } else if (col.type === 'json') {
        list = list.filter((l) => JSON_OPERATORS.indexOf(l.value) !== -1);
      }
    } else if (key === 'flow.task' || key === 'flow.assignee') {
      list = options;
      list = list.filter((l) => ["="].indexOf(l.value) !== -1);
    } else if (key === 'flow.candidates' || key === 'flow.others') {
      list = options;
      list = list.filter((l) => ["in"].indexOf(l.value) !== -1);
    } else if (key === 'flow.dueDate') {
      list = options;
      list = list.filter((l) => DATE_OPERATORS.indexOf(l.value) !== -1);
    }
    if (list) {
      return (
        <Select
          style={{ width: 150 }}
          options={list}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
        />
      );
    }
  }
  return null;
};

const ValueField = ({
  columns,
  form,
  formKey,
  parentName,
  name,
  value,
  onChange,
  placeholder,
}) => {
  const values = form.getFieldValue(["value", parentName, "value", name]);
  const keyRef = useRef();
  const operatorRef = useRef();
  if (values && values.key && values.operator) {
    const key = values.key;
    const operator = values.operator;
    if ((keyRef.current && keyRef.current !== key) || (operatorRef.current && operatorRef.current !== operator)) {
      if (onChange && value) onChange(null);
    }
    keyRef.current = key;
    operatorRef.current = operator;
    const col = getArrayElementByAttribute(columns, "dataIndex", key);
    if (col) {
      if (values.operator === "has" || values.operator === "missing") {
        return null;
      } else if (values.name && values.label && formKey) {
        return (
          <Space className="row-status-container">
            <Tooltip title={values.value}>
              <Tag className="row-status">
                <IntlMessages id={`form.${formKey}.savedsearch.${values.name}`} text={values.label}/>
              </Tag>
            </Tooltip>
          </Space>
        ) ;
      } else if (values.operator === "matches" || (values.operator === "in" && col.type !== "select")) {
        return (
          <Input
            className="advanced-search-value-feild"
            value={value}
            onChange={onChange}
            placeholder={placeholder}
          />
        );
      } else {
        if (col.type === "string" || col.type === "json") {
          return (
            <Input
              className="advanced-search-value-feild"
              value={value}
              onChange={onChange}
              placeholder={placeholder}
            />
          );
        } else if (col.type === "number") {
          return (
            <InputNumber
            className="advanced-search-value-feild"
              value={value}
              onChange={onChange}
              placeholder={placeholder}
            />
          );
        } else if (col.type === "date") {
          return (
            <DatePickerNode
              value={value}
              onChange={onChange}
              picker={col.picker}
              showTime={col.showTime}
              format={col.format}
              stringMode={col.stringMode}
              showNow={col.showNow}
              withTimezone={col.withTimezone}
              placeholder={placeholder}
            />
          );
        } else if (col.type === "time") {
          return (
            <TimePickerNode
              value={value}
              onChange={onChange}
              placeholder={placeholder}
            />
          );
        } else if (col.type === "select") {
          let parentValues = null;
          if (col.parentkeys && col.parentkeys.length > 0) {
            parentValues = [];
            const parent = form.getFieldValue(["value", parentName])?.value;
            for (let i = 0; i < col.parentkeys.length; i++) {
              parentValues[i] = null;
              const parentKey = col.parentkeys[i];
              if (Array.isArray(parent)) {
                for (const element of parent) {
                  if (
                    element &&
                    element.key === parentKey &&
                    element.operator === "="
                  ) {
                    parentValues[i] = element.value;
                    break;
                  }
                }
              }
            }
          }
          return (
            <MySelect
              className="advanced-search-value-feild"
              value={values.operator === "in" ? (value || []) : value}
              onChange={onChange}
              selectkey={col.selectkey}
              filter={col.filter}
              parentkeys={col.parentkeys}
              parentValues={parentValues}
              isUseCode={col.isUseCode}
              isHideCode={col.isHideCode}
              placeholder={placeholder}
              mode={values.operator === "in" ? 'multiple' : undefined}
            />
          );
        } else if (col.type === "bool") {
          return <Select
            className="advanced-search-value-feild"
            value={value}
            onChange={onChange}
          >
            <Select.Option value={true}>True</Select.Option>
            <Select.Option value={false}>False</Select.Option>
          </Select>
        }
      }
    } else if (key === 'flow.task') {
      const flowKey = getFlowKey()
      const isPreview = getFormType() === 'preview';
      return <TaskStatusSelect
        style={{ width: 300 }}
        value={value}
        onChange={onChange}
        flowKey={flowKey} isPreview={isPreview}/>
    } else if (key === 'flow.assignee') {
          return (
            <MySelect
              style={{ width: 300 }}
              value={value}
              onChange={onChange}
              selectkey={'User'}
              isUseCode={true}
            />
          );
    } else if (key === 'flow.candidates' || key === 'flow.others') {
          return (
            <MySelect
              style={{ width: 300 }}
              value={value || []}
              onChange={onChange}
              selectkey={'User'}
              isUseCode={true}
              mode={'multiple'}
            />
          );
    } else if (key === 'flow.dueDate') {
          return (
            <DatePickerNode
              value={value}
              onChange={onChange}
              placeholder={placeholder}
            />
          );
    }
  }
  return null;
};

const isAdvvanceSearchParam = (params) => {
  if (params) {
    if (Array.isArray(params)) {
      if (!isEmpty(params)) {
        if (params[0].operator === 'or') {
          return true;
        }
      }
    }
  }
  return false;
}

export const SearchPanelToolbar = ({
  dataClassConfig, dataKey, onDataClassChange, selectedDataClass, options, relation,
  allDataClasses, currentPathRef, computeDisplayMode, searchTextInputRef, isRelation,
  searchText, setSearchText, lbl, setDescending, settings, doExtExport, readOnly,
  advanceSearch, sort, sortOptions, onSortChange, descending, isForm, doExtAdd,
  pagination, isPreview, doOpenAdvanceSearch, disabled, doTriggerSaveSearch,
  doUpdateSettings, doBack, doAdd, doDownload, doExport, isUploading, isDownloading,
  onSearch, isExporting, doUpload, formKey, doPublish
}) => {
  const [mySearchText, setMySearchText] = useState("");
  const timerRef = useRef();
  const initializedRef = useRef();
  const isSyncingRef = useRef();

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
    const isFocus = document.activeElement === searchTextInputRef.current?.input;
    if (!isFocus) {
      isSyncingRef.current = false;
    } else {
      timerRef.current = setTimeout(() => {
        setSearchText(mySearchText);
        timerRef.current = null;
      }, 1000);
    }
  }, [mySearchText, searchTextInputRef, setSearchText])

  useEffect(() => {
    const isFocus = document.activeElement === searchTextInputRef.current?.input;
    console.log('SearchPanelToolbar', {isFocus, searchText, initializedRef, mySearchText})
    if (!isEmpty(searchText) && !initializedRef.current) {
      if (isEmpty(mySearchText)) {
        setMySearchText(searchText);
        isSyncingRef.current = true;
      }
      initializedRef.current = true;
    } else if (initializedRef.current && !isFocus && !timerRef.current && searchText !== mySearchText) {
      isSyncingRef.current = true;
      setMySearchText(searchText);
    }
  }, [searchText, mySearchText, searchTextInputRef])
  return (
    <div className="widget-dataentry-toolbar">
      <Space>
        {!dataClassConfig && !dataKey && <Select placeholder={<IntlMessages id="system.data-enty.select-a-class" text="Select a Class"/>} className="widget-dataentry-tool-field search-box" onChange={onDataClassChange} allowClear showSearch value={selectedDataClass?.key} filterOption={dfo}>
          {options.map(option => {
            let label = option.label;
            const rel = relation.length > 0 ? relation[relation.length - 1] : null;
            let tips = null;
            if (rel && option.value === selectedDataClass.key) {
              const ParentClass = getArrayElementByAttribute(allDataClasses, "key", rel.parentClass);
              if (ParentClass?.labelPattern && rel.record) label = ParentClass.displayName + " " + prepareText(ParentClass.labelPattern, rel.record);
              relation.forEach(r => {
                label = label + " / " + r.relatedClass;
              })
              currentPathRef.current = label;
            } else if (!rel && option.value === selectedDataClass?.key) {
              currentPathRef.current = label;
              tips = selectedDataClass.key;
            }
            return <Select.Option key={option.value} value={option.value}><Tooltip mouseEnterDelay={1} title={tips}>{label}</Tooltip></Select.Option>
          })}
        </Select>}
        {computeDisplayMode.simplesearch &&
          selectedDataClass &&
          selectedDataClass.simpleSearchIndex &&
          selectedDataClass.simpleSearchIndex.length > 0 &&
          <Input.Search ref={searchTextInputRef} disabled={isRelation} value={mySearchText} onChange={(e) => setMySearchText(e.target.value)} className={"widget-dataentry-tool-field simple-search " + (advanceSearch ? " hidden " : "") + (!dataClassConfig && !dataKey ? " data-class-visable " : "")} onSearch={onSearch} />}
        <Select placeholder={lbl("system.form.sort-by", "Sort By")} className={"widget-dataentry-tool-field sort-by "+((advanceSearch && computeDisplayMode.simplesearch) ? "hidden" : "")} value={sort} options={sortOptions} onChange={onSortChange} style={{ width: 150 }} allowClear />
        <Tooltip mouseEnterDelay={1} title={lbl('system.form.sort-direction', 'Sort Direction')}><Button onClick={()=>setDescending(!descending)} icon={<span className="tool-icon">{descending ? <GrDescend/> : <GrAscend/>}</span>} className="widget-dataentry-tool-btn sort"></Button></Tooltip>
      </Space>
      <div style={{"marginLeft":"auto"}}></div>
      {settings.dataDownload && computeDisplayMode.download && <Tooltip mouseEnterDelay={1} title={lbl('system.form.download', 'Download')}><Button disabled={!selectedDataClass || disabled} onClick={doDownload} loading={isDownloading} icon={<span className="tool-icon"><MdDownload /></span>} className="widget-dataentry-tool-btn download" /></Tooltip>}
      {doExtExport && computeDisplayMode.export && <Tooltip mouseEnterDelay={1} title={lbl('system.form.export', 'Export')}><Button disabled={!selectedDataClass || disabled} onClick={doExport} loading={isExporting} icon={<span className="tool-icon"><BiExport /></span>} className="widget-dataentry-tool-btn export" /></Tooltip>}
      {!readOnly && !isRelation && settings.dataUpload && computeDisplayMode.upload && <Tooltip mouseEnterDelay={1} title={lbl('system.form.upload', 'Upload')}><Upload showUploadList={false} beforeUpload={doUpload}><Button disabled={!selectedDataClass || disabled} loading={isUploading} icon={<span className="tool-icon"><MdUpload /></span>} className="widget-dataentry-tool-btn upload" /></Upload></Tooltip>}
      {!isForm && (!readOnly || isRelation) && computeDisplayMode.add && <Tooltip mouseEnterDelay={1} title={lbl('system.form.add', 'Add')}><Button disabled={!selectedDataClass || disabled} onClick={doAdd} icon={<span className="tool-icon"><MdAdd /></span>} className="widget-dataentry-tool-btn add" /></Tooltip>}
      {doExtAdd && computeDisplayMode.add && <Tooltip mouseEnterDelay={1} title={lbl('system.form.add', 'Add')}><Button disabled={!selectedDataClass || disabled} onClick={(e) => {
          doExtAdd(e, pagination)
        }} icon={<span className="tool-icon"><MdAdd /></span>} className="widget-dataentry-tool-btn add" /></Tooltip>}
      {!computeDisplayMode.restrictedsearch &&
        selectedDataClass &&
        selectedDataClass.advanceSearchIndex &&
        selectedDataClass.advanceSearchIndex.length > 0 &&
        <Tooltip mouseEnterDelay={1} title={lbl('system.form.filter', 'Filter')}><Button onClick={doOpenAdvanceSearch} style={advanceSearch ? {backgroundColor:"rgba(255,255,255,0.4)"} : null} icon={<span className="tool-icon"><MdOutlineFilterList /></span>} className="widget-dataentry-tool-btn" /></Tooltip>}
      {computeDisplayMode.restrictedsearch &&
        selectedDataClass &&
        selectedDataClass.advanceSearchIndex &&
        selectedDataClass.advanceSearchIndex.length > 0 &&
        <Tooltip mouseEnterDelay={1} title={lbl('system.form.save-search', 'Save Search')}><Button onClick={doTriggerSaveSearch} icon={<span className="tool-icon"><AiOutlineSave /></span>} className="widget-dataentry-tool-btn" /></Tooltip>}
      {!dataClassConfig && <Tooltip mouseEnterDelay={1} title={lbl('system.form.back', 'Back')}><Button disabled={!isRelation || disabled} onClick={doBack} icon={<span className="tool-icon"><MdArrowBack /></span>} className="widget-dataentry-tool-btn" /></Tooltip>}
      {selectedDataClass && (isPreview || settings.userSettings || !isForm) && computeDisplayMode.settings && <Tooltip mouseEnterDelay={1} title={lbl('system.form.settings', 'Settings')}><Button onClick={doUpdateSettings} icon={<span className="tool-icon"><MdSettings /></span>} className="widget-dataentry-tool-btn" /></Tooltip>}
      {formKey && isPreview && <Tooltip mouseEnterDelay={1} title={lbl('system.form.publish', 'Publish')}><Button disabled={disabled} onClick={doPublish} icon={<span className="tool-icon"><MdOutlinePublic /></span>} className="widget-dataentry-tool-btn" /></Tooltip>}
    </div>
  )
}

export const SearchPanel = ({
  onOk,
  onSave,
  dataClass,
  selectedDataClass,
  params,
  allDataClasses,
  settings,
  triggerSave,
  displayMode,
}) => {
  const { locale } = settingsSignal;
  const [columns, setColumns] = useState([]);
  const [keys, setKeys] = useState([]);
  const [savedSearchVisible, setSavedSearchVisible] = useState(false);
  const [form] = Form.useForm();
  const [formKey, setFormKey] = useState();
  const lbl = useLbl(locale);
  const mounted = useRef();
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      let DataClassConfig = selectedDataClass;
      if (!DataClassConfig && dataClass && allDataClasses && allDataClasses.length > 0) {
        DataClassConfig = getArrayElementByAttribute(
          allDataClasses,
          "key",
          dataClass
        );
      }
      try {
        if (!DataClassConfig)
          DataClassConfig = await dataExplorer.getDataClass(dataClass);
        if (DataClassConfig) {
          let columns = DataClassConfig.columns;
          if (DataClassConfig.advanceSearchIndex) columns = columns.filter(
            (c) =>
              DataClassConfig.advanceSearchIndex.indexOf(c.dataIndex) !== -1 &&
              !settings.nosearch?.[c.dataIndex]
          );
          const formKey = DataClassConfig.formKey || dataClass;
          setFormKey(formKey);
          const keys = columns.map((c) => {
            return {
              value: c.dataIndex,
              label: lbl(`form.${formKey}.${c.dataIndex}`, c.title),
            };
          });
          if (getFlowKey()) {
            keys.push({
              value: 'flow.task',
              label: lbl(`system.flow.task`, `Task`),
            });
            keys.push({
              value: 'flow.assignee',
              label: lbl(`system.flow.assignee`, `Assignee`),
            });
            keys.push({
              value: 'flow.candidates',
              label: lbl(`system.flow.candidates`, `Candidates`),
            });
            keys.push({
              value: 'flow.others',
              label: lbl(`system.flow.others`, `Others`),
            });
            keys.push({
              value: 'flow.dueDate',
              label: lbl(`system.flow.dueDate`, `Due Date`),
            })
          }
          if (mounted.current) setColumns(columns);
          if (mounted.current) setKeys(keys);
        }
        if (isAdvvanceSearchParam(params)) {
          form.setFieldsValue(params[0]);
        } else {
          form.setFieldsValue({
            operator: "or",
            value: [{ operator: "and", value: [{}] }],
          });
        }
      } catch (e) {
        log("set record value error", e);
        message.error(displayError(e));
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataClass, allDataClasses, params]);

  useEffect(() => {
    if (triggerSave) {
      doSaveSearch()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerSave])

  const doSearch = () => {
    const values = form.getFieldsValue(true);
    if (onOk) onOk([values]);
  };

  const doSaveSearch = async () => {
    try {
      await form.validateFields();
      setSavedSearchVisible(true);
    } catch (e) {
      log(e);
    }
  };

  const SavedSearchModal = ({ visible, onOk, onCancel }) => {
    const [saveSearchForm] = Form.useForm();
    const initialValues = {
      name: "",
      autoSearch: true,
      openSearchPanel: false,
    };
    const mounted = useRef();
    useEffect(() => {
      mounted.current = true;
      return () => {
        mounted.current = false;
      };
    }, []);
    useEffect(() => {
      if (visible) {
        saveSearchForm.setFieldsValue(initialValues);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible]);
    const onFinish = async () => {
      try {
        const values = form.getFieldsValue(true);
        const compressed = compressJsonData(JSON.stringify(values));
        const saveSearchValues = saveSearchForm.getFieldsValue(true);
        const name = saveSearchValues.name;
        const autoSearch = !!saveSearchValues.autoSearch;
        const openSearchPanel = !!saveSearchValues.openSearchPanel;
        await dataExplorer.saveSearch(
          dataClass,
          name,
          autoSearch,
          openSearchPanel,
          compressed
        );
        if (onOk) onOk();
      } catch (e) {
        log("onFinish error", e);
        message.error(displayError(e));
      }
    };

    return (
      <Modal
        width={600}
        title={<IntlMessages id="" text="Save Search" />}
        className="widget-dataentry-modal"
        visible={visible}
        onOk={() => saveSearchForm.submit()}
        onCancel={onCancel}
      >
        <Form
          form={saveSearchForm}
          name={"SaveSearchForm"}
          colon={false}
          onFinish={onFinish}
        >
          <Form.Item
            name="name"
            label="Name"
            labelCol={{ span: 8 }}
            rules={[
              {
                required: true,
                message: (
                  <IntlMessages
                    id="system.form.saved-search-missing-name"
                    text="Please input a name for this search."
                  />
                ),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="autoSearch"
            label="Auto Search"
            labelCol={{ span: 8 }}
            valuePropName="checked"
            hidden={displayMode?.restrictedsearch}
          >
            <Switch />
          </Form.Item>
          <Form.Item
            name="openSearchPanel"
            label="Open Search Panel"
            labelCol={{ span: 8 }}
            valuePropName="checked"
            hidden={displayMode?.restrictedsearch}
          >
            <Switch />
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  const operators = [
    { value: "=", label: "=" },
    { value: "!=", label: "!=" },
    { value: ">", label: ">" },
    { value: ">=", label: ">=" },
    { value: "<", label: "<" },
    { value: "<=", label: "<=" },
    {
      value: "matches",
      label: lbl("system.form.advance-search.operator.matches", "Matches"),
    },
    {
      value: "missing",
      label: lbl(
        "system.form.advance-search.operator.missing",
        "Missing Value"
      ),
    },
    {
      value: "has",
      label: lbl("system.form.advance-search.operator.has", "Has Value"),
    },
    { value: "in", label: lbl("system.form.advance-search.operator.in", "In") },
  ];

  const AndList = ({
    parentName,
    parentFieldKey,
    parentRemove,
    parentLength,
    ...restField
  }) => {
    return (
      <Form.List name={[parentName, "value"]}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, fieldKey }, index, arr) => (
              <Space
                key={key}
                style={{ display: "flex", marginBottom: 8 }}
                align="baseline"
                wrap
              >
                <Form.Item
                  {...restField}
                  name={[name, "key"]}
                  fieldKey={[fieldKey, "key"]}
                  rules={[
                    {
                      required: true,
                      message: lbl(
                        "system.form.advance-search.missing-field",
                        "Field is missing."
                      ),
                    },
                  ]}
                >
                  <Select
                    className="advanced-search-key-feild"
                    options={keys}
                    placeholder={lbl(
                      "system.form.advance-search.field",
                      "Field"
                    )}
                    filterOption={dfo}
                    allowClear
                    showSearch
                    showArrow
                  />
                </Form.Item>
                <Form.Item shouldUpdate>
                  {() => {
                    return (
                      <Form.Item
                        {...restField}
                        name={[name, "operator"]}
                        fieldKey={[fieldKey, "operator"]}
                        rules={[
                          {
                            required: operatorRequired({
                              columns,
                              form,
                              parentName,
                              name,
                            }),
                            message: lbl(
                              "system.form.advance-search.missing-operator",
                              "Operator is missing."
                            ),
                          },
                        ]}
                      >
                        <OperatorField
                          columns={columns}
                          form={form}
                          parentName={parentName}
                          name={name}
                          options={operators}
                          placeholder={lbl(
                            "system.form.advance-search.operator",
                            "Operator"
                          )}
                        />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
                <Form.Item shouldUpdate>
                  {() => {
                    return (
                      <Form.Item
                        {...restField}
                        name={[name, "value"]}
                        fieldKey={[fieldKey, "value"]}
                        rules={[
                          {
                            required: valueRequired({
                              columns,
                              form,
                              parentName,
                              name,
                            }),
                            message: lbl(
                              "system.form.advance-search.missing-value",
                              "Value is missing."
                            ),
                          },
                        ]}
                      >
                        <ValueField
                          columns={columns}
                          form={form}
                          formKey={formKey}
                          parentName={parentName}
                          name={name}
                          placeholder={lbl(
                            "system.form.advance-search.value",
                            "Value"
                          )}
                        />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
                {(parentLength > 1 || arr.length > 1) && (
                  <BiMinusCircle
                    className={"icon-btn"}
                    onClick={() =>
                      arr.length === 1 ? parentRemove() : remove(name)
                    }
                  />
                )}
              </Space>
            ))}
            <Form.Item>
              <Button
                className="widget-dataentry-advance-add-btn"
                size="small"
                type="dashed"
                onClick={() => add()}
                block
              >
                {lbl("system.form.search.add-and", "Add Row")}
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    );
  };

  return (
    <div>
      <Form form={form} name={"Search"} colon={false} onFinish={doSearch}>
        <Form.Item name="operator" hidden>
          <Input />
        </Form.Item>
        <Form.List name="value">
          {(fields, { add, remove }) => (
            <>
              {fields.map(
                ({ key, name, fieldKey, ...restField }, index, arr) => (
                  <div key={key}>
                    <Form.Item name={[name, "operator"]} hidden>
                      <Input />
                    </Form.Item>
                    <AndList
                      key={key}
                      parentName={name}
                      parentFieldKey={fieldKey}
                      parentRemove={() => remove(name)}
                      parentLength={arr.length}
                      {...restField}
                    />
                  </div>
                )
              )}
              <Form.Item>
                <Button
                  className="widget-dataentry-advance-add-btn"
                  size="small"
                  type="dashed"
                  onClick={() => add({ operator: "and", value: [{}] })}
                  block
                >
                  {lbl("system.form.search.add-or", "Add Group")}
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <div className="widget-dataentry-advance-search-btnbar">
          <Button
            className="widget-dataentry-advance-search-btn first"
            icon={
              <span>
                <AiOutlineSave />
              </span>
            }
            onClick={doSaveSearch}
          />
          <Button
            className="widget-dataentry-advance-search-btn"
            icon={
              <span>
                <AiOutlineSearch />
              </span>
            }
            htmlType="submit"
          />
        </div>
      </Form>
      <SavedSearchModal
        visible={savedSearchVisible}
        onOk={() => {
          setSavedSearchVisible(false);
          if (onSave) onSave();
        }}
        onCancel={() => setSavedSearchVisible(false)}
      />
    </div>
  );
};

export const getActionColumn = ({
  formKey, lbl, getExtAction, doExtEdit, doExtDelete, computeDisplayMode,
  readOnly, isRelation, pagination, doEdit, doDelete,
}) => {
  return {
    title: lbl('system.form.action', 'Action'),
    dataIndex: 'action',
    width: '20px',
    render: (_, record) => {
      let node = null;

      if (getExtAction) node = getExtAction(record, computeDisplayMode.action);

      if (!node && (doExtEdit || doExtDelete)) node = (
        <Space>
          {doExtEdit && computeDisplayMode.edit && <Typography.Link onClick={(e) => doExtEdit(record, e, pagination)}>
            <BiEditAlt />
          </Typography.Link>}
          {doExtDelete && computeDisplayMode.delete && <Popconfirm
            title={lbl("system.dataentry.confirm.delete", "Are you sure to delete this record?")}
            onConfirm={(e) => {
              e?.preventDefault();
              e?.stopPropagation();
              doExtDelete(record)
            }}
            onCancel={(e) => {
              e?.preventDefault();
              e?.stopPropagation();
            }}
            okText={lbl("system.confirm.yes", "Yes")}
            cancelText={lbl("system.confirm.no", "No")}
          >
            <Typography.Link>
              <BiTrashAlt />
            </Typography.Link>
          </Popconfirm>}
        </Space>);

      if (!node && !formKey && !readOnly) node = (
        <Space>
          {!isRelation && <Typography.Link onClick={() => doEdit(record)}>
            <BiEditAlt />
          </Typography.Link>}
          <Popconfirm
            title={lbl("system.dataentry.confirm.delete", "Are you sure to delete this record?")}
            onConfirm={() => doDelete(record)}
            okText={lbl("system.confirm.yes", "Yes")}
            cancelText={lbl("system.confirm.no", "No")}
          >
            <Typography.Link>
              <BiTrashAlt />
            </Typography.Link>
          </Popconfirm>
        </Space>
      );

      return node;
    },
  }
}

export const getExpandIcon = (expandedRowRender, mergedColumns) => {
  if (mergedColumns?.length === 0) {
    return ({record, ...otherProps}) => {
      console.log("otherProps", otherProps)
      return expandedRowRender(record);
    }
  } else {
    return expandIcon;
  }
}

export const headerExpandIcon = ({expandedAll, onExpandAll, mergedColumns}) => {

  if (mergedColumns?.length === 0) {
    return (
      <span className="cell-content summary-header">
        <ProfileOutlined style={{fontSize: "24px", color: "grey"}}/>
      </span>
    )
  } else {
    const onClick = (e) => {
      onExpandAll(!expandedAll);
    }

    const Icon = expandedAll ? MinusSquareOutlined : PlusSquareOutlined;
    return (
      <span className="cell-content clickable" onClick={onClick}>
        <Icon style={{fontSize: "20px", color: "grey"}} onClick={onClick}/>
      </span>
    )
  }
}

export const expandIcon = ({record, expanded, onExpand}) => {
  const onClick = (e) => {
    onExpand(record, e);
    e.stopPropagation();
  }

  const Icon = expanded ? MinusSquareOutlined : PlusSquareOutlined;

  return (
    <span className="cell-content clickable" onClick={onClick}>
      <Icon style={{fontSize: "20px", color: "grey"}} onClick={onClick}/>
    </span>
  )
}

export const filterActionCol = (columns) => {
  if (columns.length > 0 && columns[0]?.dataIndex === 'action' && !columns[0]?.type) {
    return columns.slice(1);
  } else {
    return [...columns];
  }
}

export const runPostExportDataAction = async (dataClass, result, columns) => {
  const actionType = "PostExportDataActionFrontEnd";
  const actions = await dataExplorer.getSearchActionPolicies(dataClass, actionType);
  const requestContext = getRequestContext();
  const isPreview = !!dataClass.match(/^UfxPreview.*$/)
  const resultMap = {result, columns}
  for (const element of actions) {
    const script = element.data;
    const scriptKey = element.actionKey;
    const versionStamp = element.versionStamp;
    const frontendApi = new FrontendApi();
    runScript({script, versionStamp, scriptKey, actionType, isPreview, resultMap, requestContext, frontendApi});
  }
  return resultMap;
}

export const runPreImportDataAction = async (dataClass, file, columns) => {
  const actionType = "PreImportDataActionFrontEnd";
  const actions = await dataExplorer.getSearchActionPolicies(dataClass, actionType);
  const requestContext = getRequestContext();
  const isPreview = !!dataClass.match(/^UfxPreview.*$/)
  const resultMap = {file, columns}
  for (const element of actions) {
    const script = element.data;
    const scriptKey = element.actionKey;
    const versionStamp = element.versionStamp;
    const frontendApi = new FrontendApi();
    runScript({script, versionStamp, scriptKey, actionType, isPreview, resultMap, requestContext, frontendApi});
  }
  return resultMap;
}